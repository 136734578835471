import { useCallback } from 'react'

import { closeServiceAssignment, openServiceAssignment } from '../../../actions'
import { analytics } from '../../../services/analytics'

type Options = {
	onError: (message: string) => void
	onSuccess: () => void
	pin: string
	serviceAssignmentId: string
}

export const usePausing = ({
	serviceAssignmentId,
	pin,
	onSuccess,
	onError
}: Options) => {
	const pause = useCallback(
		async (timeOption: number) => {
			const pauseForSeconds = timeOption * 60

			// We want to track failures here so analytics is only after the request
			try {
				await closeServiceAssignment(
					serviceAssignmentId,
					pin,
					pauseForSeconds
				)

				analytics.trackEvent('Pause Requested', {
					Service_Assignment_ID: serviceAssignmentId,
					Pause_Duration: pauseForSeconds,
					Status: 'Success'
				})

				onSuccess()
			} catch (error: any) {
				analytics.trackEvent('Pause Requested', {
					Service_Assignment_ID: serviceAssignmentId,
					Pause_Duration: pauseForSeconds,
					Status: 'Rejected'
				})

				const unwrappedError =
					error.response && (await error.response.json())
				const message = unwrappedError?.message
				onError(message)
			}
		},
		[onError, onSuccess, pin, serviceAssignmentId]
	)

	const accept = useCallback(async () => {
		analytics.trackEvent('Accept Requested', {
			Service_Assignment_ID: serviceAssignmentId
		})

		try {
			await openServiceAssignment(serviceAssignmentId)

			onSuccess()
		} catch (error: any) {
			const unwrappedError =
				error.response && (await error.response.json())
			const message = unwrappedError?.message
			onError(message)
		}
	}, [onError, onSuccess, serviceAssignmentId])

	return { pause, accept }
}
