import { useCallback, useMemo, useState } from 'react'

import { partition } from 'lodash'

import { InventoryStatus } from '../../@types/Item'
import { updateItemStatuses } from '../../actions'
import { analytics } from '../../services/analytics'
import { itemRecordToItemStatusList } from '../../utils/item'
import { UseEditableOrderStateOptions } from './types'

export const useEditableOrderState = ({
	itemStatuses,
	serviceAssignmentId,
	onSubmit,
	orderId
}: UseEditableOrderStateOptions) => {
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [isEditing, setIsEditing] = useState(false)
	const [edits, setEdits] = useState<Record<string, InventoryStatus>>({})

	const [lastSubmittedEdits, setLastSubmittedEdits] = useState<
		Record<string, InventoryStatus>
	>({})

	const editedItemStatuses = useMemo(() => {
		return {
			...itemStatuses,
			...edits
		}
	}, [edits, itemStatuses])

	const startEditing = useCallback(() => {
		analytics.trackEvent('Order Editing Started', { Order_ID: orderId })

		setIsEditing(true)
	}, [orderId])

	const updateItemStatus = useCallback(
		({ itemId, status }: { itemId: string; status: InventoryStatus }) => {
			analytics.trackEvent('Item Inventory Status Changed via Order', {
				Item_ID: itemId,
				Order_ID: orderId,
				Inventory_Status: status
			})

			setEdits((state) => ({
				...state,
				[itemId]: status
			}))
		},
		[orderId]
	)

	// Makes it possible to return to "previous state" after cancelling.
	// We can't clean the edits, see comments on submit.
	const cancel = useCallback(() => {
		analytics.trackEvent('Order Editing Cancelled', { Order_ID: orderId })

		setEdits(lastSubmittedEdits)
		setIsEditing(false)
	}, [lastSubmittedEdits, orderId])

	// Please note that due to items being cached, we don't clear the edits on submit.
	// It would be best if we could just trust the state coming from the backend as this can cause all
	// manners of complications, but this is maybe "good enough".
	const submit = useCallback(async () => {
		const items = itemRecordToItemStatusList(edits)

		analytics.trackEvent('Order Editing Submitted', {
			Item_IDs: items.map((item) => item.itemId),
			Service_Assignment_ID: serviceAssignmentId,
			Order_ID: orderId
		})

		// We can't do "dirty" state as we need to persist the edits, so let's just not do anything if there's no edits
		if (items.length === 0) {
			return
		}

		setIsSubmitting(true)

		// This only works if we have two statuses. If we ever toggle between more, revisit this.
		const [inStock, oos] = partition(
			items,
			(item) => item.status === InventoryStatus.IN_STOCK
		)

		try {
			if (inStock.length > 0) {
				await updateItemStatuses({
					itemIds: inStock.map((item) => item.itemId),
					status: InventoryStatus.IN_STOCK,
					serviceAssignmentId
				})
			}

			if (oos.length > 0) {
				await updateItemStatuses({
					itemIds: oos.map((item) => item.itemId),
					status: InventoryStatus.OUT_OF_STOCK,
					serviceAssignmentId
				})
			}

			setLastSubmittedEdits(edits)
			onSubmit()
			setIsEditing(false)
		} catch (error: any) {
			const unwrappedError =
				error.response && (await error.response.json())
			const message = unwrappedError?.message
			alert(message) // this is quite crude, but there's no good space to show the error in the order
		} finally {
			setIsSubmitting(false)
		}
	}, [edits, onSubmit, orderId, serviceAssignmentId])

	return {
		isEditing,
		editedItemStatuses,
		startEditing,
		cancel,
		submit,
		updateItemStatus,
		isSubmitting
	}
}
