import { first } from 'lodash'

import { LoadingArea } from '../../@types/LoadingArea'
import { getSelectedStationIdFromStore } from '../../utils/localStorage'
import { LoadingAreaAndStationSelection } from './types'

export const getLoadingAreaAndStationSelection = (
	loadingAreas: LoadingArea[]
): LoadingAreaAndStationSelection | null => {
	const selectionFromPreviousSession = getSelectionFromStore(loadingAreas)

	if (selectionFromPreviousSession) {
		return selectionFromPreviousSession
	}

	return getSelectionIfSingleLoadingAreaSingleStation(loadingAreas)
}

const getSelectionFromStore = (loadingAreas: LoadingArea[]) => {
	const stationId = getSelectedStationIdFromStore()

	const loadingArea = loadingAreas.find((loadingArea) =>
		loadingArea.stations.some((x) => x._id === stationId)
	)

	if (loadingArea && stationId) {
		return { loadingAreaId: loadingArea._id, stationId }
	}

	return null
}

const getSelectionIfSingleLoadingAreaSingleStation = (
	loadingAreas: LoadingArea[]
) => {
	const loadingArea = loadingAreas.length === 1 ? first(loadingAreas) : null

	const station =
		loadingArea?.stations.length === 1 ? first(loadingArea.stations) : null

	if (loadingArea && station) {
		return { loadingAreaId: loadingArea._id, stationId: station._id }
	}

	return null
}
