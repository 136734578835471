import ky from 'ky'
import UAParser from 'ua-parser-js'
import uuid from 'uuid'

import { container } from './container'

const {
	browser: { name, major }
} = UAParser()
const DEVICE_VERSION = `${name} ${major}`
// example "Chrome 77"

const key = 'requestStatistics'

function getDatapoints() {
	try {
		const datapoints = JSON.parse(localStorage.getItem(key)) || []
		return datapoints.filter(
			({ created }) => created > Date.now() - 15 * 60 * 1000
		)
	} catch (err) {
		return []
	}
}

function setDatapoints(datapoints) {
	localStorage.setItem(key, JSON.stringify(datapoints))
}

export function pushEvent(url, method, duration, response) {
	const success = !!response && response.ok
	const responseTime =
		response && parseInt(response.headers.get('x-response-time'))

	let code
	if (!success) {
		if (response) {
			code = `${response.status}`
		} else {
			code = 'unspecified'
		}
	}

	const datapoint = {
		id: uuid.v1(),
		type: 'request',
		created: Date.now(),
		payload: {
			url,
			request: {
				method,
				duration_total: duration,
				duration_processing: responseTime
			},
			response: {
				success,
				error_code: code
			}
		}
	}

	setDatapoints([...getDatapoints(), datapoint])
}

export function pushError(url, method, error) {
	const offline = window.navigator.onLine === false
	const code = offline ? 'no_internet' : error.name

	const datapoint = {
		id: uuid.v1(),
		type: 'request',
		created: Date.now(),
		payload: {
			url,
			request: {
				method
			},
			response: {
				success: false,
				error_code: code
			}
		}
	}

	setDatapoints([...getDatapoints(), datapoint])
}

async function postEvents() {
	const datapoints = getDatapoints().slice(0, 20)

	if (datapoints.length === 0) {
		return
	}

	const ids = datapoints.map(({ id }) => id)
	const url = '/merchantapi/v1/statistics/report-analytics'

	let deviceExternalToken
	try {
		deviceExternalToken = container.getDeviceToken(true)
	} catch (e) {
		console.error(e)
	}

	const options = {
		headers: {
			'x-csrf-token': window.CSRF_TOKEN
		},
		json: {
			application: 'kitchen',
			app_version: window.BUILD_NUMBER,
			device_name: 'browser',
			device_version: DEVICE_VERSION,
			tz_offset: -new Date().getTimezoneOffset(),
			// pass datapoints array without id property
			// eslint-disable-next-line
			datapoints: datapoints.map(({ id, ...rest }) => ({ ...rest }))
		}
	}

	if (deviceExternalToken) {
		options.headers['device-external-token'] = deviceExternalToken
	}

	try {
		if (process.env.NODE_ENV === 'production') {
			await ky.post(url, options)
		}

		setDatapoints(getDatapoints().filter(({ id }) => !ids.includes(id)))
	} catch (err) {
		if (err.response) {
			err.message = `POST ${url} failed, HTTP status: ${err.response.status}`
		} else {
			err.message = `POST ${url} failed, ${err.name}`
		}

		console.error(err)
	}
}

async function postEventsAndSchedule() {
	await postEvents()
	setTimeout(postEventsAndSchedule, 11000)
}

postEventsAndSchedule()
