import {
	CircularProgress,
	ListItem,
	ListItemText,
	Stack,
	Typography
} from '@mui/material'

import { useAppSelector } from '../../../../../utils/storeHelpers'
import { useVenuesConfigurationQuery } from '../../../../../utils/venuesConfiguration/useVenuesConfigurationQuery'
import { phoneNumbersByCountry } from './constants'

export const MerchantPhoneNumber = () => {
	const serviceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)
	const { data = {}, isLoading } = useVenuesConfigurationQuery(
		serviceAssignmentId ? [serviceAssignmentId] : []
	)

	// Super defensive country selection..
	const country =
		serviceAssignmentId &&
		data[serviceAssignmentId]?.service_assignment?.serviceareas?.[0]
			?.place?.[0]

	if (isLoading) {
		return <CircularProgress size={16} />
	}

	if (!country) {
		return (
			<Stack flexDirection="row" gap={2} width="100%">
				<ListItem>
					<ListItemText primary="(844) 782-7393" secondary="🇺🇸 US" />
				</ListItem>
				<ListItem>
					<ListItemText primary="+44 7588 732673" secondary="🇬🇧 UK" />
				</ListItem>
				<ListItem>
					<ListItemText primary="+37 2880 4012" secondary="🇪🇪 EE" />
				</ListItem>
				<ListItem>
					<ListItemText primary="+358 8000 5723" secondary="🇫🇮 FI" />
				</ListItem>
			</Stack>
		)
	}

	const phoneNumber = phoneNumbersByCountry[country]

	return (
		<Typography fontSize={18} fontWeight={700}>
			{phoneNumber}
		</Typography>
	)
}
