import { FC } from 'react'

import { Box, Divider } from '@mui/material'

import { OrderSmallContent } from '../../containers/Station/Columns/OrdersNew/OrderNew/OrderSmall/OrderSmallContent'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { OrderActions } from '../OrderRevamp/Layout/OrderActions'
import { OrderCard } from '../OrderRevamp/Layout/OrderCard'
import { OrderHeader } from '../OrderRevamp/OrderHeader'
import { useOrderTimer } from '../OrderRevamp/OrderLabel/useOrderTimer'
import { OrderLineItems } from '../OrderRevamp/OrderLineItems'
import { OrderScrollContainer } from '../OrderRevamp/OrderScrollContainer'
import { OrderLargeProps } from './types'

export const OrderLarge: FC<OrderLargeProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderLargeRevampedView {...props} />
	}

	return <OrderLargeView {...props} />
}
const OrderLargeView: FC<OrderLargeProps> = ({
	order,
	actions,
	hideTimer,
	testId,
	renderAlert,
	isScrollable
}) => {
	return (
		<OrderCard
			testId={testId ?? 'OrderLarge'}
			col
			isTestOrder={order.testing}>
			<OrderHeader
				order={order}
				hideTimer={hideTimer}
				renderAlert={renderAlert}
			/>

			<OrderScrollContainer isEnabled={isScrollable}>
				<OrderLineItems order={order} />
			</OrderScrollContainer>

			{actions ? <OrderActions>{actions}</OrderActions> : null}
		</OrderCard>
	)
}

const OrderLargeRevampedView: FC<OrderLargeProps> = ({
	order,
	actions,
	hideTimer,
	testId,
	renderAlert,
	isScrollable,
	contextActions,
	onItemFulfil,
	showOOSFlow
}) => {
	const lateOrderAlert = useOrderTimer(order)

	return (
		<OrderCard
			testId={testId ?? 'OrderLarge'}
			col
			isTestOrder={order.testing}>
			<OrderSmallContent
				order={order}
				onViewClick={() => null}
				action={contextActions}
				renderAlert={renderAlert}
				hideTimer={!!lateOrderAlert}
			/>
			<Divider />
			<OrderScrollContainer isEnabled={isScrollable}>
				<OrderLineItems
					order={order}
					onItemFulfil={onItemFulfil}
					showOOSFlow={showOOSFlow}
				/>
			</OrderScrollContainer>
			{actions ? (
				<Box alignItems="center" justifyContent="center" px={3} py={2}>
					{actions}
				</Box>
			) : null}
		</OrderCard>
	)
}
