import { FC } from 'react'

import cx from 'classnames'

import { Icon } from '../../Icon'
import { OrderLabelProps } from './types'

// TODO: replace with MUI
export const OrderLabel: FC<OrderLabelProps> = ({
	icon,
	iconClassName,
	textClassName,
	label
}) => {
	const iconClassNames = cx('text-black opacity-30', iconClassName)
	const textClassNames = cx('text-grey', textClassName)
	const containerClassNames = cx('mt-[1px]', { 'mr-2': !!icon })

	return (
		<div className="mb-1 flex flex-row text-sm last:mb-0">
			<div className={containerClassNames}>
				{icon ? (
					<Icon className={iconClassNames} icon={icon} width={18} />
				) : null}
			</div>

			<span className={textClassNames}>{label}</span>
		</div>
	)
}
