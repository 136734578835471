import { Box, Divider, Radio, Stack, Typography } from '@mui/material'

import { RequestTypeInputProps } from './types'

export const RequestTypeInput = ({
	title,
	body,
	selected,
	children,
	type,
	disabled
}: RequestTypeInputProps) => {
	return (
		<>
			<Box sx={{ opacity: disabled ? 0.5 : undefined }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center">
					<Box>
						<Typography fontWeight={600}>{title}</Typography>
						<Typography>{body}</Typography>
						{disabled ? (
							<Typography color="error">
								A ticket is already pending for this purpose
							</Typography>
						) : null}
					</Box>

					<Radio value={type} disabled={disabled} />
				</Stack>

				{selected ? (
					<Stack direction="column" gap={{ md: 3 }} mt={2}>
						{children}
					</Stack>
				) : null}
			</Box>
			<Divider sx={{ my: 2 }} />
		</>
	)
}
