import { AlertLabel } from '../../@types/Alert'
import { DeliveryType, Order, StationStatus } from '../../@types/Order'
import { Robot } from '../../@types/Robot'
import { isRobotArrivingSoon, robotArrivingInMinutes } from '../../utils/robot'
import { OrderAlertLabelType } from './types'

export const alertLabelByType: Record<
	OrderAlertLabelType,
	(robot?: Robot) => AlertLabel
> = {
	[OrderAlertLabelType.CUSTOMER_EXPECTED]: () => ({
		text: 'Customer is waiting!'
	}),
	[OrderAlertLabelType.ROBOT_WAITING]: () => ({ text: 'Robot is waiting!' }),
	[OrderAlertLabelType.ROBOT_SOON]: () => ({ text: 'Robot arriving soon!' }),
	[OrderAlertLabelType.ROBOT_LATER]: (robot?: Robot) => {
		const robotArrival = robot ? robotArrivingInMinutes(robot) : null

		if (robotArrival !== null) {
			return {
				text: `Robot arriving (${robotArrival}min)`,
				color: 'orange'
			}
		}

		// Fallback incase we don't have a robot available
		return { text: 'Robot arriving later!', color: 'orange' }
	}
}

export const alertHasSoundByType: Record<OrderAlertLabelType, boolean> = {
	[OrderAlertLabelType.CUSTOMER_EXPECTED]: true,
	[OrderAlertLabelType.ROBOT_WAITING]: true,
	[OrderAlertLabelType.ROBOT_SOON]: true,
	[OrderAlertLabelType.ROBOT_LATER]: false
}

export const getAlertLabelType = (options: { order: Order; robot?: Robot }) => {
	const { order, robot } = options

	if (order.delivery_type === DeliveryType.PICKUP) {
		return OrderAlertLabelType.CUSTOMER_EXPECTED
	}

	if (robot?.arrived) {
		return OrderAlertLabelType.ROBOT_WAITING
	}

	if (robot && isRobotArrivingSoon(robot)) {
		return OrderAlertLabelType.ROBOT_SOON
	}

	return OrderAlertLabelType.ROBOT_LATER
}

export const alertIntervalMsByOrderStationStatus: Record<
	StationStatus,
	number | null
> = {
	[StationStatus.COMPLETED]: null,
	[StationStatus.PREPARING]: 1000 * 120, // 2min
	[StationStatus.CREATED]: 1000 * 30 // 30s
}
