import { memo, useCallback, useState } from 'react'

import { Box } from '@mui/material'

import { AlertType } from '../../../../../@types/Alert'
import { OrderStatusHR } from '../../../../../@types/Order'
import { OrderAlert } from '../../../../../components/OrderAlert'
import { OrderEditable } from '../../../../../components/OrderEditable'
import { Action } from '../../../../../components/OrderRevamp/Action'
import { ActionType } from '../../../../../components/OrderRevamp/Action/types'
import { OrderModal } from '../../../../../components/OrderRevamp/Layout/OrderModal'
import { useVenueFeatureFlags } from '../../../../../hooks/useVenueFeatureFlags'
import { dismissAlert } from '../../../../../reducers/alert'
import { analytics } from '../../../../../services/analytics'
import { makeOrderAlertId } from '../../../../../utils/order'
import { useAppDispatch } from '../../../../../utils/storeHelpers'
import { CancelOrderFlow } from '../../OrdersInProgress/OrderInProgress/CancelOrderFlow'
import { OrderSmall } from './OrderSmall'
import { NewOrderProps } from './types'

const trackStartOrder = (options: {
	location: 'Modal' | 'Board'
	orderId: number
}) => {
	analytics.trackEvent('Start Order Pressed', {
		Location: options.location,
		Order_ID: options.orderId
	})
}

export const OrderNew = memo<NewOrderProps>((props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderNewRevampedView {...props} />
	}

	return <OrderNewView {...props} />
})

export const OrderNewView = memo<NewOrderProps>(
	({ order, onStartPreparationClick }) => {
		const dispatch = useAppDispatch()
		const [isModalOpen, setIsModalOpen] = useState(false)

		const toggleModal = useCallback(() => {
			setIsModalOpen((state) => !state)
		}, [])

		const handlePreviewClick = useCallback(() => {
			analytics.trackEvent('Preview Order Pressed', {
				Order_ID: order.order_id
			})

			dispatch(
				dismissAlert(
					makeOrderAlertId(order, AlertType.ORDER_NEW_REMINDER)
				)
			)
			toggleModal()
		}, [dispatch, order, toggleModal])

		const handleStartOrderClick = useCallback(() => {
			if (order.status_hr === OrderStatusHR.CANCELLED) {
				return
			}

			setIsModalOpen(false)

			onStartPreparationClick(order)
		}, [onStartPreparationClick, order])

		const handleModalStartClicked = useCallback(() => {
			trackStartOrder({ location: 'Modal', orderId: order.order_id })

			handleStartOrderClick()
		}, [handleStartOrderClick, order.order_id])

		const handleCardStartClicked = useCallback(() => {
			trackStartOrder({ location: 'Board', orderId: order.order_id })

			handleStartOrderClick()
		}, [handleStartOrderClick, order.order_id])

		return (
			<>
				<OrderSmall
					order={order}
					onViewClick={handlePreviewClick}
					action={
						<Action
							type={ActionType.PREPARE}
							onClick={handleCardStartClicked}
						/>
					}
					renderAlert={<OrderAlert order={order} />}
				/>

				<OrderModal
					isOpen={isModalOpen}
					onRequestClose={toggleModal}
					width={900}>
					<OrderEditable
						order={order}
						isScrollable
						actions={
							<>
								<Action
									type={ActionType.CLOSE_WINDOW}
									onClick={toggleModal}
								/>
								<Action
									type={ActionType.PREPARE}
									testId="Modal-ActionPrepare"
									onClick={handleModalStartClicked}
								/>
							</>
						}
					/>
				</OrderModal>
			</>
		)
	}
)

export const OrderNewRevampedView = memo<NewOrderProps>(
	({ order, onStartPreparationClick }) => {
		const dispatch = useAppDispatch()
		const { allowOrderCancellation } = useVenueFeatureFlags()
		const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
			useState(false)
		const [isModalOpen, setIsModalOpen] = useState(false)

		const toggleModal = useCallback(() => {
			setIsModalOpen((state) => !state)
		}, [])

		const handlePreviewClick = useCallback(() => {
			analytics.trackEvent('Preview Order Pressed', {
				Order_ID: order.order_id
			})

			dispatch(
				dismissAlert(
					makeOrderAlertId(order, AlertType.ORDER_NEW_REMINDER)
				)
			)
			toggleModal()
		}, [dispatch, order, toggleModal])

		const handleStartOrderClick = useCallback(() => {
			if (order.status_hr === OrderStatusHR.CANCELLED) {
				return
			}

			setIsModalOpen(false)

			onStartPreparationClick(order)
		}, [onStartPreparationClick, order])

		const handleModalStartClicked = useCallback(() => {
			trackStartOrder({ location: 'Modal', orderId: order.order_id })

			handleStartOrderClick()
		}, [handleStartOrderClick, order.order_id])

		const handleCardStartClicked = useCallback(() => {
			trackStartOrder({ location: 'Board', orderId: order.order_id })

			handleStartOrderClick()
		}, [handleStartOrderClick, order.order_id])

		const handleCancelOrder = useCallback(() => {
			if (!allowOrderCancellation) return
			setIsCancellationDialogOpen(true)
		}, [allowOrderCancellation])

		return (
			<>
				<OrderSmall
					order={order}
					onViewClick={handlePreviewClick}
					action={
						<>
							<Action
								type={ActionType.VIEW_CONTENTS}
								onClick={handlePreviewClick}
							/>
							<Action
								type={ActionType.PREPARE}
								onClick={handleCardStartClicked}
							/>
						</>
					}
					renderAlert={<OrderAlert order={order} />}
				/>

				<OrderModal
					isOpen={isModalOpen}
					onRequestClose={toggleModal}
					width="90vw">
					<OrderEditable
						order={order}
						isScrollable
						onClose={() => setIsModalOpen(false)}
						actions={
							<>
								{allowOrderCancellation ? (
									<Box mb={2}>
										<Action
											type={ActionType.CANCEL_ORDER}
											onClick={handleCancelOrder}
											displayStyle="fullWidth"
										/>
									</Box>
								) : null}

								<Action
									type={ActionType.START_PREPARATION}
									testId="Modal-ActionPrepare"
									onClick={handleModalStartClicked}
									displayStyle="fullWidth"
									variant="contained"
								/>
							</>
						}
					/>
				</OrderModal>

				<CancelOrderFlow
					isOpen={isCancellationDialogOpen}
					setIsOpen={setIsCancellationDialogOpen}
					order={order}
					onCancelOrder={() => setIsModalOpen(false)}
				/>
			</>
		)
	}
)
