import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { LoadingArea } from '../@types/LoadingArea'

type State = {
	loadingAreasById: Record<string, LoadingArea>
	selectedLoadingAreaId: string | null
}

const initialState: State = {
	loadingAreasById: {},
	selectedLoadingAreaId: null
}

const loadingAreaSlice = createSlice({
	name: 'loadingAreas',
	initialState,
	reducers: {
		updateLoadingAreasById(
			state,
			action: PayloadAction<Record<string, LoadingArea>>
		) {
			state.loadingAreasById = action.payload
		},
		updateSelectedLoadingArea(state, action: PayloadAction<string | null>) {
			state.selectedLoadingAreaId = action.payload
		}
	}
})

export const { updateLoadingAreasById, updateSelectedLoadingArea } =
	loadingAreaSlice.actions

export default loadingAreaSlice.reducer
