import { FC } from 'react'

import styles from '../../index.module.scss'
import { Props } from './types'

export const PausedBackContent: FC<Props> = ({
	closeModal,
	onUpdate,
	error
}) => {
	return (
		<>
			<div className={styles.header}>
				Confirm backing to normal operating
			</div>
			<div className={styles.content}>
				<b>
					Customers will see standard delivery times in Starship app
					after you pressing Update button.
				</b>
			</div>
			{error && <div className={styles.error}>{error}</div>}
			<div className={styles.footer}>
				<div className={styles.closeButton} onClick={closeModal} />
				<div className={styles.updateButton} onClick={onUpdate} />
			</div>
		</>
	)
}
