import { memo, useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { getOrderSecondsElapsedSinceVisible } from '../../utils/order'

const TIMER_TICK_MS = 1000

export const Time = memo<{ visibleAt: string }>(({ visibleAt }) => {
	const [now, setNow] = useState(dayjs())

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(dayjs())
		}, TIMER_TICK_MS)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const elapsedSecondsSinceVisible = getOrderSecondsElapsedSinceVisible(
		visibleAt,
		now
	)
	const elapsedMins = Math.floor(elapsedSecondsSinceVisible / 60)
	const elapsedSecs = elapsedSecondsSinceVisible % 60
	return <>{`${elapsedMins}min ${elapsedSecs}s`}</>
})
