import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import humanizeDuration from 'humanize-duration'
import ky from 'ky'
import _groupBy from 'lodash/groupBy'

import { fetchServer } from './api/fetchServer'
import { container } from './container'

export const calculateTimelyOrder = (timelyOrders, ordersTotal) => {
	const result = (timelyOrders * 100) / ordersTotal
	if (isNaN(result)) {
		return 0
	}
	return Math.round(result)
}

export const formatCurrencyLocale = (value, currencyLocal) => {
	let localization

	switch (currencyLocal) {
		case 'EUR':
			localization = 'de-DE'
			break
		case 'USD':
			localization = 'en-US'
			break
		case 'GBP':
			localization = 'en-GB'
			break

		default:
			localization = 'en-US'
			break
	}

	const formatter = new Intl.NumberFormat(localization, {
		style: 'currency',
		currency: currencyLocal,
		minimumFractionDigits: 2
	})

	return value !== undefined
		? formatter.format(value / 100)
		: formatter.format(0)
}

export const formatTimeDiff = (firstDate, secondDate) => {
	const timeDiffInMilliseconds = dayjs(secondDate).diff(firstDate)

	const shortCustomTime = humanizeDuration.humanizer({
		language: 'shortEn',
		languages: {
			shortEn: {
				h: () => 'h',
				m: () => 'm',
				s: () => 's'
			}
		}
	})

	const timeDiffFormatted = shortCustomTime(timeDiffInMilliseconds, {
		round: true,
		delimiter: '',
		spacer: '',
		units: ['h', 'm', 's']
	})

	return `+ ${timeDiffFormatted}`
}

export function captureError(error, info) {
	if (error.response) {
		if (![502, 503].includes(error.response.status)) {
			Sentry.captureException(error, { extra: info })
		}
	} else {
		Sentry.captureException(error, { extra: info })
		console.error(error, info)
	}
}

export async function postDebugEvent(actionType, payload) {
	// include env data if found (loadingarea, serviceassignment etc.)
	let env = {}
	if (window.analyticsEnv) {
		env = window.analyticsEnv
	}
	const body = {
		application: 'kitchen',
		app_version: `${container.getVersion()}`,
		device_name: 'web',
		device_version: navigator.userAgent,
		tz_offset: 0,
		datapoints: [
			{
				id: generateDatapointId(),
				type: 'track',
				action: actionType,
				created: Math.ceil(Date.now() / 1000),
				payload: { ...payload, env }
			}
		]
	}

	if (process.env.NODE_ENV === 'development') {
		console.info('Debug event', body) // eslint-disable-line no-console
		return
	}
	const url = '/merchantapi/v1/statistics/report-analytics'

	let deviceExternalToken
	try {
		deviceExternalToken = container.getDeviceToken(true)
	} catch (e) {
		console.error(e)
	}
	const options = {
		json: body,
		headers: {
			'x-csrf-token': window.CSRF_TOKEN
		}
	}
	if (deviceExternalToken) {
		options.headers['device-external-token'] = deviceExternalToken
	}

	try {
		await ky.post(url, options)
	} catch (error) {
		if (error.response) {
			error.message = `POST ${url} failed, HTTP status: ${error.response.status}`
		} else {
			error.message = `POST ${url} failed, ${error.name}`
		}

		console.error(error)
	}
}
export function generateDatapointId() {
	return new Date().valueOf() + Math.ceil(Math.random() * 10000000000)
}
export async function fetchLoadingareaTags() {
	const { partners } = await fetchServer('/v1/loadingareas')
	return getTagsFromPartners(partners)
}
export function getTagsFromPartners(partners) {
	const loadingareas = {}
	partners.map((partner) => {
		partner.serviceassignments.map((serviceassignment) => {
			loadingareas[serviceassignment.loadingarea_id] =
				serviceassignment.loadingarea_tags.join(',')
		})
	})
	return loadingareas
}
export function fetchCurrentLoadingareaTags() {
	return window._currentLoadingareaTags
}
export function setCurrentLoadingareaTags(tags) {
	window._currentLoadingareaTags = tags
}
