import { useCallback, useEffect } from 'react'

import { AlertOrderLate } from '../../@types/Alert'
import { Order } from '../../@types/Order'
import { dismissAlert } from '../../reducers/alert'
import { useAppDispatch } from '../../utils/storeHelpers'

export const useDismissStaleLateOrderAlerts = (options: {
	incompleteOrders: Order[]
	orderLateAlerts: AlertOrderLate[]
}) => {
	const { incompleteOrders, orderLateAlerts } = options

	const dispatch = useAppDispatch()

	const handleDismiss = useCallback(() => {
		const incompleteOrderIds = new Set(
			incompleteOrders.map((order) => order._id)
		)

		orderLateAlerts.forEach((alert) => {
			if (incompleteOrderIds.has(alert.orderId)) {
				return
			}

			dispatch(dismissAlert(alert.id))
		})
	}, [dispatch, incompleteOrders, orderLateAlerts])

	useEffect(() => {
		handleDismiss()
	}, [handleDismiss])
}
