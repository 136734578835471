import groupBy from 'lodash/groupBy'

import { LineItemPackaging } from '../../@types/LineItem'
import {
	LineItemModifier,
	LineItemModifierType
} from '../../@types/LineItemModifier'

export type LineItemNoteParsed = {
	message?: string
	modifiersByType?: Record<string, LineItemModifier[]>
	packaging?: LineItemPackaging
	upchargedModifier?: boolean
}

export const parseNoteValue = (value: string): LineItemNoteParsed => {
	try {
		const { note, modifiers = [], ...rest } = parseJSON(value)

		const filteredModifiers = modifiers
			.filter((modifier) => !isNo(modifier))
			.map(parseModifier)
			.filter(filterModifiers)
		return {
			message: note,
			modifiersByType: groupBy(filteredModifiers, 'type'),
			upchargedModifier:
				'upchargedModifier' in rest && !!rest.upchargedModifier,
			packaging:
				'packaging' in rest
					? (rest.packaging as LineItemPackaging)
					: undefined
		}
	} catch {
		return { message: value }
	}
}

// Yes this is weird
export const parseModifier = (modifier: LineItemModifier): LineItemModifier => {
	return {
		...modifier,
		type: isToggle(modifier) ? 'Other' : modifier.type,
		value: isToggle(modifier) ? modifier.type : modifier.value
	}
}

const parseJSON = (
	value: string
): {
	modifiers: LineItemModifier[]
	note: string
} => {
	return JSON.parse(value)
}

const isToggle = (modifier: LineItemModifier) => {
	return LineItemModifierType.TOGGLE === modifier.modifierType
}

const isYes = (modifier: LineItemModifier) => {
	return isToggle(modifier) && modifier.value === 'Yes'
}

const isNo = (modifier: LineItemModifier) => {
	return isToggle(modifier) && modifier.value === 'No'
}

const isAddon = (modifier: LineItemModifier) => {
	return LineItemModifierType.ADDIN_CHECKBOX === modifier.modifierType
}

const filterModifiers = (modifier: LineItemModifier) => {
	const isRemovedModifier =
		modifier.default &&
		modifier.qty === 0 &&
		(isYes(modifier) || isAddon(modifier))

	return isRemovedModifier || modifier.qty > 0
}
