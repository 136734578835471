import { FC, useCallback, useMemo } from 'react'

import { container } from '../../container'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { ShowVideoButton } from './ShowVideoButton'
import { initialiseTakeTheTour } from './utils/initialiseTakeTheTour'
import { skipTheTour } from './utils/skipTheTour'

export const Welcome: FC = () => {
	const onTakeTourClick = useCallback(() => {
		initialiseTakeTheTour()
		window.location.reload()
	}, [])

	const onSkipForNowClick = useCallback(() => {
		skipTheTour()
		window.location.reload()
	}, [])

	const macAddress = useMemo(() => {
		return container.getWifiMac()
	}, [])

	return (
		<div className="w-full h-full welcome grid place-items-center items-end">
			<div className="flex flex-col w-[50%] h-[calc(50%+1.5rem)] text-center min-w-[240px]">
				<div className="text-[1.5rem] font-bold pb-2">
					Welcome to Kitchen App!
				</div>
				<div className="text-[1rem]">
					It looks like you're new here!
				</div>
				<div className="text-[1rem] mb-10">
					How about some training materials?
				</div>
				<div className="w-[240px] mx-auto pb-2">
					<ShowVideoButton
						url="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_leadership_training.mp4"
						icon={<Icon icon="film" />}
						buttonCaption="Watch intro video"
						videoTitle="How Starship tools work"
					/>
				</div>
				<div className="w-[240px] mx-auto">
					<Button
						caption="Take the tour"
						isLoading={false}
						onClick={onTakeTourClick}
						isSmallText={true}
						icon={<Icon icon="car" />}
						type="default"
					/>
				</div>
				<div className="flex-1">
					<div className="h-[50%]">
						<div className="text-grey-mid pt-10">
							{macAddress && <>Mac Address: {macAddress}</>}
						</div>
					</div>
					<div className="h-[50%] pt-3 w-fit mx-auto">
						<div
							className="text-purple-cornflowerSmall cursor-pointer"
							onClick={onSkipForNowClick}>
							Skip for now
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
