import { OpenStatusMap } from '../../@types/OpenStatusMap'

export enum PageState {
	ACCEPTING_INITIAL = 'ACCEPTING_INITIAL',
	ACCEPTING_PIN_CODE = 'ACCEPTING_PIN_CODE',
	ACCEPTING_SELECT_TIME = 'ACCEPTING_SELECT_TIME',
	PAUSED_BACK_TO_NORMAL = 'PAUSED_BACK_TO_NORMAL',
	PAUSED_INITIAL = 'PAUSED_INITIAL'
}

export type Status = {
	op_status: OpenStatusMap
	paused_until?: number
}

export type VenueStatusProps = {
	closeModal: () => void
	isModalOpen?: boolean
}

export const CHECK_STATUS_TIMEOUT_MS = 30000
