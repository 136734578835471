import React, { memo, useMemo, useRef } from 'react'
import { ViewportList } from 'react-viewport-list'

import { useMediaQuery } from '@mui/material'

import cx from 'classnames'

import { OrderListProps } from './types'

export const OrderList = memo<OrderListProps>(
	({ renderOrder, data, renderSectionHeader, horizontalWhenSmall }) => {
		// Imported from MUI for now, but we can get this from somewhere else if/when we remove MUI
		const isLarge = useMediaQuery('(min-width: 1024px)')

		const wrapperClasses = cx(
			'flex flex-1 overflow-auto bg-grey-veryLight p-5',
			{
				'flex-row lg:flex-col': horizontalWhenSmall,
				'flex-col': !horizontalWhenSmall
			}
		)

		// Needs some size, otherwise windowing will fail
		const headerWrapperClasses = cx({
			'relative w-[1px] lg:w-full': horizontalWhenSmall
		})

		// When horizontal, header needs to be positioned above the first
		// order card. To achieve this, we do a little css trickery and position
		// the header absolutely within a 1px wrapper. The end result is that
		// the header is plopped out of the normal flow of list items
		const headerClasses = cx({
			'absolute w-[250px] lg:relative lg:w-full': horizontalWhenSmall
		})

		const orderClasses = cx('mb-4 last:mb-6', {
			'mr-4 mt-9 lg:mt-0 last:mr-6 lg:mr-0 lg:last:mr-0':
				horizontalWhenSmall
		})

		// We need to treat headers and orders as equal to be able to window the content
		const listItems = useMemo(() => {
			return data
				.filter((section) => section.orders.length > 0)
				.flatMap((section) => [section.header, ...section.orders])
		}, [data])

		const reference = useRef<HTMLDivElement | null>(null)

		return (
			<div className={wrapperClasses} ref={reference}>
				<ViewportList
					viewportRef={reference}
					items={listItems}
					axis={horizontalWhenSmall && !isLarge ? 'x' : 'y'}
					overscan={7}>
					{(item) => {
						if ('text' in item) {
							return (
								<div
									key={item.text}
									className={headerWrapperClasses}>
									<div className={headerClasses}>
										{renderSectionHeader
											? renderSectionHeader(item)
											: null}
									</div>
								</div>
							)
						}

						return (
							<div key={item._id} className={orderClasses}>
								{renderOrder(item)}
							</div>
						)
					}}
				</ViewportList>
			</div>
		)
	}
)
