import { useCallback, useState } from 'react'

import { Typography } from '@mui/material'

import cx from 'classnames'

import { HowToLoadOrderWithoutPhoneDialog } from '../HowToLoadOrderWithoutPhoneDialog'
import { Icon } from '../Icon'

export const HowLoadOrderWithoutPhoneCard = ({
	className,
	iconOnlyWhenSmallScreen
}: {
	className?: string
	iconOnlyWhenSmallScreen?: boolean
}) => {
	const [isModalOpen, setModalOpen] = useState(false)

	const toggleModal = useCallback(() => {
		setModalOpen((state) => !state)
	}, [])

	const rootClassNames = cx(
		'bg-purple active:bg-purple/80 text-white flex items-center rounded-2xl shadow-md px-4 py-3 justify-between',
		{ 'px-1 py-1 lg:px-4 lg:py-3': iconOnlyWhenSmallScreen },
		className
	)

	const textClassNames = cx({
		'hidden lg:block': iconOnlyWhenSmallScreen
	})

	const iconClassNames = cx('w-10', {
		'ml-0 lg:ml-2': iconOnlyWhenSmallScreen
	})

	return (
		<>
			<div className={rootClassNames} onClick={toggleModal}>
				<div className={textClassNames}>
					<Typography variant="caption" display="block">
						How to load robots without phone
					</Typography>
				</div>
				<Icon icon="helpOutline" className={iconClassNames} />
			</div>

			<HowToLoadOrderWithoutPhoneDialog
				isOpen={isModalOpen}
				onClose={toggleModal}
			/>
		</>
	)
}
