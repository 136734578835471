import { memo } from 'react'

import { Box, Typography } from '@mui/material'

import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { ModifierProps } from './types'

export const Modifier = memo<ModifierProps>((props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <ModifierRevampedView {...props} />
	}

	return <ModifierView {...props} />
})

export const ModifierView = memo<ModifierProps>(({ type, modifiers }) => {
	return (
		<div className="mb-6 grid w-full grid-cols-12 first:mt-5 last:mb-0">
			<div className="col-span-2 mt-[-2px] pr-1">
				<span className="text-grey">{type}</span>
			</div>

			<div className="col-span-10">
				{modifiers.map((modifier) => (
					<div
						key={modifier.modifier_id + modifier.value_id}
						className="mb-4 flex flex-row last:mb-0">
						<span className="text-lg leading-none text-black-body">
							{modifier.value}
						</span>
					</div>
				))}
			</div>
		</div>
	)
})

export const ModifierRevampedView = memo<ModifierProps>(
	({ type, modifiers }) => {
		return (
			<Box>
				{modifiers.map((modifier) => (
					<Box key={modifier.modifier_id + modifier.value_id}>
						<Typography variant="body1" color="secondary.dark">
							<Typography display="inline" mr={1}>
								•
							</Typography>
							<Typography display="inline" fontWeight="500">
								{type}
							</Typography>
							<Typography display="inline" fontWeight="500">
								{' - '}
							</Typography>
							<Typography display="inline" fontWeight="500">
								{modifier.value}
							</Typography>
						</Typography>
					</Box>
				))}
			</Box>
		)
	}
)
