import { useEffect, useMemo } from 'react'

import { AlertType } from '../../@types/Alert'
import { Order, StationStatus } from '../../@types/Order'
import { dismissAlert } from '../../reducers/alert'
import { useAppDispatch, useAppSelector } from '../../utils/storeHelpers'

// Dismiss reminders that:
// - are not for new orders anymore
// - remind about an order that has another alert
export const useDismissStaleNewOrderReminderAlerts = (orders: Order[]) => {
	const dispatch = useAppDispatch()
	const alerts = useAppSelector((state) => state.alert.alerts)

	const newOrders = useMemo(() => {
		return orders.filter(
			(order) => order.station_status_hr === StationStatus.CREATED
		)
	}, [orders])

	const reminders = useMemo(() => {
		return Object.values(alerts).filter(
			(alert) => alert.type === AlertType.ORDER_NEW_REMINDER
		)
	}, [alerts])

	const otherAlerts = useMemo(() => {
		return Object.values(alerts).filter(
			(alert) => alert.type !== AlertType.ORDER_NEW_REMINDER
		)
	}, [alerts])

	useEffect(() => {
		const newOrderIds = new Set(newOrders.map((order) => order._id))

		const dismissableReminders = reminders.filter((alert) => {
			const newOrderExists = newOrderIds.has(alert.orderId)
			const otherAlertExists = !!otherAlerts.some(
				(otherAlert) => otherAlert.orderId === alert.orderId
			)
			return !newOrderExists || otherAlertExists
		})

		dismissableReminders.forEach((alert) => {
			dispatch(dismissAlert(alert.id))
		})
	}, [dispatch, newOrders, otherAlerts, reminders])
}
