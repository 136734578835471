import { LoadingArea, LoadingStation } from '../../@types/LoadingArea'
import EscEncoder from '../../escencoder'
import { getPrintSettings } from './utils'

export function generateTestReceipt(
	loadingarea: LoadingArea,
	loadingstation: LoadingStation
) {
	const settings = getPrintSettings()

	const result = new EscEncoder()
		.initialize()
		.fontSize(settings.fontSize.lg)
		.bold(true)
		.qrcode({
			value: `Starship Kitchen App build ${window.BUILD_NUMBER}`,
			size: 'large'
		})
		.newline(2)
		.line('Starship Kitchen app')
		.fontSize(settings.fontSize.md)
		.newline()
		.align('left')
		.bold(false)
		.line(`Build number: ${window.BUILD_NUMBER}`)
		.line(loadingarea.name)
		.line(loadingstation.name)

	if (loadingarea.tags && loadingarea.tags.length > 0) {
		result.newline().fontSize(settings.fontSize.sm)

		for (const tag of loadingarea.tags) {
			result.line(' ' + tag)
		}
	}

	// Font size example
	result
		.fontSize(settings.fontSize.sm)
		.line('Text sizes')
		.fontSize(settings.fontSize.sm)
		.line('Small')
		.fontSize(settings.fontSize.md)
		.line('Medium')
		.fontSize(settings.fontSize.lg)
		.line('Large')
		.fontSize(settings.fontSize.xl)
		.line('X-Large')

	return result.cut().encode()
}
