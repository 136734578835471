import React from 'react'

import PropTypes from 'prop-types'

import { OrderHistoryItem, OrderHistoryListHeader } from '../components'
import './styles.scss'

export const OrderHistoryList = ({ orders, currency }) => {
	return (
		<div className={'order-history-container'}>
			<OrderHistoryListHeader />
			<div className={'order-history-list-container'}>
				{orders.map(
					({
						orderId,
						received,
						started,
						completed,
						loaded,
						itemCount,
						lineItems,
						payment,
						type,
						price,
						orderStatus
					}) => (
						<OrderHistoryItem
							key={orderId}
							orderId={orderId}
							received={received}
							started={started}
							completed={completed}
							loaded={loaded}
							itemCount={itemCount || lineItems}
							paymentMethod={payment}
							type={type}
							price={price}
							currency={currency}
							orderStatus={orderStatus}
						/>
					)
				)}
			</div>
		</div>
	)
}

OrderHistoryList.propTypes = {
	orders: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired
}
