import { memo, useCallback, useMemo, useState } from 'react'

import { Box } from '@mui/material'

import { InventoryStatus } from '../../../../../@types/Item'
import {
	DeliveryType,
	OrderStatusHR,
	StationStatus
} from '../../../../../@types/Order'
import { updateItemStatuses } from '../../../../../actions'
import { OrderAlert } from '../../../../../components/OrderAlert'
import { OrderCancelled } from '../../../../../components/OrderCancelled'
import { OrderEditable } from '../../../../../components/OrderEditable'
import { OrderLarge } from '../../../../../components/OrderLarge'
import { Action } from '../../../../../components/OrderRevamp/Action'
import { ActionsCollection } from '../../../../../components/OrderRevamp/Action/ActionCollection'
import { ActionType } from '../../../../../components/OrderRevamp/Action/types'
import { OrderModal } from '../../../../../components/OrderRevamp/Layout/OrderModal'
import { useVenue } from '../../../../../hooks/useVenue'
import { useVenueFeatureFlags } from '../../../../../hooks/useVenueFeatureFlags'
import { getOrderFulfilment } from '../../../../../selectors'
import { analytics } from '../../../../../services/analytics'
import { store } from '../../../../../store'
import { CancelOrderFlow } from './CancelOrderFlow'
import { CollectOrderDialog } from './CollectOrderDialog'
import { OutOfStockItemDialog } from './OutOfStockItemDialog'
import { OrderInProgressProps } from './types'
import { useOrderPacking } from './useOrderPacking'

export const OrderInProgress = memo<OrderInProgressProps>((props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderInProgressRevampedView {...props} />
	}

	return <OrderInProgressView {...props} />
})

const OrderInProgressView = memo<OrderInProgressProps>(
	({ order, onMarkReady, onPrint }) => {
		const [isModalOpen, setModalOpen] = useState(false)

		const handleMarkReady = useCallback(() => {
			onMarkReady(order)
		}, [onMarkReady, order])

		const handlePrint = useCallback(() => {
			analytics.trackEvent('Print Pressed', {
				Location: 'Board',
				Order_ID: order.order_id
			})

			onPrint(order)
		}, [onPrint, order])

		const toggleModal = useCallback(() => {
			setModalOpen((state) => !state)
		}, [])

		if (order.status_hr === OrderStatusHR.CANCELLED) {
			return <OrderCancelled order={order} />
		}

		return (
			<>
				<OrderLarge
					order={order}
					actions={
						<>
							<Action
								type={ActionType.PRINT_AGAIN}
								testId="ActionPrintAgain"
								className="border-r-[1px] border-r-grey-light"
								onClick={handlePrint}
							/>
							<Action
								type={ActionType.EDIT_CONTENTS}
								testId="ActionEditContents"
								onClick={toggleModal}
							/>
							<Action
								type={ActionType.MARK_READY}
								testId="ActionMarkReady"
								onClick={handleMarkReady}
							/>
						</>
					}
					renderAlert={<OrderAlert order={order} />}
				/>

				<OrderModal isOpen={isModalOpen} onRequestClose={toggleModal}>
					<OrderEditable
						order={order}
						isScrollable
						actions={
							<>
								<Action
									type={ActionType.CLOSE_WINDOW}
									testId="ActionCloseWindow"
									onClick={toggleModal}
								/>
							</>
						}
					/>
				</OrderModal>
			</>
		)
	}
)

const OrderInProgressRevampedView = memo<OrderInProgressProps>(
	({ order, onMarkReady, onPrint, onItemFulfil, isFulfilling }) => {
		const { venue } = useVenue()
		const { allowOrderCancellation } = useVenueFeatureFlags()
		const { unfulfilledItems, fulfilledItems } = useOrderPacking(
			order.order_id
		)

		const [isModalOpen, setModalOpen] = useState(false)
		const [isCancellationDialogOpen, setIsCancellationDialogOpen] =
			useState(false)
		const [isOrderReadyDialogOpen, setIsOrderReadyDialogOpen] =
			useState(false)

		const [OOSItem, setOOSItem] = useState<{
			itemId: string
			itemName: string
		} | null>(null)

		const handleMarkReady = useCallback(async () => {
			setIsOrderReadyDialogOpen(true)
		}, [])

		const handleConfirmOrderReady = useCallback(async () => {
			setIsOrderReadyDialogOpen(false)
			setModalOpen(false)

			try {
				await onItemFulfil?.(
					order.order_id,
					getOrderFulfilment(order.order_id)(store.getState())
				)
			} catch {
				return
			}

			onMarkReady(order)
		}, [onItemFulfil, onMarkReady, order])

		const handlePrint = useCallback(() => {
			analytics.trackEvent('Print Pressed', {
				Location: 'Board',
				Order_ID: order.order_id
			})

			onPrint(order)
		}, [onPrint, order])

		const toggleModal = useCallback(() => {
			setModalOpen((state) => !state)
		}, [])

		const handleCancelOrder = useCallback(() => {
			setIsCancellationDialogOpen(true)
		}, [])

		const handleMarkOOS = useCallback(() => {
			if (!OOSItem) return
			if (!venue) return

			setOOSItem(null)

			updateItemStatuses({
				itemIds: [OOSItem.itemId],
				status: InventoryStatus.OUT_OF_STOCK,
				serviceAssignmentId: venue.service_assignment._id
			})
		}, [OOSItem, venue])

		const handleSetOOSItem = useCallback(
			(itemId: string, itemName: string) => {
				setOOSItem({ itemId, itemName })
			},
			[]
		)

		const finalizeActionButton = useMemo(() => {
			if (fulfilledItems.length === 0) {
				return (
					<Action
						type={ActionType.CANCEL_ORDER}
						onClick={handleCancelOrder}
						displayStyle="fullWidth"
						isLoading={isFulfilling}
					/>
				)
			}

			return (
				<Action
					type={ActionType.FINALIZE_FOR_SHIPPING}
					testId="ActionMarkReady"
					onClick={handleMarkReady}
					displayStyle="fullWidth"
					variant="contained"
					isLoading={isFulfilling}
				/>
			)
		}, [
			fulfilledItems.length,
			handleCancelOrder,
			handleMarkReady,
			isFulfilling
		])

		if (order.status_hr === OrderStatusHR.CANCELLED) {
			return <OrderCancelled order={order} />
		}

		return (
			<>
				<OrderLarge
					order={order}
					onItemFulfil={onItemFulfil}
					showOOSFlow={handleSetOOSItem}
					contextActions={
						<>
							<Action
								type={ActionType.VIEW_CONTENTS}
								onClick={toggleModal}
							/>
							<ActionsCollection
								actions={[
									{
										type: ActionType.VIEW_CONTENTS,
										onClick: toggleModal
									},
									{
										type: ActionType.PRINT_AGAIN,
										onClick: handlePrint
									},
									...(fulfilledItems.length > 0
										? [
												{
													type: ActionType.FINALIZE_FOR_SHIPPING,
													onClick: handleMarkReady,
													isLoading: isFulfilling
												}
										  ]
										: []),
									...(allowOrderCancellation
										? [
												{
													type: ActionType.CANCEL_ORDER,
													onClick: handleCancelOrder,
													isLoading: isFulfilling
												}
										  ]
										: [])
								]}
							/>
						</>
					}
					actions={finalizeActionButton}
					renderAlert={<OrderAlert order={order} />}
				/>

				<OrderModal
					isOpen={isModalOpen}
					onRequestClose={toggleModal}
					width="90vw">
					<OrderEditable
						order={order}
						isScrollable
						onClose={() => setModalOpen(false)}
						onItemFulfil={onItemFulfil}
						showOOSFlow={handleSetOOSItem}
						orderStatus={StationStatus.PREPARING}
						actions={
							<>
								<Box mb={2}>
									<Action
										type={ActionType.REPRINT_ORDER}
										onClick={handlePrint}
										displayStyle="fullWidth"
									/>
								</Box>

								{allowOrderCancellation ||
								fulfilledItems.length === 0 ? (
									<Box mb={2}>
										<Action
											type={ActionType.CANCEL_ORDER}
											onClick={handleCancelOrder}
											displayStyle="fullWidth"
											isLoading={isFulfilling}
										/>
									</Box>
								) : null}

								{fulfilledItems.length > 0 ? (
									<Action
										type={ActionType.FINALIZE_FOR_SHIPPING}
										onClick={handleMarkReady}
										displayStyle="fullWidth"
										variant="contained"
										isLoading={isFulfilling}
									/>
								) : null}
							</>
						}
					/>
				</OrderModal>

				<CancelOrderFlow
					isOpen={isCancellationDialogOpen}
					setIsOpen={setIsCancellationDialogOpen}
					order={order}
					onCancelOrder={() => setModalOpen(false)}
				/>

				{OOSItem && (
					<OutOfStockItemDialog
						itemName={OOSItem.itemName}
						onMarkOutOfStockClick={handleMarkOOS}
						onCancelClick={() => setOOSItem(null)}
					/>
				)}

				{isOrderReadyDialogOpen && (
					<CollectOrderDialog
						fulfilledItems={fulfilledItems.map(
							(item) => `${item.name} x${item.qty}`
						)}
						unfulfilledItems={unfulfilledItems.map(
							(item) => `${item.name} x${item.qty}`
						)}
						onEditOrderClick={() =>
							setIsOrderReadyDialogOpen(false)
						}
						onDoneClick={handleConfirmOrderReady}
						isRobotDelivery={
							order.delivery_type === DeliveryType.TO_CUSTOMER
						}
					/>
				)}
			</>
		)
	}
)
