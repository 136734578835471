import { memo, useCallback, useEffect, useRef } from 'react'

import { fetchStationOrders } from '../../actions'
import { useAppDispatch } from '../../utils/storeHelpers'

const DEFAULT_INTERVAL_MS = 1000 * 10 // 10s

export const OrderPoller = memo<{
	loadingAreaId: string
	loadingStationId: string
}>(({ loadingAreaId, loadingStationId }) => {
	const dispatch = useAppDispatch()
	const timeout = useRef<NodeJS.Timeout>()

	const fetchOrdersAndReschedule = useCallback(() => {
		try {
			dispatch(fetchStationOrders(loadingAreaId, loadingStationId))
		} catch {
			// noop
		} finally {
			// Rechedule even if fetch fails
			timeout.current = setTimeout(() => {
				fetchOrdersAndReschedule()
			}, DEFAULT_INTERVAL_MS)
		}
	}, [dispatch, loadingAreaId, loadingStationId])

	useEffect(() => {
		fetchOrdersAndReschedule()

		return () => {
			clearTimeout(timeout.current)
		}
	}, [fetchOrdersAndReschedule])

	return null
})
