import { LineItem } from './LineItem'

export enum OrderStatusHR {
	AUTHORIZED = 'AUTHORIZED',
	CANCELLED = 'CANCELLED',
	CREATED = 'CREATED',
	PICKING_COMPLETED = 'PICKING_COMPLETED',
	PICKING_STARTED = 'PICKING_STARTED',
	READY = 'READY',
	UNCONFIRMED = 'UNCONFIRMED'
}

export enum StationStatus {
	COMPLETED = 'COMPLETED',
	CREATED = 'CREATED',
	PREPARING = 'PREPARING'
}

export enum DeliveryType {
	PICKUP = 'PICKUP',
	TO_CUSTOMER = 'TO_CUSTOMER'
}

export type Order = {
	_id: number
	delivery_type?: DeliveryType
	lineitems: LineItem[]
	loadingareaId: string
	loadingstationId: string
	name_hr: string
	order_id: number
	payment_provider_name: string
	picked_up?: boolean
	preparation_time_elapsed?: number
	preparation_time_needed?: number
	preparation_time_remaining?: number
	seconds_since_mark_start?: number
	serviceassignment_id: string
	station_status_hr: StationStatus
	status_hr: OrderStatusHR
	tax?: number
	testing?: boolean
	visible_at: string
	kiosk_order_id: string | null
}
