import { memo } from 'react'

import classNames from 'classnames'

import { Icon } from '../Icon'
import styles from './MenuItem.module.scss'
import { MenuItemProps } from './types'

export const MenuItem = memo<MenuItemProps>(
	({
		isSelected,
		onClick,
		label,
		value,
		customIcon: CustomIcon,
		dataTestId
	}) => {
		const classes = classNames(styles.root, {
			[styles.active]: isSelected
		})

		return (
			<div data-testid={dataTestId} className={classes} onClick={onClick}>
				<div>{label}</div>

				<div style={{ flex: 1 }} />

				<div>{value}</div>

				{CustomIcon ?? (
					<Icon
						icon="chevron"
						style={{
							marginRight: -10,
							transform: 'rotate(90deg) scale(0.65)'
						}}
					/>
				)}
			</div>
		)
	}
)
