import { useMutation, useQuery } from '@tanstack/react-query'

import {
	PreflightCheck,
	PreflightCheckSettings,
	PreflightCheckValue
} from '../@types/PreflightCheck'
import { container } from '../container'

const getPreflightCheckSettings = (serviceAssignmentId: string) => {
	const deviceExternalToken = container.getDeviceToken(true)

	return fetch(
		`${window.location.origin}/merchant-operations-api/api/v2/preflight-check-settings/${serviceAssignmentId}`,
		{
			method: 'GET',
			headers: {
				'device-external-token': deviceExternalToken,
				'x-csrf-token': window.CSRF_TOKEN
			}
		}
	)
		.then((response) => response.json())
		.catch((error) => {
			// Do nothing, if settings are missing, the venue doesn't need preflight checks shown
			return error
		})
}

const getPreflightCheck = (serviceAssignmentId: string) => {
	const deviceExternalToken = container.getDeviceToken(true)

	return fetch(
		`${window.location.origin}/merchant-operations-api/api/v2/preflight-checks/${serviceAssignmentId}`,
		{
			method: 'GET',
			headers: {
				'device-external-token': deviceExternalToken,
				'x-csrf-token': window.CSRF_TOKEN
			}
		}
	)
		.then((response) => response.json())
		.catch((error) => {
			// Do nothing, if settings are missing, the venue doesn't need preflight checks shown
			return error
		})
}

const createPreflightCheck = (
	serviceAssignmentId: string,
	data: PreflightCheckValue[]
) => {
	const deviceExternalToken = container.getDeviceToken(true)

	return fetch(
		`${window.location.origin}/merchant-operations-api/api/v2/preflight-checks/${serviceAssignmentId}`,
		{
			method: 'PUT',
			body: JSON.stringify({ values: data }),
			headers: {
				'content-type': 'application/json',
				'device-external-token': deviceExternalToken,
				'x-csrf-token': window.CSRF_TOKEN
			}
		}
	)
		.then((response) => response.json())
		.catch((error) => {
			// Do nothing, if settings are missing, the venue doesn't need preflight checks shown
			return error
		})
}

export const usePreflightChecks = (
	serviceAssignmentId: string,
	options: { refetchInterval?: number }
) => {
	return useQuery<string[], any, PreflightCheckSettings, any>({
		queryKey: ['preflightChecks', serviceAssignmentId],
		queryFn: () =>
			Promise.all([
				getPreflightCheckSettings(serviceAssignmentId),
				getPreflightCheck(serviceAssignmentId)
			]),
		select: ([settings, latestCheck]: [
			PreflightCheckSettings,
			PreflightCheck & { statusCode: number }
		]): PreflightCheckSettings | undefined => {
			if (latestCheck && latestCheck.statusCode !== 404) {
				// E.g. 403 shouldn't trigger a preflight check, no matter what settings say
				return undefined
			}
			if (!latestCheck || !latestCheck.values) {
				return settings
			}
			return {
				...settings,
				completed: true,
				fields: settings.fields.map((settingField) => ({
					...settingField,
					value: latestCheck.values.find(
						(check) => check.field === settingField.field
					)?.value
				}))
			}
		},
		retry: false,
		refetchInterval: options.refetchInterval
	})
}

export const useCreatePreflightCheck = (serviceAssignmentId: string) => {
	return useMutation<PreflightCheck, any, PreflightCheckValue[]>({
		mutationKey: ['preflightChecks', serviceAssignmentId],
		mutationFn: (data: PreflightCheckValue[]) =>
			createPreflightCheck(serviceAssignmentId, data)
	} as any)
}
