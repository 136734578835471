import { FC } from 'react'

import { OrderActionsProps } from './types'

export const OrderActions: FC<OrderActionsProps> = ({ children }) => {
	return (
		<div
			data-testid="OrderActions"
			className="w-full border-t border-solid border-t-grey-light">
			<div className="flex h-[86px] flex-1 flex-row">{children}</div>
		</div>
	)
}
