import { OrderLabel } from '.'
import { Order } from '../../../@types/Order'
import { orderHasReusePassEnabled } from '../../../utils/order'

type Props = {
	order: Order
}

export const OrderLabelReusePass = ({ order }: Props) => {
	const hasReusePassEnabled = orderHasReusePassEnabled(order)

	if (!hasReusePassEnabled) {
		return null
	}

	return <OrderLabel label="ReusePass" />
}
