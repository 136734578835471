import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react'

import { Button } from '../../Button'
import { Icon } from '../../Icon'
import { usePlaceTestOrder } from '../usePlaceTestOrder'
import { Props } from './types'

export const PlaceTestOrder: FC<Props> = ({
	closeModal,
	serviceAssignmentId,
	loadingStationId
}) => {
	const [createPickupOrder, setCreatePickupOrder] = useState(true)
	const {
		mutate,
		isLoading: isPlacingOrder,
		isError,
		isSuccess
	} = usePlaceTestOrder()

	const onPlace = useCallback(() => {
		mutate({
			serviceAssignmentId,
			loadingStationId,
			createPickupOrder
		})
	}, [mutate, serviceAssignmentId, loadingStationId, createPickupOrder])

	useEffect(() => {
		if (isSuccess) {
			closeModal()
		}
	}, [closeModal, isSuccess])

	return (
		<div className={isPlacingOrder ? 'pointer-events-none opacity-50' : ''}>
			<div className="px-8 py-6">
				<div className="mb-2 flex flex-wrap justify-between">
					<div className="text-xl font-bold">Create test order</div>
					<div onClick={closeModal} className="cursor-pointer">
						<Icon icon="close" />
					</div>
				</div>
				<div className="text-grey-dark">
					A test order allows you to learn and practice safely how to
					process Starship orders.
				</div>
			</div>
			<hr className="m-0 h-px text-grey-light" />
			<div className="px-8 py-6">
				<div className="mb-6 text-grey-dark">Select order type</div>
				<div
					onChange={(event: FormEvent<HTMLInputElement>) => {
						setCreatePickupOrder(
							(event.target as HTMLInputElement).value ===
								'pickup'
						)
					}}
					className="grid w-full grid-cols-[80%_20%] items-start gap-y-4">
					<label htmlFor="pickup">
						<div>Customer pickup</div>
						<div className="text-sm text-grey">
							Kitchen App only
						</div>
					</label>
					<input
						type="radio"
						id="pickup"
						value="pickup"
						name="order-type"
						className="scale-150 place-self-center"
						defaultChecked={true}
					/>

					<label htmlFor="delivery">
						<div>Robot delivery</div>
						<div className="text-sm text-grey">
							Requires Runner device & app
						</div>
					</label>
					<input
						type="radio"
						id="delivery"
						value="delivery"
						name="order-type"
						className="scale-150 place-self-center"
					/>
				</div>
				{isError && (
					<div className="py-4 text-red">
						Unable to place order, please check if no active test
						orders exist for this venue
					</div>
				)}
			</div>
			<hr className="m-0 h-px text-grey-light" />
			<div className="px-6 py-6">
				<div className="w-full">
					<Button
						type="default"
						caption="Start"
						onClick={onPlace}
						isLoading={isPlacingOrder}
					/>
				</div>
			</div>
		</div>
	)
}
