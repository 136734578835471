import {
	setMenuTour,
	setShowWelcomePage,
	setTakeTheTour
} from '../../../utils/localStorage'

export const skipTheTour = () => {
	setShowWelcomePage(false)
	setTakeTheTour(false)
	setMenuTour(false)
}
