import dayjs from 'dayjs'
import { partition } from 'lodash'

import { Robot, RobotArrivals } from '../../@types/Robot'

export const ROBOT_ARRIVING_SOON_THRESHOLD_MINS = 5

export const robotArrivingInMinutes = (robot: Robot) => {
	const robotEta = robot?.etaBotLoadingArrival?.mid

	return robotEta ? dayjs(robotEta).diff(dayjs(), 'minute') : null
}

export const isRobotArrivingSoon = (robot: Robot) => {
	if (robot.arrived) {
		return true
	}

	const robotArrival = robotArrivingInMinutes(robot)

	return robotArrival === null
		? false
		: robotArrival <= ROBOT_ARRIVING_SOON_THRESHOLD_MINS
}

export const mapRobotArrivals = (robots: Robot[]): RobotArrivals => {
	const [availableRobots, arrivingRobots] = partition(
		robots,
		(robot) => robot.arrived
	)

	return {
		arrivingEtasSeconds: arrivingRobots
			.map((robot) => robot.etaBotLoadingArrival)
			.filter((arrivalEta): arrivalEta is { mid: string } => !!arrivalEta)
			.map((arrivalEta) => {
				return dayjs(arrivalEta.mid).diff(dayjs(), 'seconds')
			}),
		availableCount: availableRobots.length
	}
}
