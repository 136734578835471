import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { fetchDailyReport } from '../../actions'
import {
	getCurrency,
	getCursor,
	getOrders,
	getReportErrorFetching,
	getReportIsFetching,
	getSummary
} from '../../reducers/report'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import './Report.scss'
import {
	OrderHistoryList,
	OrderHistoryReportNav,
	ReportSummary,
	ReportViewSwitcher
} from './components'

class Report extends React.Component {
	componentDidMount() {
		const {
			getReport,
			cursor: { current }
		} = this.props
		getReport(current)
	}

	render() {
		const {
			orders,
			summary,
			currency,
			isFetching,
			getReport,
			cursor,
			errorFetching
		} = this.props

		return isFetching ? (
			<LoadingIndicator />
		) : errorFetching ? (
			<div className={'report-error-container'}>
				<span className={'report-error-message'}>
					Something went wrong.
					<br />
					Please try again later.
				</span>
			</div>
		) : (
			<div className={'report-container'}>
				<ReportViewSwitcher />
				<OrderHistoryReportNav getReport={getReport} cursor={cursor} />
				<OrderHistoryList orders={orders} currency={currency} />
				<ReportSummary
					summary={summary}
					currency={currency}
					cursor={cursor}
				/>
			</div>
		)
	}
}

Report.propTypes = {
	orders: PropTypes.array.isRequired,
	summary: PropTypes.object.isRequired,
	currency: PropTypes.string.isRequired,
	getReport: PropTypes.func.isRequired,
	isFetching: PropTypes.bool.isRequired,
	errorFetching: PropTypes.string,
	serviceAssignmentId: PropTypes.string.isRequired,
	cursor: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
	return {
		isFetching: getReportIsFetching(state),
		errorFetching: getReportErrorFetching(state),
		orders: getOrders(state),
		summary: getSummary(state),
		currency: getCurrency(state),
		cursor: getCursor(state)
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	const { serviceAssignmentId } = ownProps
	return {
		getReport: (date) =>
			dispatch(fetchDailyReport(serviceAssignmentId, date))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
