import { FC } from 'react'

import { Grid } from '@mui/material'

import classNames from 'classnames'

import { useVenueFeatureFlags } from '../../../../hooks/useVenueFeatureFlags'
import { Props } from './types'

export const Column: FC<Props> = (props) => {
	const { children, ...restProps } = props
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return (
			<ColumnRevampedView {...restProps}>{children}</ColumnRevampedView>
		)
	}

	return <ColumnView {...restProps}>{children}</ColumnView>
}

const ColumnView: FC<Props> = ({ children, className }) => {
	const classes = classNames(
		'flex flex-col overflow-hidden lg:h-full',
		className
	)

	return <div className={classes}>{children}</div>
}

const ColumnRevampedView: FC<Props> = ({ children, sx, lg, sm }) => {
	return (
		<Grid
			item
			lg={lg}
			sm={sm}
			sx={{
				flex: 1,
				display: 'flex',
				flexDirection: 'column',
				overflow: 'hidden',

				borderRightWidth: '1px',
				borderRightStyle: 'solid',
				borderRightColor: 'grey.300',

				borderTopWidth: '1px',
				borderTopStyle: 'solid',
				borderTopColor: 'grey.300',

				height: {
					lg: '100%',
					sm: '260px'
				},
				...sx
			}}>
			{children}
		</Grid>
	)
}
