import { createSelector } from '@reduxjs/toolkit'
import { isEqual, omit } from 'lodash'

import { FulfillmentStatus } from '../@types/LineItem'
import { LoadingArea, LoadingStation } from '../@types/LoadingArea'
import { Order } from '../@types/Order'
import * as fromLineItems from '../reducers/lineitems'
import { RootState } from '../store'

// These values are omitted to have any possibility of memoising the output
const omittableValues = [
	'preparation_time_elapsed',
	'preparation_time_remaining',
	'seconds_since_mark_start'
] as const

const EMPTY_RESULT = []

export const getStationStatus = createSelector(
	[
		(state: RootState) => state.orders.byId,
		(state: RootState) => state.orders.pickedUp,
		(state: RootState) => state.lineitems,
		(_, props: { loadingarea: LoadingArea }) => props.loadingarea,
		(_, props: { loadingstation: LoadingStation }) => props.loadingstation
	],
	(ordersById, pickedUp, lineitems, loadingarea, loadingstation) => {
		const orders: Order[] = Object.values<Order>(ordersById)
			.filter(
				(order) =>
					order.loadingareaId === loadingarea._id &&
					order.loadingstationId === loadingstation._id
			)
			.map((order) => {
				const enhancedOrder: Order = {
					...omit(order, omittableValues),
					lineitems: fromLineItems.getOrderLineItems(
						lineitems,
						order._id
					),
					picked_up: pickedUp[order._id] || false
				}

				return enhancedOrder
			})

		return orders
	},
	// Orders are currently fetched every N seconds
	// in order to prevent re-renders every N seconds, even if nothing changed,
	// let's do a deep equality check to get the cached result.
	// Please note the omit(...) above to make sure we remove the constantly changing values
	{
		memoizeOptions: {
			resultEqualityCheck: isEqual
		}
	}
)

export const getOrderFulfilment = (orderId: number) =>
	createSelector(
		[(state: RootState) => state],
		(state) => {
			if (!state.lineItemFulfillment[orderId]) return EMPTY_RESULT

			return Object.entries(state.lineItemFulfillment[orderId]).map(
				([lineItemId, fulfilmentStatuses]) => ({
					lineItemId: Number(lineItemId),
					qty: Object.values(fulfilmentStatuses).filter(
						(status) => status === FulfillmentStatus.CAN_FULFILL
					).length
				})
			)
		},
		{
			memoizeOptions: {
				resultEqualityCheck: isEqual
			}
		}
	)
