import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

import { FulfillmentStatus } from '../@types/LineItem'
import { Order } from '../@types/Order'
import { RECEIVED_ORDERS } from '../actions'

type OrderId = number
type LineItemId = number
type LineItemIndex = number

export type LineItemFulfilmentState = Record<
	OrderId,
	Record<LineItemId, Record<LineItemIndex, FulfillmentStatus>>
>

const initialState: LineItemFulfilmentState = {}

const lineItemFulfilment = createSlice({
	name: 'lineItemFulfillment',
	initialState,
	reducers: {
		setLineItemFulfilment(
			state,
			action: PayloadAction<{
				orderId: OrderId
				lineItemId: LineItemId
				index: LineItemIndex
				fulfillmentStatus: FulfillmentStatus
			}>
		) {
			const { fulfillmentStatus, lineItemId, orderId, index } =
				action.payload

			state[orderId][lineItemId][index] = fulfillmentStatus
		}
	},
	extraReducers: (builder) => {
		builder.addCase(RECEIVED_ORDERS, (state, action) => {
			// making dirty typing until migrating orders reducer to ts
			const typedAction = action as unknown as {
				orders: Order[]
			}

			const existingOrders = new Set(Object.keys(state).map(Number))

			// making an assumption that if all fulfilled_qty are zeroes for all the line items, then it's a new order
			// and we fill every line item fulfilment status with CAN_FULFIL value
			// if there's at least one that is not zero, then it has been altered — we'll use fulfilled_qty value
			const newOrderLineItemsFulfillment = typedAction.orders
				.filter((order) => !existingOrders.has(order.order_id))
				.reduce((accumulator, order) => {
					const hasOrderBeenFulfilled = order.lineitems.some(
						(lineItem) => lineItem.fulfilled_qty !== 0
					)

					accumulator[order.order_id] = order.lineitems.reduce(
						(accumulator, lineItem) => {
							accumulator[lineItem._id] = [
								...(hasOrderBeenFulfilled
									? [
											..._.fill(
												Array.from({
													length: lineItem.fulfilled_qty
												}),
												FulfillmentStatus.CAN_FULFILL
											),
											..._.fill(
												Array.from({
													length:
														lineItem.qty -
														lineItem.fulfilled_qty
												}),
												FulfillmentStatus.CAN_NOT_FULFILL
											)
									  ]
									: _.fill(
											Array.from({
												length: lineItem.qty
											}),
											FulfillmentStatus.CAN_FULFILL
									  ))
							].reduce((accumulator, value, index) => {
								accumulator[index] = value
								return accumulator
							}, {})

							return accumulator
						},
						{}
					)
					return accumulator
				}, {})

			return {
				...state,
				...newOrderLineItemsFulfillment
			}
		})
	}
})

export const { setLineItemFulfilment } = lineItemFulfilment.actions

export default lineItemFulfilment.reducer
