import { ReactNode, memo, useCallback, useState } from 'react'

import { InsertDriveFile, VideoFile } from '@mui/icons-material'
import {
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material'

import { FileType } from '../../@types/File'
import videoPoster from '../../assets/images/video-poster.jpg'
import { analytics } from '../../services/analytics'
import { ImageDocumentDialog } from '../ImageDocumentDialog'
import { VideoDialog } from '../VideoDialog'
import { FileListItemProps } from './types'

const labelByFileType: Record<FileType, string> = {
	[FileType.Document]: 'Document',
	[FileType.VIDEO]: 'Video'
}

const iconByFileType: Record<FileType, ReactNode> = {
	[FileType.Document]: <InsertDriveFile color="primary" />,
	[FileType.VIDEO]: <VideoFile color="primary" />
}

export const FileListItem = memo<FileListItemProps>(
	({ title, fileType, src }) => {
		const [isOpen, setOpen] = useState(false)

		const toggleModal = useCallback(() => {
			setOpen((state) => !state)
		}, [])

		const openFile = useCallback(() => {
			analytics.trackEvent('Open FAQ File', { Title: title })
			toggleModal()
		}, [title, toggleModal])

		return (
			<>
				<ListItem disablePadding divider>
					<ListItemButton
						onClick={openFile}
						disableGutters
						sx={{ py: 2 }}>
						<ListItemText primary={title} />

						<ListItemIcon color="tomato">
							<Typography color="primary" mr={2}>
								{labelByFileType[fileType]}
							</Typography>
							{iconByFileType[fileType]}
						</ListItemIcon>
					</ListItemButton>
				</ListItem>

				{fileType === FileType.VIDEO && (
					<VideoDialog
						contentTestID="faq-video"
						poster={videoPoster}
						src={src}
						title={title}
						onClose={toggleModal}
						isOpen={isOpen}
					/>
				)}

				{fileType === FileType.Document && (
					<ImageDocumentDialog
						contentTestID="faq-document"
						src={src}
						title={title}
						onClose={toggleModal}
						isOpen={isOpen}
					/>
				)}
			</>
		)
	}
)
