import { FC } from 'react'

import { Box } from '@mui/material'

import cx from 'classnames'

import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { OrderCardProps } from './types'

export const OrderCard: FC<OrderCardProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderCardRevampedView {...props} />
	}

	return <OrderCardView {...props} />
}

const OrderCardView: FC<OrderCardProps> = ({
	className,
	children,
	col,
	testId,
	isTranslucent,
	isTestOrder
}) => {
	const classes = cx(
		'flex justify-between overflow-hidden rounded-2xl bg-white shadow-md min-h-[94px]',
		{
			'flex-row': !col,
			'flex-col': col,
			'opacity-50': isTranslucent,
			'test-order': isTestOrder
		},
		className
	)

	return (
		<div data-testid={testId} className={classes}>
			{children}
		</div>
	)
}

const OrderCardRevampedView: FC<OrderCardProps> = ({
	className,
	children,
	col,
	testId,
	isTranslucent,
	isTestOrder
}) => {
	const classes = cx(
		{
			'test-order': isTestOrder
		},
		className
	)

	return (
		<Box
			className={classes}
			data-testid={testId}
			display="flex"
			flexDirection={col ? 'column' : 'row'}
			justifyContent="space-between"
			borderRadius={2}
			minHeight={88}
			boxShadow={1}
			flex={1}
			sx={{
				opacity: isTranslucent ? 0.5 : 1,
				backgroundColor: 'white'
			}}>
			{children}
		</Box>
	)
}
