import React from 'react'

import './styles.scss'

export const OrderHistoryListHeader = () => {
	return (
		<div className={'order-history-header-container'}>
			<div
				className={
					'order-history-header-cell order-history-header-cell-medium'
				}>
				<span>ORDER N°</span>
			</div>
			<div
				className={
					'order-history-header-cell order-history-header-cell-medium'
				}>
				<span>RECEIVED</span>
			</div>
			<div className={'order-history-header-cell'}>
				<span>STARTED</span>
			</div>
			<div className={'order-history-header-cell'}>
				<span>COMPLETED</span>
			</div>
			<div
				className={
					'order-history-header-cell order-history-header-cell-sm'
				}>
				<span>TYPE</span>
			</div>
			<div
				className={
					'order-history-header-cell order-history-header-cell-medium'
				}>
				<span>LOADED/PICKUP</span>
			</div>
			<div
				className={
					'order-history-header-cell order-history-header-cell-medium'
				}>
				<span>ITEM COUNT</span>
			</div>
			<div className={'order-history-header-cell'}>
				<span>PAYMENT METHOD</span>
			</div>
			<div className={'order-history-header-cell'}>
				<span>PRICE (EX. TAX)</span>
			</div>
		</div>
	)
}
