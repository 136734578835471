import { memo, useCallback } from 'react'
import Markdown from 'react-markdown'

import { AirportShuttle } from '@mui/icons-material'
import { Box, Button, List, ListItem, Typography } from '@mui/material'

import dayjs from 'dayjs'

import { AlertListItemProps } from './types'

export const AlertListItem = memo<AlertListItemProps>(
	({ message, onStartTourPress }) => {
		const handleStartTourPress = useCallback(() => {
			onStartTourPress(message.steps ?? [])
		}, [message.steps, onStartTourPress])

		const isNew = !message.delivered_at

		return (
			<ListItem
				divider
				sx={{
					px: 3,
					backgroundColor: isNew ? 'primary.light' : undefined
				}}>
				<Box>
					<Typography
						variant="caption"
						color="text.secondary"
						marginRight={1}>
						{dayjs(message.created_at).format('L')}
					</Typography>

					{isNew ? (
						<Typography
							variant="caption"
							fontWeight="bold"
							color="error">
							NEW
						</Typography>
					) : null}

					{message.title ? (
						<Typography fontWeight="bold">
							{message.title}
						</Typography>
					) : null}

					<Box sx={{ '& img': { maxWidth: '100%' } }}>
						<Markdown
							components={{
								ul: ({ children }) => (
									<List
										sx={{
											listStyle: 'square',
											marginLeft: 2.5
										}}>
										{children}
									</List>
								),
								ol: ({ children }) => (
									<List
										sx={{
											listStyle: 'number',
											marginLeft: 2.5
										}}>
										{children}
									</List>
								),
								li: ({ children }) => (
									<ListItem
										sx={{ display: 'list-item' }}
										disableGutters
										disablePadding>
										{children}
									</ListItem>
								)
							}}>
							{message.content}
						</Markdown>
					</Box>

					{message.steps?.length ? (
						<Button
							sx={{ mt: 1 }}
							variant="contained"
							startIcon={<AirportShuttle />}
							onClick={handleStartTourPress}>
							Take the tour
						</Button>
					) : null}
				</Box>
			</ListItem>
		)
	}
)
