import React, { Component, ErrorInfo, ReactNode } from 'react'

import * as Sentry from '@sentry/react'

import { captureError } from '../../utils'
import styles from './index.module.scss'

interface Props {
	children: ReactNode
}

interface State {
	hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false
	}

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		captureError(error, errorInfo)
	}

	public render() {
		if (this.state.hasError) {
			return (
				<div className={styles.root} data-testid="errorboundary">
					<p>We&apos;re sorry — something has gone wrong.</p>
					{Sentry.lastEventId() && (
						<p>
							Our team has been notified (Sentry event:{' '}
							{Sentry.lastEventId()})
						</p>
					)}
				</div>
			)
		}

		return this.props.children
	}
}
