import { useMemo } from 'react'

import { InventoryStatus, Item } from '../../@types/Item'
import { Order } from '../../@types/Order'
import { useAppSelector } from '../../utils/storeHelpers'

export const useItemStatuses = (options: { order: Order }) => {
	const itemsForOrdersById = useAppSelector(
		(state) => state.itemsForOrders.byId
	)

	return useMemo(() => {
		const includedItemIds = new Set(
			options.order.lineitems.map((lineItem) => lineItem.item)
		)
		const values = (Object.values(itemsForOrdersById) as Item[]).filter(
			(value: Item) => includedItemIds.has(value._id)
		)

		return values.reduce((accumulator, current) => {
			return {
				...accumulator,
				[current._id]: current.status
			}
		}, {} as Record<string, InventoryStatus>)
	}, [itemsForOrdersById, options.order.lineitems])
}
