import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import { keyBy } from 'lodash'

import { ConnectedPrinter } from '../../@types/Printer'
import { container } from '../../container'
import { Station } from '../../containers/Station'
import { useVenue } from '../../hooks/useVenue'
import { analytics } from '../../services/analytics'
import {
	postDebugEvent as _postDebugEvent,
	fetchCurrentLoadingareaTags,
	fetchLoadingareaTags
} from '../../utils'
import { useGuidedTour } from '../../utils/guidedTour/useGuidedTour'
import { getActiveServiceAssignment } from '../../utils/serviceAssignment'
import { AppMainDashBoardGuidedTour } from '../GuidedTour/AppMainDashBoardGuidedTour'
import { LateOrderAlertManager } from '../LateOrderAlertManager'
import { Menu } from '../Menu'
import { MerchantDataManager } from '../MerchantDataManager'
import { OrderNewNotifier } from '../OrderNewNotifier'
import { OrderPoller } from '../OrderPoller'
import { PreflightCheck } from '../PreflightCheck'
import { RobotDistributer } from '../RobotDistributer'
import { TopBar } from '../TopBar'
import { Welcome } from '../Welcome'
import styles from './index.module.scss'
import { AppProps } from './types'
import { useSaveStationToStore } from './useSaveStationToStore'

const TAG_INTERVAL = 10 * 60 * 1000 // 10 minutes

const loadingAreaCheck = async () => {
	try {
		const newTags = JSON.stringify(await fetchLoadingareaTags())

		const currentTags = fetchCurrentLoadingareaTags()

		if (currentTags && newTags && newTags !== currentTags) {
			// refresh needed
			console.log('refresh needed')
			window.location.reload()
		}
	} catch (error) {
		console.warn('failed loading area tag update', error)
	}
}

// To keep track of the printer and how it is connected (USB/Bluetooth)
export const updateConnectedPrinterInfo = (
	activePrinter?: ConnectedPrinter | null
) => {
	_postDebugEvent('connectedPrinterDetails', {
		printer:
			activePrinter === undefined
				? container.getConnectedPrinter()
				: activePrinter
	})
}

// expose the postDebugEvent for getting events from android native app
window.postDebugEvent = (actionType, payload) => {
	console.warn('postDebugEvent', actionType, window.tags)
	const isError =
		payload &&
		payload.printResultCode !== undefined &&
		payload.printResultCode !== -1
	_postDebugEvent(actionType, { ...payload, isError } || {})
}

export const App: FC<AppProps> = ({
	serviceassignments,
	loadingareas,
	user
}) => {
	useSaveStationToStore()
	const navigate = useNavigate()
	const { loadingareaId, loadingstationId } = useParams()
	const [menuActive, setMenuActive] = useState(false)
	const { venue } = useVenue()

	const toggleMenu = useCallback(() => {
		setMenuActive((state) => !state)
	}, [])

	useEffect(() => {
		const tagsInterval = setInterval(loadingAreaCheck, TAG_INTERVAL)

		return () => {
			clearInterval(tagsInterval)
		}
	}, [])

	useEffect(() => {
		const selectedLoadingArea = loadingareas.find(
			(loadingArea) => loadingArea._id === loadingareaId
		)
		const selectedStation = selectedLoadingArea?.stations.find(
			(station) => station._id === loadingstationId
		)

		if (selectedLoadingArea) {
			analytics.setUserProperty(
				'Loading Area Id',
				selectedLoadingArea._id
			)
			analytics.setUserProperty(
				'Loading Area Name',
				selectedLoadingArea.name
			)
		}

		if (selectedStation) {
			analytics.setUserProperty('Station Id', selectedStation._id)
			analytics.setUserProperty('Station Name', selectedStation.name)
			analytics.setUserProperty(
				'Service Assignment Id',
				selectedStation.serviceassignment
			)
		}
	}, [loadingareaId, loadingareas, loadingstationId])

	useEffect(() => {
		const handleOrientationChange = () => {
			analytics.setUserProperty(
				'Device Orientation',
				window.screen.width > window.screen.height
					? 'landscape'
					: 'portrait'
			)
		}

		handleOrientationChange()

		window.addEventListener('orientationchange', handleOrientationChange)

		return () => {
			window.removeEventListener(
				'orientationchange',
				handleOrientationChange
			)
		}
	}, [])

	// This will return the service assignment id that belongs to a selected loading area id
	const [serviceAssignmentId] = useMemo(() => {
		return getActiveServiceAssignment(serviceassignments, loadingstationId)
	}, [loadingstationId, serviceassignments])

	if (serviceAssignmentId === undefined) {
		throw new Error('Service assignment is missing')
	}

	const { showWelcomePage, showMainDashBoardTour } = useGuidedTour(
		venue,
		serviceAssignmentId
	)

	const loadingarea = useMemo(() => {
		return loadingareas.find(({ _id }) => _id === loadingareaId)
	}, [loadingareaId, loadingareas])

	const loadingstation = useMemo(() => {
		return (
			loadingarea &&
			loadingarea.stations.find(({ _id }) => _id === loadingstationId)
		)
	}, [loadingarea, loadingstationId])

	const loadingAreasById = useMemo(() => {
		return keyBy(loadingareas, (la) => la._id)
	}, [loadingareas])

	if (!loadingarea || !loadingstation) {
		navigate('/', { replace: true })
		return null
	}

	window.tags = loadingarea.tags

	// gather envinroment data for analytics and debug
	const environment = {
		serviceAssignmentId,
		loadingstationId,
		loadingareaId,
		userId: user._id,
		username: user.name
	}

	// inject to global namespace for later use in utils/postDebugEvent
	window.analyticsEnv = environment

	if (showWelcomePage) {
		return <Welcome />
	}

	return (
		<>
			<AppMainDashBoardGuidedTour enabled={showMainDashBoardTour} />

			<MerchantDataManager
				serviceAssignments={serviceassignments}
				loadingAreas={loadingAreasById}
				selectedLoadingAreaId={loadingareaId ?? null}
				selectedServiceAssignmentId={serviceAssignmentId}
			/>

			<RobotDistributer loadingAreaId={loadingareaId} />

			<LateOrderAlertManager />
			<OrderNewNotifier />

			{loadingstationId && loadingareaId ? (
				<OrderPoller
					loadingStationId={loadingstationId}
					loadingAreaId={loadingareaId}
				/>
			) : null}

			<div className={styles.root}>
				<TopBar toggleMenu={toggleMenu} isMenuActive={menuActive} />

				{menuActive && (
					<Box
						sx={{
							position: 'absolute',
							top: {
								lg: '64px',
								sm: '120px'
							},
							bottom: 0,
							left: 0,
							right: 0,
							zIndex: 99
						}}>
						<Menu
							{...{
								user,
								loadingarea,
								loadingstation,
								loadingareas,
								serviceassignments,
								serviceAssignmentId,
								updateConnectedPrinterInfo,
								venueConfiguration: venue,
								toggleMenu
							}}
						/>
					</Box>
				)}

				<PreflightCheck
					menuActive={menuActive}
					onNavigateToFAQ={toggleMenu}
					loadingStation={loadingstation}
					loadingArea={loadingarea}
				/>

				<Station
					loadingarea={loadingarea}
					loadingstation={loadingstation}
				/>
			</div>
		</>
	)
}
