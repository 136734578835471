import dayjs from 'dayjs'
import { first, last } from 'lodash'

import {
	ParsedWorkingHour,
	WorkingHoursSegment
} from '../../@types/WorkingHours'

// Converts working hour segments, which represent just a day number and start times as seconds from 00:00,
// into times relative to current time.
export const makeHours = (options: {
	now: dayjs.Dayjs
	segments: WorkingHoursSegment[]
}): ParsedWorkingHour[] => {
	const { now, segments } = options
	return addExtraMonday(segments).map((workingHour) => {
		const currentDay = now.day()

		// This week Sunday is 0 and 7 is next week Sunday, so we need to do some silly math here.
		const daysToAdd = workingHour.day - (currentDay === 0 ? 7 : currentDay)

		const newDate = now.day(currentDay + daysToAdd).startOf('day')

		return {
			dateStart: newDate.minute(workingHour.start / 60),
			dateEnd: newDate.minute(workingHour.end / 60)
		}
	})
}

// Add "next week monday" to ensure "over midnight Sunday" works correctly (we only get current week currently)
const addExtraMonday = (hours: WorkingHoursSegment[]) => {
	const firstSegment = first(hours)
	const lastSegment = last(hours)

	return firstSegment?.day === 1 && lastSegment?.day === 7
		? [...hours, { ...firstSegment, day: 8 }] // day 8 = next week monday
		: hours
}
