import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Robot } from '../@types/Robot'

type State = {
	robotsForOrders: Record<string, Robot>
}

const initialState: State = {
	robotsForOrders: {}
}

const robotDistributionSlice = createSlice({
	name: 'robotDistribution',
	initialState,
	reducers: {
		updateRobotDistribution(
			state,
			action: PayloadAction<Record<string, Robot>>
		) {
			state.robotsForOrders = action.payload
		}
	}
})

export const { updateRobotDistribution } = robotDistributionSlice.actions

export default robotDistributionSlice.reducer
