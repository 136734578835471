import dayjs from 'dayjs'
import linewrap from 'linewrap'
import { head, tail } from 'lodash'
import clamp from 'lodash/clamp'

import { LineItem } from '../../@types/LineItem'
import { DeliveryType, Order } from '../../@types/Order'
import EscEncoder from '../../escencoder'
import { parseNoteValue } from '../../utils/lineItem/parseNoteValue'
import {
	FontSize,
	PrintOrderQRCodeParams,
	PrintReusePassInfo,
	PrintSettings
} from './types'
import { getPrintSettings } from './utils'

// FIXME: We could have several receipt types instead of having these conflicting conditionals. Such as:
// - DELIVERY_WITH_QR
// - DELIVERY_WITH_LARGE_QR
// - DELIVERY_WITH_BARCODE
// - PICKUP
// and then have some small utilities instead of these huge functions.
// Or, then we could just review the control flow and refactor this whole thing when the spec settles down :shrug:
// However, we make so few changes here that it's not valuable to spend time refactoring this at the time of writing.
export const generateKitchenReceipt = (params: {
	itemize?: boolean
	order: Order
	qrcode?: string
	shouldPrintBarcode?: boolean
	shouldPrintDaft?: boolean
	reusePassQRCode?: string
}) => {
	const { itemize } = params

	const settings = getPrintSettings()

	if (itemize) {
		return generateMultiItemOrderReceipts(params, settings)
	}

	return generateSingleOrderReceipt(params, settings)
}

const generateSingleOrderReceipt = (
	params: {
		order: Order
		qrcode?: string
		shouldPrintBarcode?: boolean
		shouldPrintDaft?: boolean
		reusePassQRCode?: string
	},
	settings: PrintSettings
) => {
	const {
		order,
		qrcode,
		shouldPrintBarcode,
		shouldPrintDaft,
		reusePassQRCode
	} = params

	const encoder = new EscEncoder()
	const MIN_LINES = 7

	const result = encoder.initialize()

	printHeader({
		result,
		order,
		extraBottomMargin: shouldPrintBarcode ? undefined : 11,
		fontSizes: settings.fontSize
	})

	for (const lineitem of order.lineitems) {
		result.horizontalDivider(true)
		printLineitem(result, lineitem, settings.fontSize)
	}

	result.horizontalDivider(true)

	const callPrintReusePassInfo = () => {
		printReusePassInfo({
			fontSizes: settings.fontSize,
			result,
			reusePassQRCode
		})
	}

	if (shouldPrintDaft) {
		printDaftCode(result, order)
		callPrintReusePassInfo()
		printOrderQRCode({ result, order, qrcode, size: settings.qrCodeSize })
	} else if (shouldPrintBarcode) {
		printBarcode(result, order)
		callPrintReusePassInfo()
	} else {
		callPrintReusePassInfo()
		printOrderQRCode({ result, order, qrcode, size: settings.qrCodeSize })
	}

	ensureMinimumLength(result, MIN_LINES)

	return result.cut().encode()
}

const generateMultiItemOrderReceipts = (
	params: {
		order: Order
		qrcode?: string
		shouldPrintBarcode?: boolean
		shouldPrintDaft?: boolean
		reusePassQRCode?: string
	},
	settings: PrintSettings
) => {
	const {
		order,
		qrcode,
		shouldPrintBarcode,
		shouldPrintDaft,
		reusePassQRCode
	} = params

	const encoder = new EscEncoder()
	const MIN_LINES = 7

	const result = encoder.initialize()

	const totalItems = order.lineitems.reduce(
		(accumulator, current) => accumulator + current.qty,
		0
	)

	let itemCount = 1

	const callPrintReusePassInfo = () => {
		printReusePassInfo({
			fontSizes: settings.fontSize,
			result,
			reusePassQRCode
		})
	}

	for (const lineitem of order.lineitems) {
		for (let index = 1; index <= lineitem.qty; index++) {
			result
				.align('left')
				.bold(false)
				.fontSize(settings.fontSize.sm)
				.text(`Item ${itemCount} of ${totalItems}`)
				.tinyNewline(3)

			printHeader({
				result,
				order,
				extraBottomMargin: shouldPrintBarcode ? undefined : 4,
				fontSizes: settings.fontSize
			})

			result.horizontalDivider(true)
			printLineitem(result, lineitem, settings.fontSize, 1)
			result.horizontalDivider(true)

			if (shouldPrintDaft) {
				printDaftCode(result, order)
				callPrintReusePassInfo()
				printOrderQRCode({
					result,
					order,
					qrcode,
					size: settings.qrCodeSize
				})
			} else if (shouldPrintBarcode) {
				printBarcode(result, order)
				callPrintReusePassInfo()
			} else {
				callPrintReusePassInfo()
				printOrderQRCode({
					result,
					order,
					qrcode,
					size: settings.qrCodeSize
				})
			}

			ensureMinimumLength(result, MIN_LINES)

			result.cut()

			itemCount++
		}
	}

	return result.encode()
}

const wrap = (
	string_: string,
	preservedLineIndent: number,
	wrapLineIndent: number,
	lineLength = 29
) => {
	// linewrap lib seems pretty nice, but is curiosly incapable of collapsing multiple linefeeds
	// so we are working around it to stop customers from spending too much printer paper
	const processed = string_.replace(/([\n\r|]+)/g, '\r\n')
	const wrapper = linewrap(lineLength, {
		wrapLineIndent,
		preservedLineIndent,
		whitespace: 'collapse',
		respectLineBreaks: 'm1',
		lineBreak: '\r\n'
	})
	return wrapper(processed)
}

const printHeader = (options: {
	result: EscEncoder
	order: Order
	extraBottomMargin?: number
	fontSizes: FontSize
}) => {
	const { result, order, extraBottomMargin, fontSizes } = options

	const firstHalf = `${order.order_id}`.slice(0, -3)
	const secondHalf = `${order.order_id}`.slice(-3)

	const isPickup = order.delivery_type === DeliveryType.PICKUP

	result
		.align('left')
		.fontSize(fontSizes.sm)
		.bold(true)
		.text('Starship')
		.tinyNewline(3)
		.bold(true)
		.fontSize(isPickup ? fontSizes.xl : fontSizes.lg)

		.text(
			order.kiosk_order_id === null
				? `${firstHalf} ${secondHalf}`
				: `${order.kiosk_order_id}`
		)

		.tinyNewline(3)
		.fontSize(fontSizes.sm)
		.bold(true)
		.text(isPickup ? 'Customer Pick Up' : 'Robot Delivery')
		.tinyNewline(3)

	if (order.name_hr) {
		result
			.bold(false)
			.fontSize(fontSizes.sm)
			.text(order.name_hr)
			.tinyNewline(3)
	}

	printOrderTimestamps(result, order, fontSizes)

	if (order.kiosk_order_id !== null) {
		result
			.fontSize(fontSizes.sm)
			.bold(false)
			.text(`${order.order_id}`)
			.tinyNewline(3)
	}

	// for qr code
	if (!isPickup && extraBottomMargin) {
		result.tinyNewline(extraBottomMargin)

		// If no name in the order, we need some extra spacing to make up for it
		if (!order.name_hr) {
			result.tinyNewline(10)
		}
	}

	return result
}

const printLineitem = (
	result: EscEncoder,
	lineitem: LineItem,
	fontSizes: FontSize,
	quantity: number | null = null
) => {
	const { message, modifiersByType } = parseNoteValue(lineitem.note)

	result
		.fontSize(fontSizes.md)
		.bold(true)
		.text(`${quantity || lineitem.qty}x `)
		.line(wrap(lineitem.name, 0, 3, 24))
		.bold(false)

	const modifierMaxLineLength = 1320 / fontSizes.sm // with smallest font size the modifier line length is 40. A little math to scale it down as font size scales up

	if (modifiersByType && Object.keys(modifiersByType).length) {
		result.tinyNewline(3)
		Object.keys(modifiersByType).forEach((type) => {
			const typeText = `- ${type}:`
			result
				.bold(true)
				.fontSize(fontSizes.sm)
				.text(wrap(typeText, 0, 0))
				.bold(false)

			const formattedModifiers = modifiersByType[type].map(
				(modifier) => modifier.value
			)

			const formattedFirstModifier = head(formattedModifiers) ?? ''

			result.fontSize(fontSizes.sm)
			result
				.text(
					wrap(
						formattedFirstModifier,
						0,
						4,
						Math.floor(modifierMaxLineLength - typeText.length)
					)
				)
				.tinyNewline(3)

			tail(formattedModifiers).forEach((modifier) => {
				result.fontSize(fontSizes.sm)
				result
					.text(wrap(modifier, 4, 4, modifierMaxLineLength))
					.tinyNewline(3)
			})
		})
	}

	if (message) {
		result
			.bold(true)
			.fontSize(fontSizes.sm)
			.text('Note:')
			.bold(false)
			.newline(1)
		result
			.fontSize(fontSizes.sm)
			.text(wrap(message, 0, 0, 1584 / fontSizes.sm)) // eg. 1584 / 33 = 48
		result.tinyNewline(2)
	}
	return result
}

const printOrderQRCode = ({
	result,
	order,
	qrcode,
	size
}: PrintOrderQRCodeParams) => {
	if (order.delivery_type === DeliveryType.PICKUP) {
		return result
	}

	if (!qrcode) {
		return result
	}

	return result
		.align('right')
		.positionTopRightCorner()
		.qrcode({
			value: qrcode,
			size,
			lowErrorCorrection: true
		})
		.align('left')
}

const printReusePassInfo = ({
	result,
	reusePassQRCode,
	fontSizes
}: PrintReusePassInfo) => {
	if (!reusePassQRCode) {
		return result
	}

	return result
		.tinyNewline(8)
		.align('center')
		.fontSize(fontSizes.md)
		.text('ReusePass')
		.tinyNewline(6)
		.qrcode({
			value: reusePassQRCode,
			lowErrorCorrection: true
		})
		.tinyNewline(6)
		.text('ReusePass')
		.tinyNewline(4)
}

const printBarcode = (result: EscEncoder, order: Order) => {
	if (order.delivery_type === DeliveryType.PICKUP) {
		return result
	}

	return result
		.align('center')
		.tinyNewline(4)
		.barcodeEan8(`${order.order_id}`)
		.align('left')
}

const printDaftCode = (result: EscEncoder, order: Order) => {
	if (order.delivery_type === DeliveryType.PICKUP) {
		return result
	}

	return result
		.align('center')
		.tinyNewline(4)
		.daftCode6HighEscL(order.order_id, 8)
		.align('left')
}

const printOrderTimestamps = (
	result: EscEncoder,
	order: Order,
	fontSizes: FontSize
) => {
	const orderPlacedTime = dayjs(order.visible_at).format('hh:mma')
	const placeInRobotTime = dayjs(order.visible_at)
		.add(order.preparation_time_needed ?? 0, 'second')
		.format('hh:mma')

	result
		.fontSize(fontSizes.sm)
		.bold(false)
		.text(`Placed: ${orderPlacedTime}`)
		.tinyNewline(3)

	if (order.delivery_type === 'TO_CUSTOMER') {
		result
			.fontSize(fontSizes.sm)
			.bold(false)
			.text(`Load by: ${placeInRobotTime}`)
			.tinyNewline(3)
	}

	return result
}

const ensureMinimumLength = (result: EscEncoder, minLines = 7) => {
	return result.newline(clamp(minLines - result.length(), 0, minLines))
}
