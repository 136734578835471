import { FC } from 'react'

import { OrderLabel } from '.'
import { DeliveryType, Order } from '../../../@types/Order'

export const OrderLabelDeliveryType: FC<{
	order: Order
}> = ({ order }) => {
	const isToCustomer = order.delivery_type === DeliveryType.TO_CUSTOMER

	return (
		<OrderLabel
			icon={isToCustomer ? 'robotWaiting' : 'pickupHand'}
			label={isToCustomer ? 'Robot delivery' : 'Customer pickup'}
		/>
	)
}
