import React from 'react'

import _sortBy from 'lodash/sortBy'
import _sum from 'lodash/sum'
import PropTypes from 'prop-types'

import { ReactComponent as ChevronIcon } from '../../icons/Chevron.svg'
import { RadioItem } from '../Radio/Radio'

class CollapsibleRadio extends React.Component {
	state = {
		isCollapsed: true,
		selectedCount: 0
	}
	componentDidMount() {
		this.props.onHeightChanged(this.calculateHeight())
	}

	selectLocation = (title, count) => {
		this.setState({ isCollapsed: true, selectedCount: count }, () => {
			this.props.onHeightChanged(this.calculateHeight())
		})
		if (title === this.props.placeholder) {
			this.props.onValueChange('')
		} else {
			this.props.onValueChange(title)
		}
	}
	handleClick = () => {
		this.setState({ isCollapsed: !this.state.isCollapsed }, () => {
			this.props.onHeightChanged(this.calculateHeight())
		})
	}
	calculateHeight = () => {
		if (this.state.isCollapsed) {
			return 0
		}
		const numItems = Object.keys(this.props.values).length + 1
		return numItems * 57
	}

	render() {
		const { values: valuesObject, selectedValue, placeholder } = this.props
		let { isCollapsed, selectedCount } = this.state

		let values = Object.keys(valuesObject).map((key) => ({
			title: key,
			count: valuesObject[key]
		}))
		if (!values || !values.map) {
			return null
		}
		values = _sortBy(values, 'title')
		const totalCount = _sum(values.map((value) => value.count))

		if (selectedValue === '') {
			selectedCount = totalCount
		}
		values = [{ title: placeholder, count: totalCount }, ...values]
		const selectedTitle = selectedValue || placeholder

		return (
			<div>
				<div className={'item-container'}>
					<div onClick={this.handleClick} className={'radio-item'}>
						{selectedTitle}
						<div style={{ flex: 1 }} />
						<div className={'item-resultcount'}>
							{selectedCount} results
						</div>
						<ChevronIcon
							style={{
								marginRight: -10,
								transform: 'rotate(180deg) scale(0.65)'
							}}
						/>
					</div>
				</div>
				{!isCollapsed && (
					<div
						className={'radio-group'}
						style={{
							border: 'none',
							borderTop: '1px solid #E0E0E0',
							borderRadius: 0,
							margin: 0
						}}>
						{values.map(({ title, count }, key) => {
							return (
								<div className={'item-container'} key={key}>
									<RadioItem
										title={title || 'No category'}
										extra={count}
										hideCheckmark
										selected={title === selectedValue}
										onClick={() =>
											this.selectLocation(title, count)
										}
									/>
								</div>
							)
						})}
					</div>
				)}
			</div>
		)
	}
}

CollapsibleRadio.propTypes = {
	values: PropTypes.object.isRequired,
	selectedValue: PropTypes.number,
	placeholder: PropTypes.string,
	onValueChange: PropTypes.func.isRequired,
	onSizeChange: PropTypes.func,
	onHeightChanged: PropTypes.func
}
CollapsibleRadio.defaultProps = {
	values: [],
	selectedValue: '',
	onHeightChanged: () => {}
}
export default CollapsibleRadio
