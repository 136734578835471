import { LoadingButton } from '@mui/lab'
import {
	Box,
	DialogContent,
	DialogTitle,
	Divider,
	Dialog as MuiDialog,
	Typography
} from '@mui/material'

import { Props } from './types'

export const Dialog = ({
	description,
	title,
	actions,
	footer,
	content,
	padContent = true
}: Props) => {
	return (
		<MuiDialog open={true} fullWidth maxWidth="sm">
			<DialogTitle
				sx={{ lineHeight: 1 }}
				fontWeight={700}
				fontSize={20}
				mt={2}>
				{title}
			</DialogTitle>
			<DialogContent sx={{ p: padContent ? undefined : 0 }}>
				{content ?? null}

				{description?.map((text, index) => (
					<Typography fontSize={18} mt={index === 0 ? undefined : 2}>
						{text}
					</Typography>
				))}
			</DialogContent>
			<Divider />
			<Box px={3} py={2}>
				{footer ?? null}

				{actions.map(
					(
						{
							theme = 'primary',
							onClick = () => null,
							title,
							variant = 'contained',
							disabled = false,
							isLoading = false
						},
						index
					) => {
						return (
							<LoadingButton
								key={`button_${title}`}
								variant={variant}
								fullWidth
								onClick={onClick}
								color={theme}
								disabled={disabled}
								loading={isLoading}
								sx={{
									minHeight: 48,
									maxHeight: 48,
									fontSize: 18,
									mt: index === 0 ? undefined : 2,
									mb:
										index === actions.length - 1
											? 1
											: undefined
								}}>
								{title}
							</LoadingButton>
						)
					}
				)}
			</Box>
		</MuiDialog>
	)
}
