import { FC, useCallback, useState } from 'react'

import { Box } from '@mui/material'

import * as Sentry from '@sentry/react'
import { enqueueSnackbar } from 'notistack'

import {
	DeliveryType,
	OrderStatusHR,
	StationStatus
} from '../../../../../@types/Order'
import { completePicking, startPicking } from '../../../../../actions'
import { HowLoadOrderWithoutPhoneCard } from '../../../../../components/HowLoadOrderWithoutPhoneCard'
import { OrderEditable } from '../../../../../components/OrderEditable'
import { Action } from '../../../../../components/OrderRevamp/Action'
import { ActionType } from '../../../../../components/OrderRevamp/Action/types'
import { OrderModal } from '../../../../../components/OrderRevamp/Layout/OrderModal'
import { useVenueFeatureFlags } from '../../../../../hooks/useVenueFeatureFlags'
import { analytics } from '../../../../../services/analytics'
import { OrderCompact } from './OrderCompact'
import { OrderReadyProps } from './types'

export const OrderReady: FC<OrderReadyProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderReadyRevampedView {...props} />
	}

	return <OrderReadyView {...props} />
}

export const OrderReadyView: FC<OrderReadyProps> = ({
	order,
	onPickup,
	onPrint
}) => {
	const [isCompletingPicking, setIsCompletingPicking] = useState(false)
	const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)
	const [isPickingCompleted, setIsPickingCompleted] = useState(
		order.status_hr === OrderStatusHR.READY
	)

	const toggleOrderModal = useCallback(() => {
		setIsOrderModalOpen((state) => !state)
	}, [])

	const handlePickup = useCallback(() => {
		onPickup(order)
	}, [onPickup, order])

	const handlePrint = useCallback(() => {
		analytics.trackEvent('Print Pressed', {
			Location: 'Modal',
			Order_ID: order.order_id
		})

		onPrint(order)
	}, [onPrint, order])

	const handleCompletePicking = useCallback(async () => {
		setIsCompletingPicking(true)
		setIsOrderModalOpen(false)

		try {
			analytics.trackEvent('Order Picking Completed Manually', {
				Order_ID: order.order_id
			})

			await startPicking({
				loadingareaId: order.loadingareaId,
				orderId: order._id
			})
			await completePicking({
				loadingareaId: order.loadingareaId,
				orderId: order._id
			})

			enqueueSnackbar(
				'Order ready. Please load robot by showing the receipt barcode to the robot.',
				{
					variant: 'success',
					autoHideDuration: 5000,
					anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
				}
			)
			setIsPickingCompleted(true)
		} catch (error) {
			Sentry.captureException(error)

			enqueueSnackbar('Something went wrong while completing order', {
				variant: 'error',
				autoHideDuration: 5000,
				anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
			})
		} finally {
			setIsCompletingPicking(false)
		}
	}, [order._id, order.loadingareaId, order.order_id])

	const canConfirmPicking =
		!isPickingCompleted && order.delivery_type === DeliveryType.TO_CUSTOMER

	return (
		<>
			<OrderCompact
				order={order}
				onView={toggleOrderModal}
				onPickup={handlePickup}
				onCompletePicking={handleCompletePicking}
				canCompletePicking={!isPickingCompleted}
				isCompletingPicking={isCompletingPicking}
			/>

			<OrderModal
				isOpen={isOrderModalOpen}
				onRequestClose={toggleOrderModal}>
				<OrderEditable
					order={order}
					isScrollable
					disabled={!canConfirmPicking}
					footer={
						<HowLoadOrderWithoutPhoneCard className="justify-center mx-5 my-4" />
					}
					actions={
						<>
							<Action
								type={ActionType.CLOSE_WINDOW}
								testId="ActionCloseWindow"
								className="border-r-[1px] border-r-grey-light"
								onClick={toggleOrderModal}
							/>
							<Action
								type={ActionType.PRINT_AGAIN}
								testId="ActionPrintAgain"
								onClick={handlePrint}
							/>
						</>
					}
					hideTimer
				/>
			</OrderModal>
		</>
	)
}

export const OrderReadyRevampedView: FC<OrderReadyProps> = ({
	order,
	onPickup,
	onPrint
}) => {
	const [isCompletingPicking, setIsCompletingPicking] = useState(false)
	const [isOrderModalOpen, setIsOrderModalOpen] = useState(false)
	const [isPickingCompleted, setIsPickingCompleted] = useState(
		order.status_hr === OrderStatusHR.READY
	)

	const toggleOrderModal = useCallback(() => {
		setIsOrderModalOpen((state) => !state)
	}, [])

	const handlePickup = useCallback(() => {
		onPickup(order)
	}, [onPickup, order])

	const handlePrint = useCallback(() => {
		analytics.trackEvent('Print Pressed', {
			Location: 'Modal',
			Order_ID: order.order_id
		})

		onPrint(order)
	}, [onPrint, order])

	const handleCompletePicking = useCallback(async () => {
		setIsCompletingPicking(true)
		setIsOrderModalOpen(false)

		try {
			analytics.trackEvent('Order Picking Completed Manually', {
				Order_ID: order.order_id
			})

			await startPicking({
				loadingareaId: order.loadingareaId,
				orderId: order._id
			})
			await completePicking({
				loadingareaId: order.loadingareaId,
				orderId: order._id
			})

			enqueueSnackbar(
				'Order ready. Please load robot by showing the receipt barcode to the robot.',
				{
					variant: 'success',
					autoHideDuration: 5000,
					anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
				}
			)
			setIsPickingCompleted(true)
		} catch (error) {
			Sentry.captureException(error)

			enqueueSnackbar('Something went wrong while completing order', {
				variant: 'error',
				autoHideDuration: 5000,
				anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
			})
		} finally {
			setIsCompletingPicking(false)
		}
	}, [order._id, order.loadingareaId, order.order_id])

	const canConfirmPicking =
		!isPickingCompleted && order.delivery_type === DeliveryType.TO_CUSTOMER

	return (
		<>
			<OrderCompact
				order={order}
				onView={toggleOrderModal}
				onPickup={handlePickup}
				onCompletePicking={handleCompletePicking}
				canCompletePicking={!isPickingCompleted}
				isCompletingPicking={isCompletingPicking}
			/>

			<OrderModal
				isOpen={isOrderModalOpen}
				onRequestClose={toggleOrderModal}
				width="90vw">
				<OrderEditable
					order={order}
					isScrollable
					disabled={!canConfirmPicking}
					onClose={() => setIsOrderModalOpen(false)}
					footer={
						<HowLoadOrderWithoutPhoneCard className="justify-center mx-5 my-4" />
					}
					orderStatus={StationStatus.COMPLETED}
					actions={
						<>
							<Box mb={2}>
								<Action
									type={ActionType.REPRINT_ORDER}
									onClick={handlePrint}
									displayStyle="fullWidth"
								/>
							</Box>

							{order.delivery_type === DeliveryType.PICKUP ? (
								<Action
									type={ActionType.PICKED_UP_CHECK}
									onClick={handlePickup}
									isLoading={order.picked_up}
									displayStyle="fullWidth"
									variant="contained"
								/>
							) : null}
						</>
					}
					hideTimer
				/>
			</OrderModal>
		</>
	)
}
