import { memo, useEffect, useState } from 'react'

import { Typography } from '@mui/material'

import cx from 'classnames'
import dayjs from 'dayjs'

import { OrderLabel } from '.'
import { Order } from '../../../@types/Order'
import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { orderIsLate, orderShouldBeDoneInSeconds } from '../../../utils/order'
import { useAppSelector } from '../../../utils/storeHelpers'
import { Time } from '../../Time'
import { useOrderTimer } from './useOrderTimer'

const emptyObject = {}

const LATENESS_CHECK_TICK_MS = 5000

export const OrderLabelTimer = memo<{ order: Order }>(({ order }) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderLabelTimerRevampedView order={order} />
	}

	return <OrderLabelTimerView order={order} />
})

const OrderLabelTimerView = memo<{ order: Order }>(({ order }) => {
	const [now, setNow] = useState(dayjs())

	const merchantSla = useAppSelector(
		(state) =>
			state.serviceAssignment.serviceAssignmentsById[
				order.serviceassignment_id
			]?.serviceassignment_merchant_sla ?? emptyObject
	)

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(dayjs())
		}, LATENESS_CHECK_TICK_MS)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const preparationTimeNeeded = orderShouldBeDoneInSeconds({
		order,
		merchantSla
	})

	const formattedPreparationTimeNeeded = `${Math.floor(
		preparationTimeNeeded / 60
	)}min`

	const isLate = orderIsLate({ order, merchantSla, now })
	const elapsedClasses = cx('font-bold', isLate ? 'text-red' : undefined)

	return (
		<OrderLabel
			iconClassName={isLate ? '!text-red !opacity-100' : undefined}
			icon="timer"
			label={
				<>
					<span className={elapsedClasses}>
						<Time visibleAt={order.visible_at} />
					</span>
					{' / '}
					<span>{formattedPreparationTimeNeeded}</span>
				</>
			}
		/>
	)
})

const OrderLabelTimerRevampedView = memo<{ order: Order }>(({ order }) => {
	// TODO: check order late labels later
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { isLate, formattedPreparationTimeNeeded } = useOrderTimer(order)

	return (
		<OrderLabel
			label={
				<>
					{/* TODO: paint red if late? */}
					<Typography variant="body1" display="inline">
						<Time visibleAt={order.visible_at} />
					</Typography>
					<Typography variant="body1" display="inline">
						{' / '}
					</Typography>
					<Typography variant="body1" display="inline">
						{formattedPreparationTimeNeeded}
					</Typography>
				</>
			}
		/>
	)
})
