import { memo, useMemo } from 'react'

import { Box, Grid as MUIGrid } from '@mui/material'

import { groupBy } from 'lodash'

import { StationStatus } from '../../../@types/Order'
import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { getStationStatus } from '../../../selectors'
import { useAppSelector } from '../../../utils/storeHelpers'
import { sortOrders, sortPreparingOrders } from '../utils'
import { Column } from './Column'
import { Grid } from './Grid'
import { OrdersInProgress } from './OrdersInProgress'
import { OrdersNew } from './OrdersNew'
import { OrdersReady } from './OrdersReady'
import { ColumnsProps } from './types'

export const Columns = memo<ColumnsProps>((props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <ColumnsRevampedView {...props} />
	}

	return <ColumnsView {...props} />
})

const ColumnsView = memo<ColumnsProps>(
	({ loadingArea, loadingStation, onPrint, onStatusChange }) => {
		const orders = useAppSelector((state) =>
			getStationStatus(state, {
				loadingarea: loadingArea,
				loadingstation: loadingStation
			})
		)

		const ordersGrouped = useMemo(() => {
			return groupBy(orders, (order) => order.station_status_hr)
		}, [orders])

		const ordersNew = useMemo(() => {
			return sortOrders(ordersGrouped[StationStatus.CREATED])
		}, [ordersGrouped])

		const ordersInProgress = useMemo(() => {
			return sortPreparingOrders(ordersGrouped[StationStatus.PREPARING])
		}, [ordersGrouped])

		const ordersCompleted = useMemo(() => {
			return sortOrders(ordersGrouped[StationStatus.COMPLETED])
		}, [ordersGrouped])

		return (
			<div className="border-t-[1px] border-solid border-t-grey-light">
				<Grid className="h-[calc(100vh_-_128px)] lg:h-[calc(100vh_-_64px)]">
					<Column className="col-span-5 h-[calc(100vh-260px-128px)] lg:col-span-5">
						<OrdersNew
							orders={ordersNew}
							onStatusChange={onStatusChange}
						/>
					</Column>

					<Column className="col-span-7 h-[calc(100vh-260px-128px)] lg:col-span-8">
						<OrdersInProgress
							orders={ordersInProgress}
							onStatusChange={onStatusChange}
							onPrint={onPrint}
						/>
					</Column>

					<Column className="col-span-12 h-[260px] lg:col-span-3">
						<OrdersReady
							orders={ordersCompleted}
							onPrint={onPrint}
						/>
					</Column>
				</Grid>
			</div>
		)
	}
)

const ColumnsRevampedView = memo<ColumnsProps>(
	({
		loadingArea,
		loadingStation,
		onPrint,
		onStatusChange,
		onItemFulfil,
		isFulfillingError,
		isFulfilling
	}) => {
		const orders = useAppSelector((state) =>
			getStationStatus(state, {
				loadingarea: loadingArea,
				loadingstation: loadingStation
			})
		)

		const ordersGrouped = useMemo(() => {
			return groupBy(orders, (order) => order.station_status_hr)
		}, [orders])

		const ordersNew = useMemo(() => {
			return sortOrders(ordersGrouped[StationStatus.CREATED])
		}, [ordersGrouped])

		const ordersInProgress = useMemo(() => {
			return sortPreparingOrders(ordersGrouped[StationStatus.PREPARING])
		}, [ordersGrouped])

		const ordersCompleted = useMemo(() => {
			return sortOrders(ordersGrouped[StationStatus.COMPLETED])
		}, [ordersGrouped])

		return (
			<Box>
				<MUIGrid container columns={10}>
					<Column
						lg={3}
						sm={4}
						sx={{
							height: {
								lg: 'calc(100vh - 64px)',
								sm: 'calc(100vh - 64px - 64px - 260px)'
							}
						}}>
						<OrdersNew
							orders={ordersNew}
							onStatusChange={onStatusChange}
						/>
					</Column>
					<Column
						lg={4}
						sm={6}
						sx={{
							height: {
								lg: 'calc(100vh - 64px)',
								sm: 'calc(100vh - 64px - 64px - 260px)'
							}
						}}>
						<OrdersInProgress
							orders={ordersInProgress}
							onStatusChange={onStatusChange}
							onPrint={onPrint}
							onItemFulfil={onItemFulfil}
							isFulfilling={isFulfilling}
							isFulfillingError={isFulfillingError}
						/>
					</Column>
					<Column
						lg={3}
						sm={10}
						sx={{
							height: {
								lg: 'calc(100vh - 64px)',
								sm: '260px'
							}
						}}>
						<OrdersReady
							orders={ordersCompleted}
							onPrint={onPrint}
						/>
					</Column>
				</MUIGrid>
			</Box>
		)
	}
)
