import { LineItem } from '../../@types/LineItem'
import { PackagingProvider } from '../../@types/Packaging'
import { parseNoteValue } from './parseNoteValue'

export const isLineItemReusePassEnabled = (lineItem: LineItem) => {
	const packaging = parseNoteValue(lineItem.note).packaging

	return (
		packaging &&
		packaging.QRCode &&
		packaging.active &&
		packaging.providers?.includes(PackagingProvider.REUSEPASS)
	)
}
