import { Order } from '../../@types/Order'
import { Robot } from '../../@types/Robot'

export type OrderAlertProps = {
	order: Order
}

export type OrderAlertWithSoundProps = {
	order: Order
	robot?: Robot
}

export type OrderAlertInnerProps = {
	robot?: Robot
	type: OrderAlertLabelType
}

export enum OrderAlertLabelType {
	CUSTOMER_EXPECTED = 'CUSTOMER_EXPECTED',
	ROBOT_LATER = 'ROBOT_LATER',
	ROBOT_SOON = 'ROBOT_SOON',
	ROBOT_WAITING = 'ROBOT_WAITING'
}
