import { FC, useMemo } from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { sumBy } from 'lodash'

import { OrderStatusHR } from '../../../../../../../@types/Order'
import { Icon } from '../../../../../../../components/Icon'
import { Action } from '../../../../../../../components/OrderRevamp/Action'
import { ActionType } from '../../../../../../../components/OrderRevamp/Action/types'
import { OrderLabelTimer } from '../../../../../../../components/OrderRevamp/OrderLabel/OrderLabelTimer'
import { OrderSmallProps } from '../types'
import { OrderId } from './OrderId'

export const OrderSmallContent: FC<OrderSmallProps> = ({
	order,
	action,
	renderAlert,
	onViewClick,
	rightAction,
	hideTimer
}) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED

	const itemCount = useMemo(() => {
		return sumBy(order.lineitems, (lineItem) => lineItem.qty)
	}, [order.lineitems])

	return (
		<>
			<Box
				onClick={isCancelled ? undefined : onViewClick}
				display="flex"
				flex={1}
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
				px={3}
				py={2.5}>
				<Box>
					<Box display="flex" flexDirection="row">
						<OrderId
							orderId={order.order_id}
							kioskOrderId={order.kiosk_order_id}
						/>

						<Box
							display="flex"
							flexDirection="row"
							alignItems="center"
							ml={1}>
							<Icon icon="burger" width={18} height={18} />
							<Typography
								variant="subtitle1"
								color="secondary"
								ml={0.25}
								fontWeight="bold">
								{itemCount}
							</Typography>
						</Box>
					</Box>

					<Box display="flex" flexDirection="column">
						{hideTimer ? null : <OrderLabelTimer order={order} />}

						{renderAlert}
					</Box>
				</Box>

				<Stack
					ml={2}
					spacing={1}
					direction="row"
					justifyContent="flex-end"
					alignItems="center">
					{isCancelled ? (
						<Action type={ActionType.CANCELLED} />
					) : (
						<>{action}</>
					)}
				</Stack>
			</Box>
			{rightAction ?? null}
		</>
	)
}
