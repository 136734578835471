import { initial, last } from 'lodash'

import { ParsedWorkingHour } from '../../@types/WorkingHours'

// [12:00 - 14:00, 14:00 - 22:00] will be turned to [12:00 - 22:00]
// [12:00 - 23:59, 00:00 - 02:00] will be turned to [12:00 - 02:00]
// The latter is due to the fact that in panel we cannot set the end time to 00:00
export const mergeContinuousHours = (workingHours: ParsedWorkingHour[]) => {
	return workingHours.reduce<ParsedWorkingHour[]>((accumulator, date) => {
		const lastDate = last(accumulator)

		if (!lastDate) {
			return [...accumulator, date]
		}

		const dateDiff = date.dateStart.diff(lastDate.dateEnd, 'seconds')

		if (dateDiff >= 0 && dateDiff <= 60) {
			return [
				...initial(accumulator),
				{ dateStart: lastDate.dateStart, dateEnd: date.dateEnd }
			]
		}

		return [...accumulator, date]
	}, [])
}
