import { useCallback, useEffect, useState } from 'react'

import { NotificationsActive, NotificationsNone } from '@mui/icons-material'
import {
	Badge,
	Button,
	Dialog,
	DialogContent,
	List,
	Typography
} from '@mui/material'

import { MessageStep } from '../../../@types/Message'
import {
	useGetMessagesQueryWithInterval,
	useMarkMessagesDeliveredMutation
} from '../../../queries/messages'
import { analytics } from '../../../services/analytics'
import { useAppSelector } from '../../../utils/storeHelpers'
import { DialogTitleWithClose } from '../../DialogTitleWithClose'
import { CustomReactJoyride } from '../../GuidedTour/CustomReactJoyride'
import { AlertListItem } from './AlertListItem'

export const Alerts = () => {
	useEffect(() => {
		analytics.trackEvent('View Alerts')
	}, [])

	const serviceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)
	const { data: messages = [] } = useGetMessagesQueryWithInterval({
		serviceAssignmentId: serviceAssignmentId ?? ''
	})

	const { mutateAsync: markMessagesDelivered } =
		useMarkMessagesDeliveredMutation()

	const [isOpen, setIsOpen] = useState(false)
	const [activeSteps, setActiveSteps] = useState<MessageStep[]>([])

	const unseenMessages = messages.filter((message) => !message.delivered_at)
	const unseenMessageCount = unseenMessages.length
	const hasUnseenMessages = unseenMessageCount > 0

	const closeDialog = useCallback(() => {
		setIsOpen(false)

		if (hasUnseenMessages) {
			markMessagesDelivered({
				messageIds: unseenMessages.map((message) => message.id),
				serviceAssignmentId: serviceAssignmentId ?? ''
			})
		}
	}, [
		hasUnseenMessages,
		markMessagesDelivered,
		serviceAssignmentId,
		unseenMessages
	])

	const openDialog = useCallback(() => {
		setIsOpen(true)
	}, [])

	const handleStartTourPress = useCallback(
		(steps: MessageStep[]) => {
			setActiveSteps(steps)
			closeDialog()
		},
		[closeDialog]
	)

	return (
		<>
			<Button
				id="alerts-button"
				sx={{ height: 46 }}
				color={hasUnseenMessages ? 'primary' : 'secondary'}
				variant={hasUnseenMessages ? 'contained' : 'outlined'}
				onClick={openDialog}
				startIcon={
					hasUnseenMessages ? (
						<NotificationsActive />
					) : (
						<NotificationsNone color="primary" />
					)
				}
				endIcon={
					<Badge
						badgeContent={unseenMessageCount}
						showZero
						sx={{
							ml: 2,
							mr: 1.5
						}}
						color={hasUnseenMessages ? 'error' : 'primary'}
					/>
				}>
				<Typography
					color={hasUnseenMessages ? 'inherit' : 'text.primary'}>
					Alerts
				</Typography>
			</Button>

			<Dialog open={isOpen} onClose={closeDialog} fullWidth maxWidth="md">
				<DialogTitleWithClose title="Alerts" onClose={closeDialog} />
				<DialogContent dividers sx={{ padding: 0 }}>
					<List disablePadding>
						{messages.map((message) => (
							<AlertListItem
								key={message.id}
								message={message}
								onStartTourPress={handleStartTourPress}
							/>
						))}
					</List>
				</DialogContent>
			</Dialog>

			{activeSteps.length ? (
				<CustomReactJoyride
					continuous
					callback={(data) => {
						if (['reset', 'close'].includes(data.action)) {
							setActiveSteps([])
						}
					}}
					steps={activeSteps.map((step) => ({
						...step,
						disableBeacon: true
					}))}
				/>
			) : null}
		</>
	)
}
