import { FC, useState } from 'react'

import { OpenStatusMap } from '../../../@types/OpenStatusMap'
import styles from '../index.module.scss'
import { PageState } from '../types'
import { AcceptInitialContent } from './AcceptInitialContent'
import { ChooseTimeContent } from './ChooseTimeContent'
import { EnterPinCodeContent } from './EnterPinCodeContent'
import { PausedBackContent } from './PausedBackContent'
import { PausedInitialContent } from './PausedInitialContent'
import { Props } from './types'
import { usePausing } from './usePausing'

export const ChangeStatusModal: FC<Props> = ({
	serviceAssignmentId,
	status,
	closeModal,
	paused,
	updateStatus
}) => {
	const [pin, setPin] = useState<string>('')
	const [error, setError] = useState<string | undefined>()
	const [currentPage, setCurrentPage] = useState(
		status === OpenStatusMap.CLOSED_FOR_ORDERS
			? PageState.PAUSED_INITIAL
			: PageState.ACCEPTING_INITIAL
	)
	const { pause, accept } = usePausing({
		serviceAssignmentId,
		pin,
		onSuccess: () => {
			updateStatus()
			closeModal()
		},
		onError: (message) => {
			setError(message)
		}
	})

	const onPinEntered = (pin: string) => {
		setPin(pin)
		setCurrentPage(PageState.ACCEPTING_SELECT_TIME)
	}

	const buttonActions = (pageState: PageState) => {
		switch (pageState) {
			case PageState.ACCEPTING_INITIAL: {
				return {
					normalActive: true,
					pausedActive: false,
					pausedClickAction: () => {
						setCurrentPage(PageState.ACCEPTING_PIN_CODE)
					}
				}
			}
			case PageState.ACCEPTING_PIN_CODE:
			case PageState.ACCEPTING_SELECT_TIME: {
				return {
					normalActive: false,
					pausedActive: true,
					normalClickAction: () => {
						setPin('')
						setError(undefined)

						setCurrentPage(PageState.ACCEPTING_INITIAL)
					}
				}
			}
			case PageState.PAUSED_INITIAL: {
				return {
					normalActive: false,
					pausedActive: true,
					normalClickAction: () => {
						setPin('')
						setError(undefined)

						setCurrentPage(PageState.PAUSED_BACK_TO_NORMAL)
					}
				}
			}
			case PageState.PAUSED_BACK_TO_NORMAL: {
				return {
					normalActive: true,
					pausedActive: false,
					pausedClickAction: () => {
						setCurrentPage(PageState.PAUSED_INITIAL)
					}
				}
			}
			default: {
				return {
					normalActive: false,
					pausedActive: false
				}
			}
		}
	}

	const content = (pageState: PageState) => {
		switch (pageState) {
			case PageState.ACCEPTING_INITIAL: {
				return <AcceptInitialContent closeModal={closeModal} />
			}
			case PageState.ACCEPTING_PIN_CODE: {
				return <EnterPinCodeContent onPinEntered={onPinEntered} />
			}
			case PageState.ACCEPTING_SELECT_TIME: {
				return (
					<ChooseTimeContent
						closeModal={closeModal}
						onUpdate={pause}
						error={error}
					/>
				)
			}
			case PageState.PAUSED_INITIAL: {
				return (
					<PausedInitialContent
						closeModal={closeModal}
						paused={paused}
					/>
				)
			}
			case PageState.PAUSED_BACK_TO_NORMAL: {
				return (
					<PausedBackContent
						closeModal={closeModal}
						onUpdate={accept}
						error={error}
					/>
				)
			}
			default: {
				return null
			}
		}
	}

	const { normalActive, pausedActive, normalClickAction, pausedClickAction } =
		buttonActions(currentPage)

	return (
		<div className={styles.changeStatusModal}>
			<div className={styles.leftMenu}>
				<div
					className={`${styles.acceptingButton} ${
						normalActive ? styles.active : ''
					}`}
					onClick={normalClickAction}>
					Accepting orders
					<div className={styles.status}>Normal</div>
				</div>
				<div
					className={`${styles.pausedButton} ${
						pausedActive ? styles.active : ''
					}`}
					onClick={pausedClickAction}>
					No new orders
					<div className={styles.status}>Paused</div>
				</div>
			</div>
			<div className={styles.rightBlock}>{content(currentPage)}</div>
		</div>
	)
}
