import React, { ChangeEvent, useCallback, useState } from 'react'

import {
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	RadioGroup,
	TextField,
	Typography
} from '@mui/material'

import { Dialog } from '../../../../../../../components/Dialog'
import { CancellationReason } from '../types'
import { ReasonFormControlLabel } from './ReasonFormControlLabel'
import { Props } from './types'

export const CancelOrderReasonDialog = ({ onOkClick, isLoading }: Props) => {
	const [reason, setReason] = useState<CancellationReason | ''>('')
	const [description, setDescription] = useState<string>('')

	const [markAllItemsOOS, setMarkAllItemsOOS] = useState(true)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setReason(
			(event.target as HTMLInputElement).value as CancellationReason
		)
	}

	const handleOtherTextFieldFocus = useCallback(() => {
		setReason(CancellationReason.OTHER)
	}, [])

	const handleDescriptionChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setDescription(event.target.value)
		},
		[]
	)

	const isDisabled =
		reason === '' ||
		(reason === CancellationReason.OTHER && description.trim() === '')

	return (
		<Dialog
			title="Why did you need to cancel?"
			actions={[
				{
					title: 'Ok',
					onClick: () => {
						if (isDisabled) return

						onOkClick({
							reason,
							description,
							markAllItemsOOS:
								reason === 'ALL_ITEMS_WERE_OUT_OF_STOCK' &&
								markAllItemsOOS
						})
					},
					disabled: isDisabled,
					isLoading
				}
			]}
			content={
				<FormControl sx={{ width: '100%', mt: 2 }}>
					<RadioGroup value={reason} onChange={handleChange}>
						<ReasonFormControlLabel
							value={CancellationReason.TOO_BUSY}
							label="Too busy"
						/>
						<ReasonFormControlLabel
							value={
								CancellationReason.ALL_ITEMS_WERE_OUT_OF_STOCK
							}
							label="All items were out of stock"
						/>
						<ReasonFormControlLabel
							value={CancellationReason.OTHER}
							children={
								<Box
									sx={{
										flexDirection: 'column',
										flex: 1,
										display: 'flex',
										pr: 2
									}}>
									<Typography mb={1} fontSize={18}>
										Other
									</Typography>
									<TextField
										onFocus={handleOtherTextFieldFocus}
										fullWidth
										placeholder="Add comment"
										value={description}
										onChange={handleDescriptionChange}
									/>
								</Box>
							}
						/>
					</RadioGroup>
				</FormControl>
			}
			footer={
				<>
					{reason === '' ? (
						<Typography color="error" textAlign="center" mb={2}>
							Select a reason
						</Typography>
					) : null}

					{reason === CancellationReason.OTHER &&
					description.trim() === '' ? (
						<Typography color="error" textAlign="center" mb={2}>
							Please, describe why you would like to cancel.
						</Typography>
					) : null}

					{/* TODO: come back to that dialog once we have procedure to pause merchant. Just collect data for now. */}
					{/*{reason === CancellationReason.TOO_BUSY ? (*/}
					{/*	<Box*/}
					{/*		sx={{*/}
					{/*			backgroundColor: 'primary.light',*/}
					{/*			borderRadius: 2,*/}
					{/*			p: 2,*/}
					{/*			mb: 2,*/}
					{/*			alignItems: 'center',*/}
					{/*			display: 'flex',*/}
					{/*			flexDirection: 'column'*/}
					{/*		}}>*/}
					{/*		<Typography textAlign="center">*/}
					{/*			If you are currently too busy to accept new*/}
					{/*			orders, consider pausing your service*/}
					{/*		</Typography>*/}

					{/*		<Button*/}
					{/*			variant="text"*/}
					{/*			color="primary"*/}
					{/*			startIcon={<InfoOutlined color="primary" />}>*/}
					{/*			<Typography fontWeight={600} textAlign="center">*/}
					{/*				How to pause service*/}
					{/*			</Typography>*/}
					{/*		</Button>*/}
					{/*	</Box>*/}
					{/*) : null}*/}

					{reason ===
					CancellationReason.ALL_ITEMS_WERE_OUT_OF_STOCK ? (
						<FormControl sx={{ mb: 2 }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={markAllItemsOOS}
										onChange={(_, checked) =>
											setMarkAllItemsOOS(checked)
										}
									/>
								}
								label={
									'Mark all items form this order out of stock for future orders'
								}
							/>
						</FormControl>
					) : null}
				</>
			}
			padContent={false}
		/>
	)
}
