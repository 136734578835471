import { useMutation } from '@tanstack/react-query'

import { LineItemFulfilment } from '../@types/LineItem'
import { fetchServer } from '../api/fetchServer'
import { useAppSelector } from '../utils/storeHelpers'

export const useItemsFulfilment = () => {
	const loadingAreaId = useAppSelector(
		(state) => state.loadingArea.selectedLoadingAreaId
	)

	return useMutation({
		mutationKey: ['lineItemFulfilment'],
		mutationFn: (options: {
			orderId: number
			lineItemFulfilment: LineItemFulfilment[]
		}) =>
			fetchServer(
				`/v1/loadingareas/${loadingAreaId}/orders/${options.orderId}/lineitems/fulfill`,
				'POST',
				options.lineItemFulfilment.map((lineItemFulfilment) => ({
					lineItemId: lineItemFulfilment.lineItemId.toString(10),
					qty: lineItemFulfilment.qty
				}))
			)
	})
}
