import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import reducers from './reducers'

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type GetAppState = () => RootState

export const store = configureStore({
	reducer: reducers,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk]
})
