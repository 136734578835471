import { useQuery } from '@tanstack/react-query'

import { Robot } from '../@types/Robot'
import { fetchServer } from '../api/fetchServer'

const ROBOTS_POLLING_INTERVAL_MS = 1000 * 30 // 30 seconds

const BASE_QUERY = 'robots'

export const useGetRobotsQuery = (input: { loadingAreaId: string }) => {
	return useQuery([BASE_QUERY, input], () => getRobots(input))
}

export const useGetRobotsQueryWithInterval = (input: {
	loadingAreaId: string
}) => {
	return useQuery([BASE_QUERY, input], () => getRobots(input), {
		enabled: !!input.loadingAreaId,
		refetchInterval: ROBOTS_POLLING_INTERVAL_MS
	})
}

const getRobots = (input: { loadingAreaId: string }) => {
	return fetchServer<{ data: { robots: Robot[] } }>(
		`/v1/loadingareas/${input.loadingAreaId}/robots`
	)
}
