import { FC } from 'react'

import { hasTestOrder } from '../../../utils/order'
import { CustomReactJoyride } from '../CustomReactJoyride'
import { TestOrderGuidedTourProps } from '../types'

export const TestOrderNewGuidedTour: FC<TestOrderGuidedTourProps> = ({
	orders
}) => {
	if (!hasTestOrder(orders)) {
		return null
	}

	return (
		<CustomReactJoyride
			continuous={true}
			steps={[
				{
					target: '.test-order',
					content: 'Newly created order appears in the first column'
				},
				{
					target: '.test-order [data-testid="ActionViewOrder"]',
					content:
						'Tapping "View" will show you details of the order"'
				},
				{
					target: '.test-order [data-testid="ActionOrderSideButton"]',
					content:
						'Tap "Prepare" to move the order to the "In Progress" stage.'
				}
			]}
		/>
	)
}
