import { FC, ReactNode } from 'react'

export const OrderScrollContainer: FC<{
	children?: ReactNode
	isEnabled?: boolean
}> = ({ children, isEnabled }) => {
	if (isEnabled) {
		return <div className="max-h-[45vh] overflow-scroll">{children}</div>
	}

	return <>{children}</>
}
