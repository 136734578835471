import { useCallback, useState } from 'react'

import { enqueueSnackbar } from 'notistack'

import { InventoryStatus } from '../../../../../../@types/Item'
import { updateItemStatuses } from '../../../../../../actions'
import { useAppSelector } from '../../../../../../utils/storeHelpers'
import { CancelOrderConfirmationDialog } from './CancelOrderConfirmationDialog'
import { CancelOrderReasonDialog } from './CancelOrderReasonDialog'
import { CancellationReason, CancellationStage, Props } from './types'
import { useCancelOrder } from './useCancelOrder'

// TODO: add analytics
export const CancelOrderFlow = ({
	setIsOpen,
	isOpen,
	order,
	onCancelOrder
}: Props) => {
	const selectedServiceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)
	const { mutateAsync: cancelOrder } = useCancelOrder()
	const [isLoading, setIsLoading] = useState(false)

	const [stage, setStage] = useState<CancellationStage>('CONFIRM')

	const handleOnCancelOrder = useCallback(
		async ({
			reason,
			description,
			markAllItemsOOS
		}: {
			reason: CancellationReason
			description: string
			markAllItemsOOS: boolean
		}) => {
			const itemsToPutOOS = new Set(
				order.lineitems.map((lineItem) => lineItem.item)
			)

			setIsLoading(true)
			const [cancellationResult, oosResult] = await Promise.allSettled<
				[]
			>([
				cancelOrder({
					order,
					reason,
					description
				}),
				...(markAllItemsOOS
					? [
							updateItemStatuses({
								itemIds: [...itemsToPutOOS],
								serviceAssignmentId:
									selectedServiceAssignmentId,
								status: InventoryStatus.OUT_OF_STOCK
							})
					  ]
					: [])
			])

			setIsLoading(false)

			if (cancellationResult.status === 'rejected') {
				enqueueSnackbar(
					`Order ${order._id} could not be cancelled. Please, try again.`,
					{
						variant: 'error',
						autoHideDuration: 5000,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'bottom'
						}
					}
				)
				return
			}

			if (markAllItemsOOS && oosResult?.status === 'rejected') {
				enqueueSnackbar(
					`An error occurred when trying to put items Out of Stock. Please, try again later.`,
					{
						variant: 'error',
						autoHideDuration: 5000,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'bottom'
						}
					}
				)
			}

			enqueueSnackbar(`Order ${order._id} has been cancelled.`, {
				variant: 'success',
				autoHideDuration: 5000,
				anchorOrigin: { horizontal: 'center', vertical: 'bottom' }
			})
			setStage('CONFIRM')
			setIsOpen(false)
			onCancelOrder?.()
		},
		[
			cancelOrder,
			onCancelOrder,
			order,
			selectedServiceAssignmentId,
			setIsOpen
		]
	)

	if (!isOpen) return null

	return (
		<>
			{stage === 'CONFIRM' ? (
				<CancelOrderConfirmationDialog
					order={order}
					onCancelOrderClick={() => setStage('REASON')}
					onCancelClick={() => setIsOpen(false)}
				/>
			) : null}

			{stage === 'REASON' ? (
				<CancelOrderReasonDialog
					onOkClick={handleOnCancelOrder}
					isLoading={isLoading}
				/>
			) : null}
		</>
	)
}
