import { PackagingProvider } from './Packaging'

export enum FulfillmentStatus {
	CAN_FULFILL = 'CAN_FULFILL',
	CAN_NOT_FULFILL = 'CAN_NOT_FULFILL'
}

export type LineItem = {
	_id: number
	item: string
	name: string
	note: string
	qty: number
	fulfilled_qty: number
}

export type LineItemFulfilment = {
	lineItemId: number
	qty: number
}

export type LineItemPackaging = {
	active?: boolean
	QRCode?: string
	providers?: PackagingProvider[]
}
