import { FC } from 'react'

import cx from 'classnames'

import { LoadingIndicatorV2 } from '../LoadingIndicatorV2'
import { Props } from './types'

export const Button: FC<Props> = ({
	caption,
	onClick,
	isLoading,
	type,
	isSmallText,
	icon
}) => {
	const classes = cx(
		'cursor-pointer rounded-3xl p-2 text-white flex justify-center',
		{
			'bg-purple-cornflowerSmall': type === 'default',
			'bg-red': type === 'warning',
			'border border-solid border-purple-cornflowerSmall/[0.5] bg-grey-veryLight text-purple-cornflowerSmall':
				type === 'outlined',
			'text-grey bg-grey-veryLight': type === 'dimmed',
			'text-md': !!isSmallText,
			'text-lg': !isSmallText
		}
	)
	return (
		<div className={classes} onClick={onClick}>
			{isLoading ? (
				<LoadingIndicatorV2 />
			) : (
				<div className="flex flex-nowrap flex-row">
					{icon && <span className="pr-2 inline-flex">{icon}</span>}
					{caption}
				</div>
			)}
		</div>
	)
}
