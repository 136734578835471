import { useCallback, useEffect, useMemo, useState } from 'react'

import { ConnectedPrinter, PrinterConnectionType } from '../../@types/Printer'
import { updateConnectedPrinterInfo } from '../../components/App'
import {
	PrinterConnectionInterface,
	PrinterStatus
} from '../../components/PrinterStatusContext/types'
import { usePrevious } from '../../utils/usePrevious'
import { PRINTER_STATUS_UPDATE } from './types'

const convertPrinterInterfaceToPrinterConnectionType = (
	printerInterface?: PrinterConnectionInterface
): PrinterConnectionType => {
	if (printerInterface === 'USB') return 'USB'
	if (printerInterface === 'BLUETOOTH') return 'Bluetooth'
	return 'No printer'
}

const arePrinterStatesDifferent = (
	stateA: PrinterStatus | null | undefined,
	stateB: PrinterStatus | null | undefined
) => {
	return (
		stateA?.printerModel !== stateB?.printerModel ||
		stateA?.isOnline !== stateB?.isOnline ||
		stateA?.connectionInterface !== stateB?.connectionInterface
	)
}

export const usePrinterStatus = (
	shouldUsePrinter: boolean
): { printerStatus: PrinterStatus | null | undefined } => {
	const [printerStatus, setPrinterStatus] = useState<PrinterStatus | null>()
	const previousPrinterStatus = usePrevious(printerStatus)
	const [printerStatusUpdated, setPrinterStatusUpdated] = useState(false)

	useEffect(() => {
		if (!shouldUsePrinter) {
			setPrinterStatus(null)
			setPrinterStatusUpdated(false)
		}
	}, [shouldUsePrinter])

	const printerStatusMemoized = useMemo(() => {
		if (!printerStatus && !printerStatusUpdated)
			return { printerStatus: undefined }

		if (!printerStatus) return { printerStatus: null }

		return { printerStatus }
	}, [printerStatus, printerStatusUpdated])

	const messageListener = useCallback(
		(event: any) => {
			if (!shouldUsePrinter) return
			if (event.type !== 'message') return
			try {
				const data = JSON.parse(event?.data)

				if (data?.type === PRINTER_STATUS_UPDATE) {
					setPrinterStatus((data.payload as PrinterStatus) ?? null)
					setPrinterStatusUpdated(true)
				}
			} catch {}
		},
		[shouldUsePrinter]
	)

	useEffect(() => {
		window.addEventListener('message', messageListener)
		return () => window.removeEventListener('message', messageListener)
	}, [messageListener])

	useEffect(() => {
		if (!printerStatusUpdated) return

		if (
			arePrinterStatesDifferent(
				previousPrinterStatus,
				printerStatusMemoized.printerStatus
			)
		) {
			const connectedPrinter: ConnectedPrinter = {
				printerModel: printerStatusMemoized.printerStatus?.printerModel,
				connectionType: convertPrinterInterfaceToPrinterConnectionType(
					printerStatusMemoized.printerStatus?.connectionInterface
				),
				isOnline: printerStatusMemoized.printerStatus?.isOnline
			}
			updateConnectedPrinterInfo(connectedPrinter)
		}
	}, [
		previousPrinterStatus,
		previousPrinterStatus?.connectionInterface,
		previousPrinterStatus?.isOnline,
		previousPrinterStatus?.printerModel,
		printerStatusMemoized.printerStatus,
		printerStatusMemoized.printerStatus?.connectionInterface,
		printerStatusMemoized.printerStatus?.isOnline,
		printerStatusMemoized.printerStatus?.printerModel,
		printerStatusUpdated
	])

	return printerStatusMemoized
}
