import { useMemo } from 'react'

import { useAppSelector } from '../utils/storeHelpers'
import { useVenuesConfigurationQuery } from '../utils/venuesConfiguration/useVenuesConfigurationQuery'

export const useVenue = () => {
	const selectedServiceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)

	const { data: venuesData } = useVenuesConfigurationQuery(
		selectedServiceAssignmentId ? [selectedServiceAssignmentId] : []
	)

	return useMemo(() => {
		return {
			venue: selectedServiceAssignmentId
				? venuesData?.[selectedServiceAssignmentId] ?? null
				: null
		}
	}, [selectedServiceAssignmentId, venuesData])
}
