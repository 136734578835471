import React from 'react'
import { VariableSizeList } from 'react-window'

import _groupBy from 'lodash/groupBy'
import _sum from 'lodash/sum'
import PropTypes from 'prop-types'

import '../Radio/Radio.scss'
import ItemRenderer from './ItemRenderer'

const ITEM_UI_STATE_COLLAPSED = 0
const ITEM_UI_STATE_DEFAULT = 1
const ITEM_UI_STATE_SHOW_ALL = 2
const ITEM_HEIGHT = 56
const MODIFIER_VALUE_HEIGHT = 41
const ITEM_SEPARATOR_HEIGHT = 7
class InventoryList extends React.Component {
	state = {
		expandedItems: {},
		scrollableHeight: 0,
		categoryHeight: 0
	}

	listRef = React.createRef()
	shouldComponentUpdate(nextProps) {
		const update =
			nextProps.listUpdate !== this.props.listUpdate ||
			nextProps.items.length !== this.props.items.length

		if (update) {
			this.resetItemHeightCache()
		}
		return true
	}

	resetItemHeightCache = () => {
		if (this.listRef && this.listRef.current) {
			this.listRef.current.resetAfterIndex(0, true)
			this.itemHeightCache = []
		}
	}
	getItemSize = (item) => {
		if (item.type === 'modifier') {
			return item.values.length * MODIFIER_VALUE_HEIGHT
		} else if (item.type === 'page-header') {
			// add space for the extra toggle when using text search
			//const searchFilterToggleHeight = this.props.searchTerm ? 56 : 0;
			return this.state.categoryHeight + 289 // + searchFilterToggleHeight;
		} else if (item.type === 'header') {
			return ITEM_HEIGHT + 20
		} else if (item.type === 'hidden-modifiers') {
			return MODIFIER_VALUE_HEIGHT
		} else if (item.type === 'item-separator') {
			return ITEM_SEPARATOR_HEIGHT
		}
		return ITEM_HEIGHT
	}
	handleItemExpanded = (collapsed, itemId) => {
		const itemStatus = this.state.expandedItems[itemId]
		let nextStatus
		if (itemStatus >= 0) {
			if (
				itemStatus === ITEM_UI_STATE_SHOW_ALL ||
				itemStatus === ITEM_UI_STATE_DEFAULT
			) {
				nextStatus = ITEM_UI_STATE_COLLAPSED
			} else if (itemStatus === ITEM_UI_STATE_COLLAPSED) {
				nextStatus = ITEM_UI_STATE_DEFAULT
			}
		} else {
			// status not set manually
			nextStatus = collapsed
				? ITEM_UI_STATE_COLLAPSED
				: ITEM_UI_STATE_DEFAULT
		}

		this.resetItemHeightCache()
		this.setState(
			{
				expandedItems: {
					...this.state.expandedItems,
					[itemId]: nextStatus
				}
			},
			() => {}
		)
	}
	handleShowAll = (itemId) => {
		this.setState(
			{
				expandedItems: {
					...this.state.expandedItems,
					[itemId]: ITEM_UI_STATE_SHOW_ALL
				}
			},
			() => {}
		)
		this.resetItemHeightCache()
	}
	oosItemsForAssignment(assignmentId) {
		return this.props.items
			.filter((item) => item.service_assignment === assignmentId)
			.filter(
				(item) =>
					item.status === 5 ||
					(item.modifiers &&
						item.modifiers.find(
							(modifier) =>
								modifier.values &&
								modifier.values.find((value) => value.disabled)
						))
			)
	}
	oosItemCountWithModifiers(assignmentId) {
		const items = this.props.items
			.filter((item) => item.service_assignment === assignmentId)
			.filter(
				(item) =>
					item.status === 5 ||
					(item.modifiers &&
						item.modifiers.find(
							(modifier) =>
								modifier.values &&
								modifier.values.find((value) => value.disabled)
						))
			)
			.map(itemHasDisabledModifiers)
		return (
			_sum(items) +
			this.props.items
				.filter((item) => item.service_assignment === assignmentId)
				.filter((item) => item.status === 5).length
		)
	}
	handleCategoryHeightChanged = (height) => {
		this.setState({ categoryHeight: height }, () => {
			this.resetItemHeightCache()
		})
	}
	handleOnBackInStock = (assignmentId) => {
		this.props.onBackInStock(
			this.oosItemsForAssignment(assignmentId).map((i) => i._id),
			assignmentId
		)
	}
	_scrolledItems = {
		assignmentId: null,
		itemId: null,
		modifierId: null
	}

	onScrolledItemChanged(assignmentId, itemId, modifierId) {
		if (
			modifierId !== this._scrolledItems.modifierId ||
			itemId !== this._scrolledItems.itemId ||
			assignmentId !== this._scrolledItems.assignmentId
		) {
			this._scrolledItems = { assignmentId, itemId, modifierId }
			this.props.scrolledToItem(assignmentId, itemId, modifierId)
		}
	}
	itemHeightCache = []
	getItemForScrollOffset(scrollOffset, flatItems) {
		const { itemHeightCache, getItemSize } = this
		if (!itemHeightCache.length) {
			this.itemHeightCache = flatItems.reduce((accumulator, value) => {
				const previousValue = accumulator[accumulator.length - 1]
				const previousCumulativeHeight =
					(previousValue && previousValue.cumulativeHeight) || 0
				const itemHeight = getItemSize(value)
				value = {
					...value,
					cumulativeHeight: itemHeight + previousCumulativeHeight
				}
				// console.log('accumulating', accumulator.length, itemHeight + previousCumulativeHeight)
				accumulator.push(value)
				return accumulator
			}, [])
		}
		const matchingItem = this.itemHeightCache.find(
			(item) =>
				item.cumulativeHeight - 80 > scrollOffset &&
				item.type !== 'item-separator' &&
				item.type !== 'hidden-modifiers'
		)
		return matchingItem
	}
	render() {
		const {
			items,
			title,
			onItemStatusChanged,
			onModifierValueDisabledChanged,
			titleForGroupId,
			header,
			showOosOnly
		} = this.props
		const {
			handleItemExpanded,
			resetItemHeightCache,
			handleCategoryHeightChanged,
			handleOnBackInStock,
			handleShowAll,
			getItemSize
		} = this
		const itemsByAssignment = _groupBy(items, 'service_assignment')
		let flatItems = []
		flatItems.push({
			type: 'page-header'
		})

		Object.keys(itemsByAssignment)
			.map((key) => ({
				items: itemsByAssignment[key],
				service_assignment: key
			}))
			.map((assignment) => {
				const serviceAssignmentId = assignment.service_assignment
				flatItems.push({
					type: 'header',
					_id: serviceAssignmentId,
					title,
					assignmentName: titleForGroupId(
						assignment.service_assignment
					),
					itemsOutOfStock: this.oosItemCountWithModifiers(
						assignment.service_assignment
					)
				})
				assignment.items.map((item) => {
					const showAll = itemShouldShowAll(
						item,
						this.state.expandedItems
					)
					const collapsed = !itemIsExpanded(
						item,
						this.state.expandedItems
					)
					const totalNumberOfSubitems = item.modifiers
						.map((modifier) => modifier.values.length)
						.reduce((a, b) => a + b, 0)
					let visibleNumberOfSubitems = 0
					const modifiers = []
					if (!collapsed) {
						item.modifiers.map((modifier) => {
							modifiers.push({
								type: 'modifier',
								_id: modifier._id,
								title: modifier.title,
								itemId: item._id,
								serviceAssignment: serviceAssignmentId,
								parentItem: item._id,
								service_assignment: item.service_assignment,
								values: modifier.values.filter((value) => {
									if (showAll) {
										visibleNumberOfSubitems++
										return true
									}
									// filter out modifiers with no matches if the toggle is enabled
									if (
										this.props.matchingModifiersOnly &&
										this.props.searchTerm
									) {
										if (
											value.title
												.toUpperCase()
												.includes(
													this.props.searchTerm.toUpperCase()
												)
										) {
											visibleNumberOfSubitems++
											return true
										}
										return false
									}
									if (showOosOnly) {
										if (value.disabled) {
											visibleNumberOfSubitems++
											return true
										} else {
											return false
										}
									}
									visibleNumberOfSubitems++
									return true
								})
							})
						})
					}
					flatItems.push({
						type: 'item',
						_id: item._id,
						title: item.name,
						serviceAssignment: serviceAssignmentId,
						checked: item.status !== 5,
						service_assignment: item.service_assignment,
						hasModifiers: item.modifiers && item.modifiers.length,
						hiddenModifierCount:
							totalNumberOfSubitems - visibleNumberOfSubitems,
						collapsed
					})

					flatItems.push(
						...modifiers.filter(
							(modifier) => modifier.values.length > 0
						)
					)
					// show the more modifiers item if search or oosOnly toggle are enabled and there are hidden modifiers
					if (
						((this.props.matchingModifiersOnly &&
							this.props.searchTerm) ||
							showOosOnly) &&
						!collapsed &&
						totalNumberOfSubitems - visibleNumberOfSubitems > 0
					) {
						flatItems.push({
							_id: item._id,
							serviceAssignment: serviceAssignmentId,
							hiddenModifierCount:
								totalNumberOfSubitems - visibleNumberOfSubitems,
							type: 'hidden-modifiers'
						})
					}
					flatItems.push({
						type: 'item-separator'
					})
				})
			})

		// the searchterm to items for inline hilighting
		flatItems = flatItems.map((flatItem) => {
			if (flatItem.type === 'modifier' || flatItem.type === 'item') {
				return { ...flatItem, searchMatch: this.props.searchTerm }
			}
			return flatItem
		})
		if (!items || !items.length) {
			flatItems.push({
				type: 'header',
				assignmentName: 'Results'
			})
			flatItems.push({
				type: 'no-results'
			})
		}

		return (
			<div
				ref={(ref) => {
					if (
						ref &&
						ref.offsetHeight &&
						!this.state.scrollableHeight
					) {
						this.setState({ scrollableHeight: ref.offsetHeight })
					}
				}}
				style={{ flex: 1 }}>
				<VariableSizeList
					className={'item-list'}
					ref={this.listRef}
					itemData={{
						itemsArray: flatItems,
						onItemStatusChanged,
						onModifierValueDisabledChanged,
						handleItemExpanded,
						header,
						resetItemHeightCache,
						categoryHeightChanged: handleCategoryHeightChanged,
						handleOnBackInStock,
						handleShowAll
					}}
					estimatedItemSize={ITEM_HEIGHT}
					overscanCount={20}
					useIsScrolling
					onScroll={({ scrollOffset }) => {
						// calculate cumulative heights
						// find item that matches
						const item = this.getItemForScrollOffset(
							scrollOffset,
							flatItems
						)

						let assignmentId, itemId, modifierId
						if (!item || !item.type) {
							//console.warn('no item found', item)
							return
						}
						if (item.type === 'modifier') {
							modifierId = item._id
							itemId = item.itemId
							assignmentId = item.service_assignment
						} else if (item.type === 'item') {
							itemId = item._id
							assignmentId = item.service_assignment
						} else if (item.type === 'header') {
							assignmentId = item._id
						}
						this.onScrolledItemChanged(
							assignmentId,
							itemId,
							modifierId
						)
						return
					}}
					height={this.state.scrollableHeight}
					itemSize={(index) => {
						return getItemSize(flatItems[index])
					}}
					itemCount={flatItems.length}>
					{ItemRenderer}
				</VariableSizeList>
			</div>
		)
	}
}
function itemHasDisabledModifiers(item) {
	if (!item || !item.modifiers) {
		return false
	}
	const found = item.modifiers.map(
		(modifier) => modifier.values.filter((value) => value.disabled).length
	)
	return _sum(found)
}
function itemIsExpanded(item, expandedItems) {
	const userState = expandedItems[item._id]
	const { expandedByDefault } = item
	const _itemHasDisabledModifiers = itemHasDisabledModifiers(item)
	if (userState !== undefined) {
		if (userState > 0) {
			return true
		}
		return false
	}
	return (
		(!!userState && userState > 0) || // item has been clicked and is not collapsed
		userState > ITEM_UI_STATE_COLLAPSED ||
		(_itemHasDisabledModifiers && userState !== ITEM_UI_STATE_COLLAPSED) ||
		(expandedByDefault && !userState > 0) //  && expandedItems[item._id] > ITEM_UI_STATE_COLLAPSED
	)
}
function itemShouldShowAll(item, expandedItems) {
	return expandedItems[item._id] === ITEM_UI_STATE_SHOW_ALL
}

InventoryList.propTypes = {
	listUpdate: PropTypes.number,
	scrolledToItem: PropTypes.func,
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			status: PropTypes.number.isRequired
		})
	).isRequired,
	titleForGroupId: PropTypes.func,
	onItemStatusChanged: PropTypes.func,
	onModifierValueDisabledChanged: PropTypes.func,
	onBackInStock: PropTypes.func,
	header: PropTypes.func,
	matchingModifiersOnly: PropTypes.bool,
	showOosOnly: PropTypes.bool,
	searchTerm: PropTypes.string
}

export default InventoryList
