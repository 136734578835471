import type {} from '@mui/lab/themeAugmentation'
import { createTheme } from '@mui/material'

export const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280
		}
	},
	typography: {
		fontFamily: 'MierB, serif',
		fontSize: 16,
		body1: {
			fontSize: 16
		},
		body2: {
			fontSize: 14
		}
	},
	palette: {
		mode: 'light',
		primary: {
			main: '#2e2bb6',
			light: '#DAE0F6'
		},
		secondary: {
			main: '#666',
			dark: '#333'
		},
		error: {
			main: '#c53f3f',
			light: '#ffd9e2'
		},
		warning: {
			main: '#c27c12'
		},
		info: {
			main: '#12809f',
			light: '#77E96D'
		},
		success: {
			main: '#367c3f'
		},
		grey: {
			300: '#E0E0E0',
			600: '#666666'
		}
	},
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
					borderRadius: 8
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingRight: 0,
					marginRight: '1rem'
				}
			}
		},
		MuiTabPanel: {
			styleOverrides: {
				root: {
					paddingLeft: 0,
					paddingRight: 0
				}
			}
		}
	}
})
