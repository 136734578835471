import dayjs from 'dayjs'

import { AlertType } from '../../@types/Alert'
import { Order } from '../../@types/Order'
import { ServiceAssignmentMerchantSla } from '../../@types/ServiceAssignmentMerchantSla'
import { isLineItemReusePassEnabled } from '../lineItem/isLineItemReusePassEnabled'
import { parseNoteValue } from '../lineItem/parseNoteValue'

export const DEFAULT_PREPARATION_SLA_MIN = 15

export const makeOrderAlertId = (order: Order, type: AlertType) => {
	return `${order._id}-${type}`
}

export const formatOrderId = (orderId: number) => {
	return `${orderId.toString().padStart(3, '0').slice(-3)}`
}

export const getOrderSecondsElapsedSinceVisible = (
	visibleAt: string,
	now: dayjs.Dayjs
) => {
	return (now ?? dayjs()).diff(visibleAt, 's')
}

export const orderShouldBeDoneInSeconds = (options: {
	merchantSla: ServiceAssignmentMerchantSla
	order: Order
}) => {
	const { merchantSla, order } = options

	const parsedPrepFinishedSla = Number.parseInt(
		merchantSla.PREPARATION_FINISHED_MIN,
		10
	)

	// SLA exists, use SLA
	if (!Number.isNaN(parsedPrepFinishedSla)) {
		return parsedPrepFinishedSla * 60
	}

	// No SLA, use preparation_time_needed or default
	return order.preparation_time_needed
		? Math.floor(order.preparation_time_needed)
		: DEFAULT_PREPARATION_SLA_MIN * 60
}

export const orderIsLate = (options: {
	merchantSla: ServiceAssignmentMerchantSla
	now: dayjs.Dayjs
	order: Order
}) => {
	const { merchantSla, order, now } = options

	return (
		getOrderSecondsElapsedSinceVisible(order.visible_at, now) >
		orderShouldBeDoneInSeconds({ order, merchantSla })
	)
}

export const hasTestOrder = (orders: Order[]) =>
	orders.some((order) => order.testing)

export const orderHasReusePassEnabled = (order: Order) => {
	return order.lineitems.some(isLineItemReusePassEnabled)
}

export const getOrderReusePassQRCode = (order: Order) => {
	const lineItemWithQRCode = order.lineitems.find((lineItem) =>
		isLineItemReusePassEnabled(lineItem)
	)

	if (!lineItemWithQRCode) {
		return undefined
	}

	return parseNoteValue(lineItemWithQRCode.note).packaging?.QRCode
}
