import cx from 'classnames'

import { Icon } from '../Icon'

export function SettingRadioRow({
	text,
	selected,
	onClick
}: {
	text: string
	selected: boolean
	onClick: () => void
}) {
	const classNames = cx(
		'flex justify-between items-center px-4 py-3 border-b-[1px] border-solid border-grey-light bg-white last:border-b-0 active:bg-black/20',
		{ 'font-bold': selected }
	)
	return (
		<div className={classNames} onClick={onClick}>
			<span>{text}</span>
			{selected ? <Icon icon="checkmark" /> : null}
		</div>
	)
}
