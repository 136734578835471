import React, { ChangeEvent, FC, ReactNode } from 'react'

import classNames from 'classnames'

import './index.scss'

type Props = {
	checked: boolean
	disabled?: boolean
	id: string
	label?: ReactNode
	onChange: (event: ChangeEvent<HTMLDivElement>) => void
}

// TODO: Convert css to css modules.
export const OnOffSwitch: FC<Props> = ({
	id,
	checked,
	disabled,
	onChange,
	label
}) => {
	const className = classNames('onoffswitch', {
		'onoffswitch-disabled': disabled
	})

	return (
		<>
			{label ? <div className="mr-4 mt-[-2px]">{label}</div> : null}
			<div className={className}>
				<input
					data-testid="onoffswitch"
					onChange={onChange}
					type="checkbox"
					id={id}
					name="onoffswitch"
					className="onoffswitch-checkbox"
					checked={checked}
					disabled={disabled}
				/>
				<label className="onoffswitch-label" htmlFor={id} />
			</div>
		</>
	)
}
