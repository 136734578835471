import React, { FC, useCallback, useEffect, useState } from 'react'

import cx from 'classnames'

import { getVenueStatus } from '../../actions'
import { useAppSelector } from '../../utils/storeHelpers'
import { Icon } from '../Icon'
import { Modal } from '../Modal/Modal'
import { ChangeStatusModal } from './ChangeStatusModal'
import styles from './index.module.scss'
import { CHECK_STATUS_TIMEOUT_MS, Status, VenueStatusProps } from './types'
import { getAcceptingOrdersText, getTextClasses } from './utils'

export const VenueStatus: FC<VenueStatusProps> = ({
	isModalOpen,
	closeModal
}) => {
	const serviceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)

	const [status, setStatus] = useState<Status | undefined>()

	const fetchStatus = useCallback(() => {
		if (serviceAssignmentId) {
			getVenueStatus(serviceAssignmentId).then(setStatus)
		}
	}, [serviceAssignmentId])

	const scheduledStatusFetching = useCallback(async () => {
		fetchStatus()
		const timeout = setTimeout(() => {
			scheduledStatusFetching()
		}, CHECK_STATUS_TIMEOUT_MS)
		return () => {
			clearTimeout(timeout)
		}
	}, [fetchStatus])

	useEffect(() => {
		scheduledStatusFetching().then()
	}, [scheduledStatusFetching])

	if (!status || !serviceAssignmentId) {
		return null
	}

	return (
		<>
			<div
				className={cx(
					'flex flex-row items-center',
					getTextClasses(status)
				)}>
				{getAcceptingOrdersText(status)}{' '}
				<Icon icon="chevronRound" className="ml-1 mt-[2px]" />
			</div>
			<Modal
				isOpen={!!isModalOpen}
				onRequestClose={closeModal}
				className={styles.modal}
				width={920}>
				<ChangeStatusModal
					updateStatus={fetchStatus}
					serviceAssignmentId={serviceAssignmentId}
					status={status.op_status}
					closeModal={closeModal}
					paused={status.paused_until}
				/>
			</Modal>
		</>
	)
}
