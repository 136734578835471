import { keyBy } from 'lodash'
import { combineReducers } from 'redux'

import { RECEIVED_ORDERS } from '../actions'

// This is a bit sloppy but this is how we do state in this app so :shrug:
// Also this is here because we don't want to conflict with the "items" reducer which is used for inventory
const byId = (state = {}, action: any) => {
	if (action.type === RECEIVED_ORDERS) {
		return keyBy(action.items, '_id')
	}

	return state
}

export const reducer = combineReducers({
	byId
})
