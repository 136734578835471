import { memo } from 'react'

import { Close, Menu } from '@mui/icons-material'
import { Box, Button, useMediaQuery } from '@mui/material'

import { Alerts } from './Alerts'
import { HelpCenter } from './HelpCenter'
import { RobotAvailability } from './RobotAvailability'
import { StarshipLogo } from './StarshipLogo'
import { VenueInfo } from './VenueInfo'
import { TopBarProps } from './types'

export const TopBar = memo<TopBarProps>(({ toggleMenu, isMenuActive }) => {
	const isLarge = useMediaQuery('(min-width: 1024px)')

	return (
		<Box sx={{ background: 'white' }}>
			{isLarge ? null : (
				<Box
					sx={{
						display: 'flex',
						width: '100%',
						borderBottomWidth: 1,
						borderBottomStyle: 'solid',
						borderBottomColor: 'grey.300'
					}}>
					<VenueInfo isHorizontal />

					{/* The refresh button is from native, we just need to make space for it */}
					{/* In responsive layout it might end up either on top or bottom row of header, handling that here. */}
					<Box ml={2} height={40} width={40} />
				</Box>
			)}

			<Box
				sx={{
					display: 'flex',
					flex: 1,
					width: '100%',
					justifyContent: 'space-between'
				}}>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					height={64}
					bgcolor="white"
					px={2}
					justifyContent="space-between">
					<Button
						variant="outlined"
						color="secondary"
						startIcon={isMenuActive ? <Close /> : <Menu />}
						onClick={toggleMenu}
						sx={{ height: 46, mr: 1 }}>
						<StarshipLogo />
					</Button>

					{isLarge ? <VenueInfo /> : null}
				</Box>

				<Box display="flex" flexDirection="row" alignItems="center">
					<RobotAvailability />

					<Box
						ml={3}
						display="flex"
						flexDirection="row"
						alignItems="center">
						<Box mr={1}>
							<Alerts />
						</Box>

						<HelpCenter />

						{/* The refresh button is from native, we just need to make space for it */}
						{/* We should just make it non-native and have it call some native fn */}
						<Box ml={2} height={40} width={isLarge ? 40 : 0} />
					</Box>
				</Box>
			</Box>
		</Box>
	)
})

export const TopBarStatic = () => {
	return (
		<Box
			display="flex"
			height={64}
			bgcolor="white"
			px={2}
			alignItems="center"
			justifyContent="center">
			<StarshipLogo />
		</Box>
	)
}
