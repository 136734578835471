import { FC } from 'react'

import { OrderLabel } from '.'
import { Order } from '../../../@types/Order'

export const OrderLabelTax: FC<{ order: Order }> = ({ order }) => {
	const taxSubTotal = (order && order.tax && order.tax / 100) || 0

	const taxSubTotalFormatted = taxSubTotal.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD'
	})

	return (
		<OrderLabel
			icon="monetization"
			label={`Tax: ${taxSubTotalFormatted}`}
		/>
	)
}
