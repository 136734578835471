import React from 'react'

import classnames from 'classnames'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { ReactComponent as SimpleArrow } from '../../../icons/SimpleArrow.svg'

export const OrderHistoryReportNav = ({
	cursor: { prev, next, current },
	getReport
}) => {
	const onToggleCursorChange = (cursorDate) => {
		getReport(cursorDate)
	}

	const shouldDisableButton = (cursorDate) =>
		cursorDate === null ? true : false

	return (
		<div className={'order-history-nav-container'}>
			<span className={'nav-date-text'}>
				{dayjs(current).format('dddd, MM/DD/YYYY')}
			</span>
			<button
				className={classnames({
					'nav-button': true,
					'nav-button nav-button--blocked': shouldDisableButton(prev)
				})}
				disabled={shouldDisableButton(prev)}
				onClick={() => onToggleCursorChange(prev)}>
				<SimpleArrow className={'nav-icon-prev'} />
				Prev.
			</button>
			<span className={'nav-spacer'}></span>
			<button
				className={classnames({
					'nav-button': true,
					'nav-button nav-button--blocked': shouldDisableButton(next)
				})}
				disabled={shouldDisableButton(next)}
				onClick={() => onToggleCursorChange(next)}>
				Next
				<SimpleArrow className={'nav-icon-next'} />
			</button>
		</div>
	)
}

OrderHistoryReportNav.propTypes = {
	cursor: PropTypes.object,
	getReport: PropTypes.func
}
