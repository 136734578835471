import { InventoryStatus } from '../../@types/Item'

export const itemRecordToItemStatusList = (
	record: Record<string, InventoryStatus>
) => {
	return Object.entries(record).map(([key, value]) => ({
		itemId: key,
		status: value
	}))
}
