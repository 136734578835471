import { useCallback } from 'react'

import { enqueueSnackbar } from 'notistack'

import { useCreateMerchantServiceRequestMutation } from '../../../../../queries/merchantServiceRequests'
import { analytics } from '../../../../../services/analytics'
import { FormValues } from './types'

export const useSubmit = (
	serviceAssignmentId: string,
	onSuccess: () => void
) => {
	const { mutateAsync: createMerchantServiceRequest } =
		useCreateMerchantServiceRequestMutation()

	return useCallback(
		async (values: FormValues) => {
			analytics.trackEvent('Submit Service Request', {
				Type: values.type
			})

			try {
				const robotIdComment = values.robotId
					? ` Robot id: ${values.robotId}`
					: ''
				const phoneNumberComment = values.phoneNumber
					? ` Phone number: ${values.phoneNumber}`
					: ''

				await createMerchantServiceRequest({
					serviceAssignmentId: serviceAssignmentId ?? '',
					data: {
						type: values.type,
						text: `${values.comment}${robotIdComment}${phoneNumberComment}`
					}
				})

				enqueueSnackbar(
					'Support request created successfully! Merchant support will resolve the request as soon as possible.',
					{
						variant: 'success',
						autoHideDuration: 5000,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'bottom'
						}
					}
				)

				onSuccess()
			} catch {
				enqueueSnackbar(
					'Something went wrong. Please try again later.',
					{
						variant: 'error',
						autoHideDuration: 5000,
						anchorOrigin: {
							horizontal: 'center',
							vertical: 'bottom'
						}
					}
				)
			}
		},
		[createMerchantServiceRequest, onSuccess, serviceAssignmentId]
	)
}
