import { FC, memo } from 'react'

import {
	Battery30,
	Battery50,
	Battery60,
	Battery80,
	BatteryAlert,
	BatteryCharging20,
	BatteryCharging30,
	BatteryCharging50,
	BatteryCharging60,
	BatteryCharging80,
	BatteryChargingFull,
	BatteryFull
} from '@mui/icons-material'

import { BatteryManager } from '../BatteryCheck/types'

export const BatteryIcon: FC<BatteryManager> = memo(
	// eslint-disable-next-line sonarjs/cognitive-complexity
	({ level, charging }) => {
		if (charging) {
			if (level < 0.2) {
				return <BatteryCharging20 fontSize="inherit" />
			}
			if (level <= 0.3) {
				return <BatteryCharging30 fontSize="inherit" />
			}
			if (level <= 0.5) {
				return <BatteryCharging50 fontSize="inherit" />
			}
			if (level <= 0.6) {
				return <BatteryCharging60 fontSize="inherit" />
			}
			if (level <= 0.8) {
				return <BatteryCharging80 fontSize="inherit" />
			}
			return <BatteryChargingFull fontSize="inherit" />
		}
		if (level < 0.2) {
			return <BatteryAlert fontSize="inherit" color="error" />
		}
		if (level <= 0.3) {
			return <Battery30 fontSize="inherit" color="warning" />
		}
		if (level <= 0.5) {
			return <Battery50 fontSize="inherit" />
		}
		if (level <= 0.6) {
			return <Battery60 fontSize="inherit" />
		}
		if (level <= 0.8) {
			return <Battery80 fontSize="inherit" />
		}
		return <BatteryFull fontSize="inherit" />
	}
)
