import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T, initWithValue?: boolean) {
	const reference = useRef<T | undefined>(initWithValue ? value : undefined)

	useEffect(() => {
		reference.current = value
	})

	return reference.current
}
