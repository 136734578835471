import { FC, useCallback } from 'react'

import { Box, Divider, Typography } from '@mui/material'

import pluralize from 'pluralize'

import { StationStatus } from '../../@types/Order'
import { fetchStationOrders } from '../../actions'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { useAppDispatch } from '../../utils/storeHelpers'
import { Action } from '../OrderRevamp/Action'
import { ActionType } from '../OrderRevamp/Action/types'
import { OrderActions } from '../OrderRevamp/Layout/OrderActions'
import { OrderCard } from '../OrderRevamp/Layout/OrderCard'
import { OrderHeader } from '../OrderRevamp/OrderHeader'
import {
	EditableOrderLineItems,
	OrderLineItems
} from '../OrderRevamp/OrderLineItems'
import { OrderScrollContainer } from '../OrderRevamp/OrderScrollContainer'
import { useItemStatuses } from '../OrderRevamp/useItemStatuses'
import { OrderGeneralInfo } from './OrderGeneralInfo'
import { OrderEditableProps } from './types'
import { useEditableOrderState } from './useEditableOrderState'

export const OrderEditable: FC<OrderEditableProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderEditableRevampedView {...props} />
	}

	return <OrderEditableView {...props} />
}

export const OrderEditableView: FC<OrderEditableProps> = ({
	order,
	actions,
	isScrollable,
	hideTimer,
	disabled,
	footer
}) => {
	const itemStatuses = useItemStatuses({ order })

	const dispatch = useAppDispatch()

	const onSubmit = useCallback(() => {
		dispatch(
			fetchStationOrders(order.loadingareaId, order.loadingstationId)
		)
	}, [dispatch, order.loadingareaId, order.loadingstationId])

	const {
		isEditing,
		startEditing,
		editedItemStatuses,
		updateItemStatus,
		cancel,
		submit,
		isSubmitting
	} = useEditableOrderState({
		serviceAssignmentId: order.serviceassignment_id,
		itemStatuses,
		onSubmit,
		orderId: order._id
	})

	const handleUpdateItemStatus = useCallback(
		(options: Parameters<typeof updateItemStatus>[0]) => {
			if (!isEditing) {
				startEditing()
			}

			updateItemStatus(options)
		},
		[isEditing, startEditing, updateItemStatus]
	)

	return (
		<OrderCard col isTestOrder={order.testing}>
			<OrderHeader order={order} hideTimer={hideTimer} />

			<OrderScrollContainer isEnabled={isScrollable}>
				<EditableOrderLineItems
					order={order}
					itemStatuses={editedItemStatuses}
					updateItemStatus={handleUpdateItemStatus}
					disabled={isSubmitting || disabled}
				/>
			</OrderScrollContainer>

			{footer}

			{isEditing ? (
				<div className="flex items-center justify-center border-t-[1px] border-solid border-t-grey-light py-4">
					<span className="text-black-body">
						You are making changes to order content
					</span>
				</div>
			) : null}

			<OrderActions>
				{isEditing ? (
					<>
						<Action
							type={ActionType.CANCEL_EDIT}
							testId="ActionCancelEdit"
							className={isSubmitting ? 'opacity-50' : undefined}
							onClick={isSubmitting ? undefined : cancel}
						/>

						<Action
							type={ActionType.SAVE_EDIT}
							testId="ActionSaveEdit"
							className={isSubmitting ? 'opacity-50' : undefined}
							onClick={isSubmitting ? undefined : submit}
						/>
					</>
				) : (
					actions
				)}
			</OrderActions>
		</OrderCard>
	)
}

export const OrderEditableRevampedView: FC<OrderEditableProps> = ({
	order,
	actions,
	onClose,
	orderStatus,
	onItemFulfil,
	showOOSFlow
}) => {
	const showLineItemStatus = orderStatus !== StationStatus.CREATED
	const allowLineItemStatusEdit = orderStatus === StationStatus.PREPARING

	const totalItems = order.lineitems.reduce(
		(accumulator, lineItem) => accumulator + lineItem.qty,
		0
	)

	const displayedOrderId = order.kiosk_order_id ?? order.order_id

	return (
		<OrderCard col isTestOrder={order.testing}>
			<Box height="80vh" flex={1} display="flex" flexDirection="column">
				<Box
					px={3}
					py={2}
					display="flex"
					justifyContent="space-between"
					alignItems="center">
					<Typography fontSize={18} fontWeight={700}>
						Order {displayedOrderId}
					</Typography>

					<Action
						type={ActionType.CLOSE_WINDOW}
						onClick={() => onClose?.()}
					/>
				</Box>
				<Divider />

				<Box
					sx={{
						display: 'flex',
						flex: 1
					}}>
					<Box
						sx={{
							flex: 2,
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100%',
							justifyContent: 'space-between',
							borderRightWidth: '1px',
							borderRightStyle: 'solid',
							borderRightColor: 'grey.300'
						}}>
						<OrderGeneralInfo order={order} />

						{actions ? (
							<Box>
								<Divider />
								<Box
									alignItems="center"
									justifyContent="center"
									px={3}
									py={3}>
									{actions}
								</Box>
							</Box>
						) : null}
					</Box>

					<Box
						sx={{
							overflowY: 'scroll',
							height: 'calc(80vh - 80px)',
							flex: 3
						}}>
						<Box
							sx={{
								flex: 1,
								height: 56,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								px: 3
							}}>
							<Typography
								color="grey.600"
								fontWeight={600}
								fontSize={18}>
								Contents
							</Typography>
							<Typography color="grey.600" fontSize={18}>
								{totalItems} {pluralize('item', totalItems)}
							</Typography>
						</Box>

						<Divider />

						<OrderLineItems
							order={order}
							showLineItemStatus={showLineItemStatus}
							allowLineItemStatusEdit={allowLineItemStatusEdit}
							onItemFulfil={onItemFulfil}
							showOOSFlow={showOOSFlow}
						/>
					</Box>
				</Box>
			</Box>
		</OrderCard>
	)
}
