import { partition } from 'lodash'

import { Alert, AlertOrderLate, AlertType } from '../../@types/Alert'
import { DeliveryType, Order } from '../../@types/Order'
import { Robot } from '../../@types/Robot'
import { isRobotArrivingSoon } from '../../utils/robot'
import { OrderSection } from './types'

export const makeOrderSectionsByPriority = (options: {
	orderIsLateByOrderId: Record<string, true>
	orders: Order[]
	robotArrivingSoonByOrderId: Record<string, boolean>
}): OrderSection[] => {
	const { orders, robotArrivingSoonByOrderId, orderIsLateByOrderId } = options

	const [prioOrders, otherOrders] = partition(orders, (order) => {
		const robotSoon = robotArrivingSoonByOrderId[order._id]
		const isLate = orderIsLateByOrderId[order._id]

		if (order.delivery_type === DeliveryType.PICKUP && isLate) {
			return true
		}

		return isLate && robotSoon
	})

	return [
		{
			header: {
				text: 'Prepare ASAP!',
				color: 'red'
			},
			orders: prioOrders
		},
		{
			header: {
				text: 'Next',
				color: 'purple'
			},
			orders: otherOrders
		}
	]
}

export const makeOrderSectionsByDeliveryType = (
	orders: Order[]
): OrderSection[] => {
	const [pickupOrders, deliveryOrders] = partition(orders, (order) => {
		return order.delivery_type === DeliveryType.PICKUP
	})

	return [
		{
			header: {
				text: 'For robot loading',
				icon: 'robotWaiting'
			},
			orders: deliveryOrders
		},
		{
			header: {
				text: 'For customer pickup',
				icon: 'pickupHand'
			},
			orders: pickupOrders
		}
	]
}

export const getOrderIsLateByOrderId = (alerts: Alert[]) => {
	return Object.fromEntries(
		alerts
			.filter(
				(alert): alert is AlertOrderLate =>
					alert.type === AlertType.ORDER_LATE
			)
			.map((current) => [current.orderId, true as const])
	)
}

export const getRobotArrivingSoonByOrderId = (
	robotsByOrderId: Record<number, Robot>
) => {
	return Object.fromEntries(
		Object.entries(robotsByOrderId).map(([orderId, robot]) => [
			orderId,
			isRobotArrivingSoon(robot)
		])
	)
}
