import dayjs from 'dayjs'

import { WorkingHoursSegment } from '../../@types/WorkingHours'
import { getVenueOperatingStatus } from './getVenueOperatingStatus'
import { makeHours } from './makeHours'
import { mergeContinuousHours } from './mergeContinuousHours'

export const parseWorkingHours = (options: {
	now: dayjs.Dayjs
	segments: WorkingHoursSegment[]
}) => {
	const { now, segments } = options

	const hours = mergeContinuousHours(makeHours({ now, segments }))

	return { operating: getVenueOperatingStatus({ hours, now }), hours }
}
