import { useMutation, useQuery } from '@tanstack/react-query'

import {
	MerchantServiceRequest,
	MerchantServiceRequestCreateInput
} from '../@types/MerchantServiceRequest'
import { BASE_URL_MERCHANT_OPERATIONS_API } from '../api/constants'
import { fetchServer } from '../api/fetchServer'
import { queryClient } from '../queryClient'

const BASE_QUERY = 'merchantServiceRequests'

export const useGetMechantServiceRequests = (input: {
	serviceAssignmentId: string
}) => {
	return useQuery({
		queryKey: [BASE_QUERY, input],
		queryFn: () =>
			fetchServer<MerchantServiceRequest[]>(
				`/v2/merchant-service-requests/${input.serviceAssignmentId}`,
				'GET',
				undefined,
				BASE_URL_MERCHANT_OPERATIONS_API
			)
	})
}

export const useCreateMerchantServiceRequestMutation = () => {
	return useMutation({
		mutationKey: ['createMerchantServiceRequest'],
		mutationFn: (input: {
			serviceAssignmentId: string
			data: MerchantServiceRequestCreateInput
		}) => {
			return fetchServer<void>(
				`/v2/merchant-service-requests/${input.serviceAssignmentId}`,
				'POST',
				input.data,
				BASE_URL_MERCHANT_OPERATIONS_API
			)
		},
		onSuccess: () => {
			return queryClient.invalidateQueries({
				queryKey: [BASE_QUERY]
			})
		}
	})
}
