import { FulfillmentStatus } from '../../../../@types/LineItem'

export type Props = {
	showSelector: boolean
	setShowSelector: (value: boolean) => void
	value: FulfillmentStatus
	onChange: (value: FulfillmentStatus) => void
	allowLineItemStatusEdit?: boolean
}

export const LineItemFulfillmentStatusSelectorOptions: Record<
	FulfillmentStatus,
	{ color: 'error' | 'success'; title: string }
> = {
	[FulfillmentStatus.CAN_FULFILL]: {
		color: 'success',
		title: 'Can fulfill'
	},
	[FulfillmentStatus.CAN_NOT_FULFILL]: {
		color: 'error',
		title: 'Cannot fulfill'
	}
}
