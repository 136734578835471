import {
	Identify,
	Types,
	identify,
	init as initAmplitude,
	setDeviceId,
	track
} from '@amplitude/analytics-browser'

import {
	AnalyticsEventListWithParameters,
	AnalyticsEventListWithoutParameters,
	AnalyticsUserProperty
} from '../../@types/analytics'

class Analytics {
	public isInitialized = false

	public initialize = async (apiKey?: string) => {
		if (!apiKey) {
			return
		}

		await initAmplitude(apiKey).promise
		this.isInitialized = true
	}

	public setUserProperty = (
		property: AnalyticsUserProperty,
		value: Types.ValidPropertyType
	) => {
		if (!this.isInitialized) {
			return
		}

		try {
			const event = new Identify()
			event.set(property, value)
			identify(event)
		} catch {
			// Swallow error.
			// Any Amplitude errors will be logged by the logger to console.error, but it won't halt the runtime.
		}
	}

	public setDeviceId = (deviceId: string) => {
		if (!this.isInitialized) {
			return
		}

		try {
			setDeviceId(deviceId)
		} catch {
			// Swallow error.
			// Any Amplitude errors will be logged by the logger to console.error, but it won't halt the runtime.
		}
	}

	// https://www.typescriptlang.org/docs/handbook/2/functions.html#function-overloads
	public trackEvent<EventName extends keyof AnalyticsEventListWithParameters>(
		name: EventName,
		properties: AnalyticsEventListWithParameters[EventName],
		options?: Types.EventOptions
	): void
	public trackEvent<EventName extends AnalyticsEventListWithoutParameters>(
		event: EventName,
		properties: void,
		options?: Types.EventOptions
	): void
	public trackEvent(
		event: string,
		properties: Record<string, any> | void,
		options?: Types.EventOptions
	) {
		if (!this.isInitialized) {
			return
		}

		try {
			track(
				event,
				properties === undefined ? undefined : properties,
				options
			)
		} catch {
			// Swallow error.
			// Any Amplitude errors will be logged by the logger to console.error, but it won't halt the runtime.
		}
	}

	// This is a bit silly, but this gives us the possibility to test this
	public reset() {
		this.isInitialized = false
	}
}

export const analytics = new Analytics()
