import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import EscEncoder from './escencoder'
import { calculateTimelyOrder, formatCurrencyLocale } from './utils'

dayjs.extend(utc)

export const generateKitchenReport = (
	{ count, completed, cancelled, timely, totalSum },
	currency,
	date
) => {
	const currentPrintedTime = new Date(Date.now())

	const formattedPrint = new EscEncoder()
		.initialize()
		.newline(3)
		.align('center')
		.fontSize(50)
		.bold(true)
		.line('Starship')
		.newline()
		.fontSize(83)
		.bold(true)
		.line(`${dayjs(date).format('MM/DD/YYYY')}`)
		.newline(1)
		.align('left')
		.fontSize(50)
		.bold(false)
		.line('DAILY ORDERS')
		.fontSize(83)
		.bold(true)
		.line(` ${count.value}`)
		.newline()
		.fontSize(50)
		.bold(false)
		.line('CANCELLED ORDERS')
		.fontSize(83)
		.bold(true)
		.line(` ${cancelled.value}`)
		.newline()
		.fontSize(50)
		.bold(false)
		.line('COMPLETED ORDERS')
		.fontSize(83)
		.bold(true)
		.line(` ${completed.value}`)
		.newline()
		.fontSize(50)
		.bold(false)
		.line('TIMELY ORDERS')
		.fontSize(83)
		.bold(true)
		.line(` ${calculateTimelyOrder(timely.value, count.value)}%`)
		.newline()
		.fontSize(50)
		.bold(false)
		.line('TOTAL SUM (EX. TAX)')
		.fontSize(83)
		.bold(true)
		.line(` ${formatCurrencyLocale(totalSum.value, currency)}`)
		.newline()
		.align('center')
		.fontSize(33)
		.bold(false)
		.line(
			`Printed: ${dayjs(currentPrintedTime).format(
				'MM/DD/YYYY'
			)}-${currentPrintedTime.getHours()}:${currentPrintedTime.getMinutes()}`
		)
		.newline(2)
		.cut()

	return formattedPrint.encode()
}
