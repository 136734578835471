import React from 'react'

import PropTypes from 'prop-types'

import { ReactComponent as ChevronIcon } from '../../icons/Chevron.svg'

function CollapseButton({ onClick = () => {}, collapsed }) {
	return (
		<div
			style={{
				width: 55,
				height: 56,
				marginRight: -25,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
			onClick={() => onClick(!collapsed)}>
			{collapsed ? (
				<ChevronIcon
					style={{
						marginRight: -10,
						transform: 'rotate(180deg) scale(0.65)'
					}}
				/>
			) : (
				<ChevronIcon
					style={{
						marginRight: -10,
						transform: 'rotate(0deg) scale(0.65)'
					}}
				/>
			)}
		</div>
	)
}
CollapseButton.propTypes = {
	onClick: PropTypes.func,
	collapsed: PropTypes.bool
}

export default CollapseButton
