import { base4ToEscK, base4ToEscKArray, toBase4 } from './utils/daftCode'

const SELECT_ESCP = [0x1b, 0x69, 0x61, 0x00]
const INIT_ESCP = [0x1b, 0x40]

// refer to Brother ESC/P command reference for details
// http://support.brother.com/g/b/manuallist.aspx?c=us&lang=en&prod=lpql820nwbeus&type2=81

class EscPosEncoder {
	constructor() {
		this._reset()
	}

	_reset() {
		this._buffer = []
	}

	_encode(value) {
		return value
			.replace(/[\u2018\u2019]/g, "'")
			.replace(/[\u201C\u201D]/g, '"')
			.normalize('NFD')
			.replace(/[^\x00-\x7F]/g, '') // eslint-disable-line no-control-regex
	}

	_queue(value) {
		value.forEach((item) => this._buffer.push(item))
	}

	_bytes(str) {
		let bytes = []

		for (let i = 0; i < str.length; i++) {
			bytes.push(str.charCodeAt(i))
		}

		this._queue(bytes)
	}

	initialize() {
		this._queue(SELECT_ESCP)
		this._queue(INIT_ESCP)

		this._queue([
			0x1b,
			0x6b,
			0x0b // select font
		])

		/* Nice and big text */

		/*
		this._queue([
			0x1b, 0x6b, 0x01 // select font
			]);

		this._queue([
			0x1b, 0x58, 0x00, 48, 0 // select size
			]);
		*/

		return this
	}

	fontSize(fontSize) {
		// with bitmap fonts only n=24, 32 and 48 are valid and last byte has to be 0
		// with outline fonts 33, 38, 42, 46, 50, 58, 67, 75, ... and last byte has to be 1

		const validFontSizes = [
			33, 38, 42, 46, 50, 67, 75, 83, 92, 100, 117, 133, 150, 167, 200,
			233, 267, 300, 333, 367, 400
		]
		const sizeIndex = validFontSizes.indexOf(fontSize)

		// global font size reduction: use the next smallest font size, except when already at the smallest size (33)
		const reducedFontSize =
			sizeIndex === 0 ? validFontSizes[0] : validFontSizes[sizeIndex - 1]

		this._queue([0x1b, 0x58, 0x00, reducedFontSize, 0])

		return this
	}

	text(value) {
		let bytes = this._encode(value)

		this._queue([bytes])

		return this
	}
	horizontalDivider(isDash) {
		// set a really small line feed
		this._queue([0x1b, 0x41, 0x1])
		// this reduces the space the divider takes vertically,
		// leading to tighter margins
		this.fontSize(33)

		if (isDash) {
			this.text('------------------------------------')
			this.newline(2)
		} else {
			this.text('____________________________________')
			this.newline(4)
		}

		this.fontSize(42)
		this._queue([0x1b, 0x41, 0x4])
		return this
	}
	tinyNewline(count = 1) {
		// set a really small line feed
		this._queue([0x1b, 0x41, 0x1])
		this.newline(count)
		//restore line feed size
		this._queue([0x1b, 0x41, 0x4])
		return this
	}
	newline(count = 1) {
		for (let i = 1; i <= count; i++) {
			this._queue([0x0a, 0x0d])
		}

		return this
	}

	line(value) {
		this.text(value)
		this.newline()

		return this
	}

	italic(value) {
		if (value) {
			this._queue([0x1b, 0x34])
		} else {
			this._queue([0x1b, 0x35])
		}

		return this
	}

	doublestrike(value) {
		if (value) {
			this._queue([0x1b, 0x47])
		} else {
			this._queue([0x1b, 0x48])
		}

		return this
	}

	leftMargin(value) {
		this._queue([0x1b, 0x6c, value])

		return this
	}

	bold(value) {
		if (value) {
			this._queue([0x1b, 0x45])
		} else {
			this._queue([0x1b, 0x46])
		}

		return this
	}

	align(value) {
		const alignments = {
			left: 0x00,
			center: 0x01,
			right: 0x02
		}

		if (value in alignments) {
			this._queue([0x1b, 0x61, alignments[value]])
		} else {
			throw new Error('Unknown alignment')
		}

		return this
	}
	daftCode(value) {
		const base4 = toBase4(value)
		if (!base4) {
			return this
		}
		const daftBytes = base4ToEscK(base4)
		if (!daftBytes) {
			return this
		}
		// ESC-K-daft-data
		this._queue([0x1b, 'K', ...daftBytes])
		return this
	}
	daftCode3High(value, width) {
		const base4 = toBase4(value)
		if (!base4) {
			return this
		}
		const daftBytesArray = base4ToEscKArray(base4, 3, width)
		if (!daftBytesArray) {
			return this
		}

		daftBytesArray.forEach((daftBytes) => {
			// ESC-K-daft-data from array
			this._queue([0x1b, 'K', ...daftBytes])
		})
		return this
	}
	daftCode3HighEscL(value, width = 6) {
		const base4 = toBase4(value)
		if (!base4) {
			return this
		}
		const daftBytesArray = base4ToEscKArray(base4, 3, width)
		if (!daftBytesArray) {
			return this
		}

		daftBytesArray.forEach((daftBytes) => {
			// ESC-K-daft-data from array
			this._queue([0x1b, 'L', ...daftBytes])
		})
		return this
	}
	daftCode6High(value, width) {
		const base4 = toBase4(value)
		if (!base4) {
			return this
		}
		const daftBytesArray = base4ToEscKArray(base4, 6, width)
		if (!daftBytesArray) {
			return this
		}

		daftBytesArray.forEach((daftBytes) => {
			// ESC-K-daft-data from array
			this._queue([0x1b, 'K', ...daftBytes])
		})
		return this
	}
	daftCode6HighEscL(value, width = 6) {
		const base4 = toBase4(value)
		if (!base4) {
			return this
		}
		const daftBytesArray = base4ToEscKArray(base4, 6, width)
		if (!daftBytesArray) {
			return this
		}

		daftBytesArray.forEach((daftBytes) => {
			// ESC-K-daft-data from array
			this._queue([0x1b, 'L', ...daftBytes])
		})
		return this
	}
	barcode(value) {
		// with r1 (code below barcode)
		this._queue([
			0x1b,
			0x69,
			't0',
			'r1', // r1 prints code below barcode
			'h',
			78,
			0x0 // total height (barcode + ), 48 is the minimum
		])

		// barcode data start marker
		this._queue([0x42])

		// barcode data
		this.text(value)

		// NB, with 'ta' and 'tf' need to use triple end marker
		// with 't0' only one

		// barcode data end marker
		this._queue([0x5c])

		return this
	}

	/**
	 * Creates EAN8 barcode for printer
	 * @param {String} value which barcode should have
	 * @returns {EscPosEncoder}
	 */
	barcodeEan8(value) {
		this._queue([0x1b, 0x69, 't5', 'r1', 'h', 144, 1, 'w3'])

		this._queue([0x42])

		// EAN8 has to be exactly 7 characters. Fill missing characters with 0s.
		const valueWithLeadingZeroes = value.padStart(7, '0')

		this.text(valueWithLeadingZeroes)

		this._queue([0x5c])

		return this
	}

	/**
	 * Creates QRCode for a printer
	 * @param {EscEncoderPrintQRCodeParams} QR code params
	 * @returns {EscPosEncoder}
	 */
	qrcode({ value, size: qrSize, lowErrorCorrection }) {
		const size =
			qrSize === 'large' ? 0x12 : qrSize === 'medium' ? 0x10 : 0x8
		const errorCorrection = lowErrorCorrection ? 0x01 : 0x02
		this._queue([0x1b, 'i', 'q'])
		this._queue([size, 0x0, 0x00, 0x00, 0x00, 0x00, errorCorrection, 0x00])

		this.text(value)

		this._queue([0x5c, 0x5c, 0x5c])

		return this
	}

	positionTopRightCorner() {
		this._queue([0x1b, 0x28, 0x56, 0x2, 0x0, 0x0, 0x0])

		return this
	}

	cut() {
		this._queue([0x0c])

		return this
	}

	length() {
		return this._buffer.filter((b) => [0x0a, '\n'].includes(b)).length
	}

	encode() {
		const result = this._buffer
			.map((b) => (typeof b === 'number' ? String.fromCharCode(b) : b))
			.join('')
		this._reset()
		return result
	}
}

export default EscPosEncoder
