import { FC } from 'react'

import styles from '../../index.module.scss'
import { minutesLeftUntilAccepting } from '../../utils'

type Props = {
	closeModal: () => void
	paused?: number
}
export const PausedInitialContent: FC<Props> = ({ closeModal, paused }) => (
	<>
		{paused && (
			<div className={styles.header}>
				Paused for {minutesLeftUntilAccepting(paused)} minutes
			</div>
		)}
		{!paused && <div className={styles.header}>Paused</div>}
		<div className={styles.content}>
			<b>
				Customers unable to make orders to the restaurant in Starship
				app.
			</b>
		</div>
		<div className={styles.footer}>
			<div className={styles.closeButton} onClick={closeModal} />
			<div className={`${styles.updateButton} ${styles.disabled}`} />
		</div>
	</>
)
