import { memo } from 'react'

import cx from 'classnames'

import { Icon } from '../../Icon'
import { OrderListSubheaderProps } from './types'

export const OrderListSubheader = memo<OrderListSubheaderProps>(
	({ text, color, icon }) => {
		const circleClasses = cx('mt-[2px] h-3 w-3 rounded-full', {
			'bg-red': color === 'red',
			'bg-purple': color === 'purple',
			'bg-grey': !color
		})

		const iconClasses = cx({
			'text-red': color === 'red',
			'text-purple': color === 'purple',
			'text-grey': !color
		})

		return (
			<div className="mb-4 flex flex-row items-center">
				{icon ? (
					<Icon icon={icon} className={iconClasses} />
				) : (
					<div className={circleClasses} />
				)}
				<div className="ml-2">{text}</div>
			</div>
		)
	}
)
