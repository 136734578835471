import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { fetchServer } from '../../api/fetchServer'
import { queryClient } from '../../queryClient'

const GET_TEST_ORDERS_QUERY_KEY = 'getTestOrders'

export const useGetTestOrders = (
	serviceAssignmentId: string,
	loadingStationId: string,
	enableAutoQuery = true
): UseQueryResult<any, any> => {
	return useQuery({
		queryKey: [GET_TEST_ORDERS_QUERY_KEY],
		queryFn: async () =>
			getTestOrders({ serviceAssignmentId, loadingStationId }),
		enabled: enableAutoQuery
	})
}

const getTestOrders = async ({
	serviceAssignmentId,
	loadingStationId
}): Promise<any> => {
	return await fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/get-test-orders/station/${loadingStationId}`,
		'GET'
	)
}

export const invalidateGetTestOrdersQuery = () => {
	queryClient.invalidateQueries({
		queryKey: [GET_TEST_ORDERS_QUERY_KEY]
	})
}
