import { FC, memo, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
	Box,
	Checkbox,
	FormControlLabel,
	InputLabel,
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	TextField
} from '@mui/material'

import { PreflightCheckSettingFieldType } from '../../../@types/PreflightCheck'
import { Icon } from '../../Icon'
import { ShowVideoButton } from '../../Welcome/ShowVideoButton'
import { Props } from './types'

export const CheckItem: FC<Props> = memo(
	({ label, field, value, type, onChange, loadingArea, minLimit = 7 }) => {
		const navigate = useNavigate()
		const { loadingareaId, loadingstationId } = useParams()
		const handleChange = useCallback(
			(stationId: string) => {
				onChange({ correct_station: undefined })
				navigate(`/${loadingareaId}/stations/${stationId}`)
			},
			[loadingareaId, navigate, onChange]
		)

		if (type === PreflightCheckSettingFieldType.NUMBER) {
			return (
				<>
					<ListItem>
						<ListItemText>{label}</ListItemText>
						<ListItemIcon>
							<FormControlLabel
								control={
									<Checkbox
										checked={value >= minLimit}
										onClick={() => onChange(minLimit)}
									/>
								}
								label="Yes"
							/>
							<FormControlLabel
								control={
									<Checkbox
										checked={value < minLimit}
										onClick={() => onChange('')}
									/>
								}
								label="No"
							/>
						</ListItemIcon>
					</ListItem>
					{value !== undefined && value < minLimit && (
						<Box px={2} pb={2}>
							<InputLabel>
								How many rolls do you have left?
							</InputLabel>
							<TextField
								type="number"
								value={value}
								size="small"
								fullWidth
								inputProps={{
									inputMode: 'numeric',
									pattern: '[0-9]*'
								}}
								onChange={(event) =>
									onChange(
										event.target.value === ''
											? ''
											: Number.parseInt(
													event.target.value
											  )
									)
								}
							/>
						</Box>
					)}
				</>
			)
		}
		if (type === PreflightCheckSettingFieldType.STRING) {
			return (
				<ListItem sx={{ pt: 2 }}>
					<TextField
						value={value}
						placeholder={label}
						size="small"
						fullWidth
						onChange={(event) => onChange(event.target.value)}
					/>
				</ListItem>
			)
		}
		return (
			<>
				<ListItem>
					<ListItemText>{label}</ListItemText>
					<ListItemIcon>
						<FormControlLabel
							control={
								<Checkbox
									checked={value === true}
									onClick={() => onChange(true)}
								/>
							}
							label="Yes"
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={value === false}
									onClick={() => onChange(false)}
								/>
							}
							label="No"
						/>
					</ListItemIcon>
				</ListItem>
				{field === 'correct_station' && value === false && (
					<Box px={2} pb={2}>
						<InputLabel>Please select your station</InputLabel>
						<Select
							fullWidth
							value={loadingstationId}
							size="small"
							onChange={(event) =>
								handleChange(event.target.value)
							}>
							{loadingArea.stations.map((station) => (
								<MenuItem value={station._id} key={station._id}>
									{station.name}
								</MenuItem>
							))}
						</Select>
					</Box>
				)}
				{field === 'remotecast_permissions' && !value && (
					<div className="px-2 pb-2">
						<ShowVideoButton
							url="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_remote_cast.mp4"
							buttonCaption="Watch video about RemoteCast Permissions"
							videoTitle="RemoteCast Permissions"
							icon={<Icon icon="film" />}
							type="outlined"
						/>
					</div>
				)}
			</>
		)
	}
)
