import { FC } from 'react'

import { hasTestOrder } from '../../../utils/order'
import { CustomReactJoyride } from '../CustomReactJoyride'
import { TestOrderGuidedTourProps } from '../types'

export const TestOrderReadyGuidedTour: FC<TestOrderGuidedTourProps> = ({
	orders
}) => {
	if (!hasTestOrder(orders)) {
		return null
	}

	return (
		<CustomReactJoyride
			continuous={true}
			steps={[
				{
					target: '.test-order',
					content:
						'The order is now ready for picking and to be loaded into a robot or picked up by a customer.'
				},
				{
					target: '.test-order [data-testid="ActionPickedUp"]',
					content:
						'Tap "Pick Up" to mark the order as picked up by a customer.'
				},
				{
					target: '.test-order [data-testid="ActionCompletePicking"]',
					content:
						'Tap "Load order without phone" to mark order ready for loading.'
				},
				{
					target: '.test-order [data-testid="ActionCompletePicking"]',
					content:
						'When order is ready for loading, you can simply show the order receipt to the robot to unlock the robot.'
				}
			]}
		/>
	)
}
