import classNames from 'classnames'

import { OpenStatusMap } from '../../@types/OpenStatusMap'
import { Status } from './types'

export const minutesLeftUntilAccepting = (pausedUntilSeconds: number) => {
	const estimatedPauseTimeMinutes = Math.floor(
		(pausedUntilSeconds - Date.now() / 1000) / 60
	)
	return estimatedPauseTimeMinutes < 0 ? 0 : estimatedPauseTimeMinutes
}

export const getAcceptingOrdersText = (status: Status) => {
	const pausedText = status.paused_until
		? `Paused for ${minutesLeftUntilAccepting(status.paused_until)} minutes`
		: 'Paused'
	return status.op_status === OpenStatusMap.CLOSED_FOR_ORDERS
		? pausedText
		: 'Accepting orders'
}

export const getTextClasses = (status: Status) => {
	return classNames({
		'text-green': status.op_status !== OpenStatusMap.CLOSED_FOR_ORDERS,
		'text-red': status.op_status === OpenStatusMap.CLOSED_FOR_ORDERS
	})
}
