import { FC, memo, useEffect, useState } from 'react'

import { Box, CircularProgress, Divider, Typography } from '@mui/material'

import { BatteryIcon } from '../BatteryIcon'
import { sleep } from '../utils/sleep'
import { BatteryManager, Props } from './types'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const BatteryCheck: FC<Props> = memo(({ onNext }) => {
	const [battery, setBattery] = useState<BatteryManager>()
	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		const getBattery = async () => {
			const [currentBattery] = await Promise.all([
				(navigator as any).getBattery(),
				sleep(1000)
			])
			setBattery(currentBattery)
			if (currentBattery.charging) {
				if (currentBattery.level < 0.2) {
					await sleep(5000)
					return onNext()
				}
				if (currentBattery.level < 0.5) {
					await sleep(3000)
					return onNext()
				}
				if (currentBattery.level >= 0.5) {
					await sleep(3000)
					return onNext()
				}
			}
			if (!currentBattery.charging) {
				if (currentBattery.level < 0.2) {
					await sleep(5000)
					return getBattery()
				}
				if (currentBattery.level < 0.5) {
					await sleep(5000)
					return getBattery()
				}
				if (currentBattery.level >= 0.5) {
					await sleep(5000)
					return onNext()
				}
			}
		}
		getBattery()
	}, [onNext])

	const levelLabel =
		typeof battery?.level === 'number'
			? Number.parseFloat((battery.level * 100).toFixed(2)) + '%'
			: '...'
	return (
		<Box>
			{battery && (
				<Box textAlign="center" mb={2}>
					<Box fontSize={96}>
						<BatteryIcon
							level={battery.level}
							charging={battery.charging}
						/>
					</Box>
					<Typography variant="h4">
						{battery?.level >= 0.5 && `Battery level ${levelLabel}`}
						{battery?.level > 0.2 &&
							battery.level < 0.5 &&
							`Battery low (${levelLabel})`}
						{battery?.level <= 0.2 &&
							`Battery critically low (${levelLabel})`}
					</Typography>
					<Typography variant="subtitle1">
						{battery?.charging === false &&
							'Please connect the device to a charger'}
						{battery?.charging === true && 'The device is charging'}
					</Typography>
				</Box>
			)}
			{!battery && (
				<Box display="flex" justifyContent="center" pt={4} pb={6}>
					<CircularProgress size={96} />
				</Box>
			)}
			{battery && battery.level < 0.2 && !battery.charging && (
				<>
					<Divider />
					<Box display="flex" justifyContent="center" pt={2}>
						<Typography variant="caption">
							If you are still having issues, call our merchant
							support
						</Typography>
					</Box>
				</>
			)}
		</Box>
	)
})
