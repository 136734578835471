import { useMutation, useQuery } from '@tanstack/react-query'

import { Message } from '../@types/Message'
import { BASE_URL_MERCHANT_OPERATIONS_API } from '../api/constants'
import { fetchServer } from '../api/fetchServer'
import { queryClient } from '../queryClient'

const MESSAGES_BASE_QUERY_KEY = 'messages'

const MESSAGES_POLLING_INTERVAL_MS = 1000 * 300 // 5 min

export const useGetMessagesQueryWithInterval = (input: {
	serviceAssignmentId: string
}) => {
	return useQuery({
		queryKey: [MESSAGES_BASE_QUERY_KEY, input],
		queryFn: () =>
			fetchServer<Message[]>(
				`/v2/messages/${input.serviceAssignmentId}`,
				'GET',
				undefined,
				BASE_URL_MERCHANT_OPERATIONS_API
			),
		enabled: !!input.serviceAssignmentId,
		refetchInterval: MESSAGES_POLLING_INTERVAL_MS
	})
}

export const useMarkMessagesDeliveredMutation = () => {
	return useMutation({
		mutationKey: ['markMessageDelivered'],
		mutationFn: (input: {
			serviceAssignmentId: string
			messageIds: string[]
		}) => {
			return fetchServer<void>(
				`/v2/messages/${input.serviceAssignmentId}/deliveries`,
				'POST',
				{ message_ids: input.messageIds },
				BASE_URL_MERCHANT_OPERATIONS_API
			)
		},
		onSuccess: () => {
			return queryClient.invalidateQueries({
				queryKey: [MESSAGES_BASE_QUERY_KEY]
			})
		}
	})
}
