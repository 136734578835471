import { useMemo } from 'react'

import { Typography } from '@mui/material'

import { partition } from 'lodash'

import { useGetMechantServiceRequests } from '../../../../../queries/merchantServiceRequests'
import { useAppSelector } from '../../../../../utils/storeHelpers'
import { RequestTable } from './RequestTable'

export const MerchantServiceRequestList = () => {
	const serviceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)

	const { data = [] } = useGetMechantServiceRequests({
		serviceAssignmentId: serviceAssignmentId ?? ''
	})

	const [pendingRequests, closedRequests] = useMemo(() => {
		return partition(data, (serviceRequest) => !serviceRequest.closed_at)
	}, [data])

	// Applies for both empty list and initial loading states
	if (data.length === 0) {
		return null
	}

	return (
		<>
			<Typography fontSize="large" fontWeight="bold" mb={2}>
				Recent support tickets
			</Typography>

			<RequestTable title="Pending" serviceRequests={pendingRequests} />
			<RequestTable title="Closed" serviceRequests={closedRequests} />
		</>
	)
}
