import { useQuery } from '@tanstack/react-query'
import { pick } from 'lodash'

import { fetchServer } from '../../api/fetchServer'
import { VenueConfiguration, VenuesConfiguration } from './types'

// Most likely feature flags will not change frequently
const STALE_TIME = 10 * (60 * 1000) // 10 mins
const CACHE_TIME = 15 * (60 * 1000) // 15 mins

export const useVenuesConfigurationQuery = (serviceAssignmentIds: string[]) => {
	return useQuery({
		queryKey: ['venuesConfiguration', serviceAssignmentIds],
		queryFn: () => fetchVenuesConfiguration(serviceAssignmentIds),
		select: (data) => {
			return data
				.map((result) =>
					pick(result, ['feature_flags', 'service_assignment', 'id'])
				)
				.reduce((accumulator, current) => {
					accumulator[current.service_assignment._id] = current
					return accumulator
				}, {} as VenuesConfiguration)
		},
		refetchInterval: STALE_TIME,
		staleTime: STALE_TIME,
		cacheTime: CACHE_TIME,
		enabled: serviceAssignmentIds.length !== 0
	})
}

const fetchVenuesConfiguration = (
	serviceAssignmentIds: string[]
): Promise<VenueConfiguration[]> =>
	fetchServer<VenueConfiguration[]>('/v1/venues-configuration', 'POST', {
		service_assignment_ids: serviceAssignmentIds
	})
