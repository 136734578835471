import React, { memo, useCallback } from 'react'

import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'

import cx from 'classnames'

import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { Icon } from '../../Icon'
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator'
import { actions } from './actions'
import { ActionProps } from './types'

export const Action = memo<ActionProps>((props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <ActionRevampedView {...props} />
	}

	return <ActionView {...props} />
})

const ActionView = memo<ActionProps>(
	({ className, testId, type, onClick, isLoading }) => {
		const { label, color, icon, className: actionClassName } = actions[type]

		// I love me some classes
		const classes = cx(
			'flex flex-1 items-center justify-center flex-col border-solid text-center select-none',
			{
				'text-white bg-purple active:bg-purple/80': color === 'purple',
				'text-white bg-red': color === 'red',
				'text-white bg-green active:bg-green/80': color === 'green',
				'text-purple bg-black/0 active:bg-black/20': !color
			},
			{
				'active:bg-purple/80': color === 'purple' && onClick,
				'active:bg-red/80': color === 'red' && onClick,
				'active:bg-green/80': color === 'green' && onClick,
				'active:bg-black/20': !color && onClick
			},
			actionClassName,
			className
		)

		return (
			<div
				data-testid={testId}
				className={classes}
				onClick={onClick}
				role="button">
				{isLoading ? (
					<LoadingIndicator />
				) : (
					<>
						{icon ? <Icon icon={icon} /> : null}
						<span>{label}</span>
					</>
				)}
			</div>
		)
	}
)

const getButtonColorTheme = (color?: string) => {
	if (color === 'red') return 'error'
	if (color === 'green') return 'success'
	return 'primary'
}

const ActionRevampedView = memo<ActionProps>(
	({
		className,
		testId,
		type,
		onClick,
		isLoading,
		variant = 'outlined',
		displayStyle = 'icon',
		isContextAction = false
	}) => {
		const { label, color, icon } = actions[type]

		const buttonColour = getButtonColorTheme(color)

		const handleClick = useCallback(
			(event: React.MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation()
				onClick?.()
			},
			[onClick]
		)

		return (
			<LoadingButton
				data-testid={testId}
				onClick={handleClick}
				disableRipple
				loading={isLoading}
				color={buttonColour}
				variant={variant}
				fullWidth={displayStyle === 'fullWidth'}
				sx={{
					...(isContextAction
						? {
								minHeight: 72,
								maxHeight: 72,
								border: 0,
								borderRadius: 0
						  }
						: {
								minHeight: 48,
								maxHeight: 48,
								border: '1px solid grey.300',
								borderRadius: '8px'
						  }),
					padding: 0,
					...(displayStyle === 'icon'
						? { minWidth: 48, maxWidth: 48 }
						: {})
				}}>
				{icon && <Icon icon={icon} width={24} height={24} />}
				{displayStyle !== 'icon' && (
					<Typography ml={1} fontSize={18}>
						{label}
					</Typography>
				)}
			</LoadingButton>
		)
	}
)
