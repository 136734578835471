import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'

import { fetchServer } from './api/fetchServer'
import { isLineItemUpchargedModifier } from './utils/lineItem/isLineItemUpchargedModifier'

export const RECEIVED_ORDERS = 'receivedOrders'
export const OPTIMISTIC_ORDER_UPDATE = 'optimisticOrderUpdate'
export const RECEIVED_ORDER_UPDATE = 'receivedOrderUpdate'
export const APP_STATE = 'appState'
export const ORDER_PICKED_UP = 'orderPickedUp'
export const ITEM_UPDATE = 'itemUpdate'
export const OPTIMISTIC_ITEM_UPDATE = 'optimisticItemUpdate'
export const OPTIMISTIC_MODIFIER_VALUE_UPDATE = 'optimisticModifierValueUpdate'
export const RECEIVED_ITEMS = 'receivedItems'

export const FETCH_REPORT_PENDING = 'FETCH_REPORT_PENDING'
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS'
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR'

export function fetchStationOrders(loadingareaId, loadingstationId) {
	return function (dispatch, getState) {
		return fetchServer(
			`/v1/loadingareas/${loadingareaId}/stations/${loadingstationId}/orders`
		)
			.then((response) => {
				if (!getState().orders.updatesInProgress) {
					dispatch({
						type: RECEIVED_ORDERS,
						orders: response.orders.map((order) => ({
							...order,
							loadingareaId,
							loadingstationId
						})),
						lineitems: response.lineitems.filter(
							(lineItem) => !isLineItemUpchargedModifier(lineItem)
						),
						stations: [],
						items: response.items,
						stationId: loadingstationId
					})
				}
			})
			.catch((error) => {
				if (error.response?.status) {
					if (![502, 503].includes(error.response.status)) {
						Sentry.captureException(error)
					}
				} else {
					Sentry.captureException(error)
				}
			})
	}
}

function orderUpdate({ order = {}, items = [], lineitems = [] }) {
	return {
		type: RECEIVED_ORDER_UPDATE,
		items,
		order,
		lineitems
	}
}

export function setSectionStatus(
	loadingareaId,
	stationId,
	_id,
	orderId,
	status,
	currentStatus
) {
	return function (dispatch) {
		dispatch({
			type: OPTIMISTIC_ORDER_UPDATE,
			order: {
				order_id: orderId,
				station_status_hr: status
			}
		})

		return fetchServer(
			`/v1/loadingareas/${loadingareaId}/orders/${_id}/stations/${stationId}/status`,
			'POST',
			{
				status
			}
		)
			.then((response) => {
				dispatch(orderUpdate({ ...response }))
			})
			.catch((error) => {
				dispatch(
					orderUpdate({
						order: {
							order_id: orderId,
							station_status_hr: currentStatus
						}
					})
				)
				if (![502, 503].includes(error.response?.status)) {
					Sentry.captureException(error)
				}
			})
	}
}

export function setItemStatus(status, itemId, assignmentId) {
	return function (dispatch) {
		dispatch({
			type: OPTIMISTIC_ITEM_UPDATE,
			status,
			itemId,
			assignmentId
		})
		return fetchServer(
			`/v1/serviceassignments/${assignmentId}/items/status`,
			'post',
			{
				items: [itemId],
				status
			}
		)
			.then((response) => {
				if (!(response && response.code === 1)) {
					throw response
				}
			})
			.catch(() => {
				dispatch({
					type: OPTIMISTIC_ITEM_UPDATE,
					status: status === 5 ? 0 : 5,
					itemId,
					assignmentId
				})
			})
	}
}

export function setAllToInStock(itemIds, assignmentId) {
	return function (dispatch) {
		const status = 0
		itemIds.map((itemId) => {
			dispatch({
				type: OPTIMISTIC_ITEM_UPDATE,
				status,
				itemId,
				assignmentId,
				updateModifiers: true
			})
		})
		return fetchServer(
			`/v1/serviceassignments/${assignmentId}/items/status`,
			'post',
			{
				items: itemIds,
				status,
				toggle_modifiers: true
			}
		)
			.then((response) => {
				if (!(response && response.code === 1)) {
					throw response
				}
			})
			.catch(() => {
				/*
                              dispatch({
                                type: OPTIMISTIC_ITEM_UPDATE,
                                status: status === 5 ? 0 : 5,
                                itemIds,
                                assignmentId,
                              })
                               */
			})
	}
}

export function setModifierValueDisabled(
	serviceAssignment,
	itemId,
	modifierId,
	modifierValueId,
	disabled
) {
	return function (dispatch) {
		dispatch({
			type: OPTIMISTIC_MODIFIER_VALUE_UPDATE,
			serviceAssignment,
			itemId,
			modifierId,
			modifierValueId,
			disabled
		})
		return fetchServer(
			`/v1/serviceassignments/${serviceAssignment}/items/${itemId}/modifiers/${modifierId}/values/${modifierValueId}/disabled`,
			'post',
			{
				disabled
			}
		)
			.then((response) => {
				if (!(response && response.code === 1)) {
					throw response
				}
			})
			.catch(() => {
				dispatch({
					type: OPTIMISTIC_MODIFIER_VALUE_UPDATE,
					serviceAssignment,
					itemId,
					modifierId,
					modifierValueId,
					disabled: !disabled
				})
			})
	}
}

export function fetchItems(loadingareaId) {
	return function (dispatch) {
		return fetchServer(`/v1/loadingareas/${loadingareaId}/items`).then(
			(res) => {
				dispatch({
					type: RECEIVED_ITEMS,
					items: res.items
				})
			}
		)
	}
}

export function fetchReportPending() {
	return {
		type: FETCH_REPORT_PENDING
	}
}

export function fetchReportSuccess(
	cursor,
	orders,
	summary,
	currency,
	current = dayjs(Date.now()).format('YYYY-MM-DD')
) {
	return {
		type: FETCH_REPORT_SUCCESS,
		cursor: {
			current,
			prev: cursor.prev,
			next: cursor.next
		},
		orders,
		summary,
		currency
	}
}

export function fetchReportError(errorFetching) {
	return {
		type: FETCH_REPORT_ERROR,
		errorFetching
	}
}

export const fetchDailyReport = (serviceAssignmentId, date) => {
	return (dispatch) => {
		dispatch(fetchReportPending())
		return fetchServer(
			`/v1/kitchen-report/${serviceAssignmentId}/daily/${date}`
		)
			.then((res) => {
				const {
					data: {
						cursor,
						report: { orders, summary },
						serviceAssignment: { currency }
					}
				} = res
				dispatch(
					fetchReportSuccess(cursor, orders, summary, currency, date)
				)
			})
			.catch((error) => {
				dispatch(fetchReportError(error.message))
			})
	}
}

export function setOrderPickedUp(loadingAreaId, stationId, _id, orderId) {
	return function (dispatch) {
		dispatch({
			type: ORDER_PICKED_UP,
			order_id: orderId,
			picked_up: true
		})

		return fetchServer(
			`/v1/loadingareas/${loadingAreaId}/orders/${_id}/customerpickedup`,
			'POST',
			{}
		)
			.then(() => {
				dispatch(fetchStationOrders(loadingAreaId, stationId))
			})
			.catch((error) => {
				dispatch({
					type: ORDER_PICKED_UP,
					order_id: orderId,
					picked_up: false
				})
				console.error(error)
			})
	}
}

export function closeServiceAssignment(
	serviceAssignmentId,
	close_password,
	pause_duration
) {
	return fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/close`,
		'POST',
		{
			close_password,
			close_reason: 'CLOSED_BY_KITCHEN',
			pause_duration
		}
	)
}

export function openServiceAssignment(serviceAssignmentId) {
	return fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/open`,
		'POST',
		{}
	)
}

export function getVenueStatus(serviceAssignmentId) {
	return fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/status`,
		'GET'
	)
}

export function updateItemStatuses({ itemIds, serviceAssignmentId, status }) {
	return fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/items/status`,
		'POST',
		{
			items: itemIds,
			status: status
		}
	)
}

export function completePicking({ loadingareaId, orderId }) {
	return fetchServer(
		`/v1/loadingareas/${loadingareaId}/orders/${orderId}/pickingcompleted`,
		'POST',
		{}
	)
}

export function startPicking({ loadingareaId, orderId }) {
	return fetchServer(
		`/v1/loadingareas/${loadingareaId}/orders/${orderId}/pickingstarted`,
		'POST',
		{ fulfill: true }
	)
}
