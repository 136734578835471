import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ServiceAssignment } from '../@types/Partner'

type State = {
	selectedServiceAssignmentId: string | null
	serviceAssignmentsById: Record<string, ServiceAssignment>
}

const initialState: State = {
	serviceAssignmentsById: {},
	selectedServiceAssignmentId: null
}

const serviceAssignmentSlice = createSlice({
	name: 'serviceAssignments',
	initialState,
	reducers: {
		updateServiceAssignmentsById(
			state,
			action: PayloadAction<Record<string, ServiceAssignment>>
		) {
			state.serviceAssignmentsById = action.payload
		},
		updateSelectedServiceAssignmentId(
			state,
			action: PayloadAction<string | null>
		) {
			state.selectedServiceAssignmentId = action.payload
		}
	}
})

export const {
	updateServiceAssignmentsById,
	updateSelectedServiceAssignmentId
} = serviceAssignmentSlice.actions

export default serviceAssignmentSlice.reducer
