import { APP_STATE, RECEIVED_ORDERS } from '../actions'

function app(state = { timestamp: Date.now() }, action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return {
				...state,
				timestamp: Date.now()
			}

		case APP_STATE:
			return {
				...state,
				...action.appstate
			}

		default:
			return state
	}
}

export default app
