import { FC, Fragment, memo, useCallback } from 'react'

import { Box, Button, Divider, List, Typography } from '@mui/material'

import { PreflightCheckSettingsField } from '../../../@types/PreflightCheck'
import { CheckItem } from '../CheckItem'
import { Props } from './types'

export const MultiCheck: FC<Props> = memo(
	({ values, checks, onChange, onNext, stationName, loadingArea }) => {
		const disabled = checks.some(
			(check) =>
				check.is_required &&
				(values[check.field] === undefined ||
					values[check.field] === '')
		)
		const makeCheckLabel = useCallback(
			(check: PreflightCheckSettingsField) => {
				if (check.name.includes('$stationName')) {
					return check.name.replace('$stationName', stationName)
				}
				if (check.name.includes('$minLimit')) {
					return check.name.replace(
						'$minLimit',
						(check.attributes?.min_limit ?? 7).toString()
					)
				}
				return check.name
			},
			[stationName]
		)
		return (
			<Box>
				<Box textAlign="center" mb={2}>
					<Typography variant="h4">Supplies and setup</Typography>
				</Box>
				<Divider />
				<Box>
					<List>
						{checks.map((check, index) => (
							<Fragment key={check.field}>
								<CheckItem
									loadingArea={loadingArea}
									field={check.field}
									value={values[check.field]}
									minLimit={check.attributes?.min_limit}
									type={check.type}
									label={makeCheckLabel(check)}
									onChange={(value) =>
										onChange({
											...values,
											[check.field]: value
										})
									}
								/>
								{index !== checks.length - 1 && <Divider />}
							</Fragment>
						))}
					</List>
				</Box>
				<Box pt={2}>
					<Button
						variant="contained"
						fullWidth
						onClick={onNext}
						disabled={disabled}>
						Complete preflight
					</Button>
				</Box>
			</Box>
		)
	}
)
