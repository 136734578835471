import { useMutation } from '@tanstack/react-query'

import { container } from '../../../../../../container'
import { CancelOrderArguments, CancellationReason } from './types'

export const useCancelOrder = () => {
	return useMutation({
		mutationKey: ['cancelOrder'],
		mutationFn: (payload: CancelOrderArguments) => cancelOrder(payload)
	})
}

const cancelOrder = async ({
	order,
	reason,
	description
}: CancelOrderArguments) => {
	const deviceExternalToken = container.getDeviceToken(true)

	const cancelReason =
		reason === CancellationReason.ALL_ITEMS_WERE_OUT_OF_STOCK
			? 'NO_ITEMS_AVAILABLE'
			: 'MERCHANT_CANCELLED'
	const cancelDescription =
		reason === CancellationReason.OTHER
			? `Merchant has cancelled with reason: ${description}`
			: 'Merchant is busy'

	return fetch(
		`${window.location.origin}/merchant-operations-api/api/v2/orders/${order._id}/cancel`,
		{
			method: 'POST',
			headers: {
				'content-type': 'application/json',
				'device-external-token': deviceExternalToken,
				'x-csrf-token': window.CSRF_TOKEN
			},
			body: JSON.stringify({
				cancelReason,
				description: cancelDescription
			})
		}
	).then((result) => {
		if (!result.ok) {
			throw new Error(result.statusText)
		}

		return result.status
	})
}
