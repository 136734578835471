import { useMemo } from 'react'

import { Order } from '../../@types/Order'
import { LineItemFulfilmentState } from '../../reducers/lineItemFulfillment'

// need to correctly count items by item id
// 1) if there's just 1 item - no need to count (null)
// 2) if there's a few of same (same line item with qty) - show that
// 3) if there's a few of same item but in different line items - show that
// also show fulfilment statuses for each item
export const useLineItemsData = ({
	order,
	lineItemFulfillment
}: {
	order: Order
	lineItemFulfillment: LineItemFulfilmentState
}) => {
	const itemCounts = useMemo(() => {
		return order.lineitems.reduce((accumulator, lineItem) => {
			if (accumulator[lineItem.item]) {
				accumulator[lineItem.item] += lineItem.qty
			} else {
				accumulator[lineItem.item] = lineItem.qty
			}

			return accumulator
		}, {})
	}, [order.lineitems])

	const lineItemsWithIndexAndStatus = useMemo(() => {
		const itemsCountDecrement = { ...itemCounts }

		return order.lineitems.flatMap((lineItem) => {
			// the trickstery with "subLineItems" is done to handle items that have qty > 1 to show them on separate lines
			return Array.from({ length: lineItem.qty })
				.fill(null)
				.map((_, lineItemIndex) => {
					const index =
						itemCounts[lineItem.item] -
						itemsCountDecrement[lineItem.item] +
						1
					// ah, modification in map bad
					itemsCountDecrement[lineItem.item] -= 1

					return {
						lineItem,
						meta: {
							countIndex:
								itemCounts[lineItem.item] > 1 ? index : null,
							total: itemCounts[lineItem.item],
							status:
								lineItemFulfillment?.[order.order_id]?.[
									lineItem._id
								]?.[lineItemIndex] ?? null,
							lineItemIndex
						}
					}
				})
		})
	}, [lineItemFulfillment, itemCounts, order.lineitems, order.order_id])

	return { lineItemsWithIndexAndStatus }
}
