import { useCallback, useEffect } from 'react'

import { soundService } from '../../services/sound'

type UseSoundIntervalOptions = {
	callback?: () => void
	intervalMs: number | null
	shouldPlay: boolean
}

export const useSoundInterval = ({
	callback,
	intervalMs,
	shouldPlay
}: UseSoundIntervalOptions) => {
	const play = useCallback(() => {
		soundService.playSound()

		if (callback) {
			callback()
		}
	}, [callback])

	useEffect(() => {
		if (!shouldPlay || intervalMs === null) {
			return
		}

		const interval = setInterval(() => {
			play()
		}, intervalMs)

		return () => {
			clearInterval(interval)
		}
	}, [intervalMs, play, shouldPlay])
}
