import dayjs from 'dayjs'

export type WorkingHoursSegment = {
	day: number
	end: number
	start: number
}

export type ParsedWorkingHour = {
	dateEnd: dayjs.Dayjs
	dateStart: dayjs.Dayjs
}

export enum VenueOperatingStatus {
	CLOSED = 'CLOSED',
	OPEN = 'OPEN'
}

export type VenueOperatingOpen = {
	closesAt: string
	status: VenueOperatingStatus.OPEN
}

export type VenueOperatingClosed = {
	status: VenueOperatingStatus.CLOSED
}

export type VenueOperating = VenueOperatingOpen | VenueOperatingClosed
