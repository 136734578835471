import { Box, Typography, useTheme } from '@mui/material'

export const OrderId = ({
	orderId,
	kioskOrderId
}: {
	orderId: number
	kioskOrderId: string | null
}) => {
	const theme = useTheme()

	if (kioskOrderId === null) {
		return (
			<Typography variant="subtitle1" fontWeight="bold">
				{orderId}
			</Typography>
		)
	}

	return (
		<Box display="flex" flexDirection="row" alignItems="center">
			<Box
				sx={{
					borderRadius: '3px',
					background: theme.palette.secondary.dark,
					color: '#ffffff',
					mr: 0.5,
					height: 17,
					display: 'flex',
					px: 1,
					alignItems: 'center'
				}}>
				<Typography fontWeight="700" fontSize={12}>
					KIOSK
				</Typography>
			</Box>

			<Typography variant="subtitle1" fontWeight="bold">
				{kioskOrderId}
			</Typography>
		</Box>
	)
}
