import { throttle } from 'lodash'

import { container } from '../../container'

export const SOUND_MINIMUM_INTERVAL_MS = 1000

const service = () => {
	// Avoid driving the merchant to absolute madness by discarding sounds if a sound has just played
	const throttledPlaySound = throttle(
		container.playSound,
		SOUND_MINIMUM_INTERVAL_MS,
		{
			trailing: false
		}
	)

	return {
		playSound: throttledPlaySound
	}
}

export const soundService = service()
