import { useMemo } from 'react'

import { useGetMechantServiceRequests } from '../../../../../queries/merchantServiceRequests'

export const usePendingServiceRequests = (serviceAssignmentId: string) => {
	const { data = [], isLoading } = useGetMechantServiceRequests({
		serviceAssignmentId: serviceAssignmentId ?? ''
	})

	return useMemo(() => {
		return {
			pendingServiceRequests: data.filter(
				(serviceRequest) => !serviceRequest.closed_at
			),
			isLoading
		}
	}, [data, isLoading])
}
