import { memo } from 'react'

import { Dialog } from '@mui/material'

import { VideoDialogProps } from './types'

export const VideoDialog = memo<VideoDialogProps>(
	({ poster, src, title, contentTestID, isOpen, onClose }) => {
		return (
			<Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
				<video
					src={src}
					width="100%"
					controls
					disablePictureInPicture
					controlsList="nodownload"
					poster={poster}
					title={title}
					data-testid={contentTestID}
				/>
			</Dialog>
		)
	}
)
