import { FC, MouseEvent, useState } from 'react'

import { PinInput } from '../../PinInput'
import styles from '../../index.module.scss'

export const EnterPinCodeContent: FC<{
	onPinEntered: (pin: string) => void
}> = ({ onPinEntered }) => {
	const [pin, setPin] = useState<string>('')
	const [errorMessage, setErrorMessage] = useState<string | undefined>()

	const onClick = (event: MouseEvent<HTMLDivElement>) => {
		event.preventDefault()
		if (pin.length === 4) {
			onPinEntered(pin)
		} else {
			setErrorMessage('Please enter the PIN')
		}
	}

	return (
		<>
			<div className={styles.header}>Enter PIN to change status</div>
			<PinInput pin={pin} setPin={setPin} errorMessage={errorMessage} />
			<div className={styles.footer}>
				<div className={styles.updateButton} onClick={onClick} />
			</div>
		</>
	)
}
