import { FC } from 'react'

import { Modal } from '../../Modal/Modal'
import { OrderModalProps } from './types'

export const OrderModal: FC<OrderModalProps> = ({
	onRequestClose,
	children,
	isOpen,
	width = 720
}) => {
	return (
		<Modal isOpen={isOpen} onRequestClose={onRequestClose} width={width}>
			{children}
		</Modal>
	)
}
