import { FC, forwardRef, useCallback } from 'react'

import cx from 'classnames'
import { motion } from 'framer-motion'

import { useOrderLateAlert } from '../../hooks/useOrderLateAlert'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { useAppSelector } from '../../utils/storeHelpers'
import { SpeechBubble } from '../SpeechBubble'
import { animationVariants, useAnimation } from './animation'
import { OrderAlertInnerProps, OrderAlertProps } from './types'
import { useSoundInterval } from './useSoundInterval'
import {
	alertHasSoundByType,
	alertIntervalMsByOrderStationStatus,
	alertLabelByType,
	getAlertLabelType
} from './utils'

export const OrderAlert: FC<OrderAlertProps> = ({ order }) => {
	const robot = useAppSelector(
		(state) => state.robotDistribution.robotsForOrders[order._id]
	)

	const alert = useOrderLateAlert(order)

	const { animate, controls } = useAnimation()
	const alertType = getAlertLabelType({ order, robot })

	const soundCallback = useCallback(() => {
		animate()
	}, [animate])

	useSoundInterval({
		intervalMs:
			alertIntervalMsByOrderStationStatus[order.station_status_hr],
		shouldPlay: alertHasSoundByType[alertType] && !!alert,
		callback: soundCallback
	})

	if (!alert) {
		return null
	}

	return (
		<MotionOrderAlertInner
			type={alertType}
			robot={robot}
			variants={animationVariants}
			animate={controls}
		/>
	)
}

export const OrderAlertInner = forwardRef<HTMLDivElement, OrderAlertInnerProps>(
	({ type, robot }, reference) => {
		const { useRevampedUI } = useVenueFeatureFlags()

		const containerClassName = cx({ 'ml-[-6px] mb-2': !useRevampedUI })

		const label = alertLabelByType[type](robot)

		return (
			<div
				ref={reference}
				data-testid="alertbubble"
				className={containerClassName}>
				<SpeechBubble color={label.color ?? 'red'}>
					{label.text}
				</SpeechBubble>
			</div>
		)
	}
)

const MotionOrderAlertInner = motion(OrderAlertInner)
