import { useCallback, useState } from 'react'

import { Support } from '@mui/icons-material'
import { Button, Dialog, DialogContent, Typography } from '@mui/material'

import { DialogTitleWithClose } from '../../DialogTitleWithClose'
import { HelpCenterTabs } from './HelpCenterTabs'

export const HelpCenter = () => {
	const [isDialogOpen, setDialogOpen] = useState(false)

	const handleClose = useCallback(() => {
		setDialogOpen(false)
	}, [])

	return (
		<>
			<Button
				id="help-center-button"
				variant="outlined"
				color="secondary"
				onClick={() => setDialogOpen(true)}
				sx={{ height: 46, backgroundColor: 'white' }}
				startIcon={<Support color="primary" />}>
				<Typography color="text.primary">Help Center</Typography>
			</Button>

			<Dialog
				open={isDialogOpen}
				fullWidth
				maxWidth="md"
				onClose={handleClose}>
				<DialogTitleWithClose
					title="Help Center"
					onClose={handleClose}
				/>
				<DialogContent dividers>
					<HelpCenterTabs onSubmitSuccess={handleClose} />
				</DialogContent>
			</Dialog>
		</>
	)
}
