import React from 'react'

import PropTypes from 'prop-types'

import { ReactComponent as Search } from '../../icons/Search.svg'

class InventorySearch extends React.Component {
	input = React.createRef()
	handleOnChange = (e) => {
		if (e && e.target) {
			this.props.onChange(e.target.value)
		}
	}
	render() {
		const { value } = this.props
		return (
			<div className={'input-container search'}>
				<form
					style={{ display: 'flex', flex: 1 }}
					onSubmit={(e) => {
						e.preventDefault()
						if (this.input && this.input.current) {
							this.input.current.blur()
						}
					}}>
					<div className={'search-icon'}>
						<Search />
					</div>
					<input
						ref={this.input}
						type={'text'}
						placeholder={this.props.placeholder}
						value={value}
						onChange={this.handleOnChange}
					/>
					{!!value && (
						<div
							className={'clear-search'}
							onClick={() => {
								this.props.onChange('')
							}}>
							<div>&times;</div>
						</div>
					)}
				</form>
			</div>
		)
	}
}

InventorySearch.propTypes = {
	value: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired
}

export default InventorySearch
