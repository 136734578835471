import { combineReducers } from 'redux'

import alert from './alert'
import app from './app'
import items from './items'
import { reducer as itemsForOrdersReducer } from './itemsForOrders'
import lineItemFulfillment from './lineItemFulfillment'
import lineitems from './lineitems'
import loadingArea from './loadingArea'
import orders from './orders'
import report from './report'
import robotDistribution from './robotDistribution'
import serviceAssignment from './serviceAssignment'
import stations from './stations'

const reducers = combineReducers({
	app,
	lineitems,
	items,
	orders,
	stations,
	report,
	itemsForOrders: itemsForOrdersReducer,
	serviceAssignment,
	loadingArea,
	alert,
	robotDistribution,
	lineItemFulfillment
})

export default reducers
