import { useRef } from 'react'
import {
	Control,
	Controller,
	UseFormRegister,
	useFormState
} from 'react-hook-form'

import { Box, TextField } from '@mui/material'

import { MuiTelInput } from 'mui-tel-input'

import { FormValues } from './types'

export const CommentField = ({
	register
}: {
	register: UseFormRegister<FormValues>
}) => {
	const scrollComponentReference = useRef<HTMLElement>()

	return (
		<Box sx={{ position: 'relative', width: '100%' }}>
			<Box
				sx={{ position: 'absolute', top: -60 }}
				ref={scrollComponentReference}
			/>
			<TextField
				label="Comment"
				{...register('comment')}
				InputLabelProps={{
					shrink: true
				}}
				fullWidth
				onFocus={() =>
					scrollComponentReference.current?.scrollIntoView({
						behavior: 'smooth'
					})
				}
			/>
		</Box>
	)
}
export const PhoneNumberField = ({
	control
}: {
	control: Control<FormValues>
}) => {
	const { errors } = useFormState<FormValues>({ control })
	const scrollComponentReference = useRef<HTMLElement>()

	return (
		<Box sx={{ position: 'relative', width: '100%' }}>
			<Box
				sx={{ position: 'absolute', top: -60 }}
				ref={scrollComponentReference}
			/>
			<Controller
				name="phoneNumber"
				control={control}
				render={({ field: { value, onChange } }) => (
					<MuiTelInput
						label="Phone number for callback*"
						preferredCountries={['US', 'GB', 'EE', 'FI']}
						defaultCountry="US"
						value={value}
						fullWidth
						onChange={onChange}
						InputLabelProps={{
							shrink: true
						}}
						onFocus={() =>
							scrollComponentReference.current?.scrollIntoView({
								behavior: 'smooth'
							})
						}
						error={!!errors.phoneNumber?.message}
					/>
				)}
			/>
		</Box>
	)
}
export const RobotIdField = ({
	register,
	control
}: {
	register: UseFormRegister<FormValues>
	control: Control<FormValues>
}) => {
	const { errors } = useFormState<FormValues>({ control })
	const scrollComponentReference = useRef<HTMLElement>()

	return (
		<Box sx={{ position: 'relative', width: '100%' }}>
			<Box
				sx={{ position: 'absolute', top: -60 }}
				ref={scrollComponentReference}
			/>
			<TextField
				label="Robot ID*"
				{...register('robotId')}
				InputLabelProps={{
					shrink: true
				}}
				inputProps={{
					sx: { scrollMargin: 40 }
				}}
				fullWidth
				onFocus={() =>
					scrollComponentReference.current?.scrollIntoView({
						behavior: 'smooth'
					})
				}
				error={!!errors.robotId?.message}
			/>
		</Box>
	)
}
