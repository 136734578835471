import { FC, useMemo } from 'react'

import cx from 'classnames'
import { first } from 'lodash'

import { useGetRobotsQueryWithInterval } from '../../../queries/robots'
import { mapRobotArrivals } from '../../../utils/robot'
import { useAppSelector } from '../../../utils/storeHelpers'
import robotArrivalImg from './robot-arrival.png'
import { AvailabilityRowProps, RobotAvailabilityInnerProps } from './types'

export const RobotAvailability = () => {
	const loadingAreaId = useAppSelector(
		(state) => state.loadingArea.selectedLoadingAreaId
	)

	const { data } = useGetRobotsQueryWithInterval({
		loadingAreaId: loadingAreaId ?? ''
	})

	const robotArrivals = useMemo(() => {
		return mapRobotArrivals(data?.data.robots ?? [])
	}, [data?.data.robots])

	const nextArrivalSeconds = useMemo(() => {
		return first(robotArrivals.arrivingEtasSeconds.sort())
	}, [robotArrivals.arrivingEtasSeconds])

	return (
		<RobotAvailabilityInner
			availableRobotsCount={robotArrivals.availableCount}
			arrivingRobotsCount={robotArrivals.arrivingEtasSeconds.length}
			nextArrivalMinutes={
				nextArrivalSeconds ? Math.floor(nextArrivalSeconds / 60) : null
			}
		/>
	)
}

export const RobotAvailabilityInner: FC<RobotAvailabilityInnerProps> = ({
	arrivingRobotsCount,
	availableRobotsCount,
	nextArrivalMinutes
}) => {
	const arrivingText =
		nextArrivalMinutes === null
			? `${arrivingRobotsCount} arriving`
			: `${arrivingRobotsCount} arriving (${nextArrivalMinutes}min)`

	return (
		<div className="flex flex-row items-center" data-testid="RobotsStatus">
			<img
				className="mr-4"
				src={robotArrivalImg}
				width={28}
				alt="robotArrival"
			/>

			<div>
				<AvailabilityRow
					color="green"
					text={`${availableRobotsCount} available`}
				/>
				<AvailabilityRow color="orange" text={arrivingText} />
			</div>
		</div>
	)
}

const AvailabilityRow: FC<AvailabilityRowProps> = ({ color, text }) => {
	const bubbleClasses = cx('mr-2 h-2 w-2 rounded-full', {
		'bg-green': color === 'green',
		'bg-orange': color === 'orange'
	})

	return (
		<div className="flex flex-row items-center text-sm">
			<div className={bubbleClasses} />
			<span>{text}</span>
		</div>
	)
}
