import React from 'react'
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType
} from 'react-router-dom'

import * as Sentry from '@sentry/react'

export const initSentry = () => {
	if (process.env.NODE_ENV !== 'production') {
		return
	}

	Sentry.init({
		dsn: 'https://6a1b343a1436477c9916031c51f57177@o37965.ingest.sentry.io/1446249',
		release: process.env.REACT_APP_BUILD_NUMBER,
		integrations: [
			new Sentry.BrowserProfilingIntegration(),
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				)
			}),
			new Sentry.Replay()
		],
		tracesSampleRate: 0.1,
		profilesSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.1,
		replaysSessionSampleRate: 0.1
	})
}
