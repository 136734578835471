import { Typography } from '@mui/material'

import { Props } from './types'

export const ItemsList = ({ items, titleColor, title }: Props) => {
	if (items.length === 0) return null

	return (
		<>
			<Typography
				color={`${titleColor}.main`}
				fontWeight={600}
				fontSize={18}>
				{title}
			</Typography>
			{items?.map((name, index) => (
				<Typography fontSize={18} key={`item_${index}`}>
					• {name}
				</Typography>
			))}
		</>
	)
}
