import React from 'react'

import './LoadingIndicator.scss'

class LoadingIndicator extends React.Component {
	render() {
		return (
			<div
				data-testid="LoadingIndicator"
				className={'loadingindicator-container'}>
				<div className="index-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
		)
	}
}

export default LoadingIndicator
