import { useMemo } from 'react'

import { Order } from '../../@types/Order'
import { useAppSelector } from '../../utils/storeHelpers'
import { useDismissStaleNewOrderReminderAlerts } from './useDismissStaleNewOrderReminderAlerts'
import { useNewOrderArrivalSound } from './useNewOrderArrivalSound'
import { useNewOrderReminderSound } from './useNewOrderReminderSound'

export const OrderNewNotifier = () => {
	const ordersById: Record<string, Order> = useAppSelector(
		(state) => state.orders.byId
	)

	const orders = useMemo(() => {
		return Object.values(ordersById)
	}, [ordersById])

	useNewOrderArrivalSound(orders)
	useNewOrderReminderSound(orders)
	useDismissStaleNewOrderReminderAlerts(orders)

	return null
}
