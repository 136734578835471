import { useCallback, useState } from 'react'

import { LoadingArea, LoadingStation } from '../@types/LoadingArea'
import { sleep } from '../components/PreflightCheck/utils/sleep'
import { container } from '../container'
import { generateTestReceipt } from '../services/printer'

export const usePrinter = (useActivePrinter: boolean) => {
	const [printingTestPage, setPrintingTestPage] = useState(true)
	const printTestPage = useCallback(
		async (loadingArea: LoadingArea, loadingStation: LoadingStation) => {
			setPrintingTestPage(true)
			try {
				const isPrinterInterfaceEnabled =
					container.isPrinterInterfaceEnabled()
				if (isPrinterInterfaceEnabled) {
					container.printReceipt(
						generateTestReceipt(loadingArea, loadingStation)
					)
				}
			} catch {
				//
			} finally {
				await sleep(5000)
				setPrintingTestPage(false)
			}
		},
		[]
	)

	return {
		printingTestPage,
		printTestPage
	}
}
