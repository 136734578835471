import { Dialog } from '../../../../../../components/Dialog'
import { Props } from './types'

export const OutOfStockItemDialog = ({
	onMarkOutOfStockClick,
	itemName,
	onCancelClick
}: Props) => {
	return (
		<Dialog
			title={`Mark "${itemName}" out of stock for future orders?`}
			description={[
				'Marking out of stock will prevent customers from ordering this item from this point on.',
				'The orders that already contain it won’t be affected.'
			]}
			actions={[
				{
					title: 'Yes, mark as out of stock',
					onClick: onMarkOutOfStockClick
				},
				{
					title: 'No, do nothing',
					onClick: onCancelClick
				}
			]}
		/>
	)
}
