import { FC } from 'react'

import cx from 'classnames'

import { OrderStatusHR } from '../../../../../../@types/Order'
import { Action } from '../../../../../../components/OrderRevamp/Action'
import { ActionType } from '../../../../../../components/OrderRevamp/Action/types'
import { OrderCard } from '../../../../../../components/OrderRevamp/Layout/OrderCard'
import { OrderContent } from '../../../../../../components/OrderRevamp/Layout/OrderContent'
import { OrderContentLeft } from '../../../../../../components/OrderRevamp/Layout/OrderContentLeft'
import { OrderId } from '../../../../../../components/OrderRevamp/Layout/OrderId'
import { OrderSideButton } from '../../../../../../components/OrderRevamp/Layout/OrderSingleAction'
import { OrderLabelDeliveryType } from '../../../../../../components/OrderRevamp/OrderLabel/OrderLabelDeliveryType'
import { OrderLabelItemCount } from '../../../../../../components/OrderRevamp/OrderLabel/OrderLabelItemCount'
import { OrderLabelTimer } from '../../../../../../components/OrderRevamp/OrderLabel/OrderLabelTimer'
import { useVenueFeatureFlags } from '../../../../../../hooks/useVenueFeatureFlags'
import { OrderSmallContent } from './OrderSmallContent'
import { OrderSmallProps } from './types'

export const OrderSmall: FC<OrderSmallProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderSmallRevampedView {...props} />
	}

	return <OrderSmallView {...props} />
}

const OrderSmallView: FC<OrderSmallProps> = ({
	order,
	action,
	renderAlert,
	onViewClick
}) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED

	const orderContentClasses = cx({
		'active:bg-black/20': !isCancelled
	})

	return (
		<OrderCard isTranslucent={isCancelled} isTestOrder={order.testing}>
			<OrderContent
				className={orderContentClasses}
				onClick={isCancelled ? undefined : onViewClick}>
				<OrderContentLeft className="flex flex-col justify-between">
					<OrderId
						orderId={order._id}
						kioskOrderId={order.kiosk_order_id}
					/>

					{isCancelled ? null : (
						<div data-testid="ActionViewOrder">
							<div className="border flex items-center justify-center rounded-2xl border-solid border-purple py-1 px-5 mr-2">
								<span
									className="text-sm text-purple"
									role="button">
									View
								</span>
							</div>
						</div>
					)}
				</OrderContentLeft>

				<div className="pt-[2px]">
					{isCancelled ? null : renderAlert}

					<OrderLabelDeliveryType order={order} />
					<OrderLabelItemCount order={order} />
					{isCancelled ? null : <OrderLabelTimer order={order} />}
				</div>
			</OrderContent>

			<OrderSideButton>
				{isCancelled ? <Action type={ActionType.CANCELLED} /> : action}
			</OrderSideButton>
		</OrderCard>
	)
}

const OrderSmallRevampedView: FC<OrderSmallProps> = ({
	order,
	action,
	renderAlert,
	onViewClick,
	rightAction
}) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED

	return (
		<OrderCard isTranslucent={isCancelled} isTestOrder={order.testing}>
			<OrderSmallContent
				order={order}
				onViewClick={onViewClick}
				action={action}
				renderAlert={renderAlert}
				rightAction={rightAction}
			/>
		</OrderCard>
	)
}
