import React, { useCallback } from 'react'

import { Typography } from '@mui/material'

import { ReceiptFontScale } from '../../@types/Settings'
import { useSettingsContext } from '../SettingsContext'
import { Setting } from './Setting'
import { SettingRadioRow } from './SettingRadioRow'
import { SettingRowList } from './SettingRowList'
import { SettingTitle } from './SettingTitle'

// In the future, we might have more settings, at that point we can rip
// the base components out and use those as building blocks.
export const MenuAccessibility = () => {
	const { settings: state, setSettings } = useSettingsContext()

	const handleReceiptFontSizeChange = useCallback(
		(receiptFontScale: ReceiptFontScale) => {
			setSettings({
				...state,
				accessibility: {
					...state.accessibility,
					receiptFontScale
				}
			})
		},
		[setSettings, state]
	)

	return (
		<div className="px-10 w-full">
			<Typography variant="h4" fontWeight="bold" my={3}>
				Accessibility
			</Typography>

			<Setting>
				<SettingTitle text="Receipt Font Scale" />

				<SettingRowList>
					<SettingRadioRow
						text="Small"
						selected={
							state.accessibility.receiptFontScale === 'small'
						}
						onClick={() => handleReceiptFontSizeChange('small')}
					/>
					<SettingRadioRow
						text="Medium"
						selected={
							state.accessibility.receiptFontScale === 'medium'
						}
						onClick={() => handleReceiptFontSizeChange('medium')}
					/>
					<SettingRadioRow
						text="Large"
						selected={
							state.accessibility.receiptFontScale === 'large'
						}
						onClick={() => handleReceiptFontSizeChange('large')}
					/>
				</SettingRowList>
			</Setting>
		</div>
	)
}
