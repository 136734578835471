import React, { CSSProperties, FC, memo } from 'react'

import { Check } from '@mui/icons-material'

import { ReactComponent as ArrowForward } from '../../icons/ArrowForward.svg'
import { ReactComponent as Burger } from '../../icons/Burger.svg'
import { ReactComponent as Car } from '../../icons/Car.svg'
import { ReactComponent as CheckRound } from '../../icons/CheckRound.svg'
import { ReactComponent as Checklist } from '../../icons/Checklist.svg'
import { ReactComponent as Checkmark } from '../../icons/Checkmark.svg'
import { ReactComponent as Chevron } from '../../icons/Chevron.svg'
import { ReactComponent as ChevronDown } from '../../icons/ChevronDown.svg'
import { ReactComponent as ChevronRound } from '../../icons/ChevronRound.svg'
import { ReactComponent as Close2 } from '../../icons/Close2.svg'
import { ReactComponent as CloseIcon } from '../../icons/Close.svg'
import { ReactComponent as CloseLarge } from '../../icons/CloseLarge.svg'
import { ReactComponent as CreditCard } from '../../icons/CreditCard.svg'
import { ReactComponent as Document } from '../../icons/Document.svg'
import { ReactComponent as Done } from '../../icons/Done.svg'
import { ReactComponent as Edit } from '../../icons/Edit.svg'
import { ReactComponent as Film } from '../../icons/Film.svg'
import { ReactComponent as HamburgerIcon } from '../../icons/Hamburger.svg'
import { ReactComponent as HelpOutline } from '../../icons/HelpOutline.svg'
import { ReactComponent as Info } from '../../icons/Info.svg'
import { ReactComponent as LocalMall } from '../../icons/LocalMall.svg'
import { ReactComponent as Menu } from '../../icons/Menu.svg'
import { ReactComponent as Monetization } from '../../icons/Monetization.svg'
import { ReactComponent as Person } from '../../icons/Person.svg'
import { ReactComponent as PhoneAndroid } from '../../icons/PhoneAndroid.svg'
import { ReactComponent as Pickup } from '../../icons/Pickup.svg'
import { ReactComponent as PickupHand } from '../../icons/PickupHand.svg'
import { ReactComponent as Print } from '../../icons/Print.svg'
import { ReactComponent as Printer } from '../../icons/Printer.svg'
import { ReactComponent as ProgressActivity } from '../../icons/ProgressActivity.svg'
import { ReactComponent as Receipt } from '../../icons/Receipt.svg'
import { ReactComponent as RemoveRedEye } from '../../icons/RemoveRedEye.svg'
import { ReactComponent as RobotNoFlagTiny } from '../../icons/RobotNoFlagTiny.svg'
import { ReactComponent as RobotWaiting } from '../../icons/RobotWaiting.svg'
import { ReactComponent as Send } from '../../icons/Send.svg'
import { ReactComponent as StarshipLogo } from '../../icons/StarshipLogo.svg'
import { ReactComponent as ThreeDots } from '../../icons/ThreeDots.svg'
import { ReactComponent as Timer } from '../../icons/Timer.svg'
import { ReactComponent as Video } from '../../icons/Video.svg'

const icons = {
	chevronDown: ChevronDown,
	checkRound: CheckRound,
	printer: Printer,
	closeLarge: CloseLarge,
	pickup: Pickup,
	close: CloseIcon,
	hamburger: HamburgerIcon,
	robotNoFlagTiny: RobotNoFlagTiny,
	checklist: Checklist,
	chevron: Chevron,
	video: Video,
	document: Document,
	robotWaiting: RobotWaiting,
	localMall: LocalMall,
	timer: Timer,
	arrowForward: ArrowForward,
	pickupHand: PickupHand,
	creditCard: CreditCard,
	monetization: Monetization,
	person: Person,
	done: Done,
	edit: Edit,
	print: Print,
	close2: Close2,
	removeRedEye: RemoveRedEye,
	starshipLogo: StarshipLogo,
	chevronRound: ChevronRound,
	helpOutline: HelpOutline,
	menu: Menu,
	receipt: Receipt,
	progressActivity: ProgressActivity,
	car: Car,
	film: Film,
	phoneAndroid: PhoneAndroid,
	checkmark: Checkmark,
	check: Check,
	burger: Burger,
	info: Info,
	threeDots: ThreeDots,
	send: Send
}

export type IconName = keyof typeof icons

type Props = {
	className?: string
	height?: number | string
	icon: IconName
	style?: CSSProperties
	width?: number | string
}

const InnerIcon: FC<Props> = ({ icon, height, width, style, className }) => {
	const InnerIcon = icons[icon]

	return (
		<InnerIcon
			data-testid={`icon-${icon}`}
			className={className}
			style={{ height, width, ...style }}
		/>
	)
}

export const Icon = memo(InnerIcon)
