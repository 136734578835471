import { useMemo } from 'react'

import { AlertType } from '../@types/Alert'
import { Order } from '../@types/Order'
import { useAppSelector } from '../utils/storeHelpers'

export const useOrderLateAlert = (order: Order) => {
	const alerts = useAppSelector((state) => state.alert.alerts)

	return useMemo(() => {
		return Object.values(alerts).find(
			(alert) =>
				alert.orderId === order._id &&
				alert.type === AlertType.ORDER_LATE
		)
	}, [alerts, order._id])
}
