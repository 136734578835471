import { z } from 'zod'

import { MerchantServiceRequestType } from '../../../../../@types/MerchantServiceRequest'

export const schema = z
	.object({
		type: z.nativeEnum(MerchantServiceRequestType),
		comment: z.optional(z.string()),
		phoneNumber: z.optional(z.string()),
		robotId: z.optional(z.string())
	})
	.refine(
		(data) => {
			// phoneNumber is mandatory for CALL_BACK
			return !(
				data.type === MerchantServiceRequestType.CALL_BACK &&
				!data.phoneNumber
			)
		},
		{
			path: ['phoneNumber'],
			message: 'Phone number is required'
		}
	)
	.refine(
		(data) => {
			// robotId is mandatory for ROBOT_OPEN and ROBOT_SEND_AWAY
			return !(
				(data.type === MerchantServiceRequestType.ROBOT_OPEN ||
					data.type === MerchantServiceRequestType.ROBOT_SEND_AWAY) &&
				!data.robotId
			)
		},
		{
			path: ['robotId'],
			message: 'Robot id is required'
		}
	)

export type FormValues = z.infer<typeof schema>

export type Props = {
	onSuccess: () => void
}
