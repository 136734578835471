import { FC, useCallback, useState } from 'react'

import videoPoster from '../../../assets/images/video-poster.jpg'
import { Button } from '../../Button'
import { VideoDialog } from '../../VideoDialog'
import { Props } from './types'

export const ShowVideoButton: FC<Props> = ({
	url,
	icon,
	buttonCaption,
	videoTitle,
	type
}) => {
	const [isOpen, setOpen] = useState(false)
	const toggleModal = useCallback(() => {
		setOpen((state) => !state)
	}, [])

	const onWatchVideoClick = useCallback(() => {
		toggleModal()
	}, [toggleModal])

	return (
		<>
			<Button
				caption={buttonCaption}
				isLoading={false}
				onClick={onWatchVideoClick}
				type={type ?? 'default'}
				isSmallText={true}
				icon={icon}
			/>

			<VideoDialog
				data-testid="faq-video"
				src={url}
				poster={videoPoster}
				title={videoTitle}
				isOpen={isOpen}
				onClose={toggleModal}
			/>
		</>
	)
}
