import { FC } from 'react'

import { ReactComponent as Backspace } from '../../../icons/Backspace.svg'
import styles from './index.module.scss'
import { Props } from './types'

export const PinInput: FC<Props> = ({ pin, setPin, errorMessage }) => {
	const onTableClick = (error: any) => {
		const target = error.target as HTMLElement
		if (pin.length < 4) {
			setPin(pin + target.textContent)
		}
	}

	const onClickBackspace = (error: any) => {
		error.stopPropagation()
		if (pin.length > 0) {
			setPin(pin.slice(0, -1))
		}
	}

	return (
		<div className={styles.pinInput}>
			<div className={styles.input}>
				<div className={styles.pinNumbers} data-testid="pin-number">
					{[...Array.from({ length: 4 }).keys()].map((key) => (
						<div
							className={`${styles.number} ${
								pin.charAt(key) ? '' : styles.transparent
							}`}
							key={`pin-number-${key}`}>
							●
						</div>
					))}
				</div>
				{errorMessage && (
					<div className={styles.error}>{errorMessage}</div>
				)}
			</div>

			<table className={styles.numbersTable} onClick={onTableClick}>
				<tbody>
					<tr>
						<td>1</td>
						<td>2</td>
						<td>3</td>
					</tr>
					<tr>
						<td>4</td>
						<td>5</td>
						<td>6</td>
					</tr>
					<tr>
						<td>7</td>
						<td>8</td>
						<td>9</td>
					</tr>
					<tr>
						<td></td>
						<td>0</td>
						<td id="backspace" onClick={onClickBackspace}>
							<Backspace />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
