import { useMemo } from 'react'

import { FulfillmentStatus } from '../../../../../../@types/LineItem'
import { Order } from '../../../../../../@types/Order'
import { useAppSelector } from '../../../../../../utils/storeHelpers'

export const useOrderPacking = (orderId: number) => {
	const lineItemFulfilment = useAppSelector(
		(state) => state.lineItemFulfillment
	)
	const orders = useAppSelector(
		(state) => state.orders.byId as Record<string, Order>
	)

	const { fulfilledItems, unfulfilledItems } = useMemo(() => {
		const order = orders[orderId]

		if (!order) return { fulfilledItems: [], unfulfilledItems: [] }

		const itemIdToItemNameMap = new Map(
			order.lineitems.map((lineItem) => [lineItem.item, lineItem.name])
		)
		const itemToLineItemsMap: Record<string, number[]> = {}
		order.lineitems.forEach((lineItem) => {
			if (itemToLineItemsMap[lineItem.item]) {
				itemToLineItemsMap[lineItem.item] = [
					...itemToLineItemsMap[lineItem.item],
					lineItem._id
				]
				return
			}

			itemToLineItemsMap[lineItem.item] = [lineItem._id]
		})

		const orderItemFulfilmentQty = lineItemFulfilment[orderId]
		if (!orderItemFulfilmentQty)
			return { fulfilledItems: [], unfulfilledItems: [] }

		const lineItemFulfilmentQty = Object.entries(
			orderItemFulfilmentQty
		).reduce<Record<number, { fulfilled: number; unfulfilled: number }>>(
			(
				accumulator,
				[lineItemId, slots]: [string, Record<number, FulfillmentStatus>]
			) => {
				accumulator[lineItemId] = Object.values(slots).reduce(
					(accumulator, slot) => {
						if (slot === FulfillmentStatus.CAN_FULFILL) {
							accumulator.fulfilled += 1
						} else {
							accumulator.unfulfilled += 1
						}

						return accumulator
					},
					{ fulfilled: 0, unfulfilled: 0 }
				)

				return accumulator
			},
			{}
		)

		const { fulfilled, unfulfilled } = Object.entries(
			itemToLineItemsMap
		).reduce<{
			fulfilled: { itemId: string; qty: number; name: string }[]
			unfulfilled: { itemId: string; qty: number; name: string }[]
		}>(
			(accumulator, [itemId, lineItems]) => {
				const { fulfilled, unfulfilled } = lineItems.reduce(
					(accumulator, lineItemId) => {
						accumulator = {
							fulfilled:
								accumulator.fulfilled +
								lineItemFulfilmentQty[lineItemId].fulfilled,
							unfulfilled:
								accumulator.unfulfilled +
								lineItemFulfilmentQty[lineItemId].unfulfilled
						}

						return accumulator
					},
					{
						fulfilled: 0,
						unfulfilled: 0
					}
				)

				if (fulfilled !== 0) {
					accumulator.fulfilled.push({
						itemId,
						qty: fulfilled,
						name: itemIdToItemNameMap.get(itemId) ?? ''
					})
				}

				if (unfulfilled !== 0) {
					accumulator.unfulfilled.push({
						itemId,
						qty: unfulfilled,
						name: itemIdToItemNameMap.get(itemId) ?? ''
					})
				}

				return accumulator
			},
			{ fulfilled: [], unfulfilled: [] }
		)

		return {
			fulfilledItems: fulfilled,
			unfulfilledItems: unfulfilled
		}
	}, [lineItemFulfilment, orderId, orders])

	return { fulfilledItems, unfulfilledItems }
}
