import { FC } from 'react'

import { OrderLabel } from '.'
import { Order } from '../../../@types/Order'

export const OrderLabelName: FC<{ order: Order }> = ({ order }) => {
	if (!order.name_hr) {
		return null
	}

	return (
		<OrderLabel
			icon="person"
			label={order.name_hr}
			textClassName="text-ellipsis whitespace-nowrap"
		/>
	)
}
