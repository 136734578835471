import { orderBy, zip } from 'lodash'

import { DeliveryType, StationStatus } from '../../@types/Order'
import { Robot } from '../../@types/Robot'
import { DistributeRobotsForOrdersOptions } from './types'

export const distributeRobotsForOrders = (
	options: DistributeRobotsForOrdersOptions
) => {
	const { orders, robots } = options

	const deliveryOrders = orders.filter(
		(order) => order.delivery_type === DeliveryType.TO_CUSTOMER
	)

	// Ready orders first, then the rest by descending visible.at
	const sortedOrders = orderBy(
		deliveryOrders,
		[
			(order) => order.station_status_hr === StationStatus.COMPLETED,
			(order) => order.visible_at
		],
		['desc', 'asc']
	)

	// Arrived robots first, then ascending order robots that are arriving
	const sortedRobots = orderBy(
		robots,
		[(robot) => robot.arrived, (robot) => robot.etaBotLoadingArrival?.mid],
		['desc', 'asc']
	)

	const orderIdWithRobot = zip(sortedOrders, sortedRobots)
		.map(([order, robot]) => [order?._id, robot])
		.filter((tuple): tuple is [number, Robot] => !!tuple[0] && !!tuple[1])

	return Object.fromEntries(orderIdWithRobot)
}
