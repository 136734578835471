import { FC } from 'react'

import cx from 'classnames'

import { OrderContentProps } from './types'

export const OrderContent: FC<OrderContentProps> = ({
	children,
	className,
	onClick,
	testId
}) => {
	const classes = cx('flex flex-1 flex-row py-5 pl-5 pr-1', className)

	return (
		<div data-testid={testId} onClick={onClick} className={classes}>
			{children}
		</div>
	)
}
