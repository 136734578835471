import { FC, ReactNode } from 'react'

import { Box, Typography } from '@mui/material'

import cx from 'classnames'

import { AlertLabel } from '../../@types/Alert'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'

export const SpeechBubble: FC<{
	children?: ReactNode
	color?: AlertLabel['color']
}> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <SpeechBubbleRevampedView {...props} />
	}

	return <SpeechBubbleView {...props} />
}

export const SpeechBubbleView: FC<{
	children?: ReactNode
	color?: AlertLabel['color']
}> = ({ color, children }) => {
	const bubbleClasses = cx('relative mb-1 rounded py-1 px-2 inline-block', {
		'bg-red': color === 'red',
		'bg-orange': color === 'orange'
	})

	const arrowClasses = cx(
		'absolute top-full left-3 border-[6px] border-solid border-[transparent]',
		{
			'border-t-red': color === 'red',
			'border-t-orange': color === 'orange'
		}
	)

	return (
		<div data-testid="speechbubble" className={bubbleClasses}>
			<span className="text-white">{children}</span>
			<div className={arrowClasses} />
		</div>
	)
}

export const SpeechBubbleRevampedView: FC<{
	children?: ReactNode
	color?: AlertLabel['color']
}> = ({ color, children }) => {
	const textColor = color === 'red' ? 'error' : 'primary'

	return (
		<Box position="relative">
			<Typography color={textColor} fontWeight={600}>
				{children}
			</Typography>
		</Box>
	)
}
