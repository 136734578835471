import { memo } from 'react'

import { Dialog } from '@mui/material'

import { ImageDocumentProps } from './types'

export const ImageDocumentDialog = memo<ImageDocumentProps>(
	({ src, title, contentTestID, isOpen, onClose }) => {
		return (
			<Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
				<img src={src} alt={title} data-testid={contentTestID} />
			</Dialog>
		)
	}
)
