import { FC } from 'react'
import ReactModal from 'react-modal'

import classNames from 'classnames'

import styles from './Modal.module.scss'

const isTestEnvironment = process.env.NODE_ENV === 'test'
const isStorybook = process.env.STORYBOOK === 'true'

if (!isTestEnvironment && !isStorybook) ReactModal.setAppElement('#root')

type Props = {
	className?: string
	overlayClassName?: string
	width?: number | string
} & ReactModal.Props

export const Modal: FC<Props> = ({
	isOpen,
	children,
	className,
	overlayClassName,
	width,
	...rest
}) => {
	return (
		<ReactModal
			testId="modal"
			ariaHideApp={!isTestEnvironment}
			isOpen={isOpen}
			className={classNames(styles.modal, className)}
			style={{ content: { width } }}
			overlayClassName={classNames(styles.overlay, overlayClassName)}
			{...rest}>
			{children}
		</ReactModal>
	)
}
