import { useMemo } from 'react'

import { AlertOrderLate, AlertType } from '../../@types/Alert'
import { Order, StationStatus } from '../../@types/Order'
import { useAppSelector } from '../../utils/storeHelpers'
import { useDismissStaleLateOrderAlerts } from './useDismissStaleLateOrderAlerts'
import { useRegisterLateOrderAlerts } from './useRegisterLateOrderAlerts'

export const LateOrderAlertManager = () => {
	const alertsById = useAppSelector((state) => state.alert.alerts)

	const ordersById = useAppSelector(
		(state) => state.orders.byId as Record<string, Order>
	)

	// We are only concerned about this specific alert type
	const orderLateAlerts = useMemo(() => {
		return Object.values(alertsById).filter(
			(alert): alert is AlertOrderLate =>
				alert.type === AlertType.ORDER_LATE
		)
	}, [alertsById])

	// All not-completed orders. Cancelled orders don't matter as we don't show alerts for them
	const incompleteOrders = useMemo(() => {
		return Object.values(ordersById).filter(
			(order) =>
				![StationStatus.COMPLETED].includes(order.station_status_hr)
		)
	}, [ordersById])

	useRegisterLateOrderAlerts({ orderLateAlerts, incompleteOrders })
	useDismissStaleLateOrderAlerts({ orderLateAlerts, incompleteOrders })

	return null
}
