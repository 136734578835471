import { FC } from 'react'

import { setMenuTour } from '../../../utils/localStorage'
import { CustomReactJoyride } from '../CustomReactJoyride'
import { Props } from './types'

export const MenuGuidedTour: FC<Props> = ({ enabled }) => {
	if (!enabled) {
		return null
	}

	return (
		<CustomReactJoyride
			continuous={true}
			callback={(props) => {
				if (['reset', 'close'].includes(props.action)) {
					setMenuTour(false)
				}
			}}
			steps={[
				{
					target: '[data-testid="MenuStation"]',
					content:
						'Tap "Station" to choose the kitchen you are working for',
					disableBeacon: true
				},
				{
					target: '[data-testid="MenuInventory"]',
					content:
						'Tap "Inventory" to manage the stock of your kitchen',
					disableBeacon: true
				},
				{
					target: '[data-testid="MenuAccessibility"]',
					content:
						'Tap "Accessibility" to adjust settings such as receipt font size',
					disableBeacon: true
				},
				{
					target: '[data-testid="MenuTestPrinter"]',
					content:
						'Tap "Test printer" to send test receipt to the printer',
					disableBeacon: true
				},
				{
					target: '[data-testid="MenuTestOrder"]',
					content:
						'Tap "Create test order" to learn how to manage orders in the Kitchen App',
					disableBeacon: true
				}
			]}
		/>
	)
}
