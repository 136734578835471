import { FC } from 'react'

import styles from '../../index.module.scss'

export const AcceptInitialContent: FC<{
	closeModal: () => void
}> = ({ closeModal }) => (
	<>
		<div className={styles.header}>Accepting orders</div>
		<div className={`${styles.content}`}>
			<b>Customers will see standard delivery times in Starship app.</b>
		</div>
		<div className={styles.footer}>
			<div className={styles.closeButton} onClick={closeModal} />
			<div className={`${styles.updateButton} ${styles.disabled}`} />
		</div>
	</>
)
