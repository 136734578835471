import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from '@mui/material'

import dayjs from 'dayjs'

import { merchantServiceRequestTypeDictionary } from '../../../../../@types/MerchantServiceRequest'
import { RequestTableProps } from './types'

export const RequestTable = ({ serviceRequests, title }: RequestTableProps) => {
	const isEmpty = !serviceRequests.length

	return (
		<Box mb={3}>
			<Typography fontWeight={600} color="secondary" mb={2}>
				{title}
			</Typography>
			{isEmpty ? (
				<Typography variant="caption">No tickets</Typography>
			) : (
				<TableContainer component={Paper}>
					<Table>
						<TableBody>
							{serviceRequests.map((serviceRequest) => (
								<TableRow key={serviceRequest.id}>
									<TableCell>
										{
											merchantServiceRequestTypeDictionary[
												serviceRequest.type
											]
										}
									</TableCell>
									<TableCell>{serviceRequest.text}</TableCell>
									<TableCell>
										{dayjs(
											serviceRequest.created_at
										).format('L')}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}
		</Box>
	)
}
