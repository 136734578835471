import keyBy from 'lodash/keyBy'
import { combineReducers } from 'redux'

import {
	OPTIMISTIC_ORDER_UPDATE,
	ORDER_PICKED_UP,
	RECEIVED_ORDERS,
	RECEIVED_ORDER_UPDATE
} from '../actions'

const truncateName = (name) => {
	if (!name) {
		return ''
	}
	let parts = name.split(' ')
	// filter out empty parts
	parts = parts.filter((part) => !!part)

	let ret = ''

	// insert first name
	ret += parts[0]

	// uppercase first letter
	ret = ret[0].toUpperCase() + ret.slice(1)

	// insert uppercased last name initial
	if (parts[1]) {
		ret += ' ' + parts[1].charAt(0).toUpperCase()
	}
	return ret
}
// keep a list of order id-s for iteration
function ids(state = [], action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return action.orders.map((order) => order.order_id)
		default:
			return state
	}
}

// actual order data is keyed by order id, for easier updates
function byId(state = {}, action) {
	switch (action.type) {
		case RECEIVED_ORDERS: {
			let orders = action.orders.map((order) => {
				return {
					...order,
					name_hr: order && truncateName(order.name_hr)
				}
			})
			return keyBy(orders, 'order_id')
		}
		case OPTIMISTIC_ORDER_UPDATE:
		case RECEIVED_ORDER_UPDATE:
			return {
				...state,
				[action.order.order_id]: {
					...state[action.order.order_id],
					...action.order
				}
			}

		default:
			return state
	}
}

function updatesInProgress(state = 0, action) {
	switch (action.type) {
		case OPTIMISTIC_ORDER_UPDATE:
			return state + 1
		case RECEIVED_ORDER_UPDATE:
			return state - 1
		default:
			return state
	}
}

export const pickedUp = (state = {}, action) => {
	if (action.type === ORDER_PICKED_UP) {
		return {
			...state,
			[action.order_id]: action.picked_up
		}
	}

	return state
}

export const getOrder = (state, id) => state.byId[id]

export const getOrders = (state) => state.ids.map((id) => getOrder(state, id))

export const getOrdersWithStatus = (state, statuses) =>
	state.ids
		.map((id) => getOrder(state, id))
		.filter((order) => statuses.includes(order.status_hr))

export default combineReducers({
	updatesInProgress,
	byId,
	ids,
	pickedUp
})
