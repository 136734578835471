export enum MerchantServiceRequestType {
	CALL_BACK = 0,
	REOPEN_SERVICE = 1,
	ROBOT_OPEN = 2,
	ROBOT_SEND_AWAY = 3
}

export const merchantServiceRequestTypeDictionary: Record<
	MerchantServiceRequestType,
	string
> = {
	[MerchantServiceRequestType.CALL_BACK]: 'Call-back',
	[MerchantServiceRequestType.REOPEN_SERVICE]: 'Reopen service',
	[MerchantServiceRequestType.ROBOT_OPEN]: 'Robot open',
	[MerchantServiceRequestType.ROBOT_SEND_AWAY]: 'Robot send away'
}

export type MerchantServiceRequest = {
	id: string
	type: MerchantServiceRequestType
	text: string
	created_at: string
	updated_at: string
	closed_at?: string
}

export type MerchantServiceRequestCreateInput = {
	type: MerchantServiceRequestType
	text?: string
}
