import { FC, useEffect } from 'react'

import { LoadingArea } from '../../@types/LoadingArea'
import { ServiceAssignment } from '../../@types/Partner'
import {
	updateLoadingAreasById,
	updateSelectedLoadingArea
} from '../../reducers/loadingArea'
import {
	updateSelectedServiceAssignmentId,
	updateServiceAssignmentsById
} from '../../reducers/serviceAssignment'
import { useAppDispatch } from '../../utils/storeHelpers'

type Props = {
	loadingAreas: Record<string, LoadingArea>
	selectedLoadingAreaId: string | null
	selectedServiceAssignmentId: string | null
	serviceAssignments: Record<string, ServiceAssignment>
}

// Currently the software works as follows:
// - On init loading area / service assignment data is fetched once
// - If successful, that data is passed down to various components for use
// - This component pushes relevant data to redux for any component to use without props drilling
export const MerchantDataManager: FC<Props> = ({
	serviceAssignments,
	loadingAreas,
	selectedLoadingAreaId,
	selectedServiceAssignmentId
}) => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(updateServiceAssignmentsById(serviceAssignments))
	}, [dispatch, serviceAssignments])

	useEffect(() => {
		dispatch(updateLoadingAreasById(loadingAreas))
	}, [dispatch, loadingAreas])

	useEffect(() => {
		dispatch(updateSelectedLoadingArea(selectedLoadingAreaId))
	}, [dispatch, selectedLoadingAreaId])

	useEffect(() => {
		dispatch(updateSelectedServiceAssignmentId(selectedServiceAssignmentId))
	}, [dispatch, selectedServiceAssignmentId])

	return null
}
