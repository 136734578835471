import { useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import { AlertType } from '../../@types/Alert'
import { Order, StationStatus } from '../../@types/Order'
import { registerAlert } from '../../reducers/alert'
import { soundService } from '../../services/sound'
import { makeOrderAlertId } from '../../utils/order'
import { useAppDispatch, useAppSelector } from '../../utils/storeHelpers'

const SOUND_ALERT_INTERVAL_MS = 1000 * 60 // 1min

// Play sound on a schedule if we have new orders
export const useNewOrderReminderSound = (orders: Order[]) => {
	const dispatch = useAppDispatch()
	const [timestamp, setTimestamp] = useState<dayjs.Dayjs | null>(null)

	const alerts = useAppSelector((state) => state.alert.alerts)

	const reminderAlerts = useMemo(() => {
		return Object.values(alerts).filter(
			(alert) => alert.type === AlertType.ORDER_NEW_REMINDER
		)
	}, [alerts])

	const newOrders = useMemo(() => {
		return orders
			.filter(
				(order) => order.station_status_hr === StationStatus.CREATED
			)
			.filter((order) => {
				if (!timestamp) {
					return true
				}
				return dayjs(order.visible_at).isAfter(timestamp)
			})
	}, [orders, timestamp])

	useEffect(() => {
		if (newOrders.length === 0) {
			return
		}

		setTimestamp(dayjs())

		newOrders.forEach((order) => {
			dispatch(
				registerAlert({
					id: makeOrderAlertId(order, AlertType.ORDER_NEW_REMINDER),
					orderId: order._id,
					serviceAssignmentId: order.serviceassignment_id,
					type: AlertType.ORDER_NEW_REMINDER
				})
			)
		})
	}, [dispatch, newOrders])

	const hasReminderAlerts = reminderAlerts.length > 0

	useEffect(() => {
		if (!hasReminderAlerts) {
			return
		}

		const interval = setInterval(() => {
			soundService.playSound()
		}, SOUND_ALERT_INTERVAL_MS)

		return () => {
			clearInterval(interval)
		}
	}, [hasReminderAlerts])
}
