import { memo } from 'react'

import { Close } from '@mui/icons-material'
import { DialogTitle, IconButton } from '@mui/material'

import { Props } from './types'

export const DialogTitleWithClose = memo<Props>(
	({ title, onClose, ...rest }) => {
		return (
			<>
				<DialogTitle {...rest}>{title}</DialogTitle>
				<IconButton
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 14,
						top: 14
					}}>
					<Close />
				</IconButton>
			</>
		)
	}
)
