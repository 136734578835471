import { useState } from 'react'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'

import { HelpCenterContactUs } from './HelpCenterContactUs'
import { HelpCenterFaq } from './HelpCenterFaq'
import { HelpCenterTabsProps, TabValue } from './types'

export const HelpCenterTabs = ({ onSubmitSuccess }: HelpCenterTabsProps) => {
	const [tab, setTab] = useState<TabValue>('faq')
	return (
		<>
			<TabContext value={tab}>
				<TabList value={tab} onChange={(_, value) => setTab(value)}>
					<Tab value="faq" label="FAQ & Training" />
					<Tab value="contact" label="Contact Us" />
				</TabList>

				<TabPanel value="faq">
					<HelpCenterFaq />
				</TabPanel>
				<TabPanel value="contact">
					<HelpCenterContactUs onSubmitSuccess={onSubmitSuccess} />
				</TabPanel>
			</TabContext>
		</>
	)
}
