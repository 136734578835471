import { Chip, Stack } from '@mui/material'

import { useGetRobotsQuery } from '../../../../../queries/robots'
import { useAppSelector } from '../../../../../utils/storeHelpers'

export const BotChips = ({
	onClick
}: {
	onClick: (botName: string) => void
}) => {
	const loadingAreaId = useAppSelector(
		(state) => state.loadingArea.selectedLoadingAreaId
	)

	const { data: robotData } = useGetRobotsQuery({
		loadingAreaId: loadingAreaId ?? ''
	})

	if (!robotData?.data || robotData.data.robots.length === 0) {
		return null
	}

	return (
		<Stack direction="row" flexWrap="wrap" useFlexGap spacing={{ md: 1 }}>
			{robotData?.data.robots.map((bot) => (
				<Chip
					key={bot.botName}
					label={bot.botName}
					onClick={() => onClick(bot.botName)}
				/>
			))}
		</Stack>
	)
}
