import { FC } from 'react'

import classNames from 'classnames'

import { formatOrderId } from '../../../utils/order'
import { OrderIdProps } from './types'

export const OrderId: FC<OrderIdProps> = ({
	orderId,
	kioskOrderId,
	className
}) => {
	const classes = classNames('text-xl font-bold leading-none', className)

	if (kioskOrderId) {
		return (
			<div className="flex flex-col mb-1">
				<span className={classes}>{kioskOrderId}</span>
				<div>
					<span className="text-xs text-white font-bold rounded-sm px-2 bg-black-body">
						KIOSK
					</span>
				</div>
			</div>
		)
	}

	return <span className={classes}>{formatOrderId(orderId)}</span>
}
