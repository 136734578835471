import { useCallback } from 'react'

import { Variants, useAnimationControls } from 'framer-motion'

export const animationVariants: Variants = {
	shakeRight: {
		x: 2,
		transition: { duration: 0.1 }
	},
	shakeLeft: {
		x: -1,
		transition: { duration: 0.1 }
	},
	rest: {
		x: 0
	}
}

export const useAnimation = () => {
	const controls = useAnimationControls()

	// This is a bit tedious to do programmatically, but here we go
	const animate = useCallback(async () => {
		for (let index = 0; index < 4; index++) {
			await controls.start('shakeRight')
			await controls.start('shakeLeft')
		}
		return controls.start('rest')
	}, [controls])

	return { controls, animate }
}
