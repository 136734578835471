import { ReactNode } from 'react'

import { IconName } from '../../Icon'

export type ActionProps = {
	className?: string
	isLoading?: boolean
	onClick?: () => void
	testId?: string
	type: ActionType
	variant?: ActionVariant
	displayStyle?: DisplayStyle
	isContextAction?: boolean
}

type ActionColor = 'purple' | 'red' | 'green'

type ActionVariant = 'outlined' | 'contained'

export enum ActionType {
	ACK_CANCELLATION = 'ACK_CANCELLATION',
	CANCELLED = 'CANCELLED',
	CANCEL_EDIT = 'CANCEL_EDIT',
	CLOSE_WINDOW = 'CLOSE_WINDOW',
	EDIT_CONTENTS = 'EDIT_CONTENTS',
	MARK_READY = 'MARK_READY',
	FINALIZE_FOR_SHIPPING = 'FINALIZE_FOR_SHIPPING',
	PICKED_UP = 'PICKED_UP',
	PICKED_UP_CHECK = 'PICKED_UP_CHECK',
	PREPARE = 'PREPARE',
	START_PREPARATION = 'START_PREPARATION',
	PRINT_AGAIN = 'PRINT_AGAIN',
	REPRINT_ORDER = 'REPRINT_ORDER',
	SAVE_EDIT = 'SAVE_EDIT',
	VIEW_CONTENTS = 'VIEW_CONTENTS',
	COMPLETE_PICKING = 'COMPLETE_PICKING',
	COMPLETE_PICKING_WITH_ICON = 'CONFIRM_COMPLETE_PICKING',
	ORDER_DETAILS = 'ORDER_DETAILS',
	CANCEL_ORDER = 'CANCEL_ORDER'
}

export type DisplayStyle = 'icon' | 'fullWidth'

export type ActionLayout = {
	className?: string
	color?: ActionColor
	icon?: IconName
	label: ReactNode
}
