import keyBy from 'lodash/keyBy'
import { combineReducers } from 'redux'

import { RECEIVED_ORDERS } from '../actions'

function ids(state = [], action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return action.stations.map((station) => station._id)

		default:
			return state
	}
}

function byId(state = {}, action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return keyBy(action.stations, '_id')

		default:
			return state
	}
}

export const getStation = (state, id) => state.byId[id]
export const getStations = (state) =>
	state.ids.map((id) => getStation(state, id))

export default combineReducers({
	byId,
	ids
})
