import { FC } from 'react'

import { hasTestOrder } from '../../../utils/order'
import { CustomReactJoyride } from '../CustomReactJoyride'
import { TestOrderGuidedTourProps } from '../types'

export const TestOrderInProgressGuidedTour: FC<TestOrderGuidedTourProps> = ({
	orders
}) => {
	if (!hasTestOrder(orders)) {
		return null
	}

	return (
		<CustomReactJoyride
			continuous={true}
			steps={[
				{
					target: '.test-order',
					content: 'Order is now in the "In Progress" stage.'
				},
				{
					target: '.test-order [data-testid="ActionEditContents"]',
					content: '"Edit Contents" shows you details of the order.'
				},
				{
					target: '.test-order [data-testid="ActionPrintAgain"]',
					content: 'Tap "Print Again" to reprint the order ticket.'
				},
				{
					target: '.test-order [data-testid="ActionMarkReady"]',
					content: 'Tap "Mark as ready" to mark the order as ready.'
				}
			]}
		/>
	)
}
