import { ReceiptFontScale } from '../../@types/Settings'
import { QRCodeSize } from '../../escEncoderTypes'
import { FontSize } from './types'

export const receiptFontSizes: Record<ReceiptFontScale, FontSize> = {
	small: {
		sm: 33,
		md: 42,
		lg: 67,
		xl: 133
	},
	medium: {
		sm: 42,
		md: 46,
		lg: 75,
		xl: 150
	},
	large: {
		sm: 46,
		md: 50,
		lg: 83,
		xl: 150
	}
}

export const qrCodeSizes: Record<ReceiptFontScale, QRCodeSize> = {
	small: 'small',
	medium: 'small',
	large: 'small'
}
