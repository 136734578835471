import { FC, useCallback, useState } from 'react'

import { Modal } from '../Modal/Modal'
import { ManageTestOrder } from './ManageTestOrder'
import { PlaceTestOrder } from './PlaceTestOrder'
import { TestOrderModalProps } from './types'

export const TestOrderModal: FC<TestOrderModalProps> = ({
	serviceAssignmentId,
	loadingStationId,
	loadingAreaId,
	onCloseModal,
	isOngoingTestOrder
}) => {
	const [modalIsOpen, setModalIsOpen] = useState(true)
	const closeModal = useCallback(() => {
		setModalIsOpen(false)
		onCloseModal()
	}, [setModalIsOpen, onCloseModal])

	return (
		<Modal isOpen={modalIsOpen} width={420}>
			<div className="rounded-2xl bg-white">
				{isOngoingTestOrder ? (
					<ManageTestOrder
						closeModal={closeModal}
						serviceAssignmentId={serviceAssignmentId}
						loadingStationId={loadingStationId}
						loadingAreaId={loadingAreaId}
					/>
				) : (
					<PlaceTestOrder
						closeModal={closeModal}
						serviceAssignmentId={serviceAssignmentId}
						loadingStationId={loadingStationId}
					/>
				)}
			</div>
		</Modal>
	)
}
