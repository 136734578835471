import { Box, Typography } from '@mui/material'

import pluralize from 'pluralize'

import { Dialog } from '../../../../../../components/Dialog'
import starshipRobot from './../../../../../../assets/images/starship-robot.png'
import { ItemsList } from './ItemsList'
import { Props } from './types'

export const CollectOrderDialog = ({
	unfulfilledItems,
	fulfilledItems,
	onEditOrderClick,
	onDoneClick,
	isRobotDelivery
}: Props) => {
	return (
		<Dialog
			title="Gather all order contents and pack them in a bag."
			actions={[
				{
					title: 'Edit order',
					onClick: onEditOrderClick,
					variant: 'outlined'
				},
				{
					title: 'Done',
					onClick: onDoneClick
				}
			]}
			content={
				<Box mb={1}>
					<ItemsList
						items={fulfilledItems}
						titleColor="success"
						title={`${fulfilledItems.length} ${pluralize(
							'item',
							fulfilledItems.length
						)} will be picked and packed`}
					/>
					<Box mt={2} />
					<ItemsList
						items={unfulfilledItems}
						titleColor="error"
						title={`${unfulfilledItems.length} ${pluralize(
							'item',
							unfulfilledItems.length
						)} will be refunded to customer`}
					/>
				</Box>
			}
			footer={
				isRobotDelivery && fulfilledItems.length > 0 ? (
					<Box
						sx={{
							display: 'flex',
							background: '#F2F2F2',
							p: 2,
							mb: 2,
							borderRadius: 2
						}}>
						<img src={starshipRobot} alt="Starship robot" />
						<Box>
							<Typography fontSize={18} fontWeight={600}>
								Note!
							</Typography>
							<Typography fontSize={18}>
								This is a robot delivery order and still needs
								to be loaded into a robot.
							</Typography>
						</Box>
					</Box>
				) : null
			}
		/>
	)
}
