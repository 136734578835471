import { useCallback, useEffect } from 'react'

import dayjs from 'dayjs'

import { AlertOrderLate, AlertType } from '../../@types/Alert'
import { Order } from '../../@types/Order'
import { registerAlert } from '../../reducers/alert'
import { makeOrderAlertId, orderIsLate } from '../../utils/order'
import { useAppDispatch, useAppSelector } from '../../utils/storeHelpers'

const LATENESS_CHECK_INTERVAL_MS = 3000

export const useRegisterLateOrderAlerts = (options: {
	incompleteOrders: Order[]
	orderLateAlerts: AlertOrderLate[]
}) => {
	const { incompleteOrders, orderLateAlerts } = options

	const dispatch = useAppDispatch()

	const serviceAssignmentsById = useAppSelector(
		(state) => state.serviceAssignment.serviceAssignmentsById
	)

	const handleRegister = useCallback(() => {
		const now = dayjs()

		const lateAlertsByOrderId = Object.fromEntries(
			orderLateAlerts.map((current) => [current.orderId, current])
		)

		incompleteOrders
			// No existing alerts
			.filter((order) => !lateAlertsByOrderId[order._id])
			// Is late
			.filter((order) => {
				const merchantSla =
					serviceAssignmentsById[order.serviceassignment_id]
						?.serviceassignment_merchant_sla ?? {}

				return orderIsLate({ order, merchantSla, now })
			})
			.forEach((order) => {
				const alertId = makeOrderAlertId(order, AlertType.ORDER_LATE)

				dispatch(
					registerAlert({
						type: AlertType.ORDER_LATE,
						id: alertId,
						orderId: order._id,
						serviceAssignmentId: order.serviceassignment_id
					})
				)
			})
	}, [dispatch, orderLateAlerts, incompleteOrders, serviceAssignmentsById])

	useEffect(() => {
		handleRegister()

		const interval = setInterval(() => {
			handleRegister()
		}, LATENESS_CHECK_INTERVAL_MS)

		return () => {
			clearInterval(interval)
		}
	}, [dispatch, handleRegister])
}
