import { PrinterConnectionType } from '../../@types/Printer'

export const PRINTER_STATUS_UPDATE = 'PRINTER_STATUS_UPDATE'

export type PrinterState = {
	printerModel?: string
	interface?: PrinterConnectionType
	isOnline?: boolean
}

export const PrinterStateMockResponses: Record<string, PrinterState> = {
	none: {}
}
