import { memo, useEffect, useMemo, useState } from 'react'

import cx from 'classnames'
import dayjs from 'dayjs'

import { VenueOperatingStatus } from '../../../@types/WorkingHours'
import { useAppSelector } from '../../../utils/storeHelpers'
import { parseWorkingHours } from '../../../utils/workingHours'
import { OpeningTimesInnerProps } from './types'

const OPENING_TIMES_UPDATE_MS = 1000 * 30 // 30s

export const OpeningTimes = memo(() => {
	const [now, setNow] = useState(dayjs())

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(dayjs())
		}, OPENING_TIMES_UPDATE_MS)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const serviceAssignment = useAppSelector((state) =>
		state.serviceAssignment.selectedServiceAssignmentId
			? state.serviceAssignment.serviceAssignmentsById[
					state.serviceAssignment.selectedServiceAssignmentId
			  ]
			: null
	)

	// Should not happen, but we are defensive over here
	if (!serviceAssignment) {
		return null
	}

	return (
		<OpeningTimesInner
			workingHourSegments={
				serviceAssignment.serviceassignment_workinghours
			}
			now={now.toDate()}
		/>
	)
})

export const OpeningTimesInner = memo<OpeningTimesInnerProps>(
	({ now, workingHourSegments }) => {
		const { operating } = useMemo(() => {
			return parseWorkingHours({
				now: dayjs(now),
				segments: workingHourSegments
			})
		}, [now, workingHourSegments])

		const classes = cx('text-sm', {
			'text-grey': operating.status === VenueOperatingStatus.CLOSED
		})

		return (
			<span className={classes}>
				{operating.status === VenueOperatingStatus.OPEN
					? `Open until ${operating.closesAt}`
					: 'Closed'}
			</span>
		)
	}
)
