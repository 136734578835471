import { memo, useCallback, useState } from 'react'

import { InsertDriveFile, VideoFile } from '@mui/icons-material'
import {
	Dialog,
	DialogContent,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography
} from '@mui/material'

import videoPoster from '../../assets/images/video-poster.jpg'
import { DialogTitleWithClose } from '../DialogTitleWithClose'
import { ImageDocumentDialog } from '../ImageDocumentDialog'
import { VideoDialog } from '../VideoDialog'
import { HowToLoadOrderWithoutPhoneDialogProps } from './types'

export const HowToLoadOrderWithoutPhoneDialog =
	memo<HowToLoadOrderWithoutPhoneDialogProps>(({ isOpen, onClose }) => {
		const [isVideoOpen, setVideoOpen] = useState(false)
		const [isDocumentOpen, setDocumentOpen] = useState(false)

		const toggleVideo = useCallback(() => {
			setVideoOpen((state) => !state)
		}, [])

		const toggleDocument = useCallback(() => {
			setDocumentOpen((state) => !state)
		}, [])

		return (
			<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
				<DialogTitleWithClose
					title="How to load robots without Runner phone"
					onClose={onClose}
				/>

				<DialogContent dividers>
					<Typography>
						The process of loading a robot has been updated in
						October 2023 to allow a "phoneless" experience.
					</Typography>

					<List>
						<ListItem divider disablePadding>
							<ListItemButton
								onClick={toggleVideo}
								disableGutters>
								<ListItemText primary="Video" />

								<ListItemIcon>
									<VideoFile color="primary" />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>

						<ListItem divider disablePadding>
							<ListItemButton
								onClick={toggleDocument}
								disableGutters>
								<ListItemText primary="Document" />

								<ListItemIcon>
									<InsertDriveFile color="primary" />
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					</List>
				</DialogContent>

				<ImageDocumentDialog
					title="How to load a robot without Runner phone"
					src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_barcode_scan_training_doc_updated_12_2023.jpg"
					onClose={toggleDocument}
					isOpen={isDocumentOpen}
				/>

				<VideoDialog
					title="How to load a robot without Runner phone (video)"
					src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_barcode_scan_training_video.mp4"
					poster={videoPoster}
					onClose={toggleVideo}
					isOpen={isVideoOpen}
				/>
			</Dialog>
		)
	})
