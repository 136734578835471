import { FC, useCallback } from 'react'

import { Order, StationStatus } from '../../../../@types/Order'
import { TestOrderNewGuidedTour } from '../../../../components/GuidedTour/TestOrderNewGuidedTour'
import { OrderListByPriority } from '../../../../components/OrderList/OrderListByPriority'
import { useVenueFeatureFlags } from '../../../../hooks/useVenueFeatureFlags'
import { Header } from '../Header'
import { OrderNew } from './OrderNew'
import { OrdersNewProps } from './types'

export const OrdersNew: FC<OrdersNewProps> = ({ onStatusChange, orders }) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	const handleStartPreparation = useCallback(
		(order: Order) => {
			onStatusChange(order, StationStatus.PREPARING)
		},
		[onStatusChange]
	)

	const renderOrder = useCallback(
		(order: Order) => {
			return (
				<OrderNew
					order={order}
					onStartPreparationClick={handleStartPreparation}
				/>
			)
		},
		[handleStartPreparation]
	)

	return (
		<>
			<TestOrderNewGuidedTour orders={orders} />
			<Header dataTestId="NewOrdersColumnHeader">
				{useRevampedUI
					? `New orders (${orders.length})`
					: `${orders.length} new orders`}
			</Header>
			<OrderListByPriority orders={orders} renderOrder={renderOrder} />
		</>
	)
}
