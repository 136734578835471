import { Order } from '../../../../../../@types/Order'

export type CancellationStage = 'CONFIRM' | 'REASON'
export enum CancellationReason {
	TOO_BUSY = 'TOO_BUSY',
	ALL_ITEMS_WERE_OUT_OF_STOCK = 'ALL_ITEMS_WERE_OUT_OF_STOCK',
	// TODO: this type is not handled on BE, need to add it?
	//  or was it so that "no items available" was supposed to be combined with the comment, if "other" is chosen?
	//  https://github.com/project-echo/server-service-merchant-operations/blob/70624b87c7f994c32a50abddf4a31c53d0416a3f/src/api/src/modules/orders/dtos/cancel-order.dto.ts#L5
	OTHER = 'OTHER'
}

export type CancelOrderArguments = {
	order: Order
	reason: CancellationReason
	description?: string
}

export type Props = {
	order: Order
	isOpen: boolean
	setIsOpen: (show: boolean) => void
	onCancelOrder?: () => void
}
