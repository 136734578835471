import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoadingArea } from '../../@types/LoadingArea'
import { StationSelector } from '../../components'
import { TopBarStatic } from '../../components/TopBar'
import { getLoadingAreaAndStationSelection } from './getLoadingAreaAndStationSelection'
import styles from './index.module.scss'

type Props = {
	loadingAreas: LoadingArea[]
}

export const Init: FC<Props> = ({ loadingAreas }) => {
	const navigate = useNavigate()

	useEffect(() => {
		const selection = getLoadingAreaAndStationSelection(loadingAreas)

		if (selection) {
			navigate(
				`/${selection.loadingAreaId}/stations/${selection.stationId}`,
				{
					replace: true
				}
			)
		}
	}, [loadingAreas, navigate])

	return (
		<div className={styles.root}>
			<TopBarStatic />
			<StationSelector loadingAreas={loadingAreas} />
		</div>
	)
}
