import { useState } from 'react'

import { Box, Divider } from '@mui/material'

import { Modal } from '../../../Modal/Modal'
import { Action } from '../index'
import { ActionType } from '../types'
import { Props } from './types'

export const ActionsCollection = ({ actions }: Props) => {
	const [showActions, setShowActions] = useState(false)

	return (
		<>
			<Box position="relative">
				<Action
					type={ActionType.ORDER_DETAILS}
					onClick={() => setShowActions(true)}
				/>

				{showActions && (
					<Box
						sx={{
							position: 'absolute',
							zIndex: 1500,
							top: '100%',
							right: 0,
							width: 250,
							backgroundColor: 'white',
							borderRadius: 2,
							overflow: 'visible'
						}}>
						{actions.map((action, index) => (
							<>
								<Action
									type={action.type}
									displayStyle="fullWidth"
									variant="outlined"
									isContextAction
									onClick={() => {
										action.onClick()
										setShowActions(false)
									}}
									isLoading={action.isLoading}
									key={`${action.type}_${index}`}
								/>
								{index === actions.length - 1 ? null : (
									<Divider />
								)}
							</>
						))}
					</Box>
				)}
			</Box>

			<Modal
				isOpen={showActions}
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				onRequestClose={() => setShowActions(false)}></Modal>
		</>
	)
}
