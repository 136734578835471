import { FC, useMemo } from 'react'

import { sumBy } from 'lodash'

import { OrderLabel } from '.'
import { Order } from '../../../@types/Order'

export const OrderLabelItemCount: FC<{ order: Order }> = ({ order }) => {
	const itemCount = useMemo(() => {
		return sumBy(order.lineitems, (lineItem) => lineItem.qty)
	}, [order.lineitems])

	return <OrderLabel icon="localMall" label={`${itemCount} items`} />
}
