import dayjs from 'dayjs'

import {
	FETCH_REPORT_ERROR,
	FETCH_REPORT_PENDING,
	FETCH_REPORT_SUCCESS
} from '../actions'

const initialState = {
	cursor: {
		current: dayjs(Date.now()).format('YYYY-MM-DD')
	},
	orders: [],
	summary: {},
	currency: '',
	isFetching: false,
	errorFetching: null
}

const report = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_REPORT_PENDING:
			return {
				...state,
				isFetching: true
			}
		case FETCH_REPORT_SUCCESS:
			return {
				...state,
				cursor: {
					current: action.date,
					...action.cursor
				},
				orders: action.orders,
				summary: action.summary,
				currency: action.currency,
				isFetching: false
			}
		case FETCH_REPORT_ERROR:
			return {
				...state,
				isFetching: false,
				errorFetching: action.errorFetching
			}
		default:
			return state
	}
}

export const getCursor = (state) => state.report.cursor
export const getOrders = (state) => state.report.orders
export const getSummary = (state) => state.report.summary
export const getCurrency = (state) => state.report.currency
export const getReportIsFetching = (state) => state.report.isFetching
export const getReportErrorFetching = (state) => state.report.errorFetching

export default report
