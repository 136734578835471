import { FC, ReactNode } from 'react'

import classNames from 'classnames'

export const Grid: FC<{ children?: ReactNode; className?: string }> = ({
	children,
	className
}) => {
	const classes = classNames(
		'grid grid-cols-[repeat(12,minmax(0,1fr))] lg:grid-cols-[repeat(16,minmax(0,1fr))] gap-[1px] bg-grey-light',
		className
	)

	return <div className={classes}>{children}</div>
}
