import { FC, useEffect } from 'react'

import { Order } from '../../@types/Order'
import { useGetRobotsQueryWithInterval } from '../../queries/robots'
import { updateRobotDistribution } from '../../reducers/robotDistribution'
import { useAppDispatch, useAppSelector } from '../../utils/storeHelpers'
import { distributeRobotsForOrders } from './distributeRobotsForOrders'
import { RobotDistributerProps } from './types'

export const RobotDistributer: FC<RobotDistributerProps> = ({
	loadingAreaId
}) => {
	const dispatch = useAppDispatch()

	const { data } = useGetRobotsQueryWithInterval({
		loadingAreaId: loadingAreaId ?? ''
	})

	const ordersById = useAppSelector(
		(state) => state.orders.byId as Record<string, Order>
	)

	useEffect(() => {
		const orders = Object.values(ordersById)
		const robots = data?.data.robots ?? []

		const robotsByOrderId = distributeRobotsForOrders({ orders, robots })

		dispatch(updateRobotDistribution(robotsByOrderId))
	}, [data?.data.robots, dispatch, ordersById])

	return null
}
