import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Alert } from '../@types/Alert'

type State = {
	alerts: Record<string, Alert>
}

const initialState: State = {
	alerts: {}
}

const alertSlice = createSlice({
	name: 'alert',
	initialState,
	reducers: {
		registerAlert(state, action: PayloadAction<Alert>) {
			state.alerts[action.payload.id] = action.payload
		},
		dismissAlert(state, action: PayloadAction<string>) {
			delete state.alerts[action.payload]
		},
		resetAlerts(state) {
			state.alerts = initialState.alerts
		}
	}
})

export const { registerAlert, dismissAlert, resetAlerts } = alertSlice.actions

export default alertSlice.reducer
