import { Dialog } from '../../../../../../../components/Dialog'
import { Props } from './types'

export const CancelOrderConfirmationDialog = ({
	onCancelOrderClick,
	order,
	onCancelClick
}: Props) => {
	return (
		<Dialog
			title={`Cancel order ${order._id}?`}
			description={[
				'Customer will be informed that you cancelled the order and will be refunded in full.',
				'This cannot be undone.'
			]}
			actions={[
				{
					title: 'Yes, cancel',
					onClick: onCancelOrderClick,
					theme: 'error'
				},
				{
					title: 'No, do nothing',
					onClick: onCancelClick
				}
			]}
		/>
	)
}
