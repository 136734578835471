import { useMutation } from '@tanstack/react-query'

import { fetchServer } from '../../api/fetchServer'
import { CodeResponse, PlaceTestOrderParams } from './types'
import { invalidateGetTestOrdersQuery } from './useGetTestOrders'

export const usePlaceTestOrder = () => {
	return useMutation({
		mutationKey: ['placeTestOrder'],
		mutationFn: (payload: PlaceTestOrderParams) => placeTestOrder(payload),
		onSuccess: () => {
			invalidateGetTestOrdersQuery()
		}
	})
}

const placeTestOrder = async ({
	serviceAssignmentId,
	loadingStationId,
	createPickupOrder
}: PlaceTestOrderParams): Promise<CodeResponse> => {
	return await fetchServer(
		`/v1/serviceassignments/${serviceAssignmentId}/place-test-order/station/${loadingStationId}`,
		'POST',
		{ createPickupOrder }
	)
}
