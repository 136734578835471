import { Divider, FormControlLabel, Radio } from '@mui/material'

import { Props } from './types'

export const ReasonFormControlLabel = ({ value, label, children }: Props) => {
	return (
		<>
			<Divider />
			<FormControlLabel
				sx={{
					m: 0,
					justifyContent: 'space-between',
					px: 3,
					py: 2
				}}
				value={value}
				labelPlacement="start"
				disableTypography={!!children}
				control={<Radio />}
				label={label ?? children ?? null}
				slotProps={{ typography: { fontSize: 18 } }}
			/>
		</>
	)
}
