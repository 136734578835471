import React from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as Checkmark } from '../../icons/Checkmark.svg'
import './Radio.scss'

function Radio({
	title,
	subtitle,
	selectedValue,
	selectedGroup,
	valueGroups,
	onChange
}) {
	return (
		<div className="radio">
			<div className="radio-content">
				<div className="radio-title">{title}</div>
				<div className="radio-subtitle">{subtitle}</div>

				{valueGroups.map((valueGroup, index) => (
					<div className="radio-group" key={index}>
						{valueGroup.title && (
							<div className="radio-header">
								{valueGroup.title}
							</div>
						)}
						{valueGroup.values.map((value) => (
							<RadioItem
								key={value.id}
								title={value.title}
								selected={
									valueGroup.id === selectedGroup &&
									value.id === selectedValue
								}
								onClick={() =>
									onChange(valueGroup.id, value.id)
								}
							/>
						))}
					</div>
				))}
			</div>
		</div>
	)
}
export function RadioItem({ title, selected, onClick, extra, hideCheckmark }) {
	return (
		<div
			className={classNames('radio-item', {
				'radio-item-active': selected
			})}
			onClick={onClick}>
			{title}
			<div style={{ flex: 1 }} />
			<div className={'item-resultcount'}>{extra}</div>
			{selected && !hideCheckmark ? (
				<Checkmark />
			) : (
				<div style={{ width: 24 }} />
			)}
		</div>
	)
}
RadioItem.propTypes = {
	title: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	extra: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	hideCheckmark: PropTypes.bool
}

Radio.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	selectedValue: PropTypes.string,
	selectedGroup: PropTypes.string,
	valueGroups: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			title: PropTypes.string,
			values: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string.isRequired,
					title: PropTypes.string.isRequired
				})
			).isRequired
		})
	).isRequired,
	onChange: PropTypes.func.isRequired
}

export default Radio
