import { useEffect } from 'react'

import { FileType } from '../../../../@types/File'
import { analytics } from '../../../../services/analytics'
import { FileListItem } from '../../../FileListItem/FileListItem'

export const HelpCenterFaq = () => {
	useEffect(() => {
		analytics.trackEvent('View FAQ & Training')
	}, [])

	return (
		<>
			<FileListItem
				title="How to Deliver with Starship"
				src={
					'https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/how_we_deliver.jpg'
				}
				fileType={FileType.Document}
			/>
			<FileListItem
				title="How Starship tools work"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_leadership_training.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="How to do a Test Print"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_printing_training.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="How to swap printer paper"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_paper_training.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="Bluetooth troubleshooting"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_bluetooth_training.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="What to do if a tablet is offline"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_offline_training.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="How to setup Merchant devices"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_setup_devices.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="RemoteCast permissions"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_remote_cast.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="How to load a robot without Runner phone"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_barcode_scan_training_doc_updated_12_2023.jpg"
				fileType={FileType.Document}
			/>
			<FileListItem
				title="How to load a robot without Runner phone (video)"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/merchant_barcode_scan_training_video.mp4"
				fileType={FileType.VIDEO}
			/>
			<FileListItem
				title="Starship Kiosk - Introduction & FAQ"
				src="https://merchant-public-training-materials.s3.eu-west-1.amazonaws.com/kiosk_intro_training_20240429.jpeg"
				fileType={FileType.Document}
			/>
		</>
	)
}
