import { FC } from 'react'

import cx from 'classnames'

import { DeliveryType, OrderStatusHR } from '../../../../../../@types/Order'
import { Action } from '../../../../../../components/OrderRevamp/Action'
import { ActionType } from '../../../../../../components/OrderRevamp/Action/types'
import { OrderCard } from '../../../../../../components/OrderRevamp/Layout/OrderCard'
import { OrderContent } from '../../../../../../components/OrderRevamp/Layout/OrderContent'
import { OrderContentLeft } from '../../../../../../components/OrderRevamp/Layout/OrderContentLeft'
import { OrderId } from '../../../../../../components/OrderRevamp/Layout/OrderId'
import { OrderSideButton } from '../../../../../../components/OrderRevamp/Layout/OrderSingleAction'
import { OrderLabel } from '../../../../../../components/OrderRevamp/OrderLabel'
import { OrderLabelName } from '../../../../../../components/OrderRevamp/OrderLabel/OrderLabelName'
import { useVenueFeatureFlags } from '../../../../../../hooks/useVenueFeatureFlags'
import { OrderSmall } from '../../../OrdersNew/OrderNew/OrderSmall'
import {
	OrderCompactBaseProps,
	OrderCompactDeliveryProps,
	OrderCompactPickupProps,
	OrderCompactProps
} from './types'

export const OrderCompact: FC<OrderCompactProps> = ({
	order,
	onView,
	onPickup,
	onCompletePicking,
	canCompletePicking,
	isCompletingPicking
}) => {
	const isPickup = order.delivery_type === DeliveryType.PICKUP

	return isPickup ? (
		<Pickup order={order} onPickup={onPickup} onView={onView} />
	) : (
		<Delivery
			onView={onView}
			order={order}
			canCompletePicking={canCompletePicking}
			isCompletingPicking={isCompletingPicking}
			onCompletePicking={onCompletePicking}
		/>
	)
}

const Pickup: FC<OrderCompactPickupProps> = ({ onPickup, onView, order }) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return (
			<BaseRevamped
				onView={onView}
				order={order}
				action={[
					<Action type={ActionType.VIEW_CONTENTS} onClick={onView} />,
					<Action
						type={ActionType.PICKED_UP_CHECK}
						variant="contained"
						displayStyle="icon"
						isLoading={order.picked_up}
						onClick={order.picked_up ? undefined : onPickup}
					/>
				]}
				label={<OrderLabelName order={order} />}
			/>
		)
	}

	return (
		<Base
			onView={onView}
			order={order}
			action={
				<Action
					type={ActionType.PICKED_UP}
					testId="ActionPickedUp"
					className="p-2"
					onClick={order.picked_up ? undefined : onPickup}
					isLoading={order.picked_up}
				/>
			}
			label={<OrderLabelName order={order} />}
		/>
	)
}

const Delivery: FC<OrderCompactDeliveryProps> = ({
	onView,
	canCompletePicking,
	order,
	isCompletingPicking,
	onCompletePicking
}) => {
	const { useRevampedUI } = useVenueFeatureFlags()
	const orderIsCompleted = order.status_hr === OrderStatusHR.READY

	if (useRevampedUI) {
		return (
			<BaseRevamped
				onView={onView}
				order={order}
				action={
					<Action type={ActionType.ORDER_DETAILS} onClick={onView} />
				}
				label={
					<OrderLabel
						icon={
							orderIsCompleted ? 'robotWaiting' : 'phoneAndroid'
						}
						label={
							orderIsCompleted ? 'Awaiting loading' : 'In Phone'
						}
					/>
				}
			/>
		)
	}

	return (
		<Base
			onView={onView}
			order={order}
			label={
				<OrderLabel
					icon={orderIsCompleted ? 'robotWaiting' : 'phoneAndroid'}
					label={orderIsCompleted ? 'Awaiting loading' : 'In Phone'}
					iconClassName={orderIsCompleted ? undefined : 'mt-[2px]'}
				/>
			}
		/>
	)
}

const Base: FC<OrderCompactBaseProps> = ({ order, onView, action, label }) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED

	const orderContentClasses = cx('!pr-5 overflow-hidden', {
		'active:bg-black/20': !isCancelled
	})

	return (
		<OrderCard
			isTranslucent={isCancelled}
			className="h-[100px] w-[240px] lg:h-auto lg:w-auto"
			isTestOrder={order.testing}>
			<OrderContent
				testId="OrderContent"
				className={orderContentClasses}
				onClick={isCancelled ? undefined : onView}>
				<OrderContentLeft className="flex flex-col justify-between">
					<OrderId
						orderId={order._id}
						kioskOrderId={order.kiosk_order_id}
					/>

					{isCancelled ? null : <div className="mt-2">{label}</div>}
				</OrderContentLeft>
			</OrderContent>

			{isCancelled ? (
				<OrderSideButton>
					<Action
						type={ActionType.CANCELLED}
						testId="ActionCancelled"
					/>
				</OrderSideButton>
			) : null}

			{!isCancelled && action ? (
				<OrderSideButton>{action}</OrderSideButton>
			) : null}
		</OrderCard>
	)
}

const BaseRevamped: FC<OrderCompactBaseProps> = ({
	order,
	onView,
	action,
	label,
	rightAction
}) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED

	return (
		<OrderCard isTranslucent={isCancelled} isTestOrder={order.testing}>
			<OrderSmall
				order={order}
				onViewClick={onView}
				action={action}
				rightAction={rightAction}
			/>
		</OrderCard>
	)
}
