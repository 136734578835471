import { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { Order } from '../../../@types/Order'
import { orderIsLate, orderShouldBeDoneInSeconds } from '../../../utils/order'
import { useAppSelector } from '../../../utils/storeHelpers'

const emptyObject = {}

const LATENESS_CHECK_TICK_MS = 5000

export const useOrderTimer = (order: Order) => {
	const [now, setNow] = useState(dayjs())

	const merchantSla = useAppSelector(
		(state) =>
			state.serviceAssignment.serviceAssignmentsById[
				order.serviceassignment_id
			]?.serviceassignment_merchant_sla ?? emptyObject
	)

	useEffect(() => {
		const interval = setInterval(() => {
			setNow(dayjs())
		}, LATENESS_CHECK_TICK_MS)

		return () => {
			clearInterval(interval)
		}
	}, [])

	const preparationTimeNeeded = orderShouldBeDoneInSeconds({
		order,
		merchantSla
	})

	const formattedPreparationTimeNeeded = `${Math.floor(
		preparationTimeNeeded / 60
	)}min`

	// TODO: handle isLate
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const isLate = orderIsLate({ order, merchantSla, now })

	return { formattedPreparationTimeNeeded, isLate }
}
