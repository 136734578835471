import { ReactNode } from 'react'

import { Box, Grid, Typography } from '@mui/material'

import { FeatureFlag } from '../../../@types/FeatureFlag'
import { Order } from '../../../@types/Order'
import { orderHasReusePassEnabled } from '../../../utils/order'
import { useAppSelector } from '../../../utils/storeHelpers'
import { useOrderTimer } from '../../OrderRevamp/OrderLabel/useOrderTimer'
import { Time } from '../../Time'
import { Props } from './types'

const getOrderTypeName = (order: Order) => {
	if (order.delivery_type === 'TO_CUSTOMER') return 'Robot delivery'
	if (order.kiosk_order_id !== null) return 'Kiosk'
	return 'Pickup'
}

export const OrderGeneralInfo = ({ order }: Props) => {
	const loadingArea = useAppSelector((state) =>
		state.loadingArea.selectedLoadingAreaId
			? state.loadingArea.loadingAreasById[
					state.loadingArea.selectedLoadingAreaId
			  ]
			: null
	)
	const featureFlags = loadingArea?.tags ?? []
	const isTaxVisible = featureFlags.includes(
		FeatureFlag.KITCHEN_SHOW_TAX_SUBTOTAL
	)

	const isPaymentMethodNameVisible = featureFlags.includes(
		FeatureFlag.KITCHEN_SHOW_PAYMENT_METHOD
	)

	const { formattedPreparationTimeNeeded } = useOrderTimer(order)

	const orderType = getOrderTypeName(order)

	const customerName =
		order.name_hr && order.name_hr !== '' ? order.name_hr : '—'

	const taxSubTotal = (order && order.tax && order.tax / 100) || 0

	const taxSubTotalFormatted = taxSubTotal.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD'
	})

	const hasReusePassEnabled = orderHasReusePassEnabled(order)

	return (
		<Box px={3} py={2}>
			<Typography color="grey.600" fontWeight={600} fontSize={18} mb={2}>
				Order details
			</Typography>

			<Grid container spacing={2} columns={16}>
				{order.kiosk_order_id === null ? (
					<OrderEditableInfoRow
						title="Order Number"
						value={`${order.order_id}`}
					/>
				) : (
					<>
						<OrderEditableInfoRow
							title="Order Number"
							value={`${order.kiosk_order_id}`}
						/>
						<OrderEditableInfoRow
							title="Order ID"
							value={`${order.order_id}`}
						/>
					</>
				)}

				<OrderEditableInfoRow
					title="Time"
					value={
						<>
							<Typography
								display="inline"
								fontWeight={500}
								fontSize={18}>
								<Time visibleAt={order.visible_at} />
								<Typography
									fontWeight={500}
									fontSize={18}
									display="inline">
									{` / ${formattedPreparationTimeNeeded}`}
								</Typography>
							</Typography>
						</>
					}
				/>

				<OrderEditableInfoRow title="Order type" value={orderType} />
				<OrderEditableInfoRow title="Customer" value={customerName} />
				{isTaxVisible ? (
					<OrderEditableInfoRow
						title="Tax"
						value={taxSubTotalFormatted}
					/>
				) : null}

				{isPaymentMethodNameVisible ? (
					<OrderEditableInfoRow
						title="Paid with"
						value={order.payment_provider_name ?? '—'}
					/>
				) : null}

				{hasReusePassEnabled ? (
					<OrderEditableInfoRow
						title="Packaging"
						value="ReusePass containers"
					/>
				) : null}
			</Grid>
		</Box>
	)
}

const OrderEditableInfoRow = ({
	value,
	title
}: {
	title: string
	value: string | ReactNode
}) => {
	return (
		<>
			<Grid item xs={6}>
				<Typography fontWeight={600} fontSize={18}>
					{title}
				</Typography>
			</Grid>
			<Grid item xs={10}>
				{typeof value === 'string' ? (
					<Typography fontWeight={500} fontSize={18}>
						{value}
					</Typography>
				) : (
					value
				)}
			</Grid>
		</>
	)
}
