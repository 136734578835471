import { LoadingArea, LoadingStation } from '../../@types/LoadingArea'

export type Props = {
	loadingStation: LoadingStation
	loadingArea: LoadingArea
	onNavigateToFAQ: () => void
	menuActive: boolean
}

export enum ActiveStep {
	BATTERY,
	PRINTER,
	MULTIPLE
}
