import { ActionLayout, ActionType } from './types'

export const actions: Record<ActionType, ActionLayout> = {
	[ActionType.ACK_CANCELLATION]: {
		color: 'purple',
		icon: 'done',
		label: 'Acknowledge cancellation'
	},
	[ActionType.CANCELLED]: {
		color: 'red',
		icon: 'close2',
		label: 'Cancelled',
		className: 'text-sm'
	},
	[ActionType.CANCEL_EDIT]: {
		color: 'red',
		icon: 'close2',
		label: 'Cancel changes'
	},
	[ActionType.CLOSE_WINDOW]: { icon: 'close2', label: 'Close window' },
	[ActionType.EDIT_CONTENTS]: { icon: 'edit', label: 'Edit contents' },
	[ActionType.MARK_READY]: {
		color: 'purple',
		icon: 'done',
		label: 'Mark as ready'
	},
	[ActionType.PICKED_UP]: { color: 'green', label: 'Picked up' },
	[ActionType.PICKED_UP_CHECK]: {
		color: 'green',
		icon: 'check',
		label: 'Mark as picked up'
	},
	[ActionType.PREPARE]: {
		color: 'purple',
		icon: 'arrowForward',
		label: 'Prepare'
	},
	[ActionType.PRINT_AGAIN]: { icon: 'print', label: 'Print again' },
	[ActionType.SAVE_EDIT]: {
		color: 'green',
		icon: 'done',
		label: 'Save changes'
	},
	[ActionType.VIEW_CONTENTS]: {
		icon: 'info',
		label: 'View contents'
	},
	[ActionType.COMPLETE_PICKING]: {
		color: 'green',
		label: 'Load without phone'
	},
	[ActionType.COMPLETE_PICKING_WITH_ICON]: {
		color: 'green',
		icon: 'done',
		label: 'Load without phone'
	},
	[ActionType.ORDER_DETAILS]: {
		icon: 'threeDots',
		label: 'Order details'
	},
	[ActionType.CANCEL_ORDER]: {
		icon: 'close2',
		color: 'red',
		label: 'Cancel order'
	},
	[ActionType.FINALIZE_FOR_SHIPPING]: {
		icon: 'send',
		color: 'purple',
		label: 'Finalize for shipping'
	},
	[ActionType.START_PREPARATION]: {
		color: 'purple',
		icon: 'arrowForward',
		label: 'Start preparation'
	},
	[ActionType.REPRINT_ORDER]: {
		icon: 'print',
		label: 'Reprint order'
	}
}
