import { useSyncExternalStore } from 'react'

const subscribe = (callback: (event: Event) => void) => {
	window.addEventListener('online', callback)
	window.addEventListener('offline', callback)

	return () => {
		window.removeEventListener('online', callback)
		window.removeEventListener('offline', callback)
	}
}

const getSnapshot = () => {
	return navigator.onLine
}

export const useIsOnline = () => {
	return useSyncExternalStore(subscribe, getSnapshot)
}
