import React from 'react'

import classnames from 'classnames'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'

import { ReactComponent as Pickup } from '../../../icons/Pickup.svg'
import { ReactComponent as SpeedingRobot } from '../../../icons/SpeedingRobot.svg'
import { formatCurrencyLocale, formatTimeDiff } from '../../../utils'
import './styles.scss'

dayjs.extend(utc)

const OrderStatus = {
	CANCELLED: 'CANCELLED',
	COMPLETED: 'COMPLETED'
}

const OrderTimeStatus = {
	NORMAL: 'NORMAL',
	WARNING: 'WARNING'
}

const DeliveryType = {
	PICKUP: 'PICKUP',
	TO_CUSTOMER: 'TO_CUSTOMER'
}

export const OrderHistoryItem = ({
	orderId,
	received,
	started,
	completed,
	type,
	loaded,
	itemCount,
	lineItems,
	paymentMethod,
	price,
	currency,
	orderStatus
}) => {
	// These 2 func are to just verify order status and paint the cell with red.
	const isOrderCancelled = (orderStatus) =>
		orderStatus === OrderStatus.CANCELLED ? true : false
	const isOrderTimeExpired = (orderTimeStatus) =>
		orderTimeStatus === OrderTimeStatus.WARNING ? true : false
	const hasItemCountAndFormat = (itemCount) =>
		`${`${itemCount.fulfilled ? itemCount.fulfilled : 0}`}/${
			itemCount.total ? itemCount.total : 0
		}`

	return (
		<div className={'order-history-list-item-container'}>
			<div
				className={
					'order-history-list-item-cell order-history-list-item-cell-medium'
				}>
				<div className={'order-history-id-button'}>{orderId}</div>
			</div>
			<div
				className={
					'order-history-list-item-cell order-history-list-item-cell-medium'
				}>
				{received === null
					? ''
					: dayjs(received).utc().local().format('HH:mm')}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell--warning': isOrderTimeExpired(
						started.style
					)
				})}>
				{started.time === null
					? ''
					: formatTimeDiff(received, started.time)}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell--warning':
						isOrderTimeExpired(completed.style) ||
						isOrderCancelled(orderStatus)
				})}>
				{isOrderCancelled(orderStatus)
					? 'Customer Cancelled'
					: completed.time === null
					? ''
					: formatTimeDiff(started.time, completed.time)}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell-sm': true,
					'order-history-list-item-cell--cancelled':
						isOrderCancelled(orderStatus)
				})}>
				{type === DeliveryType.PICKUP ? (
					<Pickup />
				) : (
					<span className={'order-history-list-item-type-robot'}>
						<SpeedingRobot />
					</span>
				)}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell-medium': true,
					'order-history-list-item-cell--warning': isOrderTimeExpired(
						loaded.style
					),
					'order-history-list-item-cell--cancelled':
						isOrderCancelled(orderStatus)
				})}>
				{loaded.time === null
					? ''
					: dayjs(loaded.time).utc().local().format('HH:mm')}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell-medium': true,
					'order-history-list-item-cell--warning': isOrderTimeExpired(
						itemCount.style || lineItems.style
					),
					'order-history-list-item-cell--cancelled':
						isOrderCancelled(orderStatus)
				})}>
				{hasItemCountAndFormat(itemCount || lineItems)}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell--cancelled':
						isOrderCancelled(orderStatus)
				})}>
				{paymentMethod}
			</div>
			<div
				className={classnames({
					'order-history-list-item-cell': true,
					'order-history-list-item-cell--cancelled':
						isOrderCancelled(orderStatus)
				})}>
				<span className={'order-history-list-item-price-text'}>
					{formatCurrencyLocale(price, currency)}
				</span>
			</div>
		</div>
	)
}

OrderHistoryItem.propTypes = {
	orderId: PropTypes.number.isRequired,
	received: PropTypes.string,
	started: PropTypes.object,
	completed: PropTypes.object,
	type: PropTypes.string,
	loaded: PropTypes.object,
	itemCount: PropTypes.object,
	lineItems: PropTypes.object,
	paymentMethod: PropTypes.string,
	price: PropTypes.number,
	currency: PropTypes.string,
	orderStatus: PropTypes.string
}
