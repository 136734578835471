import { ServiceAssignment } from '../../@types/Partner'

export const getActiveServiceAssignment = (
	serviceAssignments: Record<string, ServiceAssignment>,
	loadingStationId?: string
) => {
	return (
		Object.entries(serviceAssignments).find(([, serviceAssignmentItem]) =>
			serviceAssignmentItem.loadingarea_stations.find(
				(loadingstation) =>
					loadingstation._id === loadingStationId &&
					serviceAssignmentItem.serviceassignment_id ===
						loadingstation.serviceassignment
			)
		) ?? []
	)
}
