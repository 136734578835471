import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react'

import { Settings } from '../../@types/Settings'
import { analytics } from '../../services/analytics'
import {
	getSettingsFromStore,
	setSettingsToStore
} from '../../utils/localStorage'
import { SettingsContextState } from './types'

const SettingsContext = createContext<SettingsContextState | null>(null)

export const useSettingsContext = () => {
	const context = useContext(SettingsContext)

	if (context === null) {
		throw new Error(
			'useSettingsContext must be used within a SettingsProvider'
		)
	}
	return context
}

export const SettingsProvider = ({ children }: { children: ReactNode }) => {
	const [settings, setSettings] = useState<Settings>(getSettingsFromStore())

	useEffect(() => {
		setSettingsToStore(settings)
	}, [settings])

	const handleSetSettings = useCallback((newSettings: Settings) => {
		setSettings(newSettings)
		analytics.trackEvent('Settings Changed', { Settings: newSettings })
	}, [])

	const value = useMemo(() => {
		return {
			settings,
			setSettings: handleSetSettings
		}
	}, [handleSetSettings, settings])

	return (
		<SettingsContext.Provider value={value}>
			{children}
		</SettingsContext.Provider>
	)
}
