import { useMemo } from 'react'

import {
	shouldAllowOrderCancellation,
	shouldDisableUseReceiptBarcode,
	shouldPrintDaftCode,
	shouldUseNewPrinting,
	shouldUseNewUI
} from '../utils/venuesConfiguration'
import { useVenue } from './useVenue'

export const useVenueFeatureFlags = () => {
	const { venue } = useVenue()

	return useMemo(() => {
		if (!venue) {
			return {}
		}

		return {
			useRevampedUI: shouldUseNewUI(venue),
			allowOrderCancellation: shouldAllowOrderCancellation(venue),
			// TODO: get rid of this feature flag
			useActivePrinter: shouldUseNewPrinting(venue),
			shouldPrintBarcode: !shouldDisableUseReceiptBarcode(venue),
			shouldPrintDaft: shouldPrintDaftCode(venue)
		}
	}, [venue])
}
