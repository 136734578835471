import { ChangeEvent, useCallback } from 'react'

import { ExpandMore } from '@mui/icons-material'
import {
	Backdrop,
	Box,
	Button,
	Divider,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography
} from '@mui/material'

import { FulfillmentStatus } from '../../../../@types/LineItem'
import { LineItemFulfillmentStatusSelectorOptions, Props } from './types'

export const LineItemFulfilmentStatusSelector = ({
	value,
	onChange,
	showSelector,
	setShowSelector,
	allowLineItemStatusEdit = true
}: Props) => {
	const selectedValue = LineItemFulfillmentStatusSelectorOptions[value]

	const handleSelectorClick = useCallback(() => {
		if (!allowLineItemStatusEdit) return

		setShowSelector(true)
	}, [allowLineItemStatusEdit, setShowSelector])

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (!allowLineItemStatusEdit) return

			onChange(
				(event.target as HTMLInputElement).value as FulfillmentStatus
			)
			setShowSelector(false)
		},
		[allowLineItemStatusEdit, onChange, setShowSelector]
	)

	return (
		<>
			<Box position="relative">
				<Button
					variant="text"
					color={selectedValue.color}
					endIcon={allowLineItemStatusEdit ? <ExpandMore /> : null}
					onClick={handleSelectorClick}
					disableRipple={!allowLineItemStatusEdit}
					sx={{
						maxHeight: 50,
						minWidth: 130,
						fontWeight: 600,
						fontSize: 18
					}}>
					{selectedValue.title}
				</Button>

				{showSelector && (
					<Box
						sx={{
							// TODO: the view is hidden outside the viewport if it's in the bottom of the screen
							//  adding bottom:0 would help the issue, not sure how to trigger it optimally
							// bottom: 0,
							position: 'absolute',
							zIndex: 1500,
							right: 0,
							width: 380,
							backgroundColor: 'white',
							borderRadius: 2,
							overflow: 'visible'
						}}>
						<FormControl sx={{ width: '100%' }}>
							<RadioGroup value={value} onChange={handleChange}>
								<FormControlLabel
									sx={{
										m: 0,
										justifyContent: 'space-between',
										px: 3,
										py: 2
									}}
									value={FulfillmentStatus.CAN_FULFILL}
									labelPlacement="start"
									disableTypography
									control={<Radio />}
									label={
										<Box>
											<Typography
												fontWeight={700}
												fontSize={18}
												mb={1}
												color="success.main">
												Can fulfill
											</Typography>
											<Typography fontWeight={500}>
												The item will be delivered
												exactly as ordered by customer,
												incl. modifiers.
											</Typography>
										</Box>
									}
									slotProps={{ typography: { fontSize: 18 } }}
								/>
								<Divider />
								<FormControlLabel
									sx={{
										m: 0,
										justifyContent: 'space-between',
										px: 3,
										py: 2
									}}
									value={FulfillmentStatus.CAN_NOT_FULFILL}
									labelPlacement="start"
									disableTypography
									control={<Radio />}
									label={
										<Box>
											<Typography
												fontWeight={700}
												fontSize={18}
												mb={1}
												color="error.main">
												Cannot fulfill
											</Typography>
											<Typography fontWeight={500}>
												Nothing will be delivered to the
												customer regarding this item.
											</Typography>
											<Typography fontWeight={700}>
												Note: customer will be refunded
												for this item.
											</Typography>
										</Box>
									}
									slotProps={{ typography: { fontSize: 18 } }}
								/>
							</RadioGroup>
						</FormControl>
					</Box>
				)}
			</Box>

			<Backdrop
				open={showSelector}
				onClick={() => setShowSelector(false)}
			/>
		</>
	)
}
