import React from 'react'

import { ReactComponent as Report } from '../../../icons/Report.svg'

export const ReportViewSwitcher = () => {
	return (
		<div className={'report-view-swicther-container'}>
			<div className={'report-header-container'}>
				<Report />
				<div className={'report-header-text'}>Report</div>
			</div>
		</div>
	)
}
