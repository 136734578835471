import { FC } from 'react'

import { setMenuTour, setTakeTheTour } from '../../../utils/localStorage'
import { CustomReactJoyride } from '../CustomReactJoyride'
import { Props } from './types'

export const AppMainDashBoardGuidedTour: FC<Props> = ({ enabled }) => {
	if (!enabled) {
		return null
	}

	return (
		<CustomReactJoyride
			continuous={true}
			callback={(props) => {
				if (['reset', 'close'].includes(props.action)) {
					setMenuTour(true)
					setTakeTheTour(false)
				}
			}}
			steps={[
				{
					target: '[data-testid="icon-helpOutline"]',
					content:
						'The training materials are available anytime from here',
					disableBeacon: true
				},
				{
					target: '[data-testid="StationName"]',
					content: 'The name of the station where you work',
					disableBeacon: true
				},
				{
					target: '[data-testid="HoursAndStatus"]',
					content: 'The opening hours and current venue status',
					disableBeacon: true
				},
				{
					target: '[data-testid="RobotsStatus"]',
					content:
						'How many robots are available to load and how many robots are on the way',
					disableBeacon: true
				},
				{
					target: '[data-testid="NewOrdersColumnHeader"]',
					content: 'Newly created orders appear here',
					disableBeacon: true
				},
				{
					target: '[data-testid="InProgressColumnHeader"]',
					content: 'Orders being prepared are shown here',
					disableBeacon: true
				},
				{
					target: '[data-testid="ReadyColumnHeader"]',
					content:
						'When the order ready to load or pickup it appears here',
					disableBeacon: true
				},
				{
					target: '[data-testid="ActionToggleMenu"]',
					content: 'Tap here to toggle the menu',
					disableBeacon: true
				}
			]}
		/>
	)
}
