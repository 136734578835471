import { memo, useCallback, useMemo } from 'react'

import { OrderList } from '.'
import { useAppSelector } from '../../utils/storeHelpers'
import { OrderListSubheader } from './OrderListSubheader'
import { OrderListByPriorityProps, OrderListSectionHeader } from './types'
import {
	getOrderIsLateByOrderId,
	getRobotArrivingSoonByOrderId,
	makeOrderSectionsByPriority
} from './utils'

export const OrderListByPriority = memo<OrderListByPriorityProps>(
	({ orders, renderOrder }) => {
		const alerts = useAppSelector((state) => state.alert.alerts)

		const robotsByOrderId = useAppSelector(
			(state) => state.robotDistribution.robotsForOrders
		)

		const orderSections = useMemo(() => {
			return makeOrderSectionsByPriority({
				orders,
				robotArrivingSoonByOrderId:
					getRobotArrivingSoonByOrderId(robotsByOrderId),
				orderIsLateByOrderId: getOrderIsLateByOrderId(
					Object.values(alerts)
				)
			})
		}, [alerts, orders, robotsByOrderId])

		const renderSectionHeader = useCallback(
			(header: OrderListSectionHeader) => {
				return (
					<OrderListSubheader
						color={header.color}
						text={header.text}
					/>
				)
			},
			[]
		)

		return (
			<OrderList
				data={orderSections}
				renderOrder={renderOrder}
				renderSectionHeader={renderSectionHeader}
			/>
		)
	}
)
