import { FC, ReactNode } from 'react'

import { Box, Typography } from '@mui/material'

import classNames from 'classnames'

import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'

export const Header: FC<{
	children: ReactNode
	className?: string
	dataTestId?: string
}> = (props) => {
	const { children, ...restProps } = props

	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return (
			<HeaderRevampedView {...restProps}>{children}</HeaderRevampedView>
		)
	}

	return <HeaderView {...restProps}>{children}</HeaderView>
}

const HeaderView: FC<{
	children: ReactNode
	className?: string
	dataTestId?: string
}> = ({ children, className, dataTestId }) => {
	const classes = classNames(
		'bg-white py-4 pl-5 border-b-[1px] border-solid border-b-grey-light',
		className
	)

	return (
		<div className={classes} data-testid={dataTestId}>
			<span className="font-bold text-black-body">{children}</span>
		</div>
	)
}

const HeaderRevampedView: FC<{
	children: ReactNode
	className?: string
	dataTestId?: string
}> = ({ children, className, dataTestId }) => {
	return (
		<Box
			sx={{
				py: 1,
				pl: 3
			}}
			data-testid={dataTestId}>
			<Typography fontWeight={700} color="grey.600">
				{children}
			</Typography>
		</Box>
	)
}
