import { ReactNode, createContext, useContext, useEffect } from 'react'

import { container } from '../../container'
import { useInterval } from '../../hooks/useInterval'
import { usePrinterStatus } from '../../hooks/usePrinterStatus'
import { useSettingsContext } from '../SettingsContext'
import { PrinterStatusState } from './types'

const PRINTER_POLLING_INTERVAL = 15000

const PrinterContext = createContext<PrinterStatusState | null>({
	printerStatus: undefined
})

export const usePrinterStatusContext = () => {
	const context = useContext(PrinterContext)

	if (context === null) {
		throw new Error(
			'usePrinterStatusContext must be used within PrinterStatusProvider'
		)
	}
	return context
}

export const PrinterStatusProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const { settings } = useSettingsContext()
	const printerStatus = usePrinterStatus(settings.usePrinter)

	useEffect(() => {
		if (settings.usePrinter) container.requestPrinterStatus()
	}, [settings.usePrinter])

	useInterval(
		container.requestPrinterStatus,
		settings.usePrinter ? PRINTER_POLLING_INTERVAL : null
	)

	return (
		<PrinterContext.Provider value={printerStatus}>
			{children}
		</PrinterContext.Provider>
	)
}
