import { AlertSoundSettingType } from './AlertSoundSetting'

export type AlertLabel = {
	color?: 'red' | 'orange'
	text: string
}

export enum AlertType {
	ORDER_LATE = 'ORDER_LATE',
	ORDER_NEW_REMINDER = 'ORDER_NEW_REMINDER'
}

export type AlertBase = {
	id: string
	soundSettingType?: AlertSoundSettingType
}

export type AlertOrderBase = {
	orderId: number
	serviceAssignmentId: string
} & AlertBase

export type AlertOrderLate = {
	type: AlertType.ORDER_LATE
} & AlertOrderBase

export type AlertOrderNewReminder = {
	type: AlertType.ORDER_NEW_REMINDER
} & AlertOrderBase

export type Alert = AlertOrderLate | AlertOrderNewReminder
