import React from 'react'
import Highlighter from 'react-highlight-words'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import { OnOffSwitch } from '../OnOffSwitch'
import CollapseButton from './CollapseButton'
import InventoryModifier from './InventoryModifier'
import InventoryModifierValue from './InventoryModifierValue'

class ItemRenderer extends React.PureComponent {
	render() {
		// Access the items array using the "data" prop:
		const { index, isScrolling } = this.props
		const item = this.props.data.itemsArray[index]
		const {
			onItemStatusChanged,
			onModifierValueDisabledChanged,
			handleItemExpanded,
			handleShowAll,
			header,
			handleOnBackInStock,
			categoryHeightChanged
		} = this.props.data
		if (item.type === 'header') {
			return (
				<div
					style={{ ...this.props.style }}
					className={classnames({
						'list-item-root': true,
						scrolling: isScrolling
					})}
					key={item._id}>
					<div className={'list-item-row type-header'}>
						<div
							style={{
								height: 20,
								flex: 1,
								background: '#f2f2f2'
							}}
						/>
						<div className={'radio-header'}>
							<div>{item.assignmentName}</div>
							{!!item.itemsOutOfStock && (
								<div>
									<HeaderButton
										title={`Mark ${item.itemsOutOfStock} in stock`}
										onClick={() => {
											handleOnBackInStock(item._id)
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			)
		} else if (item.type === 'item') {
			return (
				<div
					style={{ ...this.props.style }}
					className={classnames({
						'list-item-root': true,
						scrolling: isScrolling
					})}>
					<div className={'list-item-row'}>
						<div className={'radio-item'}>
							<div style={{ flex: 1 }}>
								<Highlighter
									highlightClassName="list-item-inline-hilight"
									searchWords={[item.searchMatch]}
									autoEscape={true}
									textToHighlight={item.title}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}>
								<OnOffSwitch
									id={item._id}
									checked={item.checked}
									onChange={() => {
										onItemStatusChanged(
											!item.checked,
											item._id,
											item.service_assignment
										)
									}}
								/>
								{item.hasModifiers ? (
									<CollapseButton
										collapsed={item.collapsed}
										onClick={(collapsed) =>
											handleItemExpanded(
												collapsed,
												item._id
											)
										}
									/>
								) : (
									<div style={{ width: 30 }} />
								)}
							</div>
						</div>
					</div>
				</div>
			)
		} else if (item.type === 'modifier') {
			return (
				<div
					style={{ ...this.props.style }}
					className={classnames({
						'list-item-root': true,
						scrolling: isScrolling
					})}>
					<div className={'list-item-row'}>
						<InventoryModifier
							name={item.title}
							searchTerm={item.searchMatch}>
							{item.values.map((value) => {
								return (
									<InventoryModifierValue
										id={value._id + item.itemId}
										key={
											value._id +
											item.itemId +
											item.service_assignment
										}
										searchMatch={item.searchMatch}
										name={value.title}
										disabled={value.disabled}
										onChange={() => {
											onModifierValueDisabledChanged(
												item.service_assignment,
												item.itemId,
												item._id,
												value._id,
												!value.disabled
											)
										}}
									/>
								)
							})}
						</InventoryModifier>
					</div>
				</div>
			)
		} else if (item.type === 'page-header') {
			return (
				<div
					style={{ ...this.props.style, background: '#f2f2f2' }}
					className={'list-item-root'}>
					<div className={'list-item-row'}>
						{header({ categoryHeightChanged })}
					</div>
				</div>
			)
		} else if (item.type === 'no-results') {
			return (
				<div
					style={{ ...this.props.style, background: '#f2f2f2' }}
					className={'list-item-root'}>
					<div className={'list-item-row'}>
						<div className={'radio-item'}>No Results.</div>
					</div>
				</div>
			)
		} else if (item.type === 'hidden-modifiers') {
			return (
				<div
					style={{ ...this.props.style }}
					className={'list-item-root'}>
					<div className={'list-item-row list-item-showmore'}>
						<div
							style={{ marginRight: 12 }}
							onClick={() => {
								handleShowAll(item._id)
							}}>
							Show all modifiers ({item.hiddenModifierCount})
						</div>
					</div>
				</div>
			)
		} else if (item.type === 'item-separator') {
			return <div className="item-separator"></div>
		}
	}
}

ItemRenderer.propTypes = {
	isScrolling: PropTypes.bool,
	index: PropTypes.number,
	style: PropTypes.object,
	header: PropTypes.node,
	data: PropTypes.object
}
export default ItemRenderer

export function HeaderButton({ title, onClick }) {
	return (
		<button type="button" className={'header-button'} onClick={onClick}>
			{title}
		</button>
	)
}
HeaderButton.propTypes = {
	title: PropTypes.string.isRequired,
	onClick: PropTypes.func
}
