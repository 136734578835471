import { useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { orderBy } from 'lodash'

import { Order, StationStatus } from '../../@types/Order'
import { soundService } from '../../services/sound'

// Play sound when new order comes in
export const useNewOrderArrivalSound = (orders: Order[]) => {
	const [timestamp, setTimestamp] = useState(dayjs())

	const latestNewOrder: Order | undefined = useMemo(() => {
		const newOrders = orders.filter(
			(order) => order.station_status_hr === StationStatus.CREATED
		)

		const sortedOrders = orderBy(
			newOrders,
			[(order) => order.visible_at],
			['desc']
		)

		return sortedOrders[0]
	}, [orders])

	const latestOrderVisibleAt = latestNewOrder?.visible_at

	useEffect(() => {
		if (!latestOrderVisibleAt) {
			return
		}

		const date = dayjs(latestOrderVisibleAt)

		if (date.isAfter(timestamp)) {
			soundService.playSound()
			setTimestamp(date)
		}
	}, [latestOrderVisibleAt, timestamp])
}
