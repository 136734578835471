import { orderBy } from 'lodash'

import { Order, OrderStatusHR } from '../../@types/Order'

export const sortOrders = (orders: Order[]) => {
	return orderBy(orders, [
		(order) => (order.status_hr === OrderStatusHR.CANCELLED ? 1 : -1),
		(order) => order._id
	])
}

export const sortPreparingOrders = (orders: Order[]) => {
	return orderBy(orders, [
		({ status_hr }) => (status_hr === OrderStatusHR.CANCELLED ? -1 : 1),
		({ visible_at }) => visible_at
	])
}
