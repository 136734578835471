// Types 40 (ADDIN_NUMBER) and 60 (TOGGLE_OPTION) are currently not supported
export enum LineItemModifierType {
	VARIATION = 10,
	MASTER_VARIATION = 20,
	ADDIN_CHECKBOX = 30,
	TOGGLE = 50,
	OTHER = 'Other'
}

export type LineItemModifier = {
	default?: boolean
	modifierType: LineItemModifierType
	modifier_id: string
	qty: number
	type: string
	value: 'Yes' | 'No' | string
	value_id: string
}

// This is.. not optimal, but that's how it is used. :crazy:
export type LineItemModifierToggle = Omit<LineItemModifier, 'value'> & {
	value: LineItemModifierType.TOGGLE
}
