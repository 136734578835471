import { FC, useCallback, useMemo, useState } from 'react'

import { theme } from '../../../mui/theme'
import { Box, Divider, Typography } from '@mui/material'

import cx from 'classnames'

import { InventoryStatus } from '../../../@types/Item'
import { FulfillmentStatus } from '../../../@types/LineItem'
import { useVenueFeatureFlags } from '../../../hooks/useVenueFeatureFlags'
import { isLineItemReusePassEnabled } from '../../../utils/lineItem/isLineItemReusePassEnabled'
import { parseNoteValue } from '../../../utils/lineItem/parseNoteValue'
import { OnOffSwitch } from '../../OnOffSwitch'
import { OrderContentLeft } from '../Layout/OrderContentLeft'
import { LineItemFulfilmentStatusSelector } from './LineItemFulfilmentStatusSelector'
import { Modifier } from './Modifier'
import { LineItemProps } from './types'

export const LineItem: FC<LineItemProps> = (props) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <LineItemRevampedView {...props} />
	}
	return <LineItemView {...props} />
}

const LineItemView: FC<LineItemProps> = ({
	lineItem,
	isEditable,
	isDisabled,
	isOos,
	onChange
}) => {
	const { message, modifiersByType } = useMemo(() => {
		return parseNoteValue(lineItem.note)
	}, [lineItem.note])

	const isReusePassEnabled = useMemo(() => {
		return isLineItemReusePassEnabled(lineItem)
	}, [lineItem])

	const handleChange = useCallback(() => {
		if (!onChange) {
			return
		}

		onChange({
			itemId: lineItem.item,
			status: isOos
				? InventoryStatus.IN_STOCK
				: InventoryStatus.OUT_OF_STOCK
		})
	}, [isOos, lineItem.item, onChange])

	const nameClasses = cx('text-2xl font-bold', { 'line-through': isOos })

	return (
		<div className="mb-6 flex flex-1 flex-row last:mb-0">
			<OrderContentLeft>
				<span className="text-2xl font-bold">{lineItem.qty} x</span>
			</OrderContentLeft>

			<div className="flex flex-1 flex-col">
				<div className="flex flex-row justify-between">
					<div className="flex flex-col">
						<span className={nameClasses}>{lineItem.name}</span>
						{isReusePassEnabled ? (
							<div className="mt-1 rounded-sm bg-grey-veryLight p-1 text-xs font-bold text-green">
								<span>♻️ REUSABLE PACKAGING</span>
							</div>
						) : null}
					</div>

					{isEditable && (
						<div className="flex items-center justify-center">
							<OnOffSwitch
								id={lineItem._id.toString(10)}
								checked={!isOos}
								onChange={handleChange}
								disabled={isDisabled}
								label={<OOSLabel isOos={isOos} />}
							/>
						</div>
					)}
				</div>

				{modifiersByType ? (
					<div className="flex flex-1 flex-col">
						{Object.entries(modifiersByType).map(
							([type, modifiers]) => (
								<Modifier
									key={type}
									type={type}
									modifiers={modifiers}
								/>
							)
						)}
					</div>
				) : null}

				{message ? (
					<div
						data-testid="lineitem-message"
						className="mt-5 rounded bg-grey-veryLight px-3 py-2">
						<span className="text-grey">"{message}"</span>
					</div>
				) : null}
			</div>
		</div>
	)
}

const LineItemRevampedView: FC<LineItemProps> = ({
	lineItem,
	isOos,
	onChange,
	status,
	total,
	countIndex,
	lineItemIndex,
	onFulfillmentStatusChange,
	showLineItemStatus,
	allowLineItemStatusEdit
}) => {
	const [showFulfillmentStatusSelector, setShowFulfillmentStatusSelector] =
		useState(false)

	const { message, modifiersByType } = useMemo(() => {
		return parseNoteValue(lineItem.note)
	}, [lineItem.note])

	// TODO: check designs for how to handle
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleChange = useCallback(() => {
		if (!onChange) {
			return
		}

		onChange({
			itemId: lineItem.item,
			status: isOos
				? InventoryStatus.IN_STOCK
				: InventoryStatus.OUT_OF_STOCK
		})
	}, [isOos, lineItem.item, onChange])

	const isReusePassEnabled = useMemo(() => {
		return isLineItemReusePassEnabled(lineItem)
	}, [lineItem])

	return (
		<>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				px={3}
				py={2}>
				<Box>
					<Box display="flex" flexDirection="row" mt={1}>
						<Typography fontWeight="600" fontSize={18} mb={1}>
							{lineItem.name}
						</Typography>

						<Typography
							color="primary.main"
							fontWeight="600"
							ml={1}
							fontSize={18}>
							{countIndex ? `${countIndex}/${total}` : ''}
						</Typography>
					</Box>

					{isReusePassEnabled ? (
						<Box
							sx={{
								borderRadius: '3px',
								background: theme.palette.grey[300],
								color: theme.palette.success.main,
								display: 'flex',
								px: 1,
								py: 0.5,
								alignItems: 'center'
							}}>
							<Typography fontWeight="700" fontSize={12}>
								♻️ REUSABLE PACKAGING
							</Typography>
						</Box>
					) : null}

					<Box display="flex" flexDirection="row" ml={1}>
						{modifiersByType ? (
							<Box>
								{Object.entries(modifiersByType).map(
									([type, modifiers]) => (
										<Modifier
											key={type}
											type={type}
											modifiers={modifiers}
										/>
									)
								)}
							</Box>
						) : null}

						{message ? (
							<div
								data-testid="lineitem-message"
								className="mt-5 rounded bg-grey-veryLight px-3 py-2">
								<span className="text-grey">"{message}"</span>
							</div>
						) : null}
					</Box>
				</Box>

				{status && showLineItemStatus && (
					<LineItemFulfilmentStatusSelector
						setShowSelector={setShowFulfillmentStatusSelector}
						showSelector={showFulfillmentStatusSelector}
						value={status}
						allowLineItemStatusEdit={allowLineItemStatusEdit}
						onChange={(value: FulfillmentStatus) => {
							onFulfillmentStatusChange?.({
								lineItemId: lineItem._id,
								lineItemIndex: lineItemIndex ?? 0,
								fulfillmentStatus: value
							})
						}}
					/>
				)}
			</Box>
			<Divider />
		</>
	)
}

const OOSLabel: FC<{ isOos?: boolean }> = ({ isOos }) => {
	const classes = cx({
		'text-red': isOos,
		'text-green': !isOos
	})

	return (
		<span className={classes}>{isOos ? 'Out of stock' : 'In stock'}</span>
	)
}
