import { useCallback, useMemo } from 'react'

import { Order } from '../../../../@types/Order'
import { setOrderPickedUp } from '../../../../actions'
import { TestOrderReadyGuidedTour } from '../../../../components/GuidedTour/TestOrderReadyGuidedTour'
import { HowLoadOrderWithoutPhoneCard } from '../../../../components/HowLoadOrderWithoutPhoneCard'
import { OrderList } from '../../../../components/OrderList'
import { OrderListSubheader } from '../../../../components/OrderList/OrderListSubheader'
import { OrderListSectionHeader } from '../../../../components/OrderList/types'
import { makeOrderSectionsByDeliveryType } from '../../../../components/OrderList/utils'
import { useVenueFeatureFlags } from '../../../../hooks/useVenueFeatureFlags'
import { useAppDispatch } from '../../../../utils/storeHelpers'
import { Header } from '../Header'
import { OrderReady } from './OrderReady'
import { OrdersReadyProps } from './types'

export const OrdersReady = ({ orders, onPrint }: OrdersReadyProps) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	const dispatch = useAppDispatch()

	const handlePickedOrderUp = useCallback(
		(order: Order) => {
			dispatch(
				setOrderPickedUp(
					order.loadingareaId,
					order.loadingstationId,
					order._id,
					order.order_id
				)
			)
		},
		[dispatch]
	)

	const renderOrder = useCallback(
		(order: Order) => {
			return (
				<OrderReady
					order={order}
					onPickup={handlePickedOrderUp}
					onPrint={onPrint}
				/>
			)
		},
		[handlePickedOrderUp, onPrint]
	)

	const orderSections = useMemo(() => {
		return makeOrderSectionsByDeliveryType(orders)
	}, [orders])

	const renderSectionHeader = useCallback(
		(header: OrderListSectionHeader) => {
			// A bit of a hack as we have two sections
			// - pickup
			// - robot loading
			// but we don't know which one we are rendering,
			// so, let's check make a dumb check here
			const shouldRenderHowToScanWithRobot =
				header.text === 'For robot loading'

			return (
				<div className="relative">
					<OrderListSubheader icon={header.icon} text={header.text} />
					{shouldRenderHowToScanWithRobot && (
						<HowLoadOrderWithoutPhoneCard
							// hackerman
							className="absolute top-[-6px] right-10 lg:mb-6 lg:relative lg:top0 lg:right-0"
							iconOnlyWhenSmallScreen
						/>
					)}
				</div>
			)
		},
		[]
	)

	return (
		<>
			<TestOrderReadyGuidedTour orders={orders} />
			<Header dataTestId="ReadyColumnHeader">
				{useRevampedUI
					? `Orders ready (${orders.length})`
					: `${orders.length} ready`}
			</Header>
			<OrderList
				data={orderSections}
				renderOrder={renderOrder}
				renderSectionHeader={renderSectionHeader}
				horizontalWhenSmall
			/>
		</>
	)
}
