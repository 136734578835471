import _keyBy from 'lodash/keyBy'
import _merge from 'lodash/merge'
import { combineReducers } from 'redux'

import { RECEIVED_ORDERS, RECEIVED_ORDER_UPDATE } from '../actions'

function ids(state = [], action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return action.lineitems.map((lineitem) => lineitem._id)

		default:
			return state
	}
}

function byId(state = {}, action) {
	switch (action.type) {
		case RECEIVED_ORDERS:
			return _keyBy(action.lineitems, '_id')

		case RECEIVED_ORDER_UPDATE:
			return _merge({}, state, _keyBy(action.lineitems, '_id'))

		default:
			return state
	}
}

export const getLineItem = (state, id) => state.byId[id]

export const getOrderLineItems = (state, order) =>
	state.ids
		.map((id) => getLineItem(state, id))
		.filter((lineitem) => lineitem.order === order)
		.sort((a, b) => {
			// sort order lineItems alphabetically by name and secondarily by lineitem id

			// compare lineitem names
			const compareName = a.name.localeCompare(b.name)

			// compare lineitem _id's too to make sure multiple lineitems with the same name are always in consistent order
			let compareId = 0

			if (a._id > b._id) compareId = 1
			if (b._id < a._id) compareId = -1

			return compareName || compareId
		})

export default combineReducers({
	byId,
	ids
})
