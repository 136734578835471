import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { theme } from './mui/theme'
import { ThemeProvider } from '@mui/material'

import { QueryClientProvider } from '@tanstack/react-query'
import _flatMap from 'lodash/flatMap'
import _keyBy from 'lodash/keyBy'
import _uniqBy from 'lodash/uniqBy'
import { SnackbarProvider } from 'notistack'

import { fetchServer } from './api/fetchServer'
import './assets/styles/index.scss'
import { App } from './components/App'
import DeviceRegistration from './components/DeviceRegistration'
import { ErrorBoundary } from './components/ErrorBoundary'
import { PrinterStatusProvider } from './components/PrinterStatusContext'
import { SettingsProvider } from './components/SettingsContext'
import './dayjsExtensions'
import { Init } from './pages/Init'
import './polyfills'
import { queryClient } from './queryClient'
import { analytics } from './services/analytics'
import { initSentry } from './services/sentry'
import { store } from './store'
import { getTagsFromPartners, setCurrentLoadingareaTags } from './utils'

initSentry()

const container = document.getElementById('root')
const root = createRoot(container)

analytics.initialize(
	process.env.REACT_APP_AMPLITUDE_API_KEY ?? window.AMPLITUDE_API_KEY
)

Promise.all([fetchServer('/v1/user'), fetchServer('/v1/loadingareas')])
	.then(([{ user }, { partners }]) => {
		if (window.Sentry) {
			window.Sentry.configureScope(function (scope) {
				scope.setUser({ id: user._id, user: user.name })
			})
		}

		analytics.setUserProperty(
			'App Version',
			process.env.REACT_APP_BUILD_NUMBER
		)

		const tags = JSON.stringify(getTagsFromPartners(partners))
		setCurrentLoadingareaTags(tags)

		const loadingAreas = _uniqBy(
			_flatMap(partners, (partner) => partner.serviceassignments),
			'loadingarea_id'
		)
			.filter(
				(serviceassignment) =>
					serviceassignment.loadingarea_stations.length > 0
			)
			.map((serviceassignment) => ({
				_id: serviceassignment.loadingarea_id,
				name: serviceassignment.loadingarea_name,
				tags: serviceassignment.loadingarea_tags,
				stations: serviceassignment.loadingarea_stations ?? []
			}))

		const serviceassignments = _keyBy(
			_flatMap(
				partners,
				(partner) => partner.serviceassignments,
				'serviceassignment_id'
			),
			'serviceassignment_id'
		)

		root.render(
			// Note that we are using tailwindcss css reset. We cannot use MUI reset as it
			// makes everything outside of MUI components look weird.
			<ThemeProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<SettingsProvider>
							<PrinterStatusProvider>
								<DeviceRegistration authenticated={true} />
								<ErrorBoundary>
									<SnackbarProvider />
									<BrowserRouter>
										<Routes>
											<Route
												path="/"
												index
												element={
													<Init
														loadingAreas={
															loadingAreas
														}
													/>
												}
											/>
											<Route
												path="/:loadingareaId/stations/:loadingstationId"
												element={
													<App
														user={user}
														loadingareas={
															loadingAreas
														}
														serviceassignments={
															serviceassignments
														}
													/>
												}
											/>
										</Routes>
									</BrowserRouter>
								</ErrorBoundary>
							</PrinterStatusProvider>
						</SettingsProvider>
					</Provider>
				</QueryClientProvider>
			</ThemeProvider>
		)
	})
	.catch(async (err) => {
		let errorBody
		try {
			errorBody = await err.response.json()
		} catch (e) {
			console.log('no errorBody', e)
		}

		root.render(
			<DeviceRegistration authenticated={false} error={errorBody} />
		)
		throw err
	})
