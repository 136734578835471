import { useMemo } from 'react'

import {
	getMenuTour,
	getShowWelcomePage,
	getTakeTheTour
} from '../localStorage'
import { shouldDisableGuidedTour } from '../venuesConfiguration'
import { VenueConfiguration } from '../venuesConfiguration/types'

export const useGuidedTour = (
	venueConfiguration: VenueConfiguration | null,
	serviceAssignmentId: string
) => {
	const showGuidedTour = useMemo(
		() =>
			(venueConfiguration &&
				!shouldDisableGuidedTour(venueConfiguration)) ||
			false,
		[venueConfiguration]
	)

	return {
		showWelcomePage: showGuidedTour && getShowWelcomePage(),
		showMainDashBoardTour: showGuidedTour && getTakeTheTour(),
		showMenuTour: showGuidedTour && getMenuTour()
	}
}
