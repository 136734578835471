import update from 'immutability-helper'
import _countBy from 'lodash/countBy'
import _keyBy from 'lodash/keyBy'
import { combineReducers } from 'redux'

import {
	OPTIMISTIC_ITEM_UPDATE,
	OPTIMISTIC_MODIFIER_VALUE_UPDATE,
	RECEIVED_ITEMS
} from '../actions'

function ids(state = [], action) {
	switch (action.type) {
		case RECEIVED_ITEMS:
			return action.items.map((item) => item._id)
		default:
			return state
	}
}

function byId(state = {}, action) {
	switch (action.type) {
		case RECEIVED_ITEMS:
			return _keyBy(action.items, '_id')
		case OPTIMISTIC_ITEM_UPDATE:
			return Object.assign({}, state, {
				[action.itemId]: {
					...state[action.itemId],
					status: action.status,
					modifiers: action.updateModifiers
						? setModifierValuesToEnabled(
								state[action.itemId].modifiers
						  )
						: state[action.itemId].modifiers
				}
			})
		case OPTIMISTIC_MODIFIER_VALUE_UPDATE:
			return update(state, {
				[action.itemId]: {
					modifiers: {
						$apply: (modifiers) =>
							modifiers.map((modifier) => {
								if (modifier._id === action.modifierId) {
									modifier.values = modifier.values.map(
										(value) => {
											if (
												value._id ===
												action.modifierValueId
											) {
												return {
													...value,
													disabled: action.disabled
												}
											}
											return value
										}
									)
								}
								return modifier
							})
					}
				}
			})
		default:
			return state
	}
}
function setModifierValuesToEnabled(modifiers) {
	return modifiers.map((modifier) => ({
		...modifier,
		values: modifier.values.map((value) => ({ ...value, disabled: false }))
	}))
}
function categories(state = {}, action) {
	switch (action.type) {
		case RECEIVED_ITEMS:
			return _countBy(action.items, 'location_at_merchant')
		default:
			return state
	}
}
export const getItem = (state, id) => state.byId[id]
export default combineReducers({
	byId,
	ids,
	categories
})
