import React from 'react'
import Highlighter from 'react-highlight-words'

import PropTypes from 'prop-types'

function InventoryModifier({ children, name, searchTerm }) {
	return (
		<div className={'inventory-modifier'}>
			<div className={'inventory-modifier-label'}>
				<Highlighter
					highlightClassName="list-item-inline-hilight"
					searchWords={[searchTerm]}
					autoEscape={true}
					textToHighlight={name}
				/>
			</div>
			<div className={'inventory-modifier-children'}>{children}</div>
		</div>
	)
}
InventoryModifier.propTypes = {
	children: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	searchTerm: PropTypes.string
}

export default InventoryModifier
