import { memo, useCallback } from 'react'

import { InventoryStatus } from '../../@types/Item'
import { FulfillmentStatus, LineItemFulfilment } from '../../@types/LineItem'
import { Order, StationStatus } from '../../@types/Order'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { setLineItemFulfilment } from '../../reducers/lineItemFulfillment'
import { getOrderFulfilment } from '../../selectors'
import { store } from '../../store'
import { useAppDispatch, useAppSelector } from '../../utils/storeHelpers'
import { OrderContent } from './Layout/OrderContent'
import { LineItem } from './LineItem'
import {
	EditableOrderLineItemsProps,
	InnerOrderLineItemsProps,
	OrderLineItemsProps
} from './types'
import { useItemStatuses } from './useItemStatuses'
import { useLineItemsData } from './useLineItemsData'

export const OrderLineItems = memo<OrderLineItemsProps>(
	({ order, onItemFulfil, showOOSFlow }) => {
		const itemStatuses = useItemStatuses({ order })

		return (
			<InnerLineItems
				itemStatuses={itemStatuses}
				order={order}
				onItemFulfil={onItemFulfil}
				showOOSFlow={showOOSFlow}
			/>
		)
	}
)

export const EditableOrderLineItems = memo<EditableOrderLineItemsProps>(
	({ order, itemStatuses, disabled, updateItemStatus }) => {
		return (
			<InnerLineItems
				itemStatuses={itemStatuses}
				order={order}
				disabled={disabled}
				editable
				updateItemStatus={updateItemStatus}
			/>
		)
	}
)

const InnerLineItems = memo<InnerOrderLineItemsProps>(
	({
		order,
		editable,
		itemStatuses,
		disabled,
		updateItemStatus,
		showOOSFlow,
		onItemFulfil
	}) => {
		const { useRevampedUI } = useVenueFeatureFlags()
		const dispatch = useAppDispatch()

		const lineItemFulfillment = useAppSelector(
			(state) => state.lineItemFulfillment
		)

		const { lineItemsWithIndexAndStatus } = useLineItemsData({
			order,
			lineItemFulfillment
		})

		const handleFulfillmentStatusChange = ({
			lineItemId,
			lineItemIndex,
			fulfillmentStatus
		}: {
			lineItemId: number
			fulfillmentStatus: FulfillmentStatus
			lineItemIndex: number
		}) => {
			dispatch(
				setLineItemFulfilment({
					orderId: order.order_id,
					lineItemId,
					fulfillmentStatus,
					index: lineItemIndex
				})
			)

			const orderLineItemFulfilment = getOrderFulfilment(order.order_id)(
				store.getState()
			)

			onItemFulfil?.(order.order_id, orderLineItemFulfilment)

			if (fulfillmentStatus === FulfillmentStatus.CAN_FULFILL) return

			const item = order.lineitems.find(
				(lineItem) => lineItem._id === lineItemId
			)
			if (!item) return

			const { item: itemId, name: itemName } = item

			const needToShowOOSFlow = shouldShowOOSFlow(
				order,
				orderLineItemFulfilment,
				itemId
			)
			if (!needToShowOOSFlow) return

			showOOSFlow?.(itemId, itemName)
		}

		const shouldShowOOSFlow = useCallback(
			(
				order: Order,
				orderLineItemFulfilment: LineItemFulfilment[],
				itemId: string
			) => {
				const lineItemsWithItem = Object.fromEntries(
					order.lineitems
						.filter((lineItem) => lineItem.item === itemId)
						.map((lineItem) => [lineItem._id, lineItem])
				)

				// we need to show the OOS flow only if there's a single line item (containing the item) missing fulfilment by 1
				// in other words, if that is the first item of that kind that is being marked as unfulfilled
				return (
					orderLineItemFulfilment
						.filter(
							(lineItemFulfilment) =>
								!!lineItemsWithItem[
									lineItemFulfilment.lineItemId
								]
						)
						.filter(
							(lineItemFulfilment) =>
								lineItemsWithItem[lineItemFulfilment.lineItemId]
									.qty -
									lineItemFulfilment.qty ===
								1
						).length === 1
				)
			},
			[]
		)

		if (useRevampedUI) {
			return (
				<>
					{lineItemsWithIndexAndStatus.map(
						({
							lineItem,
							meta: { lineItemIndex, countIndex, total, status }
						}) => (
							<LineItem
								lineItemIndex={lineItemIndex}
								countIndex={countIndex}
								total={total}
								status={status}
								key={lineItem._id}
								lineItem={lineItem}
								isOos={
									itemStatuses[lineItem.item] ===
									InventoryStatus.OUT_OF_STOCK
								}
								isEditable={editable}
								isDisabled={disabled}
								onChange={updateItemStatus}
								onFulfillmentStatusChange={
									handleFulfillmentStatusChange
								}
								showLineItemStatus={
									order.station_status_hr !==
									StationStatus.CREATED
								}
								allowLineItemStatusEdit={
									order.station_status_hr ===
									StationStatus.PREPARING
								}
							/>
						)
					)}
				</>
			)
		}

		return (
			<OrderContent className="mb-2 flex flex-1 flex-col !pr-4">
				{order.lineitems.map((lineItem) => (
					<LineItem
						key={lineItem._id}
						lineItem={lineItem}
						isOos={
							itemStatuses[lineItem.item] ===
							InventoryStatus.OUT_OF_STOCK
						}
						isEditable={editable}
						isDisabled={disabled}
						onChange={updateItemStatus}
					/>
				))}
			</OrderContent>
		)
	}
)
