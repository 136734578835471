import React from 'react'
import Highlighter from 'react-highlight-words'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import { OnOffSwitch } from '../OnOffSwitch'

function InventoryModifierValue({
	id,
	name,
	onChange = () => {},
	disabled,
	searchMatch
}) {
	const searchMatched =
		searchMatch && name.toUpperCase().includes(searchMatch.toUpperCase())
	return (
		<div
			className={classnames({
				'inventory-modifier-value': true,
				'inventory-modifier-value-hilight': searchMatched
			})}>
			<div
				className={'inventory-modifier-value-label'}
				style={{ flex: 1 }}>
				<Highlighter
					highlightClassName="list-item-inline-hilight"
					searchWords={[searchMatch]}
					autoEscape={true}
					textToHighlight={name}
				/>
			</div>
			<OnOffSwitch id={id} onChange={onChange} checked={!disabled} />
			<div style={{ width: 30 }} />
		</div>
	)
}
InventoryModifierValue.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	searchMatch: PropTypes.string
}

export default InventoryModifierValue
