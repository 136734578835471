import { FC } from 'react'
import ReactJoyride, { Props } from 'react-joyride'

const CORNFLOWER_RGB = 'rgb(46 43 182)'
const LIGHT_GREY = '#c9c9c9'

export const CustomReactJoyride: FC<Props> = (props) => {
	return (
		<ReactJoyride
			styles={{
				options: {
					arrowColor: CORNFLOWER_RGB,
					backgroundColor: CORNFLOWER_RGB,
					textColor: 'white'
				},
				buttonNext: {
					backgroundColor: CORNFLOWER_RGB,
					borderRadius: '0.5rem',
					borderColor: 'white',
					marginLeft: '1rem'
				},
				buttonBack: {
					color: LIGHT_GREY,
					marginRight: 0
				},
				tooltipContent: {
					textAlign: 'left'
				},
				beaconInner: {
					backgroundColor: CORNFLOWER_RGB
				},
				beaconOuter: {
					borderColor: CORNFLOWER_RGB,
					backgroundColor: 'rgba(46, 43, 182, 0.2)'
				}
			}}
			{...props}
		/>
	)
}
