import cx from 'classnames'
import { motion } from 'framer-motion'

import { useAppSelector } from '../../../utils/storeHelpers'
import { useIsOnline } from '../../../utils/useIsOnline'
import { Icon } from '../../Icon'

export const StarshipLogo = () => {
	const appTimestamp = useAppSelector((state) => state.app.timestamp)
	const isOnline = useIsOnline()

	return (
		<div className="flex flex-row items-center">
			<Icon className="mr-2" icon="starshipLogo" />

			<motion.div
				key={appTimestamp}
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				data-testid="StarshipLogo-Bubble"
				className={cx('h-2 w-2 rounded-full', {
					'bg-green': isOnline,
					'bg-red': !isOnline
				})}
			/>
		</div>
	)
}
