import {
	setMenuTour,
	setShowWelcomePage,
	setTakeTheTour
} from '../../../utils/localStorage'

export const initialiseWelcomePage = () => {
	setShowWelcomePage(true)
	setTakeTheTour(false)
	setMenuTour(false)
}
