import { useMutation } from '@tanstack/react-query'

import { fetchServer } from '../../api/fetchServer'
import { CancelOrderParams, CodeResponse } from './types'
import { invalidateGetTestOrdersQuery } from './useGetTestOrders'

export const useCancelTestOrder = () => {
	return useMutation({
		mutationKey: ['cancelTestOrder'],
		mutationFn: (payload: CancelOrderParams) => cancelOrder(payload),
		onSuccess: () => {
			invalidateGetTestOrdersQuery()
		}
	})
}

const cancelOrder = async ({
	loadingAreaId,
	orderId
}: CancelOrderParams): Promise<CodeResponse> => {
	return await fetchServer(
		`/v1/loadingareas/${loadingAreaId}/orders/${orderId}/cancel`,
		'POST',
		{}
	)
}
