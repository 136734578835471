import { FC, useCallback } from 'react'

import { Order, StationStatus } from '../../../../@types/Order'
import { TestOrderInProgressGuidedTour } from '../../../../components/GuidedTour/TestOrderInProgressGuidedTour'
import { OrderListByPriority } from '../../../../components/OrderList/OrderListByPriority'
import { useVenueFeatureFlags } from '../../../../hooks/useVenueFeatureFlags'
import { Header } from '../Header'
import { OrderInProgress } from './OrderInProgress'
import { OrdersInProgressProps } from './types'

export const OrdersInProgress: FC<OrdersInProgressProps> = ({
	orders,
	onPrint,
	onStatusChange,
	isFulfilling,
	onItemFulfil,
	isFulfillingError
}) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	const handleMarkReady = useCallback(
		(order: Order) => {
			onStatusChange(order, StationStatus.COMPLETED)
		},
		[onStatusChange]
	)

	const renderOrder = useCallback(
		(order: Order) => {
			return (
				<OrderInProgress
					order={order}
					onMarkReady={handleMarkReady}
					onPrint={onPrint}
					onItemFulfil={onItemFulfil}
					isFulfilling={isFulfilling}
					isFulfillingError={isFulfillingError}
				/>
			)
		},
		[
			handleMarkReady,
			isFulfilling,
			isFulfillingError,
			onItemFulfil,
			onPrint
		]
	)

	return (
		<>
			<TestOrderInProgressGuidedTour orders={orders} />
			<Header dataTestId="InProgressColumnHeader">
				{useRevampedUI
					? `In progress (${orders.length})`
					: `${orders.length} in progress`}
			</Header>

			<OrderListByPriority renderOrder={renderOrder} orders={orders} />
		</>
	)
}
