import { FC, useMemo } from 'react'

import { Box, Typography } from '@mui/material'

import { sumBy } from 'lodash'

import { FeatureFlag } from '../../@types/FeatureFlag'
import { OrderStatusHR } from '../../@types/Order'
import { useVenueFeatureFlags } from '../../hooks/useVenueFeatureFlags'
import { useAppSelector } from '../../utils/storeHelpers'
import { Icon } from '../Icon'
import { OrderContent } from './Layout/OrderContent'
import { OrderContentLeft } from './Layout/OrderContentLeft'
import { OrderId } from './Layout/OrderId'
import { OrderLabel } from './OrderLabel'
import { OrderLabelDeliveryType } from './OrderLabel/OrderLabelDeliveryType'
import { OrderLabelItemCount } from './OrderLabel/OrderLabelItemCount'
import { OrderLabelName } from './OrderLabel/OrderLabelName'
import { OrderLabelPaymentMethod } from './OrderLabel/OrderLabelPaymentMethod'
import { OrderLabelReusePass } from './OrderLabel/OrderLabelReusePass'
import { OrderLabelTax } from './OrderLabel/OrderLabelTax'
import { OrderLabelTimer } from './OrderLabel/OrderLabelTimer'
import { OrderHeaderProps } from './types'

export const OrderHeader: FC<OrderHeaderProps> = (props) => {
	const loadingArea = useAppSelector((state) =>
		state.loadingArea.selectedLoadingAreaId
			? state.loadingArea.loadingAreasById[
					state.loadingArea.selectedLoadingAreaId
			  ]
			: null
	)

	const featureFlags = loadingArea?.tags ?? []

	return <OrderHeaderContent featureFlags={featureFlags} {...props} />
}

const OrderHeaderContent: FC<OrderHeaderProps & { featureFlags: string[] }> = (
	props
) => {
	const { useRevampedUI } = useVenueFeatureFlags()

	if (useRevampedUI) {
		return <OrderHeaderContentRevampedView {...props} />
	}

	return <OrderHeaderContentView {...props} />
}

const OrderHeaderContentView: FC<
	OrderHeaderProps & { featureFlags: string[] }
> = ({ order, featureFlags, hideTimer, renderAlert }) => {
	const isTaxVisible = featureFlags.includes(
		FeatureFlag.KITCHEN_SHOW_TAX_SUBTOTAL
	)

	const isPaymentMethodNameVisible = featureFlags.includes(
		FeatureFlag.KITCHEN_SHOW_PAYMENT_METHOD
	)

	return (
		<OrderContent className="border-b border-solid border-b-grey-light">
			<OrderContentLeft>
				<OrderId
					orderId={order._id}
					kioskOrderId={order.kiosk_order_id}
				/>
			</OrderContentLeft>

			<div className="pt-[2px]">
				{renderAlert}

				<div className="grid w-full grid-cols-[repeat(2,minmax(10rem,1fr))] gap-x-8">
					<div>
						<OrderLabelDeliveryType order={order} />
						<OrderLabelItemCount order={order} />
						{hideTimer ? null : <OrderLabelTimer order={order} />}
					</div>

					<div>
						<OrderLabelName order={order} />
						{isPaymentMethodNameVisible ? (
							<OrderLabelPaymentMethod order={order} />
						) : null}
						{isTaxVisible ? <OrderLabelTax order={order} /> : null}
						{order.kiosk_order_id ? (
							<OrderLabel label={`Order ID: ${order._id}`} />
						) : null}
						<OrderLabelReusePass order={order} />
					</div>
				</div>
			</div>
		</OrderContent>
	)
}

const OrderHeaderContentRevampedView: FC<
	OrderHeaderProps & { featureFlags: string[] }
> = ({ order, featureFlags, hideTimer, renderAlert }) => {
	const isCancelled = order.status_hr === OrderStatusHR.CANCELLED
	const itemCount = useMemo(() => {
		return sumBy(order.lineitems, (lineItem) => lineItem.qty)
	}, [order.lineitems])

	return (
		<Box pl={3}>
			<Box display="flex" flexDirection="row">
				<Typography variant="body2" fontWeight="bold">
					{order.order_id}
				</Typography>

				{renderAlert}

				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					ml={1}>
					<Icon icon="burger" width={18} height={18} />
					<Typography
						variant="body2"
						color="secondary"
						ml={0.25}
						fontWeight="bold">
						{itemCount}
					</Typography>
				</Box>
			</Box>

			<Box display="flex" flexDirection="row">
				{isCancelled ? null : <OrderLabelTimer order={order} />}
			</Box>
		</Box>
	)
}
