import React, { FC, useCallback, useEffect, useMemo } from 'react'

import { Button } from '../../Button'
import { Icon } from '../../Icon'
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator'
import { useCancelTestOrder } from '../useCancelTestOrder'
import { useGetTestOrders } from '../useGetTestOrders'
import { Props } from './types'

export const ManageTestOrder: FC<Props> = ({
	closeModal,
	serviceAssignmentId,
	loadingStationId,
	loadingAreaId
}) => {
	const { data, isLoading } = useGetTestOrders(
		serviceAssignmentId,
		loadingStationId
	)
	const {
		mutate,
		isLoading: isCancelingOrder,
		isSuccess
	} = useCancelTestOrder()

	const order = useMemo(() => data.orders?.[0], [data.orders])
	const onCancel = useCallback(() => {
		if (order) {
			mutate({
				loadingAreaId,
				orderId: order.id
			})
		}
	}, [mutate, loadingAreaId, order])

	useEffect(() => {
		if (isSuccess) {
			closeModal()
		}
	}, [closeModal, isSuccess])

	return (
		<div
			className={
				isCancelingOrder ? 'pointer-events-none opacity-50' : ''
			}>
			<div className="px-8 py-6">
				<div className="mb-2 flex flex-wrap justify-between items-center">
					<div className="text-xl font-bold">
						Test order <span className="text-red">(ongoing)</span>
					</div>
					<div onClick={closeModal} className="cursor-pointer">
						<Icon icon="close" />
					</div>
				</div>
			</div>
			{isLoading ? (
				<LoadingIndicator />
			) : (
				<>
					<hr className="m-0 h-px text-grey-light" />
					<div className="px-8 py-6">
						<div className="mb-2 flex flex-wrap justify-between">
							<div className="font-bold">Order details</div>
						</div>
						{order ? (
							<div className="grid grid-cols-1 gap-y-2">
								<div className="flex justify-between">
									<div>Order Nr</div>
									<div className="text-grey-mid">
										{order.id}
									</div>
								</div>
								<div className="flex justify-between">
									<div>Order type</div>
									<div className="text-grey-mid">
										{order.delivery_type === 6
											? 'Customer pickup'
											: 'Robot delivery'}
									</div>
								</div>
								<div className="flex justify-between">
									<div>Status</div>
									<div className="text-grey-mid">
										{order.status_hr}
									</div>
								</div>
							</div>
						) : (
							<div>
								No Test Orders found, please refresh the page
							</div>
						)}
					</div>
					{order && (
						<>
							<hr className="m-0 h-px text-grey-light" />
							<div className="px-8 py-6">
								<div className="mb-2 flex flex-wrap justify-between">
									<div className="font-bold">Contents</div>
								</div>
								<div className="grid grid-cols-1 gap-y-2">
									{order.items.map((item) => (
										<div className="flex justify-between">
											<div>{item.name}</div>
											<div className="text-grey-mid">
												{item.fulfilled_qty}/{item.qty}{' '}
												fulfilled
											</div>
										</div>
									))}
								</div>
							</div>
						</>
					)}
					<hr className="m-0 h-px text-grey-light" />
					<div className="px-6 py-6">
						<div className="w-full">
							<Button
								type="warning"
								caption="Cancel order"
								onClick={onCancel}
								isLoading={isCancelingOrder}
							/>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
