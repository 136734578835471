import {
	setMenuTour,
	setShowWelcomePage,
	setTakeTheTour
} from '../../../utils/localStorage'

export const initialiseTakeTheTour = () => {
	setShowWelcomePage(false)
	setTakeTheTour(true)
	setMenuTour(false)
}
