import React from 'react'
import { connect } from 'react-redux'

import _countBy from 'lodash/countBy'
import _sortBy from 'lodash/sortBy'
import _uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'

import {
	fetchItems,
	setAllToInStock,
	setItemStatus,
	setModifierValueDisabled
} from '../../actions'
import { ReactComponent as SimpleArrow } from '../../icons/SimpleArrow.svg'
import { ReactComponent as UpArrow } from '../../icons/UpArrow.svg'
import CollapsibleRadio from '../CollapsibleRadio/CollapsibleRadio'
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator'
import './Inventory.scss'
import InventoryList from './InventoryList'
import InventorySearch from './InventorySearch'

class Inventory extends React.Component {
	listRef = React.createRef()
	state = {
		groups: [],
		modifiers: [],
		selectedLocationAtMerchant: '',
		oosOnly: false,
		searchValue: '',
		matchingModifiersOnly: false,
		stickyAssignment: null,
		stickyItem: null,
		stickyModifier: null,
		listUpdate: 0
	}
	handleOnItemStatusChanged = (checked, itemId, assignmentId) => {
		this.props.setItemStatus(checked ? 0 : 5, itemId, assignmentId)
		this.incrementListUpdate()
	}
	handleLocationChanged = (locationAtMerchant) => {
		this.setState({ selectedLocationAtMerchant: locationAtMerchant })
		this.incrementListUpdate()
	}
	handleSearchChange = (value) => {
		this.setState({ searchValue: value, matchingModifiersOnly: !!value })
		this.incrementListUpdate()
	}
	handleMatchingModifiersFilterChange = (event) => {
		if (event && event.target) {
			this.setState({ matchingModifiersOnly: event.target.checked })
			this.incrementListUpdate()
		}
	}
	handleOosFilterChange = (event) => {
		if (event && event.target) {
			this.setState({ oosOnly: event.target.checked })
			this.incrementListUpdate()
		}
	}
	handleModifierValueDisabledChanged = (
		serviceAssignment,
		itemId,
		modifierId,
		modifierValueId,
		disabled
	) => {
		this.props.setModifierValueDisabled(
			serviceAssignment,
			itemId,
			modifierId,
			modifierValueId,
			disabled
		)
		this.incrementListUpdate()
	}
	handleOnBackInStock = (itemIds, assignmentId) => {
		this.props.setAllToInStock(itemIds, assignmentId)
		this.incrementListUpdate()
	}
	incrementListUpdate = () => {
		this.setState({ listUpdate: this.state.listUpdate + 1 })
	}

	render() {
		const {
			searchValue,
			selectedLocationAtMerchant,
			oosOnly,
			matchingModifiersOnly,
			stickyItem,
			stickyAssignment,
			stickyModifier,
			listUpdate
		} = this.state

		const { items: allItems = [] } = this.props
		// modifier items
		let items = allItems
		if (searchValue.trim()) {
			items = items
				.filter((item) => {
					return item.modifiers.find((modifier) => {
						// modifier values match
						return (
							modifier.values.find((value) => {
								if (oosOnly) {
									return (
										value.title
											.toUpperCase()
											.includes(
												searchValue.trim().toUpperCase()
											) && value.disabled
									)
								}
								return value.title
									.toUpperCase()
									.includes(searchValue.trim().toUpperCase())
							}) || // do not show matches for modifier name if oosOnly is enabled as modifiers can't be out of stock, only their values can
							(!oosOnly &&
								item.modifiers.find((modifier) =>
									modifier.title
										.toUpperCase()
										.includes(
											searchValue.trim().toUpperCase()
										)
								))
						)
					})
				})
				.map((item) => ({ ...item, expandedByDefault: true }))
		}

		// regular text search
		items = [
			...items,
			...allItems
				.filter((item) =>
					item.name
						.toUpperCase()
						.includes(searchValue.trim().toUpperCase())
				)
				.map((item) => ({ ...item, searchTerm: searchValue }))
		]
		items = _uniqBy(items, '_id')
		if (oosOnly) {
			// include items that are out of stock or have disabled modifier values
			items = items.filter(
				(item) =>
					item.status === 5 ||
					(item.modifiers &&
						item.modifiers.find(
							(modifier) =>
								modifier.values &&
								modifier.values.find((value) => value.disabled)
						))
			)
		}
		//init a map of categories with 0 counts to make sure categories with 0 matches stay visible
		const emptyCategories = allItems.reduce((accumulator, value) => {
			return { ...accumulator, [value.location_at_merchant]: 0 }
		}, {})
		const filteredLocations = Object.assign(
			{},
			emptyCategories,
			_countBy(items, 'location_at_merchant')
		)

		// only apply location (category) filtering when the first element (all locations) is not selected
		if (selectedLocationAtMerchant != '') {
			items = items.filter((item) => {
				return item.location_at_merchant === selectedLocationAtMerchant
			})
		}
		const {
			handleOnItemStatusChanged,
			handleModifierValueDisabledChanged,
			handleOnBackInStock
		} = this
		const children = (
			<div className="inventory-content">
				{allItems && Object.keys(allItems).length ? (
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							position: 'relative'
						}}>
						{stickyAssignment && (
							<div className={'breadcrumbs'}>
								<div className={'crumb-container'}>
									{stickyAssignment && (
										<div className={'crumb-assignment'}>
											{stickyAssignment &&
												stickyAssignment.title}{' '}
											<div className={'crumb-arrow'}>
												<SimpleArrow />
											</div>
										</div>
									)}
									{stickyItem && stickyItem.title && (
										<div className={'crumb-item'}>
											{stickyItem.title}
											<div className={'crumb-arrow'}>
												<SimpleArrow />
											</div>
										</div>
									)}
									{stickyModifier && stickyModifier.title && (
										<div className={'crumb-modifier'}>
											{stickyModifier.title}
										</div>
									)}
								</div>
								<div style={{ flex: 1 }}></div>
								<div
									className={'button-back-to-top'}
									onClick={() => {
										if (
											this.listRef &&
											this.listRef.current &&
											this.listRef.current.listRef &&
											this.listRef.current.listRef.current
										) {
											this.listRef.current.listRef.current.scrollToItem(
												0
											)
										}
									}}>
									Back to top <UpArrow />{' '}
								</div>
							</div>
						)}
						<InventoryList
							ref={this.listRef}
							titleForGroupId={(id) => {
								if (
									this.props.serviceassignments &&
									this.props.serviceassignments[id]
								) {
									return this.props.serviceassignments[id]
										.serviceassignment_name
								}
							}}
							scrolledToItem={(
								assignmentId,
								itemId,
								modifierId
							) => {
								if (
									!this.props.serviceassignments ||
									!this.props.items ||
									!assignmentId
								) {
									this.setState({
										stickyItem: null,
										stickyAssignment: null,
										stickyModifier: null
									})
									return
								}
								const assignment = {
									_id: assignmentId,
									title: this.props.serviceassignments[
										assignmentId
									]?.serviceassignment_name
								}

								let foundItem = this.props.items.find(
									(item) => item._id === itemId
								)

								let foundModifier =
									foundItem &&
									foundItem.modifiers &&
									foundItem.modifiers.find(
										(modifier) =>
											modifier._id === modifierId
									)

								const modifier = foundModifier && {
									_id: modifierId,
									title: foundModifier.title
								}

								const item = {
									_id: itemId,
									title: foundItem && foundItem.name
								}
								this.setState({
									stickyAssignment: assignment,
									stickyItem: foundModifier && item,
									stickyModifier: foundModifier && modifier
								})
							}}
							onBackInStock={handleOnBackInStock}
							onItemStatusChanged={handleOnItemStatusChanged}
							onModifierValueDisabledChanged={
								handleModifierValueDisabledChanged
							}
							items={items}
							listUpdate={listUpdate}
							matchingModifiersOnly={matchingModifiersOnly}
							searchTerm={searchValue}
							showExactHitsOnly={true}
							showOosOnly={oosOnly}
							title={searchValue || 'All Items'}
							header={({ categoryHeightChanged }) => {
								return (
									<div className="inventory-fixed">
										<div className="radio-title">
											Kitchen Stock Count
										</div>
										<div className="radio-subtitle">
											Manage the stock of your kitchen
										</div>
										<div className={'inventory-controls'}>
											<div className={'inventory-search'}>
												<InventorySearch
													onChange={
														this.handleSearchChange
													}
													value={searchValue}
													placeholder={
														'Search for items'
													}
												/>
											</div>
											<CollapsibleRadio
												values={filteredLocations}
												selectedValue={
													selectedLocationAtMerchant
												}
												placeholder={'All Items'}
												onHeightChanged={
													categoryHeightChanged
												}
												onValueChange={
													this.handleLocationChanged
												}
											/>
											<label className={'radio-item'}>
												Show out of stock items only
												<input
													type={'checkbox'}
													checked={oosOnly}
													onChange={
														this
															.handleOosFilterChange
													}></input>
											</label>
										</div>
									</div>
								)
							}}
						/>
					</div>
				) : (
					<LoadingIndicator />
				)}
			</div>
		)
		return children
	}
	componentDidMount() {
		this.props.fetchItems(this.props.loadingareaId)
	}
}
Inventory.propTypes = {
	fetchItems: PropTypes.func,
	setItemStatus: PropTypes.func,
	setModifierValueDisabled: PropTypes.func,
	loadingareaId: PropTypes.string,
	items: PropTypes.array,
	categories: PropTypes.object,
	serviceassignments: PropTypes.object,
	setAllToInStock: PropTypes.func
}
const mapStateToProps = (state, props) => {
	return {
		items: _sortBy(
			state.items.ids.map((id) => {
				return state.items.byId[id]
			}),
			[(item) => item.name]
		),
		groups: props.loadingareas,
		categories: state.items.categories
	}
}
const mapDispatchToProps = {
	fetchItems,
	setModifierValueDisabled,
	setItemStatus,
	setAllToInStock
}
export default connect(mapStateToProps, mapDispatchToProps)(Inventory)
