import { getSettingsFromStore } from '../../utils/localStorage'
import { qrCodeSizes, receiptFontSizes } from './receiptFontSizes'
import { PrintSettings } from './types'

export const getPrintSettings = (): PrintSettings => {
	const settings = getSettingsFromStore()
	return {
		fontSize: receiptFontSizes[settings.accessibility.receiptFontScale],
		qrCodeSize: qrCodeSizes[settings.accessibility.receiptFontScale]
	}
}
