import md5 from 'md5'

export const computeCheckCharacter = (contents: string) =>
	Number.parseInt(md5(contents), 16).toString(4).charAt(0)

export const ESC_K_MSB = 64 + 32
export const ESC_K_MIDDLE = 16 + 8
export const ESC_K_LSB = 2 + 4

export const ESC_K_FULL_BYTE = 255
export const ESC_K_SEVEN_TOP_DOTS = 255 - 1
export const ESC_K_SEVEN_BOTTOM_DOTS = 255 - 128

export type DaftLetter = {
	letter: 'D' | 'A' | 'F' | 'T'
	ascends: boolean
	descends: boolean
}

export const DAFT_SPEC = {
	'2': { letter: 'D', ascends: false, descends: true },
	'1': { letter: 'A', ascends: true, descends: false },
	'0': { letter: 'F', ascends: true, descends: true },
	'3': { letter: 'T', ascends: false, descends: false }
}
/**
 * Convert a base10 number to its base4 representation.
 * @param input base10 number to be converted to base4.
 * @returns base4 number as a string.
 */
export const toBase4 = (input: string | number) => {
	const inputNumber = Number.parseInt(`${input}`, 10)
	return inputNumber.toString(4)
}

/**
 * Produce single height ESC-K print command data from a base4 number
 * @param input A base4 number's string representation
 * @param width width of resulting barcode lines in multiples of 6 dots
 * @returns data to be printed via esc-k command
 */
export const base4ToEscK = (input: string, width = 4) => {
	const daftData: DaftLetter[] = input
		.split('')
		.map((char) => DAFT_SPEC[char])
	if (daftData.some((char) => !char)) {
		return null
	}

	const data = daftData
		.flatMap((letter) => {
			const msb = letter.ascends ? ESC_K_MSB : 0
			const lsb = letter.descends ? ESC_K_LSB : 0
			const daftByte = msb + ESC_K_MIDDLE + lsb
			const result: number[] = []
			for (let index = 0; index < width; index += 1) {
				result.push(daftByte)
			}
			for (let index = 0; index < width; index += 1) {
				result.push(0)
			}
			return result
		})
		.slice(0, -1)

	const length = data.length
	const lengthMsb = Math.floor(length / 256)
	const lengthLSb = length - lengthMsb * 256

	return [lengthLSb, lengthMsb, ...data]
}

/**
 * Produce multiple ESC-K print command data arrays from a base4 number.
 * @param input A base4 number's string representation
 * @param commands number of ESC-K commands to use for printing. Currently only works with 3.
 * @param width width of resulting barcode lines in multiples of 6 dots
 * @returns an array of ESC-K command data arrays for printing with multiple ESC-K commands.
 */
export const base4ToEscKArray = (input: string, commands = 3, width = 4) => {
	// Leading zero == full-height bar to indicate start
	// Check-character in the end based on original input checksum
	const checkCharacter = computeCheckCharacter(input)
	const daftData: DaftLetter[] = `0${input}${checkCharacter}`
		.split('')
		.map((char) => DAFT_SPEC[char])
	if (daftData.some((char) => !char)) {
		return null
	}
	const commandArray: number[][] = []
	// Double width first bar
	for (let command = 0; command < commands; command += 1) {
		const data = daftData
			.flatMap((letter, index) => {
				const letterWidth = index === 0 ? width * 2 : width
				const letterByte =
					commands === 6
						? letterToByte6(letter, command)
						: letterToByte(letter, command)
				const result: number[] = []
				for (let index = 0; index < letterWidth; index += 1) {
					result.push(letterByte)
				}
				for (let index = 0; index < width; index += 1) {
					result.push(0)
				}
				return result
			})
			.slice(0, -width)

		const length = data.length
		const lengthMsb = Math.floor(length / 256)
		const lengthLSb = length - lengthMsb * 256

		commandArray.push([lengthLSb, lengthMsb, ...data])
	}
	return commandArray
}

const letterToByte = (letter: DaftLetter, command: number) => {
	switch (command) {
		case 0: {
			return letter.ascends ? ESC_K_FULL_BYTE : 0
		}
		case 1: {
			if (letter.ascends && letter.descends) {
				return ESC_K_FULL_BYTE
			}
			if (letter.ascends) {
				return ESC_K_SEVEN_TOP_DOTS
			}
			if (letter.descends) {
				return ESC_K_FULL_BYTE
			}
			return ESC_K_SEVEN_TOP_DOTS
		}
		case 2: {
			return letter.descends ? ESC_K_SEVEN_TOP_DOTS : 0
		}
		default: {
			return 0
		}
	}
}

const letterToByte6 = (letter: DaftLetter, command: number) => {
	if (command < 2) {
		return letter.ascends ? ESC_K_FULL_BYTE : 0
	}
	if (command < 4) {
		return ESC_K_FULL_BYTE
	}
	return letter.descends ? ESC_K_FULL_BYTE : 0
}
