import { FC, useState } from 'react'

import styles from '../../index.module.scss'
import { Props, TimeOptions } from './types'

export const ChooseTimeContent: FC<Props> = ({
	closeModal,
	onUpdate,
	error
}) => {
	const [timeSelected, setTimeSelected] = useState(10)

	const onTimeSelected = (option: number) => {
		setTimeSelected(option)
	}

	return (
		<>
			<div className={styles.header}>Not accepting orders</div>
			<div className={styles.content}>
				<b>For how long do you want to pause incoming orders?</b>
				<div className={styles.timeSelector}>
					{TimeOptions.map((option) => (
						<div
							key={`time-option-${option}`}
							onClick={() => onTimeSelected(option)}
							className={`${styles.timeOption} ${
								option === timeSelected ? styles.active : ''
							}`}>
							{option} minutes
						</div>
					))}
				</div>
				<b>Setting your kitchen paused will:</b>
				<ul>
					<li>
						Stop receiving incoming orders for {timeSelected}{' '}
						minutes
					</li>
					<li>
						Resume taking in orders after {timeSelected} minutes has
						passed
					</li>
				</ul>
			</div>
			{error && <div className={styles.error}>{error}</div>}
			<div className={styles.footer}>
				<div className={styles.closeButton} onClick={closeModal} />
				<div
					className={styles.updateButton}
					onClick={() => onUpdate(timeSelected)}
				/>
			</div>
		</>
	)
}
