import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Send } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Box,
	CircularProgress,
	Divider,
	RadioGroup,
	Stack
} from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'

import { MerchantServiceRequestType } from '../../../../../@types/MerchantServiceRequest'
import { useAppSelector } from '../../../../../utils/storeHelpers'
import { BotChips } from './BotChips'
import { CommentField, PhoneNumberField, RobotIdField } from './FormFields'
import { RequestTypeInput } from './RequestTypeInput'
import { initialValues } from './constants'
import { FormValues, Props, schema } from './types'
import { usePendingServiceRequests } from './usePendingServiceRequests'
import { useSubmit } from './useSubmit'

export const MerchantServiceRequestForm = ({ onSuccess }: Props) => {
	const serviceAssignmentId = useAppSelector(
		(state) => state.serviceAssignment.selectedServiceAssignmentId
	)

	const { pendingServiceRequests, isLoading: isRequestsLoading } =
		usePendingServiceRequests(serviceAssignmentId ?? '')

	const disabledRequestTypes = useMemo(() => {
		return pendingServiceRequests.map(
			(serviceRequest) => serviceRequest.type
		)
	}, [pendingServiceRequests])

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		register,
		reset,
		watch,
		formState: { isSubmitting, isValid }
	} = useForm<FormValues>({
		values: initialValues as any, // Need to use any type as initial values can be invalid
		resolver: zodResolver(schema),
		mode: 'onTouched'
	})

	const submit = useSubmit(serviceAssignmentId ?? '', onSuccess)

	const handleRobotChipClick = useCallback(
		(botName: string) => {
			const robotId = getValues().robotId
			setValue('robotId', `${robotId}${robotId ? ' ' : ''}${botName}`, {
				shouldTouch: true,
				shouldValidate: true
			})
		},
		[getValues, setValue]
	)

	const selectedType = watch('type')

	useEffect(() => {
		if (selectedType) {
			reset({ ...initialValues, type: selectedType })
		}
	}, [reset, selectedType])

	return (
		<>
			{isRequestsLoading ? (
				<Box display="flex" justifyContent="center" alignItems="center">
					<CircularProgress />
				</Box>
			) : null}

			{isRequestsLoading ? null : (
				<>
					<Divider sx={{ my: 2 }} />

					<Controller
						name="type"
						control={control}
						defaultValue={'' as any}
						render={({ field }) => (
							<RadioGroup
								{...field}
								onChange={(event) =>
									field.onChange(+event.target.value)
								}>
								<RequestTypeInput
									title="Request call back"
									body="Our agents will call you back on a given
									phone number."
									children={
										<>
											<CommentField register={register} />
											<PhoneNumberField
												control={control}
											/>
										</>
									}
									type={MerchantServiceRequestType.CALL_BACK}
									selected={
										field.value ===
										MerchantServiceRequestType.CALL_BACK
									}
									disabled={disabledRequestTypes.includes(
										MerchantServiceRequestType.CALL_BACK
									)}
								/>

								<RequestTypeInput
									title="Reopen service"
									body="In case your service is currently paused or
										closed."
									children={
										<CommentField register={register} />
									}
									type={
										MerchantServiceRequestType.REOPEN_SERVICE
									}
									selected={
										field.value ===
										MerchantServiceRequestType.REOPEN_SERVICE
									}
									disabled={disabledRequestTypes.includes(
										MerchantServiceRequestType.REOPEN_SERVICE
									)}
								/>

								<RequestTypeInput
									title="Open robot"
									body="If a robot doesn’t open like it should."
									children={
										<>
											<CommentField register={register} />
											<RobotIdField
												register={register}
												control={control}
											/>
											<BotChips
												onClick={handleRobotChipClick}
											/>
										</>
									}
									type={MerchantServiceRequestType.ROBOT_OPEN}
									selected={
										field.value ===
										MerchantServiceRequestType.ROBOT_OPEN
									}
									disabled={disabledRequestTypes.includes(
										MerchantServiceRequestType.ROBOT_OPEN
									)}
								/>

								<RequestTypeInput
									title="Send robot away"
									body="If a robot doesn’t drive away like it
										should."
									children={
										<>
											<CommentField register={register} />
											<RobotIdField
												register={register}
												control={control}
											/>
											<BotChips
												onClick={handleRobotChipClick}
											/>
										</>
									}
									type={
										MerchantServiceRequestType.ROBOT_SEND_AWAY
									}
									selected={
										field.value ===
										MerchantServiceRequestType.ROBOT_SEND_AWAY
									}
									disabled={disabledRequestTypes.includes(
										MerchantServiceRequestType.ROBOT_SEND_AWAY
									)}
								/>
							</RadioGroup>
						)}
					/>
				</>
			)}

			<Stack
				direction="row"
				justifyContent="flex-end"
				sx={{
					position: 'absolute',
					right: 18,
					bottom: 18,
					zIndex: 100,
					borderRadius: 0
				}}>
				<LoadingButton
					variant="contained"
					onClick={handleSubmit(submit)}
					loading={isSubmitting}
					disabled={isRequestsLoading || !isValid}
					sx={{ boxShadow: 10 }}
					startIcon={<Send />}>
					Send
				</LoadingButton>
			</Stack>
		</>
	)
}
