import dayjs from 'dayjs'

import {
	ParsedWorkingHour,
	VenueOperating,
	VenueOperatingStatus
} from '../../@types/WorkingHours'

export const getVenueOperatingStatus = (options: {
	hours: ParsedWorkingHour[]
	now: dayjs.Dayjs
}): VenueOperating => {
	const { hours, now } = options

	const workingPeriod = hours.find((wh) => {
		// Inclusive start, exclusive end (eg. a 8:00-16:00 venue is open at 8:00, but closed at 16:00)
		return now.isBetween(wh.dateStart, wh.dateEnd, undefined, '[)')
	})

	if (!workingPeriod) {
		return {
			status: VenueOperatingStatus.CLOSED
		}
	}

	return {
		status: VenueOperatingStatus.OPEN,
		closesAt: workingPeriod.dateEnd.format('LT')
	}
}
