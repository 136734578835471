import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { setSelectedStationIdToStore } from '../../utils/localStorage'

// Regardless of what sets the station to the url, keep the store up to date
// The url param is the truth for station id and loading area id, so we can trust that
export const useSaveStationToStore = () => {
	const params = useParams()

	const loadingStationId = params.loadingstationId

	useEffect(() => {
		if (loadingStationId) {
			setSelectedStationIdToStore(loadingStationId)
		}
	}, [loadingStationId])
}
