import { FC } from 'react'

import { OrderSideButtonProps } from './types'

export const OrderSideButton: FC<OrderSideButtonProps> = ({ children }) => {
	return (
		<div className="flex w-20" data-testid="ActionOrderSideButton">
			{children}
		</div>
	)
}
