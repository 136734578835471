import ky from 'ky'

import { container } from '../container'
import { pushError, pushEvent } from '../statistics'
import { BASE_URL_MERCHANT_API } from './constants'

export const fetchServer = (() => {
	let outdatedAt: number | undefined = undefined

	// Copy-paste of our old fetchServer logic to get types
	return async <T>(
		relativeUrl: string,
		method = 'GET',
		body: object | undefined = undefined,
		baseUrl?: string
	) => {
		const deviceExternalToken = container.getDeviceToken(true)

		const url = new URL(
			`${baseUrl ?? BASE_URL_MERCHANT_API}${relativeUrl}`,
			window.location.href
		).href

		const options = {
			method,
			timeout: false as const,
			headers: {
				'x-csrf-token': window.CSRF_TOKEN
			},
			json: body || undefined
		}

		if (deviceExternalToken) {
			options.headers['device-external-token'] = deviceExternalToken
		}

		const timestamp = Date.now()

		let response: Response

		try {
			response = await ky(url, options)
		} catch (error: any) {
			if (error.response) {
				pushEvent(url, method, Date.now() - timestamp, error.response)
				error.message = `${method} ${url} failed, HTTP status: ${error.response.status}`
			} else {
				pushError(url, method, error)
				error.message = `${method} ${url} failed, ${error.name}`
			}

			throw error
		}

		// if we get there, then response.ok is true
		pushEvent(url, method, Date.now() - timestamp, response)

		const currentVersion = Number.parseInt(window.BUILD_NUMBER)
		const serverVersion = Number.parseInt(
			response.headers.get('x-version') ?? ''
		)
		const isOutdated =
			currentVersion && serverVersion && currentVersion !== serverVersion
		outdatedAt = isOutdated ? outdatedAt || Date.now() : undefined

		if (outdatedAt && outdatedAt < Date.now() - 300000) {
			window.location.reload()
		}

		try {
			// response.json() fails on empty response
			if (Number(response.headers.get('content-length')) === 0) {
				// undefined typed as T as the caller should know they are not getting a response
				return undefined as T
			}
			return response.json() as Promise<T>
		} catch {
			throw new Error(
				`Unable to parse response (status: ${response.status})`
			)
		}
	}
})()
