import { useEffect, useMemo, useState } from 'react'

import { useGetTestOrders } from '../../components/TestOrderModal/useGetTestOrders'
import { shouldHideTestOrderMenu } from '../venuesConfiguration'
import { VenueConfiguration } from '../venuesConfiguration/types'

export const useTestOrder = (
	venueConfiguration: VenueConfiguration | null,
	serviceAssignmentId: string,
	loadingStationId: string
) => {
	const [showTestOrderModal, setShowTestOrderModal] = useState(false)
	const [isOngoingTestOrder, setIsOngoingTestOrder] = useState<
		boolean | undefined
	>(undefined)
	const { refetch, isFetched, data } = useGetTestOrders(
		serviceAssignmentId,
		loadingStationId,
		false
	)
	const isTestOrderEnabled = useMemo(
		() =>
			venueConfiguration && !shouldHideTestOrderMenu(venueConfiguration),
		[venueConfiguration]
	)
	const isLoaded = useMemo(
		() => isOngoingTestOrder !== undefined,
		[isOngoingTestOrder]
	)

	useEffect(() => {
		if (isTestOrderEnabled) {
			refetch().then()
		}
	}, [isTestOrderEnabled, refetch])

	useEffect(() => {
		if (isTestOrderEnabled && isFetched) {
			setIsOngoingTestOrder(data?.orders?.length > 0)
		}
	}, [data?.orders?.length, isFetched, isTestOrderEnabled])

	return {
		isTestOrderEnabled,
		showTestOrderModal,
		setShowTestOrderModal,
		isOngoingTestOrder,
		isLoaded
	}
}
