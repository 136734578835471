import { FC } from 'react'

import classNames from 'classnames'

import { OrderContentLeftProps } from './types'

export const OrderContentLeft: FC<OrderContentLeftProps> = ({
	children,
	className
}) => {
	const classes = classNames('min-w-[5.5rem]', className)

	return <div className={classes}>{children}</div>
}
